<template>
    <div style="padding-bottom: 12px;">
        <GhSubTab v-bind:items="$router.options.GhSubTabsConfiguration" :dataBlock="{id: 'config_tab_', calldispatch: 'getConfigTabActive'}"/>
        <component v-bind:is="TabConfig.subTabActive" />
    </div>
</template>
<script>
    import GhSubTab from 'fe-gh-subtab-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_config",
        components:{
            GhSubTab,
        },
        computed:{
            ...mapState(['TabConfig']),
        }
    }
</script>