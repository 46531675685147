import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
import router from "@/routes/router";
import Global from "@/store/Global";
import i18n from "@/plugins/i18n";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        to_remove_key_table: 0,
        to_remove_key_table_condition: 0,
        close_window: 0,
        data: {},
        records: {
            added: [],
            deleted: []
        },
        table_subquestions: {},
        table_subquestions_condition: {},
        type_exercise: {
            open_question: {
                type: '',
                responsible: {view: false, required: false},
                evidence: {view: false, required: false},
                feedback: {view: false, required: false},
                subquestion: {view: false},
                metric: {view: false, required: false},
                procedure: {view: false, required: false},
                example: {view: false, required: false},
                videography: {view: false, required: false},
                bibliography: {view: false, required: false},
                support_material: {view: false, required: false}
            },
            close_numeric: {
                type: '',
                responsible: {view: false, required: false},
                conditions: {},
                feedback: {view: false, required: false},
                subquestion: {view: false},
                metric: {view: false, required: false},
                procedure: {view: false, required: false},
                example: {view: false, required: false},
                videography: {view: false, required: false},
                bibliography: {view: false, required: false},
                support_material: {view: false, required: false}
            },
            close_one_correct: {
                type: '',
                responsible: {view: false, required: false},
                possible_answers: {answers: []},
                subquestion: {view: false},
                feedback: {view: false, required: false},
                metric: {view: false, required: false},
                procedure: {view: false, required: false},
                example: {view: false, required: false},
                videography: {view: false, required: false},
                bibliography: {view: false, required: false},
                support_material: {view: false, required: false}
            },
            close_n_correct: {
                type: '',
                responsible: {view: false, required: false},
                subquestion: {view: false},
                possible_answers: {answers: [], correct: ''},
                feedback: {view: false, required: false},
                metric: {view: false, required: false},
                procedure: {view: false, required: false},
                example: {view: false, required: false},
                videography: {view: false, required: false},
                bibliography: {view: false, required: false},
                support_material: {view: false, required: false}
            }
        }
    },
    mutations: {
        loadExercises(state, payload) {
            state.records_data = payload;
        },
        loadExerciseData(state, payload) {
            state.data = payload;
        },
        loadExerciseDataChange(state, payload) {
            if(payload.params.type == 'general'){
                state.data[payload.params.where] = payload.params.value;
            }else if(payload.params.type == 'open_question' || payload.params.type == 'subquestion_open_question' || payload.params.type == 'close_numeric' || payload.params.type == 'subquestion_close_numeric' || payload.params.type == 'close_n_correct' || payload.params.type == 'subquestion_close_n_correct'){
                if(payload.params.extra_where !== undefined){
                    if(payload.params.key !== undefined){
                        state.data[payload.params.type][payload.params.where][payload.params.key][payload.params.extra_where] = payload.params.value;
                    }else{
                        state.data[payload.params.type][payload.params.where][payload.params.extra_where] = payload.params.value;
                    }
                }else {
                    state.data[payload.params.type][payload.params.where] = payload.params.value;
                }
            }
        },
        loadRefreshDatas(state){
            state.type_exercise.open_question = {
                type: '',
                responsible: {view: false,required: false},
                evidence: {view: false,required: false},
                feedback: {view: false,required: false},
                subquestion: {view: false},
                metric: {view: false,required: false},
                procedure: {view: false,required: false},
                example: {view: false,required: false},
                videography: {view: false,required: false},
                bibliography: {view: false,required: false},
                support_material: {view: false,required: false}
            };
            state.type_exercise.close_numeric = {
                type: '',
                responsible: {view: false,required: false},
                conditions: {},
                feedback: {view: false,required: false},
                subquestion: {view: false},
                metric: {view: false,required: false},
                procedure: {view: false,required: false},
                example: {view: false,required: false},
                videography: {view: false,required: false},
                bibliography: {view: false,required: false},
                support_material: {view: false,required: false}
            };
            state.type_exercise.close_one_correct = {
                type: '',
                responsible: {view: false,required: false},
                possible_answers: {answers: []},
                subquestion: {view: false},
                feedback: {view: false,required: false},
                metric: {view: false,required: false},
                procedure: {view: false,required: false},
                example: {view: false,required: false},
                videography: {view: false,required: false},
                bibliography: {view: false,required: false},
                support_material: {view: false,required: false}
            };
            state.type_exercise.close_n_correct = {
                type: '',
                responsible: {view: false,required: false},
                subquestion: {view: false},
                possible_answers: {answers: [], correct: ''},
                feedback: {view: false,required: false},
                metric: {view: false,required: false},
                procedure: {view: false,required: false},
                example: {view: false,required: false},
                videography: {view: false,required: false},
                bibliography: {view: false,required: false},
                support_material: {view: false,required: false}
            };
        },
        loadExerciseType(state, payload){
            if(payload.type == 1){
                state.type_exercise.open_question = payload;
                state.type_exercise.close_numeric = {
                    type: '',
                    responsible: {view: false,required: false},
                    conditions: {},
                    feedback: {view: false,required: false},
                    subquestion: {view: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
                state.type_exercise.close_one_correct = {
                    type: '',
                    responsible: {view: false,required: false},
                    possible_answers: {answers: []},
                    subquestion: {view: false},
                    feedback: {view: false,required: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
                state.type_exercise.close_n_correct = {
                    type: '',
                    responsible: {view: false,required: false},
                    possible_answers: {answers: [], correct: ''},
                    subquestion: {view: false},
                    feedback: {view: false,required: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
            }else if(payload.type == 3){
                state.type_exercise.close_numeric = payload;
                state.type_exercise.open_question = {
                    type: '',
                    responsible: {view: false,required: false},
                    evidence: {view: false,required: false},
                    feedback: {view: false,required: false},
                    subquestion: {view: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
                state.type_exercise.close_one_correct = {
                    type: '',
                    responsible: {view: false,required: false},
                    possible_answers: {answers: []},
                    subquestion: {view: false},
                    feedback: {view: false,required: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
                state.type_exercise.close_n_correct = {
                    type: '',
                    responsible: {view: false,required: false},
                    possible_answers: {answers: [], correct: ''},
                    subquestion: {view: false},
                    feedback: {view: false,required: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
            }else if(payload.type == 4){
                state.type_exercise.close_one_correct = payload;
                state.type_exercise.open_question = {
                    type: '',
                    responsible: {view: false,required: false},
                    evidence: {view: false,required: false},
                    feedback: {view: false,required: false},
                    subquestion: {view: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
                state.type_exercise.close_numeric = {
                    type: '',
                    responsible: {view: false,required: false},
                    conditions: {},
                    feedback: {view: false,required: false},
                    subquestion: {view: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
                state.type_exercise.close_n_correct = {
                    type: '',
                    responsible: {view: false,required: false},
                    possible_answers: {answers: [], correct: ''},
                    subquestion: {view: false},
                    feedback: {view: false,required: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
            }else if(payload.type == 5){
                state.type_exercise.close_n_correct = payload;
                state.type_exercise.open_question = {
                    type: '',
                    responsible: {view: false,required: false},
                    evidence: {view: false,required: false},
                    feedback: {view: false,required: false},
                    subquestion: {view: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
                state.type_exercise.close_numeric = {
                    type: '',
                    responsible: {view: false,required: false},
                    conditions: {},
                    feedback: {view: false,required: false},
                    subquestion: {view: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
                state.type_exercise.close_one_correct = {
                    type: '',
                    responsible: {view: false,required: false},
                    possible_answers: {answers: []},
                    feedback: {view: false,required: false},
                    metric: {view: false,required: false},
                    procedure: {view: false,required: false},
                    example: {view: false,required: false},
                    videography: {view: false,required: false},
                    bibliography: {view: false,required: false},
                    support_material: {view: false,required: false}
                };
            }
        },
        loadSubQuestionExerciseType(state, payload){
            if(payload.type == 1){
                state.type_exercise.open_question = payload;
                state.type_exercise.open_question.active_sub_question = true;
                state.type_exercise.close_numeric.active_sub_question = false;
                state.type_exercise.close_one_correct.active_sub_question = false;
                state.type_exercise.close_n_correct.active_sub_question = false;
            }else if(payload.type == 3){
                state.type_exercise.close_numeric = payload;
                state.type_exercise.close_numeric.active_sub_question = true;
                state.type_exercise.open_question.active_sub_question = false;
                state.type_exercise.close_one_correct.active_sub_question = false;
                state.type_exercise.close_n_correct.active_sub_question = false;
            }else if(payload.type == 4){
                state.type_exercise.close_one_correct = payload;
                state.type_exercise.close_one_correct.active_sub_question = true;
                state.type_exercise.open_question.active_sub_question = false;
                state.type_exercise.close_numeric.active_sub_question = false;
                state.type_exercise.close_n_correct.active_sub_question = false;
            }else if(payload.type == 5){
                state.type_exercise.close_n_correct = payload;
                state.type_exercise.close_n_correct.active_sub_question = true;
                state.type_exercise.open_question.active_sub_question = false;
                state.type_exercise.close_numeric.active_sub_question = false;
                state.type_exercise.close_one_correct.active_sub_question = false;
            }
        },
        loadExercisesLikeSubquestion(state, payload){
            if(state.table_subquestions[payload.level] === undefined){
                state.table_subquestions[payload.level] = [];
            }
            if(state.table_subquestions[payload.level][payload.state] === undefined){
                state.table_subquestions[payload.level][payload.state] = [];
            }

            if(state.state == 'new'){
                state.table_subquestions[payload.level][payload.state].push(payload.data);
            }else {
                state.table_subquestions[payload.level][payload.state] = payload.data;
            }
        },
        loadExercisesLikeSubquestionCondition(state, payload){
            console.log(payload);
            if(state.table_subquestions_condition[payload.level] === undefined){
                state.table_subquestions_condition[payload.level] = [];
            }

            var condition = toString(payload.condition).split('-')[1] !== undefined ? toString(payload.condition).split('-')[1] : payload.condition;
            if(state.table_subquestions_condition[payload.level][condition] === undefined){
                state.table_subquestions_condition[payload.level][condition] = [];
            }

            if(state.table_subquestions_condition[payload.level][condition][payload.state] === undefined){
                state.table_subquestions_condition[payload.level][condition][payload.state] = [];
            }
            state.table_subquestions_condition[payload.level][condition][payload.state] = payload.data;
        },
        loadAddTemporalMetrics(state, payload){
            state.data.metrics.push(payload);
            state.records.added.push(payload);
        },
        loadCleanMetrics(state){
            state.records.added = [];
            state.records.deleted = [];
        }
    },
    actions: {
        async getExercisesLikeSubquestion(state, params){
            let requestLogin = API_URL + "exercise/subquestion/" + params.state;
            var extra = "";
            var exercises = params.state == 'new' ? Object.values(params.exercises) : params.exercises;
            for(var i=0;i<exercises.length;i++){
                extra += (i == 0 ? '?' : '&')+"id[]="+exercises[i];
            }
            requestLogin += extra;

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await state.commit('loadExercisesLikeSubquestion', {data: response.data.data,level: params.level,state: params.state});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getExercisesLikeSubquestionCondition(state, params){
            let requestLogin = API_URL + "exercise/subquestion/" + params.state + "/" + params.level + "/" + params.condition;
            var extra = "";
            var exercises = params.state == 'new' ? Object.values(params.exercises) : params.exercises;
            for(var i=0;i<exercises.length;i++){
                extra += (i == 0 ? '?' : '&')+"id[]="+exercises[i];
            }
            requestLogin += extra;

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await state.commit('loadExercisesLikeSubquestionCondition', {data: response.data.data,level: params.level,state: params.state,condition: params.condition});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        removeSubquestion(state, params){
            state.state.to_remove_key_table = params.id;
        },
        removeSubquestionNew(state, params){
            state.state.to_remove_key_table = params.id;
        },
        removeSubquestionCondition(state, params){
            state.state.to_remove_key_table_condition = params.id;
        },
        removeSubquestionNewCondition(state, params){
            state.state.to_remove_key_table_condition = params.id;
        },
        setTemporalExercise(state, params){
            state.commit('loadExerciseDataChange', params);
        },
        async getExercises(state){
            await axios.get(
                API_URL + "exercises"
            ).then(
                response => {
                    state.commit('loadExercises', response.data.data);
                    state.commit('loadRefreshDatas');
                }
            )
        },
        async removeExercise(state, item){
            let requestLogin = API_URL + "exercise/delete/" + item.id;
            let formData = new FormData();

            formData.append('exercise[id]', item.id);
            formData.append('exercise[type]', item.type);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getExercises');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getExerciseData(state, items){
            state.commit("loadCleanMetrics");
            if(items.id === undefined){
                state.commit("loadExerciseData",
                    {
                        code: '',
                        open_question: {
                            responsible: '',
                            statement: '',
                            feedback: ''
                        },
                        subquestion_open_question: {
                            responsible: '',
                            statement: '',
                            feedback: ''
                        },
                        close_numeric: {
                            responsible: '',
                            statement: '',
                            value: {
                                min: '',
                                max: ''
                            },
                            condition: {
                                1: {
                                    higher_than: '',
                                    feedback: ''
                                },
                                2: {
                                    minor_than: '',
                                    feedback: ''
                                },
                                3: {
                                    equal: '',
                                    feedback: ''
                                },
                                4: {
                                    1: '',
                                    2: '',
                                    feedback: ''
                                }
                            },
                            puntuation: ''
                        },
                        subquestion_close_numeric: {
                            responsible: '',
                            statement: '',
                            value: {
                                min: '',
                                max: '',
                            },
                            condition: {
                                1: {
                                    higher_than: '',
                                    feedback: ''
                                },
                                2: {
                                    minor_than: '',
                                    feedback: ''
                                },
                                3: {
                                    equal: '',
                                    feedback: ''
                                },
                                4: {
                                    1: '',
                                    2: '',
                                    feedback: ''
                                }
                            },
                            puntuation: ''
                        },
                        metrics: []
                    }
                );
            }else {
                let requestLogin = API_URL + "exercise/" + items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadExerciseData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async getTypeExercise(state, item){
            let request = API_URL + 'type-exercises/'+item.id;
            let formData = new FormData();

            formData.append('id', item.id);

            await axios({
                method: "get",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(item.subquestion){
                        state.commit("loadSubQuestionExerciseType", response.data.data);
                    }else{
                        state.commit("loadExerciseType", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            );
        },
        async setTemporalMetrics(state, item){
            var formData = {
                'id': state.state.data != '' ? 'T' + state.state.data.metrics.length + 1 : 'T' + 1,
                'name': item.formData[0][1],
                'description': item.formData[1][1],
            }

            state.commit("loadAddTemporalMetrics", formData);
        },
        async removeTemporalMetrics(state,items){
            var index = state.state.records.added.findIndex(metrics => metrics.id == items.id);

            if(index != '-1'){
                state.state.records.added.splice(index, 1);
            }else{
                var params = {
                    'id': items.id
                };
                state.state.records.deleted.push(params);
            }

            var indexTable = state.state.data.metrics.findIndex(data => data.id == items.id);
            state.state.data.metrics.splice(indexTable, 1);
        },
        async setProcedureDataFromExercise(state, item){
            let requestLogin = API_URL + 'procedure';
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            return await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data){
                        await this.dispatch('getFeedProcedures', {returned: true});
                        return response.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setExampleDataFromExercise(state, item){
            let requestLogin = API_URL + 'example';
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            return await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data){
                        await this.dispatch('getFeedExamples', {returned: true});
                        return response.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        // async setSectorData(state, item){
        //     let requestLogin = item.id ? API_URL + "sector/"+item.id : API_URL + "sector";
        //     let formData = new FormData();
        //
        //     formData.append('sector[id]', item.id);
        //     item.formData.forEach((item) => {
        //         formData.append(item[0], item[1]);
        //     });
        //
        //     axios({
        //         method: "post",
        //         url: requestLogin,
        //         data: formData,
        //         headers: { "Content-Type" : "multipart/form-data"}
        //     }).then(
        //         response => {
        //             state.commit("loadSectorsData", response.data);
        //             router.push({name: 'Configuración'});
        //         },
        //         error => {
        //             console.log(error);
        //         }
        //     )
        // },
        async removeSector(state, item){
            let requestLogin = API_URL + "sector/"+item.id+"/state";
            let formData = new FormData();

            formData.append('sector[id]', item.id);
            formData.append('sector[type]', item.type);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getSectors');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setExerciseData(state,item){
            var level = item.level !== undefined ? "/L-" + item.level : "";
            let requestCompaniesGroup =  item.id ? API_URL + "exercise" + level + "/" + item.id : API_URL + "exercise" + level;
            let formData = new FormData();
            formData.append('exercise[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            for (let i = 0; i < state.state.records.added.length; i++) {
                formData.append('exercise[metric][add]['+i+'][name]', state.state.records.added[i].name);
                formData.append('exercise[metric][add]['+i+'][description]', state.state.records.added[i].description);
            }

            for (let i = 0; i < state.state.records.deleted.length; i++) {
                formData.append('exercise[metric][delete]['+i+']', state.state.records.deleted[i].id);
            }

            await axios({
                method: "post",
                url: requestCompaniesGroup,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    if(level != ''){
                        var params = {
                            exercises: {0:response.data.data.id},
                            state: 'new',
                            level: (parseInt(item.level)-1),
                            condition: item.condition,
                        };

                        if(item.condition == 'n') {
                            await this.dispatch('getExercisesLikeSubquestion', params);
                        }else {
                            await this.dispatch('getExercisesLikeSubquestionCondition',params);
                        }
                        console.log(Global);
                        // Global.windowClose();
                    }else {
                        router.push({name: i18n.t('configuration')});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
