var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('GhNoDropSelector',{attrs:{"datablock":{id:'activity',title: _vm.$t('activity'),second_title:'' }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_activity"}},[_c('input',{attrs:{"type":"hidden","name":"activity[id]"},domProps:{"value":_vm.$root._route.params.id}}),_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('code') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'activity_code',
                                    name: 'activity[code]',
                                    style: 'width: 200px',
                                    value: _vm.code,
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                    text: _vm.$t('name') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                },"datainput":{
                                    required: true,
                                    id: 'activity_name',
                                    name: 'activity[name]',
                                    style: 'min-width: 200px; width: 20%',
                                    value: _vm.name,
                                    type: 'text',
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('description') + '*',
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                    class: 'T15_b',
                                },"datainput":{
                                    id: 'activity_description',
                                    name: 'activity[description]',
                                    value: _vm.description,
                                    required: true,
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.description))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhMultipleSearch',{attrs:{"datalabel":{
                                    text: _vm.$t('responsible'),
                                    style: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                    class: 'T15_b',
                                    id: 'label_exercise_responsible'
                                },"datainput":{
                                    required: false,
                                    id: 'activity_responsible_user',
                                    name: 'activity[responsible][name]',
                                    nameS:'activity[responsible][surname]',
                                    nameId:'activity[responsible][id]',
                                    style: 'width: 20.5%; min-width: 200px;',
                                    value: _vm.responsible_name,
                                    valueId: _vm.responsible_id,
                                    valueS: _vm.responsible_surname,
                                    placeholder: _vm.$t('name'),
                                    placeholderS: _vm.$t('surnames'),
                                    result_1: 'first_name',
                                    result_2: 'last_name',
                                    result_id: 'id',
                                    href: _vm.dispatchUserResponsible
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('responsible') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.responsible_name + ' ' + _vm.responsible_surname))])]],2),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c(_vm.excercise,{tag:"component"})],1),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'margin-bottom: -5px')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{key:_vm.refreshSelectors,attrs:{"datablock":{
                                    id: 'label_activity_sector',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_sector') + ':',
                                    text: _vm.$t('sector'),
                                    label: _vm.$t('sector'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: 'margin-left:102px;',
                                    required: false,
                                },"datainput":{
                                    id: 'value_activity_sector',
                                    name: 'activity[sector][]'
                                },"selected_input":_vm.selected_sector,"disabled_input":_vm.preSelecteds.pre_selected_sector,"feed":_vm.getFeeds.feed_sector}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('sector') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.selected_sector).join()))])]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'margin-bottom: -5px')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{key:_vm.refreshSelectors,attrs:{"datablock":{
                                    id: 'label_activity_bibliography',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_bibliography') + ':',
                                    text: _vm.$t('bibliography'),
                                    label: _vm.$t('bibliography'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: 'margin-left:71px;',
                                    required: false,
                                },"datainput":{
                                    id: 'value_activity_bibliography',
                                    name: 'activity[bibliography][]'
                                },"selected_input":_vm.selected_bibliography,"disabled_input":_vm.preSelecteds.pre_selected_bibliography,"feed":_vm.getFeeds.feed_bibliography}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('bibliography') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.selected_bibliography).join()))])]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'margin-bottom: -5px')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{key:_vm.refreshSelectors,attrs:{"datablock":{
                                    id: 'label_activity_videography',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_videography') + ':',
                                    text: _vm.$t('videography'),
                                    label: _vm.$t('videography'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: 'margin-left:71px;',
                                    required: false,
                                },"datainput":{
                                    id: 'value_activity_videography',
                                    name: 'activity[videography][]'
                                },"selected_input":_vm.selected_videography,"disabled_input":_vm.preSelecteds.pre_selected_videography,"feed":_vm.getFeeds.feed_videography}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('videography') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.selected_videography).join()))])]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'margin-bottom: -5px')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{key:_vm.refreshSelectors,attrs:{"datablock":{
                                    id: 'label_activity_example',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_example') + ':',
                                    text: _vm.$t('examples'),
                                    label: _vm.$t('examples'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: 'margin-left:88px;',
                                    required: false,
                                },"datainput":{
                                    id: 'value_activity_example',
                                    name: 'activity[example][]'
                                },"selected_input":_vm.selected_example,"disabled_input":_vm.preSelecteds.pre_selected_example,"feed":_vm.getFeeds.feed_examples}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('examples') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.selected_example).join()))])]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'margin-bottom: -5px')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{key:_vm.refreshSelectors,attrs:{"datablock":{
                                    id: 'label_activity_support_material',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_support_material_2') + ':',
                                    text: _vm.$t('support_material_2'),
                                    label: _vm.$t('support_material_2'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: 'margin-left:19px;',
                                    required: false,
                                },"datainput":{
                                    id: 'value_activity_support_material',
                                    name: 'activity[support_material][]'
                                },"selected_input":_vm.selected_support_material,"disabled_input":_vm.preSelecteds.pre_selected_support_material,"feed":_vm.getFeeds.feed_support_material}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('support_material_2') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.selected_support_material).join()))])]],2)]),_c(_vm.reference_thesauros,{tag:"component",attrs:{"props":{selecteds:_vm.referenceSelecteds,name:'activity'}}})],1)]},proxy:true}],null,false,39581497)}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"20px"}},[(!this.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('accept'),
            }},on:{"click":function($event){return _vm.postActivity();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.back();}}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }