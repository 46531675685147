import ConfigBibliography from "@/view/config/general/bibliography/template/bibliography";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/general/bibliography',
        name: 'config_bibliography',
        component: ConfigBibliography,
        props: {id: null, showMenu: true, otherTitle: i18n.t('bibliography')},
        children: [
            {
                path: '/config/general/bibliography/:id',
                name: 'config_bibliography_id',
                component: ConfigBibliography,
                props: {id: null, showMenu: true, otherTitle: i18n.t('bibliography')},
            },
            {
                path: '/config/general/bibliography/:id/:view',
                name: 'config_bibliography_id_view',
                component: ConfigBibliography,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('bibliography')},
            }
        ]
    }
];

export default routes;