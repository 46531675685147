<template>
    <div>
        <GhTableCommon v-if="haveData"
            :extratable="{
                id: 'exercises',
            }"
            :header="header"
            :data="ConfigExercisesTable.records_data.length == 0 ? [] : ConfigExercisesTable.records_data"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'config_exercise_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'config_exercise_id', params: {id: itemProp.id}}">
                    <GhAction
                        :dataction="{
                            id: 'edit_config_exercises',
                            text: $t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        }"
                    />
                </router-link>
                <GhAction
                    :dataction="{
                        id: 'delete_config_exercises',
                        text: $t('delete'),
                        icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                    }"
                    @click="Global.deleteConfirm('delete_confirm','Exercise', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, $t('delete_exercise'), $t('preparing_delete_exercise'), 'delete')"
                />
            </template>
            <template v-slot:footer>
                <div class="centered">
                    <router-link :to="{name: 'config_exercise'}">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_config_exercises',
                                text: $t('add')
                            }"
                        />
                    </router-link>
                </div>
            </template>
        </GhTableCommon>
        <template v-else-if="!haveData">
            <img :src="require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
        </template>
    </div>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_exercises",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['ConfigExercisesTable'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: this.$t('code'), field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; width: 100px;'},
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 200px; width: 20%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 200px; width: 80%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getExercises', '', {root: true});
            this.haveData = true;
        }
    }
</script>