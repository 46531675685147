<template>
    <div style="padding-bottom: 12px;">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'support'+label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: ConfigSupport.counters[label['result']],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib'
    import list_assignment from "@/view/config/support/assignment/list_assignment";
    import list_categories from "@/view/config/support/categories/list_categories";
    import list_holidays from "@/view/config/support/holidays/list_holidays";
    import list_schedule from "@/view/config/support/schedule/list_schedule";
    import list_priorities from "@/view/config/support/priorities/list_priorities";
    import list_flows from "@/view/config/support/flow/list_flows";
    import {mapState} from "vuex";
    export default {
        name: "list_config_support",
        components:{
            GhDropSelector
        },
        computed:{
            ...mapState(['ConfigSupport','Login'])
        },
        data(){
            return{
                data: [
                    {id: 1, name: 'category_configuration', component: list_categories, result: 'categories', show: false, second_text: 'results'},
                    {id: 2, name: 'configuration_priorities', component: list_priorities, result: 'priorities', show: false, second_text: 'results'},
                    {id: 3, name: 'configuration_assignment', component:list_assignment, result:'groups', show: false, second_text: 'results'},
                    {id: 4, name: 'schedule_configuration', component: list_schedule, result:'schedules', show: false, second_text: 'results'},
                    {id: 5, name: 'holiday_configuration', component: list_holidays,  result:'holidays', show: false, second_text: 'results'},
                    {id: 6, name: 'flow_configuration', component: list_flows,  result:'workflow-tasks', show: false, second_text: 'results'}
                ],
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getConfigSupportCounters','',{root:true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('configuration')){
                    that.Login.tabs[route.id]['sub-modules'][3]['sub-modules'].forEach((subroute,subkey) => {
                        that.data[subkey].show = subroute.have_permission;
                    });
                }
            });
        }
    }
</script>
