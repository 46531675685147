<template>
    <div v-if="haveData">
        <div>
            <GhDataSelector ref="activities" :key="refreshActivity"
                :datablock="{
                    id: 'label_ambit_activity',
                    class: 'T15_b',
                    subtitle: $t('select_activity') + ':',
                    text: $t('activity'),
                    label: $t('activity'),
                    type: 'checkbox',
                    columns: 3,
                    style: 'margin-left: 93px;display: none;',
                    required: false,
                }"
                :datainput="{
                    id: 'value_ambit_activity',
                    name: 'ambit[activity][]'
                }"
                :selected_input="selecteds"
                :feed="getFeeds.feed_selector_activities"
                @accept="axiosActivity()"
            />
        </div>
        <div>
            <GhDropDownField
                :dataBlock="{
                    id: 'drop_activities',
                    style: 'width: 100%; margin-right: 0px;',
                    title: $t('activity'),
                    subTitle: CreationAmbit.list_activity.length + ' ' + $t('results'),
                    isDrop: true
                }"
            >
                <template v-slot:content>
                    <GhTableCommon
                        :extratable="{
                            id: 'activities_table',
                        }"
                        :header="header"
                        :data="CreationAmbit.list_activity.length === 0 ? [] : CreationAmbit.list_activity"
                    >
                        <template v-slot:actions="{itemProp}" v-if="!$parent.$parent.$root._route.params.view">
                            <GhAction
                                :dataction="{
                                    id: 'edit_config_activities',
                                    text: $t('delete'),
                                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                                }"
                                @click="deleteActivity(itemProp);"
                            />
                        </template>
                        <template v-slot:footer>
                            <div class="centered" v-if="!$parent.$parent.$root._route.params.view">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_add_activity',
                                        text: $t('add')
                                    }"
                                    @click="addActivity();"
                                />
                            </div>
                        </template>
                    </GhTableCommon>
                </template>
            </GhDropDownField>
        </div>
    </div>
</template>

<script>
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapState} from "vuex";
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: "activity_ambit",
        components:{
            GhDataSelector,
            GhDropDownField,
            GhTableCommon,
            GhButton,
            GhAction,
        },
        computed: {
            ...mapState(['getFeeds','CreationAmbit','preSelecteds'])
        },
        data(){
            return{
                haveData: false,
                to_update: true,
                selecteds: [],
                refreshActivity: 0,
                header: [
                    {text: this.$t('code'), field: 'code', sorting: true, typeSort: 'code', style: 'max-width: 80px; width: 5%;'},
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px;width: 15%;'},
                    {text: this.$t('sector'), field: 'sector', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('bibliography'), field: 'bibliography', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('videography'), field: 'videography', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('examples'), field: 'examples', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('support_material_2'), field: 'support_material', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getSelectorActivities','',{root:true});

            if(this.$parent.$parent.$root._route.params.id){
                this.selecteds = this.CreationAmbit.data_ambit.activity;
            }

            if(this.$parent.$parent.$root._route.params.view){
                this.header = [
                    {text: this.$t('code'), field: 'code', sorting: true, typeSort: 'code', style: 'max-width: 80px; width: 5%;'},
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px;width: 15%;'},
                    {text: this.$t('sector'), field: 'sector', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('bibliography'), field: 'bibliography', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('videography'), field: 'videography', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('examples'), field: 'examples', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('support_material_2'), field: 'support_material', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'}
                ];
            }

            this.haveData = true;
        },
        updated(){
            if(this.$parent.$parent.$root._route.params.id && this.to_update){
                this.axiosActivity();
                this.to_update = false;
            }
        },
        methods:{
            async deleteActivity(item){
                var array_selecteds = this.selecteds;
                delete array_selecteds[item.id];
                this.selecteds = array_selecteds;

                for(var i=0;i<this.CreationAmbit.list_activity.length;i++){
                    if(this.CreationAmbit.list_activity[i].id == item.id){
                        delete this.CreationAmbit.list_activity[i];
                    }
                }
                var compactArray = this.CreationAmbit.list_activity.filter(function (item) {
                    return item !== undefined;
                });
                this.CreationAmbit.list_activity = compactArray;

                var sector = document.getElementsByName('ambit[sector][]');
                var selected_sectors = new Array();
                for(var loop_sector=0;loop_sector<sector.length;loop_sector++){
                    if(sector[loop_sector].checked == true && sector[loop_sector].parentElement.style.pointerEvents != 'none'){
                        selected_sectors[sector[loop_sector].value] = sector[loop_sector].attributes.label_required.value;
                    }
                }

                var bibliography = document.getElementsByName('ambit[bibliography][]');
                var selected_bibliographies = new Array();
                for(var loop_bibliography=0;loop_bibliography<bibliography.length;loop_bibliography++){
                    if(bibliography[loop_bibliography].checked == true && bibliography[loop_bibliography].parentElement.style.pointerEvents != 'none'){
                        selected_bibliographies[bibliography[loop_bibliography].value] = bibliography[loop_bibliography].attributes.label_required.value;
                    }
                }

                var videography = document.getElementsByName('ambit[videography][]');
                var selected_videographies = new Array();
                for(var loop_videography=0;loop_videography<videography.length;loop_videography++){
                    if(videography[loop_videography].checked == true && videography[loop_videography].parentElement.style.pointerEvents != 'none'){
                        selected_videographies[videography[loop_videography].value] = videography[loop_videography].attributes.label_required.value;
                    }
                }

                var example = document.getElementsByName('ambit[example][]');
                var selected_examples = new Array();
                for(var loop_example=0;loop_example<example.length;loop_example++){
                    if(example[loop_example].checked == true && example[loop_example].parentElement.style.pointerEvents != 'none'){
                        selected_examples[example[loop_example].value] = example[loop_example].attributes.label_required.value;
                    }
                }

                var support_material = document.getElementsByName('ambit[support_material][]');
                var selected_support_materials = new Array();
                for(var loop_support_material=0;loop_support_material<support_material.length;loop_support_material++){
                    if(support_material[loop_support_material].checked == true && support_material[loop_support_material].parentElement.style.pointerEvents != 'none'){
                        selected_support_materials[support_material[loop_support_material].value] = support_material[loop_support_material].attributes.label_required.value;
                    }
                }

                var code = document.getElementById('ambit_code').value;
                var name = document.getElementById('ambit_name').value;
                var description = document.getElementById('ambit_description').value;
                var responsible_id = document.getElementById('ambit_responsible_user_value_hidden').value;
                var responsible_name = document.getElementById('ambit_responsible_user_multiple_1').value;
                var responsible_surname = document.getElementById('ambit_responsible_user_multiple_2').value;

                await this.$store.dispatch('getPreDefindedBibliography', {actividades:array_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedSector', {actividades:array_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedVideography', {actividades:array_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedExample', {actividades:array_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedSupportMaterial', {actividades:array_selecteds},{root:true});

                var merged_sector = {...selected_sectors, ...this.preSelecteds.pre_selected_sector};
                var merged_bibliography = {...selected_bibliographies, ...this.preSelecteds.pre_selected_bibliography};
                var merged_videography = {...selected_videographies, ...this.preSelecteds.pre_selected_videography};
                var merged_example = {...selected_examples, ...this.preSelecteds.pre_selected_example};
                var merged_support_material = {...selected_support_materials, ...this.preSelecteds.pre_selected_support_material};

                this.$parent.$parent.selected_sector = merged_sector;
                this.$parent.$parent.selected_bibliography = merged_bibliography;
                this.$parent.$parent.selected_videography = merged_videography;
                this.$parent.$parent.selected_example = merged_example;
                this.$parent.$parent.selected_support_material = merged_support_material;
                this.$parent.$parent.code = code;
                this.$parent.$parent.name = name;
                this.$parent.$parent.description = description;
                this.$parent.$parent.responsible_id = responsible_id;
                this.$parent.$parent.responsible_name = responsible_name;
                this.$parent.$parent.responsible_surname = responsible_surname;

                this.$parent.$parent.refreshSelectors++;
                this.refreshActivity++;
            },
            addActivity(){
                this.Global.openPopUp('label_ambit_activity_data_selector_popup');
                this.$refs.activities.StatusPopup();
            },
            async axiosActivity(){
                var activities = document.getElementsByName('ambit[activity][]');
                var activities_selecteds = new Array();
                for(var i=0;i<activities.length;i++){
                    if(activities[i].checked == true){
                      activities_selecteds[activities[i].value] = activities[i].attributes.label_required.value;
                    }
                }
                this.selecteds = activities_selecteds;

                var sector = document.getElementsByName('ambit[sector][]');
                var selected_sectors = new Array();
                for(var loop_sector=0;loop_sector<sector.length;loop_sector++){
                    if(sector[loop_sector].checked == true && sector[loop_sector].parentElement.style.pointerEvents != 'none'){
                        selected_sectors[sector[loop_sector].value] = sector[loop_sector].attributes.label_required.value;
                    }
                }

                var bibliography = document.getElementsByName('ambit[bibliography][]');
                var selected_bibliographies = new Array();
                for(var loop_bibliography=0;loop_bibliography<bibliography.length;loop_bibliography++){
                    if(bibliography[loop_bibliography].checked == true && bibliography[loop_bibliography].parentElement.style.pointerEvents != 'none'){
                        selected_bibliographies[bibliography[loop_bibliography].value] = bibliography[loop_bibliography].attributes.label_required.value;
                    }
                }

                var videography = document.getElementsByName('ambit[videography][]');
                var selected_videographies = new Array();
                for(var loop_videography=0;loop_videography<videography.length;loop_videography++){
                    if(videography[loop_videography].checked == true && videography[loop_videography].parentElement.style.pointerEvents != 'none'){
                        selected_videographies[videography[loop_videography].value] = videography[loop_videography].attributes.label_required.value;
                    }
                }

                var example = document.getElementsByName('ambit[example][]');
                var selected_examples = new Array();
                for(var loop_example=0;loop_example<example.length;loop_example++){
                    if(example[loop_example].checked == true && example[loop_example].parentElement.style.pointerEvents != 'none'){
                        selected_examples[example[loop_example].value] = example[loop_example].attributes.label_required.value;
                    }
                }

                var support_material = document.getElementsByName('ambit[support_material][]');
                var selected_support_materials = new Array();
                for(var loop_support_material=0;loop_support_material<support_material.length;loop_support_material++){
                    if(support_material[loop_support_material].checked == true && support_material[loop_support_material].parentElement.style.pointerEvents != 'none'){
                        selected_support_materials[support_material[loop_support_material].value] = support_material[loop_support_material].attributes.label_required.value;
                    }
                }

                if(!this.$parent.$parent.$root._route.params.view) {
                    var code = document.getElementById('ambit_code').value;
                    var name = document.getElementById('ambit_name').value;
                    var description = document.getElementById('ambit_description').value;
                    var responsible_id = document.getElementById('ambit_responsible_user_value_hidden').value;
                    var responsible_name = document.getElementById('ambit_responsible_user_multiple_1').value;
                    var responsible_surname = document.getElementById('ambit_responsible_user_multiple_2').value;
                }

                await this.$store.dispatch('getListActivitiesAmbit', {actividades:activities_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedBibliography', {actividades:activities_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedSector', {actividades:activities_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedVideography', {actividades:activities_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedExample', {actividades:activities_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedSupportMaterial', {actividades:activities_selecteds},{root:true});

                var merged_sector = {...selected_sectors, ...this.preSelecteds.pre_selected_sector};
                var merged_bibliography = {...selected_bibliographies, ...this.preSelecteds.pre_selected_bibliography};
                var merged_videography = {...selected_videographies, ...this.preSelecteds.pre_selected_videography};
                var merged_example = {...selected_examples, ...this.preSelecteds.pre_selected_example};
                var merged_support_material = {...selected_support_materials, ...this.preSelecteds.pre_selected_support_material};

                if(!this.$parent.$parent.$root._route.params.view) {
                    this.$parent.$parent.selected_sector = merged_sector;
                    this.$parent.$parent.selected_bibliography = merged_bibliography;
                    this.$parent.$parent.selected_videography = merged_videography;
                    this.$parent.$parent.selected_example = merged_example;
                    this.$parent.$parent.selected_support_material = merged_support_material;
                    this.$parent.$parent.code = code;
                    this.$parent.$parent.name = name;
                    this.$parent.$parent.description = description;
                    this.$parent.$parent.responsible_id = responsible_id;
                    this.$parent.$parent.responsible_name = responsible_name;
                    this.$parent.$parent.responsible_surname = responsible_surname;
                }

                this.$parent.$parent.refreshSelectors++;
                this.refreshActivity++;
            }
        }
    }
</script>

<style>
    #drop_activities > div > div > .definition-field_header-text{
        color: black !important;
    }

    #drop_activities > div > div > .definition-field_header-text {
        width: 148px;
        display: flex;
        flex-flow: row-reverse;
    }

    #label_ambit_activity_data_select_SUMMARY{
        display: none!important;
    }
</style>