import ConfigTypeExercise from "@/view/config/general/types_exercise/template/type_exercise";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/general/type_exercise',
        name: 'config_type_exercise',
        component: ConfigTypeExercise,
        props: {id: null, showMenu: true, otherTitle: i18n.t('type_of_exercise')},
        children: [
            {
                path: '/config/general/type_exercise/:id',
                name: 'config_type_exercise_id',
                component: ConfigTypeExercise,
                props: {id: null, showMenu: true, otherTitle: i18n.t('type_of_exercise')},
            },
            {
                path: '/config/general/type_exercise/:id/:view',
                name: 'config_type_exercise_id_view',
                component: ConfigTypeExercise,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('type_of_exercise')},
            }
        ]
    }
];

export default routes;