import ListAmbits from "@/view/creation/ambits/list_ambits";
import Ambito from "@/routes/creation/ambits/ambit/route";

const route = [
    {
        path: '/list_creation_ambits',
        name: 'list_creation_ambits',
        component: ListAmbits,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Ambito,
]


export default routes;