<template>
    <div>
        <GhNoDropSelector :datablock="{id: 'company_groups',title: $t('company')}" v-if="haveData">
            <template v-slot:content>
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ListGroups.data_general_companies.name}}</label>
                    </div>
                </div>
                <div class="section_line">
                    <div class="T15_b" style="padding-left:13px;">{{$t('groups')}}</div>
                </div>
                <div class="gh-content-global">
                    <form id="form_companies_groups">
                        <div class="gh-row-content" v-for="i in ListGroups.data_general_companies.groups" :key="i.id">
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'result_search_data_groups',
                                    nameStore: 'ListGroups',
                                    imagelocation: require('../../../assets/gh_new_dessign/floating.svg'),
                                    id: i.type,
                                    text: $t(i.name),
                                    textlocation: `${$t('add')} ${i.name}`,
                                    actionresult: 'loadResultSearchGroups',
                                    nameResult: 'company[groups]['+i.type+'][]',
                                    supportPermision: true,
                                }"
                                :mainSelector="{
                                    name: 'Formsearchcompany',
                                    formId: 'group',
                                    actionsearch: 'getSearchGroups'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: ListGroups.list_group_users,
                                }"
                                :tableSelected="{
                                    headersummary: headersummary,
                                    selected: i.selected ? i.selected : []
                                }"
                            />
                        </div>
                    </form>
                </div>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton
                :datainput="{
                    id: 'btn_save',
                    text: $t('accept'),
                }"
                @click="submitCompaniesGroup();"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhButton from "fe-gh-button-lib";
    import GhSearchWindow from 'fe-gh-float-window-lib'
    import {mapState} from "vuex";
    export default {
        name: "company_groups",
        components:{
            GhNoDropSelector,
            GhButton,
            GhSearchWindow,
        },
        computed: {
            ...mapState(['ListGroups']),
        },
        data(){
            return{
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', required: true, label_required: this.$t('people'), sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: this.$t('name'), field: 'first_name', sorting: true,typeSort: 'string', style: 'max-width: 60px; width: 50%;'},
                    {text: this.$t('surname'), field: 'last_name', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 50%;'},
                ],
                headersummary: {
                    0: 'first_name',
                    1: 'last_name'
                },
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getCompanyData', {id: this.$root._route.params.id},{root:true});
            await this.$store.dispatch('getGroupUsers', {id: this.$root._route.params.id},{root:true});
            this.haveData = true;
        },
        methods:{
            async submitCompaniesGroup(){
                if(this.Global.checkRequired('form_companies_groups') == 'OK') {
                    const formData = new FormData(document.getElementById('form_companies_groups'));
                    this.$store.dispatch('setCompaniesGroup', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        defaultData: this.ListGroups.data_general_companies.groups
                    }, {root: true});
                }
            },
            back(){
                this.$router.go(-1);
            }
        }
    }
</script>
