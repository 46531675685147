var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'example_popup',
        title: _vm.$t('add_example'),
        type: 'add',
        style: 'width: 50%; min-width:920px;',
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":"form_example"}},[_c(_vm.form_example,{tag:"component"})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'btn_example_accept_popup',
                text: _vm.$t('save')
            }},on:{"click":_vm.submitExample}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_example_close_popup',
                text: _vm.$t('close')
            }},on:{"click":function($event){return _vm.Global.closePopUp('example_popup');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }