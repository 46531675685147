var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveData)?_c('GhNoDropSelector',{attrs:{"datablock":{id: 'company_assign_itinerary',title: _vm.$t('company')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ItineraryAssign.company_assign_itinerary.name))])])]),_c('div',{staticClass:"section_line"},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.$t('groups')))])]),_c('form',{attrs:{"id":"company_assign_itinerary"}},[_c('div',{staticClass:"gh-content-global"},[_c('input',{attrs:{"type":"hidden","name":"company[id]"},domProps:{"value":_vm.$root._route.params.id}}),_c('input',{attrs:{"type":"hidden","name":"company[id_itinerary]"},domProps:{"value":_vm.$root._route.params.id_itinerary}}),_c('div',{staticClass:"gh-row-content"},[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                label: {
                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                },
                                ResultSearch: 'result_search_data_groups',
                                nameStore: 'ListGroups',
                                imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                id: 'tutor',
                                text: _vm.$t('tutorships'),
                                textlocation: _vm.$t('add_user'),
                                actionresult: 'loadResultSearchGroups',
                                nameResult: 'company[groups][tutor]',
                                supportPermision: true,
                            },"mainSelector":{
                                name: 'Formsearchcompany',
                                formId: 'group',
                                actionsearch: 'getSearchGroups'
                            },"tableResolt":{
                                header: _vm.header,
                                data: _vm.ItineraryAssign.company_assign_itinerary.table.tutor ? _vm.ItineraryAssign.company_assign_itinerary.table.tutor : []
                            },"tableSelected":{
                                headersummary: _vm.headersummary,
                                selected: _vm.ItineraryAssign.company_assign_itinerary.groups.tutor ? _vm.ItineraryAssign.company_assign_itinerary.groups.tutor : []
                            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                label: {
                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                },
                                ResultSearch: 'result_search_data_groups',
                                nameStore: 'ListGroups',
                                imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                id: 'evaluator',
                                text: _vm.$t('evaluation'),
                                textlocation: _vm.$t('add_user'),
                                actionresult: 'loadResultSearchGroups',
                                nameResult: 'company[groups][evaluator]',
                                supportPermision: true,
                            },"mainSelector":{
                                name: 'Formsearchcompany',
                                formId: 'group',
                                actionsearch: 'getSearchGroups'
                            },"tableResolt":{
                                header: _vm.header,
                                data: _vm.ItineraryAssign.company_assign_itinerary.table.evaluator ? _vm.ItineraryAssign.company_assign_itinerary.table.evaluator : []
                            },"tableSelected":{
                                headersummary: _vm.headersummary,
                                selected: _vm.ItineraryAssign.company_assign_itinerary.groups.evaluator ? _vm.ItineraryAssign.company_assign_itinerary.groups.evaluator : []
                            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                label: {
                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                },
                                ResultSearch: 'result_search_data_groups',
                                nameStore: 'ListGroups',
                                imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                id: 'support',
                                text: _vm.$t('support'),
                                textlocation: _vm.$t('add_user'),
                                actionresult:'loadResultSearchGroups',
                                nameResult: 'company[groups][support]',
                                supportPermision: true,
                            },"mainSelector":{
                                name: 'Formsearchcompany',
                                formId: 'group',
                                actionsearch: 'getSearchGroups'
                            },"tableResolt":{
                                header: _vm.header,
                                data: _vm.ItineraryAssign.company_assign_itinerary.table.support ? _vm.ItineraryAssign.company_assign_itinerary.table.support : []
                            },"tableSelected":{
                                headersummary: _vm.headersummary,
                                selected: _vm.ItineraryAssign.company_assign_itinerary.groups.support ? _vm.ItineraryAssign.company_assign_itinerary.groups.support : []
                            }}})],1)])])]},proxy:true}],null,false,3612951611)}):_vm._e(),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"20px"}},[(!this.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('accept'),
            }},on:{"click":function($event){return _vm.postCompanyItinerary();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.back();}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }