var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('div',{staticClass:"section_line",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('references')))])]),_vm._l((_vm.thesauros),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content"},_vm._l((item),function(subitem,subindex){return _c('div',{key:'sub_' + subindex,staticClass:"no-default-width"},[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                    type: 'checkbox',
                    id: 'label_reference_' + subitem.name,
                    label: _vm.$t(subitem.text),
                    title: _vm.$t(subitem.text),
                    text: _vm.$t('select') + ' ' + subitem.text + ':',
                    name: _vm.props.name + '[' + subitem.name + '][]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: false,
                    checkparentdata: true,
                    style: '',
                    label_style: 'width: 149px',
                    popupStyle: 'min-width: 900px;width: 55%;',
                },"selected_input":_vm.props.selecteds[subitem.name] !== undefined ? _vm.props.selecteds[subitem.name] : [],"items":subitem.feed}})],1)}),0)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }