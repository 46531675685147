import ListGeneral from "@/view/config/general/list_general";

import Sectors from './sectors/route';
import Bibliografia from './bibliography/route';
import Videografia from './videography/route';
import Ejemplo from './examples/route';
import MaterialDeSoporte from './support_material/route';
import Procedimiento from './procedure/route';
import TipoDeEjercicio from './types_exercise/route';
import Responsables from './responsibles/route';
import Seals from './seals/route';

const route = [
    {
        path: '/config_general',
        name: 'config_general',
        component: ListGeneral,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Sectors,
    ...Bibliografia,
    ...Videografia,
    ...Ejemplo,
    ...MaterialDeSoporte,
    ...Procedimiento,
    ...TipoDeEjercicio,
    ...Responsables,
    ...Seals
]

export default routes;