import Activity from "@/view/creation/activities/templates/activity";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/creation_activities/activity',
        name: 'creation_activities',
        component: Activity,
        props: {id: null, showMenu: true,otherTitle: i18n.t('activity')},
        children: [
            {
                path: '/creation_activites/activity/:id',
                name: 'creation_activities_id',
                component: Activity,
                props: {id: null, showMenu: true, otherTitle: i18n.t('activity')},
            },
            {
                path: '/creation_activites/activity/:id/:view',
                name: 'creation_activities_id_view',
                component: Activity,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('activity')},
            }
        ]
    },
];

export default routes;