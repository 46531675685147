<template>
    <div v-if="haveData">
        <div>
            <GhDataSelector ref="ambits_certificates" :key="refreshAmbitsCertificates"
                :datablock="{
                    id: 'label_itinerary_ambits_certificates',
                    class: 'T15_b',
                    subtitle: $t('select_ambits_certificates') + ':',
                    text: $t('ambits_certificates'),
                    label: $t('ambits_certificates'),
                    type: 'checkbox',
                    columns: 3,
                    style: 'margin-left: 93px;display: none;',
                    required: false,
                }"
                :datainput="{
                    id: 'value_itinerary_ambits_certificates',
                    name: 'itinerary[ambit_certificate][]'
                }"
                :selected_input="selecteds"
                :feed="getFeeds.feed_selector_ambits_certificates"
                @accept="axiosAmbitCertificate()"
            />
        </div>
        <div>
            <GhDropDownField
                :dataBlock="{
                    id: 'drop_ambits_certificates',
                    style: 'width: 100%; margin-right: 0px;',
                    title: $t('ambits_certificates'),
                    subTitle: CreationItinerary.list_ambits_certificates.length + ' ' + $t('results'),
                    isDrop: true
                }"
            >
                <template v-slot:content>
                    <GhTableCommon
                        :extratable="{
                            id: 'ambits_certificates_table',
                        }"
                        :header="header"
                        :data="CreationItinerary.list_ambits_certificates.length === 0 ? [] : CreationItinerary.list_ambits_certificates"
                    >
                        <template v-slot:actions="{itemProp}" v-if="!$parent.$parent.$root._route.params.view">
                            <GhAction
                                :dataction="{
                                    id: 'edit_config_ambits_certificates',
                                    text: $t('delete'),
                                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                                }"
                                @click="deleteAmbitCertificate(itemProp);"
                            />
                        </template>
                        <template v-slot:footer>
                            <div class="centered" v-if="!$parent.$parent.$root._route.params.view">
                                <GhButton v-if="getFeeds.feed_selector_ambits_certificates.length !== 0"
                                    :datainput="{
                                        id: 'btn_add_ambits_certificates',
                                        text: $t('add')
                                    }"
                                    @click="addAmbitCertificate();"
                                />
                            </div>
                        </template>
                    </GhTableCommon>
                </template>
            </GhDropDownField>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";
    import GhDropDownField from "fe-gh-drop-down-field-lib";

    export default {
        name: "ambits_certificates",
        components:{
            GhDataSelector,
            GhTableCommon,
            GhButton,
            GhAction,
            GhDropDownField,
        },
        computed: {
            ...mapState(['getFeeds','CreationItinerary','preSelecteds'])
        },
        data(){
            return {
                haveData: false,
                to_update: true,
                header: [
                    {text: this.$t(''), field: 'code', sorting: true, typeSort: 'code', style: 'max-width: 80px; width: 5%;'},
                    {text: this.$t('code'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: this.$t('name'), field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px;width: 15%;'},
                    {text: this.$t('description'), field: 'sector', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('sector'), field: 'bibliography', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('bibliography'), field: 'videography', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('videography'), field: 'examples', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('support_material_2'), field: 'support_material', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                selecteds: [],
                refreshAmbitsCertificates: 0,
            }
        },
        async beforeMount(){
            if(this.$parent.$parent.$root._route.params.id){
                this.selecteds = this.CreationItinerary.data_itinerary.ambits_certificates;
            }else{
                this.getFeeds.feed_selector_ambits_certificates = [];
            }

            if(this.$parent.$parent.$root._route.params.view){
                this.header = [
                    {text: this.$t(''), field: 'code', sorting: true, typeSort: 'code', style: 'max-width: 80px; width: 5%;'},
                    {text: this.$t('code'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: this.$t('name'), field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px;width: 15%;'},
                    {text: this.$t('description'), field: 'sector', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('sector'), field: 'bibliography', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('bibliography'), field: 'videography', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('videography'), field: 'examples', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'},
                    {text: this.$t('support_material_2'), field: 'support_material', sorting: true, typeSort: 'string', style: 'max-width: 100px;width: 15%;'}
                ];
            }

            this.haveData = true;
        },
        updated(){
            if(this.$parent.$parent.$root._route.params.id && this.to_update){
                this.axiosAmbitCertificate();
                this.to_update = false;
            }
        },
        methods:{
            async deleteAmbitCertificate(item){
                var array_selecteds = this.selecteds;
                delete array_selecteds[item.id];
                this.selecteds = array_selecteds;

                for(var i=0;i<this.CreationItinerary.list_ambits_certificates.length;i++){
                    if(this.CreationItinerary.list_ambits_certificates[i].id == item.id){
                        delete this.CreationItinerary.list_ambits_certificates[i];
                    }
                }
                var compactArray = this.CreationItinerary.list_ambits_certificates.filter(function (item) {
                    return item !== undefined;
                });
                this.CreationItinerary.list_ambits_certificates = compactArray;

                var bibliography = document.getElementsByName('itinerary[bibliography][]');
                var selected_bibliographies = new Array();
                for(var loop_bibliography=0;loop_bibliography<bibliography.length;loop_bibliography++){
                    if(bibliography[loop_bibliography].checked == true && bibliography[loop_bibliography].parentElement.style.pointerEvents != 'none'){
                        selected_bibliographies[bibliography[loop_bibliography].value] = bibliography[loop_bibliography].attributes.label_required.value;
                    }
                }

                var videography = document.getElementsByName('itinerary[videography][]');
                var selected_videographies = new Array();
                for(var loop_videography=0;loop_videography<videography.length;loop_videography++){
                    if(videography[loop_videography].checked == true && videography[loop_videography].parentElement.style.pointerEvents != 'none'){
                        selected_videographies[videography[loop_videography].value] = videography[loop_videography].attributes.label_required.value;
                    }
                }

                var example = document.getElementsByName('itinerary[example][]');
                var selected_examples = new Array();
                for(var loop_example=0;loop_example<example.length;loop_example++){
                    if(example[loop_example].checked == true && example[loop_example].parentElement.style.pointerEvents != 'none'){
                        selected_examples[example[loop_example].value] = example[loop_example].attributes.label_required.value;
                    }
                }

                var support_material = document.getElementsByName('itinerary[support_material][]');
                var selected_support_materials = new Array();
                for(var loop_support_material=0;loop_support_material<support_material.length;loop_support_material++){
                    if(support_material[loop_support_material].checked == true && support_material[loop_support_material].parentElement.style.pointerEvents != 'none'){
                        selected_support_materials[support_material[loop_support_material].value] = support_material[loop_support_material].attributes.label_required.value;
                    }
                }

                var sector = document.getElementsByName('itinerary[sector][]');
                var selected_sectors = new Array();
                for(var loop_sector=0;loop_sector<sector.length;loop_sector++){
                    if(sector[loop_sector].checked == true && sector[loop_sector].parentElement.style.pointerEvents != 'none'){
                        selected_sectors[sector[loop_sector].value] = sector[loop_sector].attributes.label_required.value;
                    }
                }

                var code = document.getElementById('itinerary_code').value;
                var name = document.getElementById('itinerary_name').value;
                var description = document.getElementById('itinerary_description').value;
                var responsible_id = document.getElementById('itinerary_responsible_user_value_hidden').value;
                var responsible_name = document.getElementById('itinerary_responsible_user_multiple_1').value;
                var responsible_surname = document.getElementById('itinerary_responsible_user_multiple_2').value;

                await this.$store.dispatch('getPreDefindedBibliography', {ambits_certificates:array_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedVideography', {ambits_certificates:array_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedExample', {ambits_certificates:array_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedSupportMaterial', {ambits_certificates:array_selecteds},{root:true});

                var merged_bibliography = {...selected_bibliographies, ...this.preSelecteds.pre_selected_bibliography};
                var merged_videography = {...selected_videographies, ...this.preSelecteds.pre_selected_videography};
                var merged_example = {...selected_examples, ...this.preSelecteds.pre_selected_example};
                var merged_support_material = {...selected_support_materials, ...this.preSelecteds.pre_selected_support_material};
                this.$parent.$parent.selected_sector = {...selected_sectors};
                this.$parent.$parent.code = code;
                this.$parent.$parent.name = name;
                this.$parent.$parent.description = description;
                this.$parent.$parent.responsible_id = responsible_id;
                this.$parent.$parent.responsible_name = responsible_name;
                this.$parent.$parent.responsible_surname = responsible_surname;

                this.$parent.$parent.selected_bibliography = merged_bibliography;
                this.$parent.$parent.selected_videography = merged_videography;
                this.$parent.$parent.selected_example = merged_example;
                this.$parent.$parent.selected_support_material = merged_support_material;

                this.$parent.$parent.refreshSelectors++;
                this.refreshAmbitsCertificates++;
            },
            addAmbitCertificate(){
                this.Global.openPopUp('label_itinerary_ambits_certificates_data_selector_popup');
                this.$refs.ambits_certificates.StatusPopup();
            },
            async axiosAmbitCertificate(){
                var ambits_certificates = document.getElementsByName('itinerary[ambit_certificate][]');
                var ambits_certificates_selecteds = new Array();
                for(var i=0;i<ambits_certificates.length;i++){
                    if(ambits_certificates[i].checked == true){
                        ambits_certificates_selecteds[ambits_certificates[i].value] = ambits_certificates[i].attributes.label_required.value;
                    }
                }
                this.selecteds = ambits_certificates_selecteds;

                var bibliography = document.getElementsByName('itinerary[bibliography][]');
                var selected_bibliographies = new Array();
                for(var loop_bibliography=0;loop_bibliography<bibliography.length;loop_bibliography++){
                    if(bibliography[loop_bibliography].checked == true && bibliography[loop_bibliography].parentElement.style.pointerEvents != 'none'){
                        selected_bibliographies[bibliography[loop_bibliography].value] = bibliography[loop_bibliography].attributes.label_required.value;
                    }
                }

                var videography = document.getElementsByName('itinerary[videography][]');
                var selected_videographies = new Array();
                for(var loop_videography=0;loop_videography<videography.length;loop_videography++){
                    if(videography[loop_videography].checked == true && videography[loop_videography].parentElement.style.pointerEvents != 'none'){
                        selected_videographies[videography[loop_videography].value] = videography[loop_videography].attributes.label_required.value;
                    }
                }

                var example = document.getElementsByName('itinerary[example][]');
                var selected_examples = new Array();
                for(var loop_example=0;loop_example<example.length;loop_example++){
                    if(example[loop_example].checked == true && example[loop_example].parentElement.style.pointerEvents != 'none'){
                        selected_examples[example[loop_example].value] = example[loop_example].attributes.label_required.value;
                    }
                }

                var support_material = document.getElementsByName('itinerary[support_material][]');
                var selected_support_materials = new Array();
                for(var loop_support_material=0;loop_support_material<support_material.length;loop_support_material++){
                    if(support_material[loop_support_material].checked == true && support_material[loop_support_material].parentElement.style.pointerEvents != 'none'){
                        selected_support_materials[support_material[loop_support_material].value] = support_material[loop_support_material].attributes.label_required.value;
                    }
                }

                var sector = document.getElementsByName('itinerary[sector][]');
                var selected_sectors = new Array();
                for(var loop_sector=0;loop_sector<sector.length;loop_sector++){
                    if(sector[loop_sector].checked == true && sector[loop_sector].parentElement.style.pointerEvents != 'none'){
                        selected_sectors[sector[loop_sector].value] = sector[loop_sector].attributes.label_required.value;
                    }
                }

                if(!this.$parent.$parent.$root._route.params.view) {
                    var code = document.getElementById('itinerary_code').value;
                    var name = document.getElementById('itinerary_name').value;
                    var description = document.getElementById('itinerary_description').value;
                    var responsible_id = document.getElementById('itinerary_responsible_user_value_hidden').value;
                    var responsible_name = document.getElementById('itinerary_responsible_user_multiple_1').value;
                    var responsible_surname = document.getElementById('itinerary_responsible_user_multiple_2').value;
                }

                await this.$store.dispatch('getListAmbitsCertificates', {ambits_certificates:ambits_certificates_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedBibliography', {ambits_certificates:ambits_certificates_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedVideography', {ambits_certificates:ambits_certificates_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedExample', {ambits_certificates:ambits_certificates_selecteds},{root:true});
                await this.$store.dispatch('getPreDefindedSupportMaterial', {ambits_certificates:ambits_certificates_selecteds},{root:true});

                var merged_bibliography = {...selected_bibliographies, ...this.preSelecteds.pre_selected_bibliography};
                var merged_videography = {...selected_videographies, ...this.preSelecteds.pre_selected_videography};
                var merged_example = {...selected_examples, ...this.preSelecteds.pre_selected_example};
                var merged_support_material = {...selected_support_materials, ...this.preSelecteds.pre_selected_support_material};

                if(!this.$parent.$parent.$root._route.params.view) {
                    this.$parent.$parent.selected_bibliography = merged_bibliography;
                    this.$parent.$parent.selected_videography = merged_videography;
                    this.$parent.$parent.selected_example = merged_example;
                    this.$parent.$parent.selected_support_material = merged_support_material;
                    this.$parent.$parent.selected_sector = {...selected_sectors};
                    this.$parent.$parent.code = code;
                    this.$parent.$parent.name = name;
                    this.$parent.$parent.description = description;
                    this.$parent.$parent.responsible_id = responsible_id;
                    this.$parent.$parent.responsible_name = responsible_name;
                    this.$parent.$parent.responsible_surname = responsible_surname;
                }

                //CreationItinerary.data_itinerary.code

                this.$parent.$parent.refreshSelectors++;
                this.refreshAmbitsCertificates++;
            }
        }
    }
</script>
<style>
    #drop_ambits_certificates > div > div > .definition-field_header-text{
        color: black !important;
    }

    #drop_ambits_certificates > div > div > .definition-field_header-text {
        width: 148px;
        display: flex;
        flex-flow: row-reverse;
    }

    #label_itinerary_ambits_certificates_data_select_SUMMARY{
        display: none!important;
    }
</style>