var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"max-width":"100%","margin-right":"auto","margin-left":"auto"}},[(this.$route.matched[0] !== undefined && this.$route.matched[0].props.default.showMenu)?[_c('GhMenu',{scopedSlots:_vm._u([{key:"cabezera",fn:function(){return [_c('div',{staticClass:"logo_cabecera_square"},[_c('img',{attrs:{"src":require('./assets/main/logo-club-2.png')}})])]},proxy:true},{key:"header-platform_info",fn:function(){return [_c('GhUserIcon',{attrs:{"generalConfiguration":{class: 'flex-row-textt', icon: require('./assets/gh_new_dessign/user.svg'), title: '', style: 'top: 53px; left: 1609px; position:fixed;', id: 'Icon_User'},"bodyConfiguration":{class: 'flex-row-text', title: '', style: '', id: 'Icon_User_Body'}}}),_c('GhHelpIcon',{attrs:{"dataBlock":{icon: require('./assets/gh_new_dessign/help.svg')}}}),(_vm.haveData==true)?_c('GhLanguage',{attrs:{"dataBlock":{
                        title: _vm.$t('select_language'),
                        id:'select_lang',
                        icon: require('./assets/gh_new_dessign/arrowdown.svg')
                    },"language":_vm.getFeeds.feed_language,"selected":_vm.idioma}}):_vm._e(),_c('div',{staticClass:"header-phone T4"},[_vm._v(" "+_vm._s(_vm.$t('help_phone')+': (+34) xx yyy zz tt')+" ")]),_c('div',{staticClass:"header-email T4"},[_vm._v(" "+_vm._s(_vm.$t('help_email')+': notificaciones@globalholdings2000.es')+" ")])]},proxy:true}],null,false,805290846)}),_c('div',{staticStyle:{"margin-top":"93px"}}),_c('GhLocation',{attrs:{"dataLocation":{showButton: true, secondWindow: false, showLoader: _vm.Tabs.TabLoading, text: this.$route.matched[0] !== undefined && this.$route.matched[0].props.default.otherTitle !== undefined ? this.$route.matched[0].props.default.otherTitle : _vm.Tabs.TabActive},"dataButton":{id: 'create_incidence', text: _vm.$t('support'), style: ''}},on:{"button":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('incidence_new',{add:true}),'new_incidence')}}}),_c('div',{class:this.$route.path == '/' ? '' : 'content_page',attrs:{"id":"content_page"}},[_c('router-view'),_c('GhWarningTimeOut',{attrs:{"datablock":{
                    time: 1,
                }}}),_c('GhDeleteConfirm',{attrs:{"visible_fields":_vm.Global.state.state_confirm.item_confirm,"id_popup":'delete_confirm',"content_warning":_vm.Global.state.state_confirm.subheader_confirm},on:{"function":_vm.setDelete}})],1)]:[_c('router-view')],_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'warning_confirm',
            title: _vm.$t('required_fields'),
            type: 'warning',
            style: 'width: 35%; min-width: 430px'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: _vm.$t('must_be_follow_fields_complete') + ':',
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","margin-right":"40px","padding-bottom":"15px"}},_vm._l((_vm.Global.state.alert_required.requireds),function(item,index){return _c('div',{key:index,staticClass:"T15_b",staticStyle:{"padding-left":"20px"}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'button',
                    text: _vm.$t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }},on:{"click":function($event){return _vm.Global.closePopUp('warning_confirm');}}})]},proxy:true}])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }