<template>
    <div>
        <div v-if="haveData == true">
            <div v-for="(item,itemIndex) in TabAction.sections" :key="itemIndex">
                <GhDropSelector v-if="data[itemIndex].show"
                    :datablock="{
                        id: item.id,
                        title: $t(item.name),
                        num_results: item?.count + ' ' + $t('results'),
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="list_companies" :id="item.id" />
                    </template>
                </GhDropSelector>
            </div>
        </div>
        <GhDropSelector v-if="data[5].show"
            :datablock="{
                id: 'section_indicators',
                title: $t('indicators'),
            }"
        >
            <template v-slot:content>
                <component v-bind:is="list_action_indicators" />
            </template>
            <template v-slot:actions>
                <GhAction
                    v-for="(action,index) in actions"
                    :key="index"
                    :dataction="{id: action.id, class: 'T15_action',text:$t(action.name)}"
                    @click=" action.id == 'export_monthly_support_indicator_data' ? exportTypeExcel(1) : '';  action.id == 'export_annual_support_indicator_data' ? exportTypeExcel(0) : '';"
                />
            </template>
        </GhDropSelector>
        <GhDropSelector v-if="data[6].show"
            :datablock="{
                id: 'actions_historical_search',
                title: $t('historical'),
            }"
        >
            <template v-slot:content>
                <component v-bind:is="list_action_historical" />
            </template>
        </GhDropSelector>
        <GhNoDropSelector
            v-if="ActionHistorical && ActionHistorical.haveResult == true"
            :datablock="{
                id: 'result_search_historical',
                title:typeof ActionHistorical.resultTitle == 'number' ? ActionHistorical.resultTitle + ' ' + $t('results') : $t(ActionHistorical.resultTitle),
            }"
        >
            <template v-slot:actions="">
                <GhAction
                    :dataction="{id: 'download_data_export_historical', class: 'T15_action', text: $t('export_results')}"
                    @click="exportData()"
                />
            </template>
            <template v-slot:content>
                <component v-bind:is="result_search" />
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    import result_search from "@/view/actions/historical/result_content/result_action_historical.vue";
    import list_companies from "@/view/actions/template/list_companies.vue";
    import list_action_historical from "@/view/actions/historical/list_actions_historical.vue";
    import list_action_indicators from "@/view/actions/indicators/list_indicators.vue";
    export default {
        name: "list_actions",
        components: {
            GhAction,
            GhNoDropSelector,
            GhDropSelector,
        },
        computed:{
            ...mapState(['TabSupport','ActionHistorical','TabAction','Login'])
        },
        data(){
            return {
                haveData:false,
                list_companies:list_companies,
                list_action_indicators:list_action_indicators,
                list_action_historical:list_action_historical,
                result_search:result_search,
                actions: [
                    {
                        id:'export_monthly_support_indicator_data',
                        name: this.$t('export_monthly'),
                    },
                    {
                        id:'export_annual_support_indicator_data',
                        name: this.$t('export_annual')
                    }
                ],
                data: [
                    {show: false},
                    {show: false},
                    {show: false},
                    {show: false},
                    {show: false},
                    {show: false},
                    {show: false}
                ]
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getSectionsActions','',{root:true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('actions')){
                    that.Login.tabs[route.id]['sub-modules'].forEach((subroute,subkey) => {
                        that.data[subkey].show = subroute.have_permission;
                    });
                }
            });

            this.haveData = true;
        },
        methods:{
            exportTypeExcel(type){
              this.$store.dispatch('exportTypeExcel', {where: 'actions', type: type}, {root: true});
            },
            exportData(){
                const formData = new FormData(document.getElementById('form_search_actions_historical'));
                this.$store.dispatch('exportResultActionHistorical', {formData: [...formData]}, {root: true});
            }
        },
        destroyed() {
            this.$store.dispatch('destroyedActionHistoricalResultData',{},{root:true});
        }
    }
</script>
