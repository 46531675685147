<template>
    <div style="padding-bottom: 12px;">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'general'+label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: ConfigGeneral.counters[label['result']],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>
<script>
    import list_sectors from './sectors/list_sectors';
    import list_bibliography from "./bibliography/list_bibliography";
    import list_videography from "./videography/list_videography";
    import list_examples from "./examples/list_examples";
    import list_support_material from "./support_material/list_support_material";
    // import list_procedure from "./procedure/list_procedure";
    import list_types_exercieses from "./types_exercise/list_types_exercises";
    import list_responsibles from "./responsibles/list_responsibles.vue";
    import list_seals from "./seals/list_seals.vue";

    import GhDropSelector from 'fe-gh-dropselector-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_general",
        components: {
            GhDropSelector,
        },
        computed:{
            ...mapState(['ConfigGeneral','Login']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: 'sectors', component: list_sectors, result: 'sectors', show: false , second_text: 'results'},
                    {id: 2, name: 'bibliography', component: list_bibliography, result: 'bibliography', show: false , second_text: 'results'},
                    {id: 3, name: 'videography', component: list_videography, result: 'videography', show: false , second_text: 'results'},
                    {id: 4, name: 'examples', component: list_examples, result: 'examples', show: false , second_text: 'results'},
                    {id: 5, name: 'support_material_2', component: list_support_material, result: 'support_material', show: false , second_text: 'results'},
                    // {id: 6, name: 'Procedimientos', component: list_procedure, result: 'procedure', show: false , second_text: 'Resultados'},
                    {id: 7, name: 'types_of_exercises', component: list_types_exercieses, result: 'type_exercice', show: false , second_text: 'results'},
                    {id: 8, name: 'responsibles', component: list_responsibles, result: 'responsibles', show: false , second_text: 'results'},
                    {id: 9, name: 'stamps', component: list_seals, result: 'seals', show: false , second_text: 'results'},
                ],
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getConfigGeneralCounters','',{root:true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('configuration')){
                    that.Login.tabs[route.id]['sub-modules'][0]['sub-modules'].forEach((subroute,subkey) => {
                        that.data[subkey].show = subroute.have_permission;
                    });
                    that.data[7].show = true;
                }
            });
        },
    }
</script>
