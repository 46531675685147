<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'support_material',title: $t('support_material_2')}">
            <template v-slot:content>
                <form id="form_support_material" v-if="haveData">
                    <div style="display: flex">
                        <div class="gh-content-global" style="width: 15%">
                            <div style="width: 250px; height: 135px;">
                                <img :src="image" width="250" style="border-radius: 6px; max-height: 135px; width: auto;">
                            </div>
                            <div class="other-image">
                                <GhEasyUpload
                                    :extrablock="{
                                        id: 'support_material_other_image',
                                        class: '',
                                        name_hidden: 'support_material[other_image]',
                                        id_hidden: 'support_material_other_image',
                                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                    }"
                                    :datalabel="{
                                        id: 'support_material_other_image',
                                        style: 'margin-right: -15px'
                                    }"
                                    :datainput="{
                                        view: $root._route.params.view,
                                        accept: '.png, .jpg, .jpeg',
                                        text: $t('modify_image'),
                                        show_image: false,
                                        delete: false,
                                        href: 'easyUpload',
                                        downloadHref: 'easyDownload',
                                        file_name: ConfigSupportMaterials.data.other !== undefined && ConfigSupportMaterials.data.other.file_name ? ConfigSupportMaterials.data.other.file_name : '',
                                        file_path: ConfigSupportMaterials.data.other !== undefined && ConfigSupportMaterials.data.other.file_path ? ConfigSupportMaterials.data.other.file_path : '',
                                        file_download: ConfigSupportMaterials.data.other !== undefined && ConfigSupportMaterials.data.other.file_download ? ConfigSupportMaterials.data.other.file_download : '',
                                        required: false
                                    }"
                                />
                            </div>
                        </div>
                        <div class="gh-content-global" style="width: 85%;">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('code') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'support_material_code',
                                            name: 'support_material[code]',
                                            style: 'width: 200px',
                                            value: code,
                                            type: 'text',
                                        }"
                                        ref="RefCode"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigSupportMaterials.data.code}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('name') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'support_material_name',
                                            name: 'support_material[name]',
                                            style: 'min-width: 200px; width: 20%',
                                            value: name,
                                            type: 'text',
                                        }"
                                        ref="RefName"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigSupportMaterials.data.name}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_support_material_sector',
                                            class: 'T15_b',
                                            subtitle: $t('select_sector') + ':',
                                            text: $t('sector') + '*',
                                            label: $t('sector'),
                                            type: 'radio',
                                            columns: 3,
                                            style: 'margin-left:94px;',
                                            required: true,
                                        }"
                                        :datainput="{
                                            id: 'value_support_material_sector',
                                            name: 'support_material[sector]'
                                        }"
                                        :selected_input="ConfigSupportMaterials.data.sector ? ConfigSupportMaterials.data.sector : []"
                                        :feed="getFeeds.feed_sector"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('sector') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigSupportMaterials.data.text_sector}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description'),
                                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        }"
                                        :datainput="{
                                            id: 'support_material_description',
                                            name: 'support_material[description]',
                                            value: description
                                        }"
                                        ref="RefDescription"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigSupportMaterials.data.description}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content" :style="$root._route.params.view ? '' : 'flex-flow: row-reverse;'">
                                <template v-if="$root._route.params.view">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('example_item') + ':'}}</label>
                                </template>
                                <GhEasyUpload :key="refreshUpload"
                                    :extrablock="{
                                        id: 'support_material_attach',
                                        class: '',
                                        name_hidden: 'support_material[document]',
                                        id_hidden: 'support_material_attach',
                                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                    }"
                                    :datalabel="{
                                        id: 'label_import_block',
                                        style: 'margin-right: -15px'
                                    }"
                                    :datainput="{
                                        view: $root._route.params.view,
                                        accept: '.pdf, .png, .jpg, .jpeg',
                                        text: $t('example_item') + ' (PDF/Img)',
                                        show_image: false,
                                        delete: false,
                                        href: 'easyUpload',
                                        downloadHref: 'easyDownload',
                                        file_name: ConfigSupportMaterials.data.file_name,
                                        file_path: ConfigSupportMaterials.data.file_path,
                                        file_download: ConfigSupportMaterials.data.file_download,
                                        required: link_http !== '' ? false : true
                                    }"
                                />
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('link'),
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: false,
                                            id: 'support_material_link',
                                            name: 'support_material[link]',
                                            style: 'width: 500px',
                                            value: link_http,
                                            type: 'text',
                                        }"
                                        ref="RefLink"
                                        @keyup="setLink()"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('link') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{link_http}}</label>
                                </template>
                            </div>
                        </div>
                    </div>
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                @click="submitSupportMaterial"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhEasyUpload from 'fe-gh-easy-upload-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    export default {
        name: "support_material",
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhDataSelector,
            GhTextarea,
            GhEasyUpload,
            GhButton
        },
        computed: {
            ...mapState(['ConfigSupportMaterials', 'getFeeds', 'UploadFiles'])
        },
        data(){
            return {
                haveData: false,
                image: '',
                link_http: '',
                refreshUpload: 0
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedSector','',{root:true});
            await this.$store.dispatch('getSupportMaterialData', {id: this.$root._route.params.id},{root:true});

            this.code = this.ConfigSupportMaterials.data.code;
            this.name = this.ConfigSupportMaterials.data.name;
            this.description = this.ConfigSupportMaterials.data.description;
            this.image = this.ConfigSupportMaterials.data.image;
            this.link_http = this.ConfigSupportMaterials.data.link_http !== undefined ? this.ConfigSupportMaterials.data.link_http : '';

            this.haveData = true;
        },
        watch:{
            'UploadFiles.data'(data){
                this.image = data.image;
            }
        },
        methods: {
            async submitSupportMaterial(){
                if(this.Global.checkRequired('form_support_material') == 'OK') {
                    const formData = new FormData(document.getElementById('form_support_material'));
                    this.$store.dispatch('setSupportMaterialData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            },
            setLink(){
                this.code = this.$refs['RefCode'].datainput.value;
                this.name = this.$refs['RefName'].datainput.value;
                this.description = this.$refs['RefDescription'].datainput.value;
                this.link_http = this.$refs['RefLink'].datainput.value;
                this.refreshUpload++;
            }
        },
    }
</script>

<style>
    .other-image > .easy_upload_block{
        flex-direction: initial;
    }
    .other-image > div > #support_material_other_image_easy_download_block{
        display: none !important;
    }
</style>