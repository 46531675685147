<template>
    <div :class="$root._route.params.subquestion == 'subquestion' ? 'content_page' : 'float_window'">
        <GhLocation v-if="$root._route.params.subquestion == 'subquestion'"
            :dataLocation="{
                showButton: false,
                secondWindow: false,
                showLoader: 'haveData' ? 'OK' : '',
                text: 'Subpregunta'
            }"
        />
        <GhNoDropSelector :datablock="{id: 'exercise',title: $t('exercise')}">
            <template v-slot:content v-if="haveData">
                <form id="form_exercise">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'exercise_code',
                                        name: 'exercise[code]',
                                        style: 'width: 200px',
                                        value: ConfigExercisesTable.data.code,
                                        type: 'text',
                                    }"
                                    @keyup="persistance('general', 'code', 'RefExerciseCode', 'text')"
                                    ref="RefExerciseCode"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExercisesTable.data.code}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : ''" :key="refreshType">
                            <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                                <GhTreeSelectorBlock
                                    :dataBlock="{
                                        type: 'radio',
                                        id: 'label_exercise_type',
                                        label: $t('type_of_exercise') + '*',
                                        title: $t('type_of_exercise'),
                                        text: $t('select_type_of_exercise') + ':',
                                        name: 'exercise[type]',
                                        id_value: 'value',
                                        name_label: 'text',
                                        child_key: 'children',
                                        required: true,
                                        checkparentdata: false,
                                        style: '',
                                        label_style: 'width: 149px',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    }"
                                    :selected_input="ConfigExercisesTable.data.type !== undefined && ConfigExercisesTable.data.type.length != 0 ? ConfigExercisesTable.data.type : []"
                                    :items="getFeeds.feed_type_exercise"
                                    ref="RefType"
                                    @accept="changeType()"
                                />
                                <input type="hidden" name="exercise[type][]" :value="ConfigExercisesTable.data.type !== undefined && ConfigExercisesTable.data.type.length != 0 ? ConfigExercisesTable.data.type[0].value : ''">
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type_of_exercise') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExercisesTable.data.type_text}}</label>
                                <input type="hidden" name="exercise[type][]" :value="ConfigExercisesTable.data.type !== undefined && ConfigExercisesTable.data.type.length != 0 ? ConfigExercisesTable.data.type[0].value : ''">
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'label_exercise_sector',
                                        class: 'T15_b',
                                        subtitle: $t('select_sector') + ':',
                                        text: $t('sector') + '*',
                                        label: $t('sector'),
                                        type: 'radio',
                                        columns: 3,
                                        style: 'margin-left: 99px;',
                                        required: true,
                                    }"
                                    :datainput="{
                                        id: 'value_exercise_sector',
                                        name: 'exercise[sector]'
                                    }"
                                    :selected_input="ConfigExercisesTable.data.sector !== undefined && ConfigExercisesTable.data.sector.length != 0 ? ConfigExercisesTable.data.sector : []"
                                    :feed="getFeeds.feed_sector"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('sector') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExercisesTable.data.type_sector}}</label>
                            </template>
                        </div>
                        <template>
                            <component v-bind:is="view_type_exercise" :level="$root._route.params.level"/>
                        </template>
                        <template v-if="
                            ConfigExercisesTable.type_exercise.open_question.metric.view || ConfigExercisesTable.type_exercise.open_question.example.view || ConfigExercisesTable.type_exercise.open_question.videography.view || ConfigExercisesTable.type_exercise.open_question.bibliography.view || ConfigExercisesTable.type_exercise.open_question.support_material.view ||
                            ConfigExercisesTable.type_exercise.close_numeric.metric.view || ConfigExercisesTable.type_exercise.close_numeric.example.view || ConfigExercisesTable.type_exercise.close_numeric.videography.view || ConfigExercisesTable.type_exercise.close_numeric.bibliography.view || ConfigExercisesTable.type_exercise.close_numeric.support_material.view ||
                            ConfigExercisesTable.type_exercise.close_one_correct.metric.view || ConfigExercisesTable.type_exercise.close_one_correct.example.view || ConfigExercisesTable.type_exercise.close_one_correct.videography.view || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.view || ConfigExercisesTable.type_exercise.close_one_correct.support_material.view ||
                            ConfigExercisesTable.type_exercise.close_n_correct.metric.view || ConfigExercisesTable.type_exercise.close_n_correct.example.view || ConfigExercisesTable.type_exercise.close_n_correct.videography.view || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.view || ConfigExercisesTable.type_exercise.close_n_correct.support_material.view
                            ? true : false"
                        >
                            <div class="section_line" style="margin-bottom: 15px">
                                <div class="T15_b">{{$t('support_material')}}</div>
                            </div>
                            <div class="gh-row-content" v-if="ConfigExercisesTable.type_exercise.open_question.metric.view || ConfigExercisesTable.type_exercise.close_numeric.metric.view || ConfigExercisesTable.type_exercise.close_one_correct.metric.view || ConfigExercisesTable.type_exercise.close_n_correct.metric.view">
                                <component v-bind:is="metrics" />
                            </div>
                            <div class="gh-row-content" v-if="ConfigExercisesTable.type_exercise.open_question.example.view || ConfigExercisesTable.type_exercise.close_numeric.example.view || ConfigExercisesTable.type_exercise.close_one_correct.example.view || ConfigExercisesTable.type_exercise.close_n_correct.example.view">
<!--                                <div :class="(ConfigExercisesTable.type_exercise.open_question.procedure.view || ConfigExercisesTable.type_exercise.close_numeric.procedure.view || ConfigExercisesTable.type_exercise.close_one_correct.procedure.view || ConfigExercisesTable.type_exercise.close_n_correct.procedure.view) && (ConfigExercisesTable.type_exercise.open_question.example.view || ConfigExercisesTable.type_exercise.close_numeric.example.view || ConfigExercisesTable.type_exercise.close_one_correct.example.view || ConfigExercisesTable.type_exercise.close_n_correct.example.view) ? 'selector-min-width-45' : ''" v-if="ConfigExercisesTable.type_exercise.open_question.procedure.view || ConfigExercisesTable.type_exercise.close_numeric.procedure.view || ConfigExercisesTable.type_exercise.close_one_correct.procedure.view || ConfigExercisesTable.type_exercise.close_n_correct.procedure.view">-->
<!--                                    <div :style="(ConfigExercisesTable.type_exercise.open_question.procedure.view || ConfigExercisesTable.type_exercise.close_numeric.procedure.view || ConfigExercisesTable.type_exercise.close_one_correct.procedure.view || ConfigExercisesTable.type_exercise.close_n_correct.procedure.view) && (ConfigExercisesTable.type_exercise.open_question.example.view || ConfigExercisesTable.type_exercise.close_numeric.example.view || ConfigExercisesTable.type_exercise.close_one_correct.example.view || ConfigExercisesTable.type_exercise.close_n_correct.example.view) ? 'width: 45%' : ''">-->
<!--                                        <template v-if="!$root._route.params.view">-->
<!--                                            <GhDataSelector :key="refreshProcedures"-->
<!--                                                :datablock="{-->
<!--                                                    id: 'label_exercise_procedure',-->
<!--                                                    class: 'T15_b',-->
<!--                                                    subtitle: $t('Seleccióna un procedimiento') + ':',-->
<!--                                                    text: $t('Procedimientos') + (ConfigExercisesTable.type_exercise.open_question.procedure.required || ConfigExercisesTable.type_exercise.close_numeric.procedure.required || ConfigExercisesTable.type_exercise.close_one_correct.procedure.required || ConfigExercisesTable.type_exercise.close_n_correct.procedure.required ? '*' : ''),-->
<!--                                                    label: $t('Procedimientos'),-->
<!--                                                    type: 'checkbox',-->
<!--                                                    columns: 3,-->
<!--                                                    style: ConfigExercisesTable.type_exercise.open_question.procedure.required || ConfigExercisesTable.type_exercise.close_numeric.procedure.required || ConfigExercisesTable.type_exercise.close_one_correct.procedure.required || ConfigExercisesTable.type_exercise.close_n_correct.procedure.required ? 'margin-left:39px' : 'margin-left:48px;',-->
<!--                                                    required: ConfigExercisesTable.type_exercise.open_question.procedure.required || ConfigExercisesTable.type_exercise.close_numeric.procedure.required || ConfigExercisesTable.type_exercise.close_one_correct.procedure.required || ConfigExercisesTable.type_exercise.close_n_correct.procedure.required,-->
<!--                                                }"-->
<!--                                                :datainput="{-->
<!--                                                    id: 'value_exercise_procedure',-->
<!--                                                    name: 'exercise[procedure][]'-->
<!--                                                }"-->
<!--                                                :selected_input="ConfigExercisesTable.data.procedures !== undefined && ConfigExercisesTable.data.procedures.length != 0 ? ConfigExercisesTable.data.procedures : []"-->
<!--                                                :feed="getFeeds.feed_procedures"-->
<!--                                                ref="GhSelectorProcedure"-->
<!--                                            />-->
<!--                                        </template>-->
<!--                                        <template v-else>-->
<!--                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right; margin-left: 55px;">{{$t('Procedimientos') + ':'}}</label>-->
<!--                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExercisesTable.data.text_procedures !== undefined ? ConfigExercisesTable.data.text_procedures : ''}}</label>-->
<!--                                        </template>-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div v-if="ConfigExercisesTable.type_exercise.open_question.example.view || ConfigExercisesTable.type_exercise.close_numeric.example.view || ConfigExercisesTable.type_exercise.close_one_correct.example.view || ConfigExercisesTable.type_exercise.close_n_correct.example.view">
                                    <div>
                                        <template v-if="!$root._route.params.view">
                                            <GhDataSelector
                                                :datablock="{
                                                    id: 'label_exercise_example',
                                                    class: 'T15_b',
                                                    subtitle: $t('select_examples') + ':',
                                                    text: $t('examples') + (ConfigExercisesTable.type_exercise.open_question.example.required || ConfigExercisesTable.type_exercise.close_numeric.example.required || ConfigExercisesTable.type_exercise.close_one_correct.example.required || ConfigExercisesTable.type_exercise.close_n_correct.example.required ? '*' : ''),
                                                    label: $t('examples'),
                                                    type: 'checkbox',
                                                    columns: 3,
                                                    style: 'margin-left:83px;',
                                                    required: ConfigExercisesTable.type_exercise.open_question.example.required || ConfigExercisesTable.type_exercise.close_numeric.example.required || ConfigExercisesTable.type_exercise.close_one_correct.example.required || ConfigExercisesTable.type_exercise.close_n_correct.example.required,
                                                }"
                                                :datainput="{
                                                    id: 'value_exercise_example',
                                                    name: 'exercise[example][]'
                                                }"
                                                :selected_input="ConfigExercisesTable.data.examples !== undefined && ConfigExercisesTable.data.examples.length != 0 ? ConfigExercisesTable.data.examples : []"
                                                :feed="getFeeds.feed_examples"
                                                ref="GhSelectorExample"
                                            />
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('examples') + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExercisesTable.data.text_examples !== undefined ? ConfigExercisesTable.data.text_examples : ''}}</label>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-top: -8px;'" v-if="ConfigExercisesTable.type_exercise.open_question.videography.view || ConfigExercisesTable.type_exercise.close_numeric.videography.view || ConfigExercisesTable.type_exercise.close_one_correct.videography.view || ConfigExercisesTable.type_exercise.close_n_correct.videography.view || ConfigExercisesTable.type_exercise.open_question.bibliography.view || ConfigExercisesTable.type_exercise.close_numeric.bibliography.view || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.view || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.view">
                                <div :class="(ConfigExercisesTable.type_exercise.open_question.videography.view || ConfigExercisesTable.type_exercise.close_numeric.videography.view || ConfigExercisesTable.type_exercise.close_one_correct.videography.view || ConfigExercisesTable.type_exercise.close_n_correct.videography.view) && (ConfigExercisesTable.type_exercise.open_question.bibliography.view || ConfigExercisesTable.type_exercise.close_numeric.bibliography.view || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.view || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.view) ? 'selector-min-width-45' : ''" v-if="ConfigExercisesTable.type_exercise.open_question.bibliography.view || ConfigExercisesTable.type_exercise.close_numeric.bibliography.view || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.view || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.view">
                                    <div :style="(ConfigExercisesTable.type_exercise.open_question.videography.view || ConfigExercisesTable.type_exercise.close_numeric.videography.view || ConfigExercisesTable.type_exercise.close_one_correct.videography.view || ConfigExercisesTable.type_exercise.close_n_correct.videography.view) && (ConfigExercisesTable.type_exercise.open_question.bibliography.view || ConfigExercisesTable.type_exercise.close_numeric.bibliography.view || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.view || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.view) ? 'width: 45%' : ''">
                                        <template v-if="!$root._route.params.view">
                                            <GhDataSelector
                                                :datablock="{
                                                    id: 'label_exercise_bibliography',
                                                    class: 'T15_b',
                                                    subtitle: $t('select_bibliography') + ':',
                                                    text: $t('bibliography') + (ConfigExercisesTable.type_exercise.open_question.bibliography.required || ConfigExercisesTable.type_exercise.close_numeric.bibliography.required || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.required || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.required ? '*' : ''),
                                                    label: $t('bibliography'),
                                                    type: 'checkbox',
                                                    columns: 3,
                                                    style: ConfigExercisesTable.type_exercise.open_question.bibliography.required || ConfigExercisesTable.type_exercise.close_numeric.bibliography.required || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.required || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.required ? 'margin-left:66px;' : 'margin-left:74px;',
                                                    required: ConfigExercisesTable.type_exercise.open_question.bibliography.required || ConfigExercisesTable.type_exercise.close_numeric.bibliography.required || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.required || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.required,
                                                }"
                                                :datainput="{
                                                    id: 'value_exercise_bibliography',
                                                    name: 'exercise[bibliography][]'
                                                }"
                                                :selected_input="ConfigExercisesTable.data.bibliography !== undefined && ConfigExercisesTable.data.bibliography.length != 0 ? ConfigExercisesTable.data.bibliography : []"
                                                :feed="getFeeds.feed_bibliography"
                                            />
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right; margin-left:80px;">{{$t('bibliography') + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExercisesTable.data.text_bibliography !== undefined ? ConfigExercisesTable.data.text_bibliography : ''}}</label>
                                        </template>
                                    </div>
                                </div>
                                <div :class="(ConfigExercisesTable.type_exercise.open_question.videography.view || ConfigExercisesTable.type_exercise.close_numeric.videography.view || ConfigExercisesTable.type_exercise.close_one_correct.videography.view || ConfigExercisesTable.type_exercise.close_n_correct.videography.view) && (ConfigExercisesTable.type_exercise.open_question.bibliography.view || ConfigExercisesTable.type_exercise.close_numeric.bibliography.view || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.view || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.view) ? 'selector-min-width-45' : ''" v-if="ConfigExercisesTable.type_exercise.open_question.videography.view || ConfigExercisesTable.type_exercise.close_numeric.videography.view || ConfigExercisesTable.type_exercise.close_one_correct.videography.view || ConfigExercisesTable.type_exercise.close_n_correct.videography.view">
                                    <div :style="(ConfigExercisesTable.type_exercise.open_question.videography.view || ConfigExercisesTable.type_exercise.close_numeric.videography.view || ConfigExercisesTable.type_exercise.close_one_correct.videography.view || ConfigExercisesTable.type_exercise.close_n_correct.videography.view) && (ConfigExercisesTable.type_exercise.open_question.bibliography.view || ConfigExercisesTable.type_exercise.close_numeric.bibliography.view || ConfigExercisesTable.type_exercise.close_one_correct.bibliography.view || ConfigExercisesTable.type_exercise.close_n_correct.bibliography.view) ? 'width: 55%' : ''">
                                        <template v-if="!$root._route.params.view">
                                            <GhDataSelector
                                                :datablock="{
                                                    id: 'label_exercise_videography',
                                                    class: 'T15_b',
                                                    subtitle: $t('select_videography') + ':',
                                                    text: $t('videography') + (ConfigExercisesTable.type_exercise.open_question.videography.required || ConfigExercisesTable.type_exercise.close_numeric.videography.required || ConfigExercisesTable.type_exercise.close_one_correct.videography.required || ConfigExercisesTable.type_exercise.close_n_correct.videography.required ? '*' : ''),
                                                    label: $t('videography'),
                                                    type: 'checkbox',
                                                    columns: 3,
                                                    style: ConfigExercisesTable.type_exercise.open_question.videography.view || ConfigExercisesTable.type_exercise.close_numeric.videography.view || ConfigExercisesTable.type_exercise.close_one_correct.videography.view || ConfigExercisesTable.type_exercise.close_n_correct.videography.view ? ConfigExercisesTable.type_exercise.open_question.videography.required || ConfigExercisesTable.type_exercise.close_numeric.videography.required || ConfigExercisesTable.type_exercise.close_one_correct.videography.required || ConfigExercisesTable.type_exercise.close_n_correct.videography.required ? 'margin-left: -8px' : '' : ConfigExercisesTable.type_exercise.open_question.videography.required || ConfigExercisesTable.type_exercise.close_numeric.videography.required || ConfigExercisesTable.type_exercise.close_one_correct.videography.required || ConfigExercisesTable.type_exercise.close_n_correct.videography.required ? 'margin-left:66px;' : 'margin-left:74px;',
                                                    required: false,
                                                }"
                                                :datainput="{
                                                    id: 'value_exercise_videography',
                                                    name: 'exercise[videography][]'
                                                }"
                                                :selected_input="ConfigExercisesTable.data.videography !== undefined && ConfigExercisesTable.data.videography.length != 0 ? ConfigExercisesTable.data.videography : []"
                                                :feed="getFeeds.feed_videography"
                                            />
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right; margin-left: -12px;">{{$t('videography') + ':'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExercisesTable.data.text_videography !== undefined ? ConfigExercisesTable.data.text_videography : ''}}</label>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-top: -8px;'" v-if="ConfigExercisesTable.type_exercise.open_question.support_material.view || ConfigExercisesTable.type_exercise.close_numeric.support_material.view || ConfigExercisesTable.type_exercise.close_one_correct.support_material.view || ConfigExercisesTable.type_exercise.close_n_correct.support_material.view">
                                <template v-if="!$root._route.params.view">
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'label_exercise_support_material',
                                            class: 'T15_b',
                                            subtitle: $t('select_support_material_2') + ':',
                                            text: $t('support_material_2') + (ConfigExercisesTable.type_exercise.open_question.support_material.required || ConfigExercisesTable.type_exercise.close_numeric.support_material.required || ConfigExercisesTable.type_exercise.close_one_correct.support_material.required || ConfigExercisesTable.type_exercise.close_n_correct.support_material.required ? '*' : ''),
                                            label: $t('support_material_2'),
                                            type: 'checkbox',
                                            columns: 3,
                                            style: ConfigExercisesTable.type_exercise.open_question.support_material.required || ConfigExercisesTable.type_exercise.close_numeric.support_material.required || ConfigExercisesTable.type_exercise.close_one_correct.support_material.required || ConfigExercisesTable.type_exercise.close_n_correct.support_material.required ? 'margin-left:15px;' : 'margin-left:22px;',
                                            required: ConfigExercisesTable.type_exercise.open_question.support_material.required || ConfigExercisesTable.type_exercise.close_numeric.support_material.required || ConfigExercisesTable.type_exercise.close_one_correct.support_material.required || ConfigExercisesTable.type_exercise.close_n_correct.support_material.required,
                                        }"
                                        :datainput="{
                                            id: 'value_exercise_support_material',
                                            name: 'exercise[support_material][]'
                                        }"
                                        :selected_input="ConfigExercisesTable.data.support_material !== undefined && ConfigExercisesTable.data.support_material.length != 0 ? ConfigExercisesTable.data.support_material : []"
                                        :feed="getFeeds.feed_support_material"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('support_material_2') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExercisesTable.data.support_material !== undefined ? ConfigExercisesTable.data.text_support_material : ''}}</label>
                                </template>
                            </div>
                        </template>
                        <component v-bind:is="reference_thesauros" :props="{selecteds:referenceSelecteds,name:'excercise'}"/>
                    </div>
                </form>

<!--                <div id="action_add_procedure" style="display: none">-->
<!--                    <div style="display: flex; padding-top: 15px;">-->
<!--                        <GhAction-->
<!--                            :dataction="{-->
<!--                                id: 'add_procedure',-->
<!--                                text: $t('Añadir procedimiento'),-->
<!--                                icon: '',-->
<!--                            }"-->
<!--                            @click="openNewProcedure"-->
<!--                        />-->
<!--                    </div>-->
<!--                </div>-->
<!--                <component v-bind:is="procedure_popup" />-->
                <div id="action_add_example" style="display: none">
                    <div style="display: flex; padding-top: 15px;">
                        <GhAction
                            :dataction="{
                                id: 'add_example',
                                text: $t('add_example'),
                                icon: '',
                            }"
                            @click="openNewExample"
                        />
                    </div>
                </div>
                <component v-bind:is="example_popup"/>
            </template>
        </GhNoDropSelector>



        <div class="centered_footer" style="margin-top: 8px; padding-bottom: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                @click="submitExercise"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: this.$root._route.params.level === undefined ? $t('back') : $t('close'),
                }"
                @click="$root._route.params.level === undefined ? router.go(-1) : Global.windowClose();"
            />
        </div>
    </div>
</template>

<script>
    import reference_thesauros from "./includes/reference_thesauros.vue";
    import type_open_question from "./includes/type_open_question";
    import type_close_numeric from "./includes/type_close_numeric";
    import type_close_one_correct from "./includes/type_close_one_correct";
    import type_close_n_correct from "./includes/type_close_n_correct";
    // import procedure_popup from './popup/procedure';
    import example_popup from './popup/example';
    import metrics from './metrics';
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTreeSelectorBlock from 'fe-gh-tree-selector-block-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import GhMultipleSearch from 'fe-gh-multiple-search-lib';
    import GhChecks from 'fe-gh-checks-lib';
    import GhButton from 'fe-gh-button-lib';
    import GhAction from 'fe-gh-action-lib';
    import {mapState} from "vuex";
    export default {
        name: "exercise_template",
        components: {
            // procedure_popup,
            example_popup,
            metrics,
            GhNoDropSelector,
            GhInputTextField,
            GhLocation,
            GhTextarea,
            GhTreeSelectorBlock,
            GhDataSelector,
            GhMultipleSearch,
            GhChecks,
            GhButton,
            reference_thesauros,
            GhAction
        },
        computed: {
            ...mapState(['ConfigExercisesTable', 'getFeeds','GlobalStore'])
        },
        data(){
            return {
                haveData: false,
                showSubQuestionChildren: true,
                showSubQuestionConditionChildren: true,
                metrics: metrics,
                // procedure_popup: procedure_popup,
                example_popup: example_popup,
                // refreshProcedures: 0,
                reference_thesauros: reference_thesauros,
                view_type_exercise: '',
                parentQuestion: '',
                referenceSelecteds: {},
                refreshType: 0
            }
        },
        async beforeMount(){
            var onlySubquestions = this.$root._route.params.subquestion == 'subquestion' ? {filter:'no_subquestions'} : '';
            await this.$store.dispatch('getFeedTypeExercise',onlySubquestions,{root:true});
            await this.$store.dispatch('getFeedSector','',{root:true});
            // await this.$store.dispatch('getFeedProcedures','',{root:true});
            await this.$store.dispatch('getFeedExamples','',{root:true});
            await this.$store.dispatch('getFeedBibliography','',{root:true});
            await this.$store.dispatch('getFeedVideography','',{root:true});
            await this.$store.dispatch('getFeedSupportMaterial','',{root:true});
            await this.$store.dispatch('getFeedResponsibles', '', {root: true});
            await this.$store.dispatch('getExerciseData',{id: this.$root._route.params.id},{root:true});

            this.haveData = true;
        },
        watch: {
            haveData(value){
                if(value && (this.ConfigExercisesTable.type_exercise.open_question.length != 0 || this.ConfigExercisesTable.type_exercise.close_numeric.length != 0 || this.ConfigExercisesTable.type_exercise.close_one_correct.length != 0 || this.ConfigExercisesTable.type_exercise.close_n_correct.length != 0)){
                    // if(this.ConfigExercisesTable.type_exercise.open_question.procedure.view || this.ConfigExercisesTable.type_exercise.close_numeric.procedure.view || this.ConfigExercisesTable.type_exercise.close_one_correct.procedure.view || this.ConfigExercisesTable.type_exercise.close_n_correct.procedure.view){
                    //     this.loadActionProcedure();
                    // }

                    if(this.ConfigExercisesTable.type_exercise.open_question.example.view || this.ConfigExercisesTable.type_exercise.close_numeric.example.view || this.ConfigExercisesTable.type_exercise.close_one_correct.example.view || this.ConfigExercisesTable.type_exercise.close_n_correct.example.view){
                        this.loadActionExample();
                    }
                }

                if(this.ConfigExercisesTable.data.type !== undefined) {
                    this.changeType(this.ConfigExercisesTable.data.type[0].value);
                }
            }
        },
        methods: {
            // loadActionProcedure(){
            //     if(document.getElementById('action_add_procedure') !== null && document.getElementById('action_add_procedure').children[0] !== undefined && document.getElementById('label_exercise_procedure_data_selector_popup') !== null) {
            //         document.getElementById('label_exercise_procedure_data_selector_popup').children[0].children[1].children[0].children[0].append(
            //             document.getElementById('action_add_procedure').children[0]
            //         );
            //     }
            // },
            loadActionExample(){
                if(document.getElementById('action_add_example') !== null && document.getElementById('action_add_example').children[0] !== undefined && document.getElementById('label_exercise_example_data_selector_popup') !== null) {
                    document.getElementById('label_exercise_example_data_selector_popup').children[0].children[1].children[0].children[0].append(
                        document.getElementById('action_add_example').children[0]
                    );
                }
            },
            async changeType(id){
                await this.$store.dispatch('getTypeExercise',{
                    id: id !== undefined ? id : document.querySelectorAll('input[name="exercise[type]"]:checked')[0]._value
                }, {root: true});

                var that = this
                setTimeout(function(){
                    that.ConfigExercisesTable.data.type = [that.$refs['RefType'].datasaved];
                    that.refreshType++;
                },15);

                this.$store.dispatch('getTypeExerciseByParent',{
                  id: this.ConfigExercisesTable.type_exercise.open_question.type || this.ConfigExercisesTable.type_exercise.close_numeric.type || this.ConfigExercisesTable.type_exercise.close_one_correct.type || this.ConfigExercisesTable.type_exercise.close_n_correct.type
                }, {root: true});

                switch (this.ConfigExercisesTable.type_exercise.open_question.type || this.ConfigExercisesTable.type_exercise.close_numeric.type || this.ConfigExercisesTable.type_exercise.close_one_correct.type || this.ConfigExercisesTable.type_exercise.close_n_correct.type){
                    case '1':
                        // this.showSubQuestionChildren = this.ConfigExercisesTable.type_exercise.open_question.subquestion.view;
                        this.view_type_exercise = type_open_question;
                        break;
                    case '3':
                        // this.showSubQuestionChildren = this.ConfigExercisesTable.type_exercise.close_numeric.subquestion.view;
                        this.view_type_exercise = type_close_numeric;
                        break;
                    case '4':
                        // this.showSubQuestionChildren = this.ConfigExercisesTable.type_exercise.close_one_correct.subquestion.view;
                        this.view_type_exercise = type_close_one_correct;
                        break;
                    case '5':
                        // this.showSubQuestionChildren = this.ConfigExercisesTable.type_exercise.close_n_correct.subquestion.view;
                        this.view_type_exercise = type_close_n_correct;
                        break;
                }

                // if(this.ConfigExercisesTable.type_exercise.open_question.procedure.view || this.ConfigExercisesTable.type_exercise.close_numeric.procedure.view || this.ConfigExercisesTable.type_exercise.close_one_correct.procedure.view || this.ConfigExercisesTable.type_exercise.close_n_correct.procedure.view){
                //     this.loadActionProcedure();
                // }

                if(this.ConfigExercisesTable.type_exercise.open_question.example.view || this.ConfigExercisesTable.type_exercise.close_numeric.example.view || this.ConfigExercisesTable.type_exercise.close_one_correct.example.view || this.ConfigExercisesTable.type_exercise.close_n_correct.example.view){
                    this.loadActionExample();
                }
            },
            // openNewProcedure(){
            //     this.Global.openPopUp('procedure_popup');
            // },
            openNewExample(){
                this.Global.openPopUp('example_popup');
            },
            async submitExercise(){
                if(this.Global.checkRequired('form_exercise') == 'OK') {
                    const formData = new FormData(document.getElementById('form_exercise'));
                    this.$store.dispatch('setExerciseData', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        level: this.$root._route.params.level,
                        condition: this.$root._route.params.condition
                    }, {root: true});
                }
            },
            persistance(type, where, ref, type_input){
                var params = {};
                if(type == 'general'){
                    params = {
                        type: type,
                        where: where,
                        value: type_input == 'text' ? this.$refs[ref].$el.children[1].value : '',
                    }

                    this.$store.dispatch('setTemporalExercise',{
                        params: params
                    }, {root: true});
                }
            }
        },
    }
</script>

<style>
    .selector-min-width-45 > div > div {
        min-width: 45% !important;
    }
    .selector-min-width-55 > div > div {
        min-width: 55% !important;
    }

    #label_exercise_sector, #label_exercise_procedure, #label_exercise_example, #label_exercise_bibliography, #label_exercise_videography, #label_exercise_support_material{
      margin-right: 50px;
    }
</style>