import ListItineraries from "@/view/creation/itineraries/list_itineraries";
import Itinerario from "@/routes/creation/itineraries/itinerary/route";

const route = [
    {
        path: '/list_creation_itineraries',
        name: 'list_creation_itineraries',
        component: ListItineraries,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Itinerario,
]


export default routes;