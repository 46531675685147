<template>
    <div class="float_window">
        <GhNoDropSelector v-if="show" :datablock="{id: 'list_business',title: $t('company_file')}">
            <template v-slot:content v-if="haveData">
                <template v-if="view">
                    <ViewBusiness/>
                </template>
                <template v-if="!view">
                    <EditBusiness/>
                </template>
            </template>
            <template v-slot:actions v-if="view">
                <GhAction
                    :dataction="{
                        id: 'modify_business',
                        text: $t('modify'),
                        icon: require('../../assets/gh_new_dessign/edit.svg'),
                    }"
                    @click="changeState"
                />
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;" v-if="!view">
            <GhButton
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                type="submit"
                @click="modifyBusiness"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="changeState"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhAction from 'fe-gh-action-lib';
    import GhButton from "fe-gh-button-lib";
    import ViewBusiness from './template/view';
    import EditBusiness from './template/edit';
    import {mapState} from "vuex";
    export default {
        name: "list_business",
        components: {
            GhNoDropSelector,
            GhAction,
            GhButton,
            ViewBusiness,
            EditBusiness
        },
        computed:{
            ...mapState(['Login'])
        },
        data(){
            return {
                show: false,
                view: true,
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getBusinessData', {},{root:true});
            await this.$store.dispatch('getSocialReason', '', {root:true});
            await this.$store.dispatch('getCountry', '', {root:true});
            await this.$store.dispatch('getActivity', '', {root:true});
            await this.$store.dispatch('getNumEmployers', '', {root:true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('company')){
                    that.Login.tabs[route.id]['sub-modules'].forEach((subroute) => {
                        that.show = subroute.have_permission;
                    });
                }
            });

            this.haveData = true;
        },
        methods:{
            changeState(){
                this.view = !this.view;
            },
            async modifyBusiness(){
                if(this.Global.checkRequired('form_business') == 'OK') {
                    const formData = new FormData(document.getElementById('form_business'));
                    this.$store.dispatch('setBusinessData', {formData: [...formData]},{root:true});
                    this.changeState();
                }
            }
        }
    }
</script>