<template>
    <div v-if="haveData">
        <div class="gh-row-content" v-if="ConfigExercisesTable.type_exercise.close_numeric.responsible !== undefined && ConfigExercisesTable.type_exercise.close_numeric.responsible.view">
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_numeric_responsible',
                        class: 'T15_b',
                        subtitle: $t('select_responsible') + ':',
                        text: $t('responsible') + (ConfigExercisesTable.type_exercise.close_numeric.responsible.required ? '*' : ''),
                        label: $t('responsible'),
                        type: 'radio',
                        columns: 3,
                        style: '',
                        required: ConfigExercisesTable.type_exercise.close_numeric.responsible.required,
                    }"
                    :datainput="{
                        id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_numeric_responsible',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_numeric][responsible]'
                    }"
                    :selected_input="!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.responsible ? ConfigExercisesTable.data.subquestion_close_numeric.responsible : []) : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.responsible ? ConfigExercisesTable.data.close_numeric.responsible : [])"
                    :feed="getFeeds.feed_responsible"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.responsible ? Object.values(ConfigExercisesTable.data.subquestion_close_numeric.responsible).join() : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.responsible ? Object.values(ConfigExercisesTable.data.close_numeric.responsible).join() : '')}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhTextarea
                    :datalabel="{
                        text: $t('statement') + '*',
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_numeric_statement',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_numeric][statement]',
                        value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.statement : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.statement : ''),
                        required: true,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                    :ref="!showSubQuestion ? 'RefSubquestionCloseNumericStatement' : 'RefCloseNumericStatement'"
                    @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'statement', !showSubQuestion ? 'RefSubquestionCloseNumericStatement' : 'RefCloseNumericStatement', 'text')"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('statement') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.statement : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.statement : '')"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhTextarea
                    :datalabel="{
                        text: $t('information'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_' + (!showSubQuestion ? '_subquestion_' : '') + 'close_numeric_information',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_numeric][information]',
                        value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.information : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.information : ''),
                        required: false
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('information') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.information : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.information : '')}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhTextarea
                    :datalabel="{
                        text: $t('score_legend'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_' + (!showSubQuestion ? '_subquestion_' : '') + 'close_numeric_score_legend',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_numeric][score_legend]',
                        value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.score_legend : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.score_legend : ''),
                        required: false
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('score_legend') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.score_legend : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.score_legend : '')}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('minimum_value') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_numeric_value_min',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_numeric][value][min]',
                        style: 'width: 100px',
                        value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.value !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.value.min : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.value !== undefined ? ConfigExercisesTable.data.close_numeric.value.min : ''),
                        type: 'text',
                    }"
                    :ref="!showSubQuestion ? 'RefSubquestionCloseNumericValueMin' : 'RefCloseNumericValueMin'"
                    @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'value', !showSubQuestion ? 'RefSubquestionCloseNumericValueMin' : 'RefCloseNumericValueMin', 'text', 'min')"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('minimum_value') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.value !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.value.min : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.value !== undefined ? ConfigExercisesTable.data.close_numeric.value.min : '')}}</label>
            </template>

            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('maximum_value') + '*',
                        style: 'width: 160px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_numeric_value_max',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_numeric][value][max]',
                        style: 'width: 100px',
                        value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.value !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.value.max : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.value !== undefined ? ConfigExercisesTable.data.close_numeric.value.max : ''),
                        type: 'text',
                    }"
                    :ref="!showSubQuestion ? 'RefSubquestionCloseNumericValueMax' : 'RefCloseNumericValueMax'"
                    @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'value', !showSubQuestion ? 'RefSubquestionCloseNumericValueMax' : 'RefCloseNumericValueMax', 'text', 'max')"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('maximum_value') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.value.max : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.value.max : '')}}</label>
            </template>
        </div>
        <template v-if="showSubQuestion && ConfigExercisesTable.type_exercise.close_numeric.subquestion !== undefined && ConfigExercisesTable.type_exercise.close_numeric.subquestion.view">
            <div class="section_line" style="margin-bottom: 15px;">
                <div class="T15_b">{{$t('subquestion')}}</div>
            </div>
            <GhTableCommon :key="'A'+refresh_table_no_condition"
                :extratable="{
                    id: 'subquestions',
                }"
                :header="header"
                :data="table_no_condition"
            >
                <template v-slot:actions="{itemProp}">
                    <GhAction v-if="itemProp.new"
                        :dataction="{
                            id: 'edit_subquestions',
                            text: $t('modify'),
                            icon: require('../../../../../../assets/gh_new_dessign/edit.svg'),
                        }"
                        @click="editNewSubquestion(itemProp.id)"
                    />
                    <GhAction
                        :dataction="{
                            id: 'delete_subquestions',
                            text: $t('delete'),
                            icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                        }"
                        @click="Global.deleteConfirm('delete_confirm',itemProp.new ? 'SubquestionNew' : 'Subquestion', itemProp.id,{'name': itemProp.name, 'type_of_exercise': itemProp.type}, $t('delete_subquestion'), $t('preparing_delete_subquestion'), 'delete')"
                    />
                </template>
                <template v-slot:footer>
                    <div class="centered">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_new_subquestions',
                                text: $t('add_new')
                            }"
                            @click="newSubquestion"
                        />
                        <GhButton v-if="getFeeds.feed_excerises_no_subquestions.length !== 0"
                            :datainput="{
                                id: 'btn_add_old_subquestions',
                                text: $t('add_existing')
                            }"
                            @click="Global.openPopUp('subquestion_exist_data_selector_popup')"
                        />
                    </div>
                </template>
            </GhTableCommon>

            <div style="display: none;">
                <div v-for="(item,index) in table_no_condition" :key="'B'+index"><input type="hidden" name="exercise[close_numeric][subquestion][]" v-if="item.new" :value="item.id"></div>
            </div>

            <GhDataSelector ref="subquestion_exist_no_condition" :key="'C'+refresh_no_condition"
                :datablock="{
                    id: 'subquestion_exist',
                    class: 'T15_b',
                    subtitle: $t('select_existing_exercise') + ':',
                    text: $t('add_existing'),
                    label: $t('add_existing'),
                    type: 'checkbox',
                    columns: 3,
                    style: 'width: 149px;display: none;',
                    required: false,
                }"
                :datainput="{
                    id: 'value_subquestion_exist',
                    name: 'exercise[close_numeric][subquestion][]'
                }"
                :selected_input="selected_no_condition"
                :feed="getFeeds.feed_excerises_no_subquestions"
                @accept="addExerciseExist"
            />
        </template>
        <template v-if="ConfigExercisesTable.type_exercise.close_numeric.conditions !== undefined && ConfigExercisesTable.type_exercise.close_numeric.conditions.length != 0">
            <div class="section_line" style="margin-bottom: 15px;">
                <div class="T15_b">{{$t('conditions')}}</div>
            </div>

            <div v-for="(item,index) in for_table_condition" :key="'NC-'+item">

                <template v-for="(subitem, subindex) in ConfigExercisesTable.type_exercise.close_numeric.conditions">
                    <div class="gh-row-content" v-if="subitem == 1" :key="'condition_'+subindex+'_'+index">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('value_greather_than') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'exercise_close_numeric_condition_higher_than_'+index,
                                    name: 'exercise[close_numeric][condition]['+index+'][higher_than]',
                                    style: 'width: 100px',
                                    value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][1] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][1].higher_than !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][1].higher_than : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][1] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][1].higher_than !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][1].higher_than : ''),
                                    type: 'text',
                                }"
                                :ref="!showSubQuestion ? 'RefSubquestionCloseNumericConditionHigherThan' : 'RefCloseNumericConditionHigherThan'"
                                @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !showSubQuestion ? 'RefSubquestionCloseNumericConditionHigherThan' : 'RefCloseNumericConditionHigherThan', 'text', 'higher_than', 1)"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('value_greather_than') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][1].higher_than : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.condition[index] !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][1].higher_than : '')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content" v-if="subitem == 2" :key="'condition_'+subindex+'_'+index">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('value_less_than') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'exercise_close_numeric_condition_minor_than_'+index,
                                    name: 'exercise[close_numeric][condition]['+index+'][minor_than]',
                                    style: 'width: 100px',
                                    value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][2] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][2].minor_than !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][2].minor_than : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][2] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][2].minor_than !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][2].minor_than : ''),
                                    type: 'text',
                                }"
                                :ref="!showSubQuestion ? 'RefSubquestionCloseNumericConditionMinorThan' : 'RefCloseNumericConditionMinorThan'"
                                @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !showSubQuestion ? 'RefSubquestionCloseNumericConditionMinorThan' : 'RefCloseNumericConditionMinorThan', 'text', 'minor_than', 2)"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('value_less_than') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][2].minor_than : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][2].minor_than : '')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content" v-if="subitem == 3" :key="'condition_'+subindex+'_'+index">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('value_same_as') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'exercise_close_numeric_condition_equal_'+index,
                                    name: 'exercise[close_numeric][condition]['+index+'][equal]',
                                    style: 'width: 100px',
                                    value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][3] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][3]['equal'] !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][3]['equal'] : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][3] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][3]['equal'] !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][3]['equal'] : ''),
                                    type: 'text',
                                }"
                                :ref="!showSubQuestion ? 'RefSubquestionCloseNumericConditionEqual' : 'RefCloseNumericConditionEqual'"
                                @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !showSubQuestion ? 'RefSubquestionCloseNumericConditionEqual' : 'RefCloseNumericConditionEqual', 'text', 'equal', 3)"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('value_same_as') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][3]['equal'] : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][3]['equal'] : '')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content" v-if="subitem == 4" :key="'condition_'+subindex+'_'+index">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('value_between') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'exercise_close_numeric_condition_between_1_'+index,
                                    name: 'exercise[close_numeric][condition]['+index+'][between][1]',
                                    style: 'width: 100px',
                                    value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][1] !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][1] : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][4] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][4][1] !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][4][1] : ''),
                                    type: 'text',
                                    label_required: $t('value_between') + '*'
                                }"
                                :ref="!showSubQuestion ? 'RefSubquestionCloseNumericConditionBetween1' : 'RefCloseNumericConditionBetween1'"
                                @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !showSubQuestion ? 'RefSubquestionCloseNumericConditionBetween1' : 'RefCloseNumericConditionBetween1', 'text', 1, 4)"
                            />

                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18px; min-width: 18px; text-align: right;">{{$t('and')}}</label>

                            <GhInputTextField
                                :datalabel="{
                                    text: '',
                                    style: 'width: 18px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'exercise_close_numeric_condition_between_2_'+index,
                                    name: 'exercise[close_numeric][condition]['+index+'][between][2]',
                                    style: 'width: 100px',
                                    value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][2] !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][2] : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][4] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][4][2] !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][4][2] : ''),
                                    type: 'text',
                                    label_required: $t('value_between') + '*'
                                }"
                                :ref="!showSubQuestion ? 'RefSubquestionCloseNumericConditionBetween2' : 'RefCloseNumericConditionBetween2'"
                                @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !showSubQuestion ? 'RefSubquestionCloseNumericConditionBetween2' : 'RefCloseNumericConditionBetween2', 'text', 2, 4)"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('value_between') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][1] : '' + $t('and') + ' ' + ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][2] : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.condition[4][1] : '' + $t('y') + ' ' + ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.condition[4][2] : '')}}</label>
                        </template>
                    </div>

                    <div class="gh-row-content" :key="'feedback_'+subindex+'_'+index" v-if="ConfigExercisesTable.type_exercise.close_numeric.feedback !== undefined && ConfigExercisesTable.type_exercise.close_numeric.feedback.view">
                        <template v-if="!$root._route.params.view">
                            <GhTextarea
                                :datalabel="{
                                    text: $t('feedback') + (ConfigExercisesTable.type_exercise.close_numeric.feedback.required ? '*' : ''),
                                    class: 'T15_b',
                                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                }"
                                :datainput="{
                                    id: 'exercise_close_numeric_feedback_'+index,
                                    name: 'exercise[close_numeric][feedback]['+index+']['+subitem+']',
                                    value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][subitem] !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.condition[index][subitem].feedback !== undefined ? ConfigExercisesTable.data.subquestion_close_numeric.condition[index][subitem].feedback : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][subitem] !== undefined && ConfigExercisesTable.data.close_numeric.condition[index][subitem].feedback !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][subitem].feedback : ''),
                                    required: ConfigExercisesTable.type_exercise.close_numeric.feedback.required
                                }"
                                :ref="!showSubQuestion ? 'RefSubquestionCloseNumericConditionFeedback'+subitem : 'RefCloseNumericConditionFeedback'+subitem"
                                @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !showSubQuestion ? 'RefSubquestionCloseNumericConditionFeedback'+item : 'RefCloseNumericConditionFeedback'+item, 'text', 'feedback', item)"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][subitem].feedback : '') : (ConfigExercisesTable.data.close_numeric !== undefined ? ConfigExercisesTable.data.close_numeric.condition[index][subitem].feedback : '')}}</label>
                        </template>
                    </div>
                </template>
                <div class="gh-row-content">
                    <template v-if="!$root._route.params.view">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('punctuation') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'exercise_close_numeric_puntuation_'+index,
                                name: 'exercise[close_numeric][puntuation]['+index+']',
                                style: 'width: 100px',
                                value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] !== undefined  && ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] !== null ? ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.puntuation[index] !== undefined && ConfigExercisesTable.data.close_numeric.puntuation[index] !== null ? ConfigExercisesTable.data.close_numeric.puntuation[index] : ''),
                                type: 'text',
                            }"
                            :ref="!showSubQuestion ? 'RefSubquestionCloseNumericConditionPuntuation' : 'RefCloseNumericConditionPuntuation'"
                            @keyup="persistance(!showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'puntuation', !showSubQuestion ? 'RefSubquestionCloseNumericConditionPuntuation' : 'RefCloseNumericConditionPuntuation', 'text')"
                        />
                    </template>
                    <template v-else>
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('punctuation') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_numeric !== undefined && ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] !== undefine && ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] !== null ? ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] : '') : (ConfigExercisesTable.data.close_numeric !== undefined && ConfigExercisesTable.data.close_numeric.puntuation[index] !== undefined && ConfigExercisesTable.data.close_numeric.puntuation[index] !== null ? ConfigExercisesTable.data.close_numeric.puntuation[index] : '')}}</label>
                    </template>
                </div>

                <GhAction v-if="for_table_condition.length > 1"
                    :dataction="{
                        id: 'delete_condition_'+index,
                        text: $t('delete_condition'),
                    }"
                    @click="deleteBlockCondition(index)"
                />

                <div class="section_line" style="margin-bottom: 15px;" v-if="showSubQuestionCondition && ConfigExercisesTable.type_exercise.close_numeric.subquestion_condition !== undefined && ConfigExercisesTable.type_exercise.close_numeric.subquestion_condition.view">
                    <div class="T15_b">{{$t('subquestion')}}</div>
                </div>

                <GhTableCommon :key="'T'+refresh_table_condition" v-if="showSubQuestionCondition && ConfigExercisesTable.type_exercise.close_numeric.subquestion_condition !== undefined && ConfigExercisesTable.type_exercise.close_numeric.subquestion_condition.view"
                    :extratable="{
                        id: 'subquestions_contitions_'+index,
                    }"
                    :header="header"
                    :data="table_condition[index]"
                >
                    <template v-slot:actions="{itemProp}">
                        <GhAction v-if="itemProp.new"
                            :dataction="{
                                id: 'edit_subquestions_condition_'+index,
                                text: $t('modify'),
                                icon: require('../../../../../../assets/gh_new_dessign/edit.svg'),
                            }"
                            @click="editNewSubquestionCondition(itemProp.id)"
                        />
                        <GhAction
                            :dataction="{
                                id: 'delete_subquestions_condition_'+index,
                                text: $t('delete'),
                                icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                            }"
                            @click="levelCondition(index);Global.deleteConfirm('delete_confirm',itemProp.new ? 'SubquestionNewCondition' : 'SubquestionCondition', itemProp.id,{'name': itemProp.name, 'type_of_exercise': itemProp.type}, $t('delete_subquestion'), $t('preparing_delete_subquestion'), 'delete')"
                        />
                    </template>
                    <template v-slot:footer>
                        <div class="centered">
                            <GhButton
                                :datainput="{
                                    id: 'btn_add_new_subquestions_condition_'+index,
                                    text: $t('add_new')
                                }"
                                @click="newSubquestionCondition(index)"
                            />
                            <GhButton v-if="getFeeds.feed_excerises_no_subquestions.length !== 0"
                                :datainput="{
                                    id: 'btn_add_old_subquestions_condition_'+index,
                                    text: $t('add_existing')
                                }"
                                @click="Global.openPopUp('subquestion_exist_condition_'+index+'_data_selector_popup')"
                            />
                        </div>
                    </template>
                </GhTableCommon>

                <div style="display: none;">
                    <div v-for="(subitem,subindex) in table_condition[index]" :key="'VC'+subindex"><input type="hidden" :name="'exercise[close_numeric][condition]['+index+'][subquestion][]'" v-if="subitem.new" :value="subitem.id"></div>
                </div>

                <GhDataSelector ref="subquestion_exist_condition" :key="'WC'+index+'-'+refresh_condition"
                    :datablock="{
                        id: 'subquestion_exist_condition_'+index,
                        class: 'T15_b',
                        subtitle: $t('select_existing_exercise') + ':',
                        text: $t('add_existing'),
                        label: $t('add_existing'),
                        type: 'checkbox',
                        columns: 3,
                        style: 'width: 149px;display: none;',
                        required: false,
                    }"
                    :datainput="{
                        id: 'value_subquestion_exist_condition_'+index,
                        name: 'exercise[close_numeric][condition]['+index+'][subquestion][]'
                    }"
                    :selected_input="selected_condition[index]"
                    :feed="getFeeds.feed_excerises_no_subquestions"
                    @accept="addExerciseExistCondition(index)"
                    class="hidden_block_summary"
                />
            </div>
        </template>
        <GhAction
            :dataction="{
                id: 'new_condition',
                text: $t('add_another_condition'),
            }"
            @click="newBlockCondition"
        />
    </div>
</template>

<script>
    import type_close_numeric from '../includes/type_close_numeric';
    import type_open_question from "../includes/type_open_question";
    import type_close_one_correct from '../includes/type_close_one_correct';
    import type_close_n_correct from '../includes/type_close_n_correct';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import {mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhAction from "fe-gh-action-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";
    export default {
        name: "type_close_numeric",
        props: ['level'],
        computed: {
            ...mapState(['ConfigExercisesTable', 'getFeeds'])
        },
        components: {
            GhDataSelector,
            GhTextarea,
            GhInputTextField,
            GhTableCommon,
            GhAction,
            GhButton,
        },
        data(){
            return {
                view_sub_type_exercise: '',
                refresh_no_condition: 0,
                refresh_condition: 0,
                level_remove_condition: 0,
                actual_level: this.level === undefined ? 0 : parseInt(this.level),
                refresh_table_no_condition: 0,
                refresh_table_condition: 0,
                selected_no_condition: [],
                selected_condition: [],
                table_no_condition: [],
                table_condition: [],
                for_table_condition: [0],
                showSubQuestion: false,
                showSubQuestionCondition: false,
                haveData: false,
                header:[
                    {text: this.$t('statement'), field: 'name', sorting: true, typeSort: 'string', style: 'width: 80%;'},
                    {text: this.$t('type_of_exercise'), field: 'type', sorting: true, typeSort: 'string', style: 'max-width: 200px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        async beforeMount() {
            this.ConfigExercisesTable.to_remove_key_table = 0;
            this.ConfigExercisesTable.to_remove_key_table_condition = 0;
            this.showSubQuestion = this.$parent.$parent.showSubQuestionChildren;
            this.showSubQuestionCondition = this.$parent.$parent.showSubQuestionConditionChildren;

            await this.$store.dispatch('getFeedExercisesWithoutSubquestions','',{root:true});

            this.selected_no_condition = this.ConfigExercisesTable.data.close_numeric.subquestion !== undefined && this.ConfigExercisesTable.data.close_numeric.subquestion.selecteds !== undefined && this.ConfigExercisesTable.data.close_numeric.subquestion.selecteds.length !== 0 ? this.ConfigExercisesTable.data.close_numeric.subquestion.selecteds : {};
            var selecteds = this.selected_no_condition;
            var tmp_array = [];
            for(var e=0;e<Object.keys(selecteds).length;e++){
                tmp_array.push(Object.keys(selecteds)[e]);
            }

            await this.$store.dispatch('getExercisesLikeSubquestion',{exercises:tmp_array,state:'old',level:this.actual_level},{root:true});
            this.table_no_condition = [];

            if(this.ConfigExercisesTable.table_subquestions[this.actual_level] !==  undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].old !== undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].old.length !== 0){
                for(var i=0;i<this.ConfigExercisesTable.table_subquestions[this.actual_level].old.length;i++){
                    this.table_no_condition.push(this.ConfigExercisesTable.table_subquestions[this.actual_level].old[i]);
                }
            }

            if(this.ConfigExercisesTable.table_subquestions[this.actual_level] !==  undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].new !== undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].new.length !== 0){
                for(var t=0;t<this.ConfigExercisesTable.table_subquestions[this.actual_level].new.length;t++){
                    this.table_no_condition.push(this.ConfigExercisesTable.table_subquestions[this.actual_level].new[t]);
                }
            }
            this.refresh_table_no_condition++;

            if(this.ConfigExercisesTable.data.close_numeric.condition !== undefined){
                for(var j=0;j<this.ConfigExercisesTable.data.close_numeric.condition.length;j++){
                    if(this.selected_condition[j] === undefined) this.selected_condition[j] = {};
                    this.selected_condition[j] = this.ConfigExercisesTable.data.close_numeric.condition[j] !== undefined && this.ConfigExercisesTable.data.close_numeric.condition[j].subquestions !== undefined && this.ConfigExercisesTable.data.close_numeric.condition[j].subquestions.selecteds !== undefined && this.ConfigExercisesTable.data.close_numeric.condition[j].subquestions.selecteds.length !== 0 ? this.ConfigExercisesTable.data.close_numeric.condition[j].subquestions.selecteds : {};

                    var selecteds_condition = this.selected_condition[j];
                    var tmp_array_condition = [];
                    for(var ec=0;ec<Object.keys(selecteds_condition).length;ec++){
                        tmp_array_condition.push(Object.keys(selecteds_condition)[ec]);
                    }

                    await this.$store.dispatch('getExercisesLikeSubquestionCondition',{exercises:tmp_array_condition,state:'old',level:this.actual_level,condition:j},{root:true});
                }
            }

            this.ConfigExercisesTable.table_subquestions_condition[this.actual_level] = this.ConfigExercisesTable.table_subquestions_condition[this.actual_level] !== undefined ? this.ConfigExercisesTable.table_subquestions_condition[this.actual_level] : [];
            for(var x=0;x<Object.keys(this.ConfigExercisesTable.table_subquestions_condition).length;x++){
                if(this.table_condition[x] === undefined) this.table_condition[x] = [];

                if(
                    this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x] !== undefined &&
                    this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x].old !== undefined &&
                    this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x].old.length !== 0){
                    for(var h = 0; h < this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x].old.length; h++) {
                        this.table_condition[x].push(this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x].old[h]);
                    }
                }

                if(this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x] !== undefined && this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x].new !== undefined && this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x].new.length !== 0){
                    for(var k = 0; k < this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x].new.length; k++) {
                        this.table_condition[x].push(this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][x].new[k]);
                    }
                }
            }

            this.refresh_table_condition++;

            for(var b=0;b<this.ConfigExercisesTable.data.close_numeric.condition.length;b++){
                if(b != 0) this.newBlockCondition();
            }
            this.haveData = true;
        },
        watch:{
            'ConfigExercisesTable.to_remove_key_table'(data){
                delete this.$refs.subquestion_exist_no_condition.checkdata[data];
                this.$refs.subquestion_exist_no_condition.countdata--;
                this.selected_no_condition = this.$refs.subquestion_exist_no_condition.checkdata;
                var to_uncheck = document.querySelectorAll('input[name="exercise[close_numeric][subquestion][]"][value="'+data+'"]')[0];
                to_uncheck.checked = false;
                this.refresh_no_condition++;
                this.addExerciseExist();
            },
            'ConfigExercisesTable.to_remove_key_table_condition'(data){
                console.log(this.$refs.subquestion_exist_condition);
                console.log(this.$refs.subquestion_exist_condition[this.level_remove_condition]);
                delete this.$refs.subquestion_exist_condition[this.level_remove_condition].checkdata[data];
                this.$refs.subquestion_exist_condition[this.level_remove_condition].countdata--;
                this.selected_condition[this.level_remove_condition] = this.$refs.subquestion_exist_condition[this.level_remove_condition].checkdata;
                var to_uncheck = document.querySelectorAll('input[name="exercise[close_numeric][condition]['+this.level_remove_condition+'][subquestion][]"][value="'+data+'"]')[0];
                to_uncheck.checked = false;
                this.refresh_condition++;
                this.addExerciseExistCondition(this.level_remove_condition);
            }
        },
        methods: {
            levelCondition(index){
                this.level_remove_condition = index;
            },
            newBlockCondition(){
                var block = this.for_table_condition[this.for_table_condition.length-1];
                block++;
                this.for_table_condition.push(block);
            },
            deleteBlockCondition(item){
                delete this.for_table_condition[item];
                this.for_table_condition = this.for_table_condition.filter(n => n || n == 0);
            },
            async addExerciseExist(){
                var selecteds = document.querySelectorAll('input[name="exercise[close_numeric][subquestion][]"]:checked');
                var tmp_array = [];
                for(var i=0;i<selecteds.length;i++){
                    tmp_array.push(selecteds[i].value);
                }

                await this.$store.dispatch('getExercisesLikeSubquestion',{exercises:tmp_array,state:'old',level:this.actual_level},{root:true});
                this.table_no_condition = [];

                if(this.ConfigExercisesTable.table_subquestions[this.actual_level].old !== undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].old.length !== 0){
                    for(var n=0;n<this.ConfigExercisesTable.table_subquestions[this.actual_level].old.length;n++){
                        this.table_no_condition.push(this.ConfigExercisesTable.table_subquestions[this.actual_level].old[n]);
                    }
                }

                if(this.ConfigExercisesTable.table_subquestions[this.actual_level].new !== undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].new.length !== 0){
                    for(var t=0;t<this.ConfigExercisesTable.table_subquestions[this.actual_level].new.length;t++){
                        this.table_no_condition.push(this.ConfigExercisesTable.table_subquestions[this.actual_level].new[t]);
                    }
                }

                this.refresh_table_no_condition++;
            },
            async addExerciseExistCondition(index){
                var selecteds = document.querySelectorAll('input[name="exercise[close_numeric][condition]['+index+'][subquestion][]"]:checked');
                console.log(selecteds);
                var tmp_array = [];
                for(var i=0;i<selecteds.length;i++){
                    tmp_array.push(selecteds[i].value);
                }

                await this.$store.dispatch('getExercisesLikeSubquestionCondition',{exercises:tmp_array,state:'old',level:this.actual_level,condition:index},{root:true});
                this.table_condition[index] = [];

                if(this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][index].old !== undefined && this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][index].old.length !== 0){
                    for(var n=0;n<this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][index].old.length;n++){
                        this.table_condition[index].push(this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][index].old[n]);
                    }
                }

                if(this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][index].new !== undefined && this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][index].new.length !== 0){
                    for(var t=0;t<this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][index].new.length;t++){
                        this.table_condition[index].push(this.ConfigExercisesTable.table_subquestions_condition[this.actual_level][index].new[t]);
                    }
                }

                this.refresh_table_condition++;
            },
            newSubquestion(){
                var level_send = this.level === undefined ? 1 : parseInt(this.level)+1;
                this.Global.windowOpen(this.Global.openSecondWindow('config_exercise_subquestion', {subquestion:'subquestion',level:level_send,condition:'n'}),'new_subquestion_'+level_send);
            },
            editNewSubquestion(id){
                var level_send = this.level === undefined ? 1 : parseInt(this.level)+1;
                this.Global.windowOpen(this.Global.openSecondWindow('config_exercise_subquestion_id', {subquestion:'subquestion',level:level_send,id:id,condition:'n'}),'new_subquestion_'+level_send);
            },
            newSubquestionCondition(index){
                var level_send = this.level === undefined ? 1 : parseInt(this.level)+1;
                this.Global.windowOpen(this.Global.openSecondWindow('config_exercise_subquestion', {subquestion:'subquestion',level:level_send,condition:'c-'+index}),'new_subquestion_'+level_send);
            },
            editNewSubquestionCondition(id){
                var level_send = this.level === undefined ? 1 : parseInt(this.level)+1;
                this.Global.windowOpen(this.Global.openSecondWindow('config_exercise_subquestion_id', {subquestion:'subquestion',level:level_send,id:id,condition:'c'}),'new_subquestion_'+level_send);
            },
            async changeSubQuestion(id){
                await this.$store.dispatch('getTypeExercise',{
                    id: id !== undefined ? id : document.querySelectorAll('input[name="exercise' + (!this.showSubQuestion ? '[subquestion]' : '') + '[close_numeric][subquestion][type][]"]:checked')[0]._value,
                    subquestion: true
                }, {root: true});

                if(this.ConfigExercisesTable.type_exercise.open_question.active_sub_question){
                    this.view_sub_type_exercise = type_open_question;
                }else if(this.ConfigExercisesTable.type_exercise.close_n_correct.active_sub_question){
                    this.view_sub_type_exercise = type_close_n_correct;
                }else if(this.ConfigExercisesTable.type_exercise.close_one_correct.active_sub_question){
                    this.view_sub_type_exercise = type_close_one_correct;
                }else if(this.ConfigExercisesTable.type_exercise.close_numeric.active_sub_question){
                    this.view_sub_type_exercise = type_close_numeric;
                }

                // if(this.ConfigExercisesTable.type_exercise.open_question.procedure.view || this.ConfigExercisesTable.type_exercise.close_numeric.procedure.view || this.ConfigExercisesTable.type_exercise.close_one_correct.procedure.view || this.ConfigExercisesTable.type_exercise.close_n_correct.procedure.view){
                //     this.$parent.$parent.loadActionProcedure();
                // }

                if(this.ConfigExercisesTable.type_exercise.open_question.example.view || this.ConfigExercisesTable.type_exercise.close_numeric.example.view || this.ConfigExercisesTable.type_exercise.close_one_correct.example.view || this.ConfigExercisesTable.type_exercise.close_n_correct.example.view){
                    this.$parent.$parent.loadActionExample();
                }
            },
            persistance(type, where, ref, type_input, extra_where, key){
                var reference = this.$refs[ref].length !== undefined ? this.$refs[ref][0] : this.$refs[ref];
                var params = {};

                params = {
                    type: type,
                    where: where,
                    extra_where: extra_where,
                    key: key,
                    value: reference.$el.children.length == 2 ? reference.$el.children[1].value : reference.$el.children[0].value,
                }

                this.$store.dispatch('setTemporalExercise',{
                    params: params
                }, {root: true});

                if(type == 'close_numeric'){
                    params = {
                        type: type,
                        where: where,
                        extra_where: extra_where,
                        key: key,
                        value: type_input == 'text' ? this.$refs[ref].$el === undefined ? this.$refs[ref][0].$el.children[1].value : this.$refs[ref].$el.children[1].value : '',
                    }

                    this.$store.dispatch('setTemporalExercise',{
                        params: params
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #label_exercise_close_numeric_responsible_selector_label, #label_exercise_subquestion_close_numeric_responsible_selector_label{
        width: 150px;
        text-align: end;
    }

    #subquestion_exist_data_select_SUMMARY,.hidden_block_summary > div > .gh_data_selector-summary{
        display: none !important;
    }
</style>