import UserFileList from "@/view/user_file/list_user_file";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/user_file',
        name: i18n.t('user_file'),
        component: UserFileList,
        props: {showMenu: true},
    }
];

export default routes;