/**
 * Author: Sunil Shrestha
 * Date: July 26, 2023
 * Version: 2
 */
import { API_URL } from "@/common/config";
import axios from "axios";
import store from "@/store/index";
import router from "@/routes/router";

// Default values for token name
const defaultTokenName = 'token';
const defaultUserTab  = '/todo';
export const AuthService = {

        checkLocalStorageAndCookie(token_name = defaultTokenName, cookie_name = '', callback_url = '') {
            const _token = this.getToken(token_name)
            const _cookie = this.getCookie(cookie_name);

            if ((!_token || !_cookie) && callback_url) {
                window.location.href = callback_url;
            }
        },
        getCookie(name) {
            const value = "; " + document.cookie;
            const parts = value.split("; " + name + "=");
            if (parts.length === 2) {
                return parts.pop().split(";").shift();
            }
            return null;
        },
        getToken(name) {
            const token = localStorage.getItem(name);
            return token ? token : null;
        },
        getAuthType: async function () {
            let endpoint = API_URL + 'auth/parameters' ;
            const response = await axios.get(endpoint);
            const authType = response.data; // Modify this based on your API response
            return authType;

        },
        doAuthSSO:  async function() {
            const apiEndpoint = API_URL + "auth/login"; // Replace this with the actual API endpoint URL
            let data = {}; // Add your request data here
            const response = await axios.post(apiEndpoint, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            // Call a separate function to handle the successful response
            await this.handleAuthSuccess(response.data.data);
        },
        redirectUserAfterAuth: function(data){
            const savedTabItem = localStorage.getItem('TabItem');
            let targetRoute = '';
            if(data.questionary_done === 0)
            {
                targetRoute = 'initial_itinerary';
            }
            else
            {
                targetRoute = savedTabItem
                    ? router.options.routes.find((route) => route.name === savedTabItem)?.path
                    : defaultUserTab;
            }
            router.push({ path: targetRoute });
            location.reload();
        },
        isAuthenticated: function () {
            return !!localStorage.getItem(defaultTokenName);
        },
        getAccessToken: function () {
            return localStorage.getItem(defaultTokenName);
        },
        verifyToken: async function () {
            const token = this.getAccessToken();
            if (!token) {
                throw new Error("Token not found in local storage.");
            }
            const apiEndpoint = API_URL + "auth/verify";
            try {
                const response = await axios.post(apiEndpoint, { token });
                return response.status === 200;
            } catch (error) {
                console.error("Error verifying token:", error);
                return false;
            }
        },
        // Define functions to handle successful authentication
        async handleAuthSuccess(data) {
            localStorage.clear();
            store.commit("LOAD_ERROR", '');
            await store.dispatch('setUserAuthData', data) ;
            await store.dispatch('setTokenExpiration', data) ;
            await store.dispatch('getTabsWithPerm', data) ;
            this.redirectUserAfterAuth(data);
        },

};

export default AuthService;
