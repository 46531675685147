import Vue from "vue";
import vuex from "vuex";
import router from "@/routes/router";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        subTabActive: '',
        nameSubTabActive:'',
        listActivities: [],
        listAmbits: [],
        listCertifictes: [],
        listItineraries: [],
    },
    mutations: {
        loadSubActiveItem(state, payload) {
            state.subTabActive = payload
        },
        loadNameSubActiveItem(state,payload){
            state.nameSubTabActive = payload
        },
        loadListActivities(state,payload){
            state.listActivities = payload
        },
        loadListAmbits(state,payload){
            state.listAmbits = payload
        },
        loadListCertificates(state,payload){
            state.listCertifictes = payload
        },
        loadlistItineraries(state,payload){
            state.listItineraries = payload
        },
    },
    actions: {
        getCreationTabActive(state, item) {
            let idTab = ''
            let name = ''
            router.options.routes.forEach((items,index) => {
                if ((item.call == items.name && item.call)  || items.name == item.value){
                    idTab = router.options.routes[index].component;
                    name = items.name
                }

                state.commit("loadSubActiveItem", idTab != '' ? idTab : '');
                state.commit("loadNameSubActiveItem", name != '' ? name : '');
            });
        },
        async getListActivities(state){
            let requestLogin = API_URL + 'creation/activities';

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadListActivities", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getListAmbits(state){
            let requestLogin = API_URL + 'creation/scopes';

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadListAmbits", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getListCertificates(state){
            let requestLogin = API_URL + 'creation/certificates';

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadListCertificates", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getlistItineraries(state){
            let requestLogin = API_URL + 'creation/itineraries';

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadlistItineraries", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeActivity(state,item){
            for(var i=0;i<state.state.listActivities.length;i++){
                if(state.state.listActivities[i].id == item.id){
                    delete state.state.listActivities[i];
                }
            }
            state.state.listActivities = state.state.listActivities.filter(function (item) {
                return item !== undefined;
            });

            let requestLogin = API_URL + 'creation/activities/'+item.id+'/delete/';

            await axios({
                method: "DELETE",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadlistItineraries", response.data.data);
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeAmbit(state,item){
            for(var i=0;i<state.state.listAmbits.length;i++){
                if(state.state.listAmbits[i].id == item.id){
                    delete state.state.listAmbits[i];
                }
            }
            state.state.listAmbits = state.state.listAmbits.filter(function (item) {
                return item !== undefined;
            });

            let requestLogin = API_URL + 'creation/scopes/'+item.id+'/delete';

            await axios({
                method: "DELETE",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadlistItineraries", response.data.data);
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeItinerary(state,item){
            for(var i=0;i<state.state.listItineraries.length;i++){
                if(state.state.listItineraries[i].id == item.id){
                    delete state.state.listItineraries[i];
                }
            }
            state.state.listItineraries = state.state.listItineraries.filter(function (item) {
                return item !== undefined;
            });

            let requestLogin = API_URL + 'creation/itineraries/'+item.id+'/delete/';

            await axios({
                method: "DELETE",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadlistItineraries", response.data.data);
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeCertificate(state,item){
            for(var i=0;i<state.state.listCertifictes.length;i++){
                if(state.state.listCertifictes[i].id == item.id){
                    delete state.state.listCertifictes[i];
                }
            }
            state.state.listCertifictes = state.state.listCertifictes.filter(function (item) {
                return item !== undefined;
            });

            let requestLogin = API_URL + 'creation/certificates/'+item.id+'/delete';

            await axios({
                method: "delete",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadlistItineraries", response.data.data);
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};