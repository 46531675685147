import ConfigSectors from "@/view/config/general/sectors/template/sector";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/general/sectors',
        name: 'config_sectors',
        component: ConfigSectors,
        props: {id: null, showMenu: true, otherTitle: i18n.t('sector')},
        children: [
            {
                path: '/config/general/sectors/:id',
                name: 'config_sectors_id',
                component: ConfigSectors,
                props: {id: null, showMenu: true, otherTitle: i18n.t('sector')},
            },
            {
                path: '/config/general/sectors/:id/:view',
                name: 'config_sectors_id_view',
                component: ConfigSectors,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('sector')},
            }
        ]
    }
];

export default routes;