import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
import router from "@/routes/router";
import i18n from "@/plugins/i18n";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        data: ''
    },
    mutations: {
        loadSeals(state, payload) {
            state.records_data = payload;
        },
        loadSealsData(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        async getSeals(state){
            await axios.get(
                API_URL + "seals"
            ).then(
                response => {
                    state.commit('loadSeals', response.data.data);
                }
            )
        },
        async getSealsData(state, items){
            if(items.id === undefined){
                state.commit("loadSealsData", '');
            }else {
                let requestLogin = API_URL + "seal/" + items.id;
                let formData = new FormData();
                formData.append("id", items.id);
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadSealsData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setSealData(state, item){
            let requestLogin = item.id ? API_URL + "seal/"+item.id : API_URL + "seal";
            let formData = new FormData();

            formData.append('seal[id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadSealsData", response.data);
                    router.push({name: i18n.t('configuration')});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeSeal(state, item){
            let requestLogin = API_URL + "seal/"+item.id+"/delete";
            let formData = new FormData();

            formData.append('seal[id]', item.id);
            formData.append('seal[type]', item.type);

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getSeals');
                        await this.dispatch('getConfigGeneralCounters');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
