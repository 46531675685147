var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhTableCommon',{attrs:{"extratable":{
            id: 'holidays_table',
        },"header":_vm.header,"data":_vm.ConfigHolidays.records_data.length == 0 ? [] : _vm.ConfigHolidays.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'holidays_view', params: {id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13",attrs:{"href":itemProp[labelProp]}},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'holidays_id', params: { id: itemProp.id, view: false}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_conf_supp_holiday',
                        text: _vm.$t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        item_prop: itemProp
                    }}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }