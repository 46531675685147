import SituationList from "@/view/situation/list_situation";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/my_situation',
        name: i18n.t('my_situation'),
        component: SituationList,
        props: {showMenu: true},
        children:[
            {
                path: '/my_situation/:id/:view',
                name: 'my_situation_view',
                component: SituationList,
                props: {id: null, showMenu: true,view:true,  otherTitle: i18n.t('my_situation')},
            },
        ]
    }
];

export default routes;
