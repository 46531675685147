var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"margin-top":"20px"}},[_c('div',[_c('GhSelectorPayment',{attrs:{"dataBlock":{
                id: 'pago',
                name: 'test[]',
                title: _vm.$t('start_way_excelence'),
                process_pay_text: _vm.$t('paying'),
                btn_text_shop: '',
                btn_text_pay: '',
                btn_text_cancel: '',
                mapState: 'ToPay',
                load_type_button: ['loadTypeButton', 'typeButton'],
                load_process_pay: ['loadProcessPay', 'processPay'],
                load_items_pay: ['loadItemsPay', 'itemsPay'],
                dispatch_payment: 'setPayments'
            },"dataCards":_vm.ToPay.payments},scopedSlots:_vm._u([{key:"infoBox",fn:function({itemProp}){return [_c('div',{staticClass:"T15_b title_explication"},[_vm._v(" "+_vm._s(itemProp.title_explication)+" ")]),_c('div',{staticClass:"T15"},[_vm._v(" "+_vm._s(itemProp.explication)+" ")]),_c('div',{staticClass:"T15_b title_recomendation"},[_vm._v(" "+_vm._s(itemProp.recomendation)+" ")]),_c('div',{staticClass:"T15"},[_vm._v(" "+_vm._s(itemProp.title_recomendation)+" ")])]}}],null,false,60345426)})],1),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"20px"}},[_c('GhButton',{attrs:{"datainput":{id: 'btn_back',text: _vm.$t('back'),class: '', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.back();}}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }