var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{
        id: 'types_exercieses',
    },"header":_vm.header,"data":_vm.ConfigTypeExercise.records_data.length == 0 ? [] : _vm.ConfigTypeExercise.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'config_type_exercise_id_view', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'config_type_exercise_id', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                    id: 'edit_config_type_exercise',
                    text: _vm.$t('modify'),
                    icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                }}})],1),_c('GhAction',{attrs:{"dataction":{
                id: 'delete_config_type_exercise',
                text: _vm.$t('delete'),
                icon: require('../../../../assets/gh_new_dessign/trash.svg'),
            }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','TypeExercise', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, _vm.$t('delete_exercise_type'), _vm.$t('preparing_delete_exercise_type'), 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'config_type_exercise'}}},[_c('GhButton',{attrs:{"datainput":{
                        id: 'btn_add_config_type_exercise',
                        text: _vm.$t('add')
                    }}})],1)],1)]},proxy:true}],null,false,3090503719)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }