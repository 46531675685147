<template>
    <div>
        <GhNoDropSelector :datablock="{id: 'company_assign_itinerary',title: $t('company')}" v-if="haveData">
            <template v-slot:content>
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ItineraryAssign.company_assign_itinerary.name}}</label>
                    </div>
                </div>
                <div class="section_line">
                    <div class="T15_b" style="padding-left:13px;">{{$t('groups')}}</div>
                </div>
                <form id="company_assign_itinerary">
                    <div class="gh-content-global">
                        <input type="hidden" name="company[id]" :value="$root._route.params.id">
                        <input type="hidden" name="company[id_itinerary]" :value="$root._route.params.id_itinerary">
                        <div class="gh-row-content">
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'result_search_data_groups',
                                    nameStore: 'ListGroups',
                                    imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                    id: 'tutor',
                                    text: $t('tutorships'),
                                    textlocation: $t('add_user'),
                                    actionresult: 'loadResultSearchGroups',
                                    nameResult: 'company[groups][tutor]',
                                    supportPermision: true,
                                }"
                                :mainSelector="{
                                    name: 'Formsearchcompany',
                                    formId: 'group',
                                    actionsearch: 'getSearchGroups'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: ItineraryAssign.company_assign_itinerary.table.tutor ? ItineraryAssign.company_assign_itinerary.table.tutor : []
                                }"
                                :tableSelected="{
                                    headersummary: headersummary,
                                    selected: ItineraryAssign.company_assign_itinerary.groups.tutor ? ItineraryAssign.company_assign_itinerary.groups.tutor : []
                                }"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'result_search_data_groups',
                                    nameStore: 'ListGroups',
                                    imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                    id: 'evaluator',
                                    text: $t('evaluation'),
                                    textlocation: $t('add_user'),
                                    actionresult: 'loadResultSearchGroups',
                                    nameResult: 'company[groups][evaluator]',
                                    supportPermision: true,
                                }"
                                :mainSelector="{
                                    name: 'Formsearchcompany',
                                    formId: 'group',
                                    actionsearch: 'getSearchGroups'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: ItineraryAssign.company_assign_itinerary.table.evaluator ? ItineraryAssign.company_assign_itinerary.table.evaluator : []
                                }"
                                :tableSelected="{
                                    headersummary: headersummary,
                                    selected: ItineraryAssign.company_assign_itinerary.groups.evaluator ? ItineraryAssign.company_assign_itinerary.groups.evaluator : []
                                }"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'result_search_data_groups',
                                    nameStore: 'ListGroups',
                                    imagelocation: require('../../../../assets/gh_new_dessign/floating.svg'),
                                    id: 'support',
                                    text: $t('support'),
                                    textlocation: $t('add_user'),
                                    actionresult:'loadResultSearchGroups',
                                    nameResult: 'company[groups][support]',
                                    supportPermision: true,
                                }"
                                :mainSelector="{
                                    name: 'Formsearchcompany',
                                    formId: 'group',
                                    actionsearch: 'getSearchGroups'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: ItineraryAssign.company_assign_itinerary.table.support ? ItineraryAssign.company_assign_itinerary.table.support : []
                                }"
                                :tableSelected="{
                                    headersummary: headersummary,
                                    selected: ItineraryAssign.company_assign_itinerary.groups.support ? ItineraryAssign.company_assign_itinerary.groups.support : []
                                }"
                            />
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered" style="margin-top: 20px;">
            <GhButton v-if="!this.$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('accept'),
                }"
                @click="postCompanyItinerary();"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import {mapState} from "vuex";
    import GhSearchWindow from "fe-gh-float-window-lib";

    export default {
        name: "itinerary_assign",
        components: {
            GhSearchWindow,
            GhNoDropSelector,
            GhButton
        },
        computed:{
            ...mapState(['ItineraryAssign']),
        },
        data(){
            return{
                haveData: false,
                header: [
                    {text: '', field: 'check', checkall:true,typeCheck: 'checkbox', required: true, label_required: this.$t('people'), sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: this.$t('name'), field: 'name', sorting: true,typeSort: 'string', style: 'max-width: 60px; width: 50%;'},
                    {text: this.$t('surname'), field: 'last_name', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 50%;'},
                ],
                headersummary: {
                    0: 'name',
                    1: 'last_name'
                },
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getCompanyAssignItinerary', {id:this.$root._route.params.id,id_itinerary:this.$root._route.params.id_itinerary}, {root: true});
            this.haveData = true;
        },
        methods:{
            back(){
                this.$router.go(-1);
            },
            async postCompanyItinerary(){
                if(this.Global.checkRequired('company_assign_itinerary') == 'OK') {
                    const formData = new FormData(document.getElementById('company_assign_itinerary'));
                    await this.$store.dispatch('setCompanyAssingItinerary', {
                      formData: [...formData],
                    }, {root: true});
                }
            }
        }
    }
</script>

<style scoped>

</style>