<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'example',title: $t('example')}">
            <template v-slot:content>
                <form id="form_example" v-if="haveData">
                    <component v-bind:is="from_example" />
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                @click="submitExample"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import from_example from './form_example';
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhButton from 'fe-gh-button-lib';
    export default {
        name: "example_template",
        components: {
            from_example,
            GhNoDropSelector,
            GhButton
        },
        data(){
            return {
                haveData: false,
                from_example: from_example
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedSector','',{root:true});
            await this.$store.dispatch('getExampleData', {id: this.$root._route.params.id},{root:true});
            this.haveData = true;
        },
        methods: {
            async submitExample(){
                if(this.Global.checkRequired('form_example') == 'OK') {
                    const formData = new FormData(document.getElementById('form_example'));
                    this.$store.dispatch('setExampleData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        },
    }
</script>