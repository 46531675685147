import Vue from "vue";
import vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

//LOGIN
import Login from './login/Login';
import Tabs from './tabs/tabs';
import getFeeds from './feeds/feeds';
import preSelecteds from './feeds/pre_selecteds';

// --- ITINERARY MAIN
import MainItinerary from './itinerary/initial/main';
// --- LIST QUIZZ
import ListQuizz from './itinerary/initial/list_quizz';
// --- RESULT QUIZZ
import ResultQuizz from './itinerary/initial/result_quizz';
// --- LIST COMPANIES
import ListCompanies from './gh_admin/list_companies';
// --- LIST QUIZZ
import ToPay from './itinerary/initial/to_payment';

// --- LIST GROUPS
import ListGroups from './perms/list_groups';

// -- GLOBAL
import TabTodo from './todo/list_todo';

// -- GLOBAL
import TabGlobal from './global/list_global';
import AllItinerary from './global/template/all_itinerary';
import RecomendedItinerary from './global/template/recomended_itinerary';

// -- MY ITINERARY
import TabMyItinerary from './itinerary/client/list_itinerary';
import AssignItinerary from './itinerary/client/template/assignment';

// -- MY SITUATION
import TabMySituation from './situation/list_situation';

// -- BUSINESS
import TabBusiness from './business/business';
// -- USERS
import TabUsers from './users/list_users';
import TemplateUser from './users/template/user';
// -- CONFIG
import TabConfig from './config/list_config';
import ConfigGeneral from './config/general/list_general';
import ConfigSectors from './config/general/sectors/list_sectors';
import ConfigBibliographies from './config/general/bibliography/list_bibliography';
import ConfigVideographies from './config/general/videography/list_videography';
import ConfigExamples from './config/general/examples/list_examples'
import ConfigSupportMaterials from './config/general/support_material/list_support_material';
import ConfigProcedures from './config/general/procedure/list_procedure';
import ConfigTypeExercise from './config/general/type_exercise/list_type_exercise';
import ConfigResponsible from './config/general/responsibles/list_responsibles';
import ConfigSeals from './config/general/seals/list_seals';
import ConfigExercises from './config/exercises/list_exercises';
import ConfigExercisesTable from './config/exercises/exercises/list_exercises';
import ConfigAround from './config/around/list_around';
import ConfigCategories from './config/support/categories/list_categories'
import ConfigPriorities from './config/support/priorities/list_priorities'
import ConfigHolidays from './config/support/holidays/list_holidays'
import ConfigFlows from './config/support/flows/list_flows'
import ConfigAssignment from './config/support/assignment/list_assignment'
import ConfigSchedule from './config/support/schedule/list_schedule'
import ConfigSupport from './config/support/list_config_support'
// -- CREATION
import TabCreation from './creation/list_creation';
import CreationActivity from './creation/activities/activity';
import CreationAmbit from './creation/ambits/ambit';
import CreationCertificate from './creation/certificates/certificate';
import CreationItinerary from './creation/itineraries/itinerary';
// -- BILLING
import TabBilling from './billing/list_billing';
import HistoricBilling from './billing/historic/historic';

// -- SUPPORT
import TabSupport from './support/list_support'
import SupportPendingRequestsGroups from './support/pending_requests_groups/list_pending_requests_groups'
import SupportPendingRequests from './support/pending_requests/list_pending_requests'
import SupportHistorical from './support/historical/list_historical'
import SupportIndicators from './support/indicators/list_indicators'

// -- USER FILE
import TabUserFile from './user_file/user_file'

// -- EVALUATION
import TabEvaluation from './evaluation/list_evaluations';

// -- TUTOR
import TabTutor from './tutor/list_tutor';

// -- ACTIONS
import TabAction from './actions/list_action'
import ActionListCompanies from './actions/list_companies/list_companies'
import ActionHistorical from './actions/historical/list_action_historical'
// -- DATA SUPERVISED/EVALUATED
import TabSupervisedEvaluated from './supervised_evaluated/list_supervised_evaluated';

import GlobalState from './Global';

import TabSearch from './search/list_search';

import UploadFiles from './attach/upload';


// -- ASSIGN
import TabAssing from './assing/list_assign';
import ItineraryAssign from './assing/itinerary_assign/itinerary_assign';

Vue.use(vuex);

export default new vuex.Store({
    modules: {
        Login: Login,
        MainItinerary: MainItinerary,
        ListQuizz: ListQuizz,
        ResultQuizz: ResultQuizz,
        ToPay: ToPay,
        Tabs: Tabs,
        getFeeds: getFeeds,
        preSelecteds: preSelecteds,
        TabSearch: TabSearch,
        TabTodo: TabTodo,
        TabGlobal: TabGlobal,
        AllItinerary: AllItinerary,
        RecomendedItinerary: RecomendedItinerary,
        TabMyItinerary: TabMyItinerary,
        AssignItinerary: AssignItinerary,
        TabMySituation: TabMySituation,
        TabBusiness: TabBusiness,
        ListCompanies: ListCompanies,
        TabConfig: TabConfig,
        ConfigGeneral: ConfigGeneral,
        ConfigSectors: ConfigSectors,
        ConfigBibliographies: ConfigBibliographies,
        ConfigVideographies: ConfigVideographies,
        ConfigExamples: ConfigExamples,
        ConfigSupportMaterials: ConfigSupportMaterials,
        ConfigProcedures: ConfigProcedures,
        ConfigTypeExercise: ConfigTypeExercise,
        ConfigResponsible: ConfigResponsible,
        ConfigSeals: ConfigSeals,
        ConfigExercises: ConfigExercises,
        ConfigExercisesTable: ConfigExercisesTable,
        ConfigAround: ConfigAround,
        ConfigSupport:ConfigSupport,
        ConfigCategories:ConfigCategories,
        ConfigPriorities:ConfigPriorities,
        ConfigHolidays:ConfigHolidays,
        ConfigFlows:ConfigFlows,
        ConfigSchedule:ConfigSchedule,
        ConfigAssignment:ConfigAssignment,
        TabUsers: TabUsers,
        TemplateUser: TemplateUser,
        TabCreation: TabCreation,
        CreationActivity: CreationActivity,
        CreationAmbit: CreationAmbit,
        CreationCertificate: CreationCertificate,
        CreationItinerary: CreationItinerary,
        TabSupport: TabSupport,
        SupportPendingRequestsGroups: SupportPendingRequestsGroups,
        SupportPendingRequests: SupportPendingRequests,
        SupportHistorical: SupportHistorical,
        SupportIndicators: SupportIndicators,
        TabBilling: TabBilling,
        HistoricBilling: HistoricBilling,
        ListGroups: ListGroups,
        GlobalState: GlobalState,
        UploadFiles: UploadFiles,
        TabUserFile: TabUserFile,
        TabAction:TabAction,
        ActionHistorical:ActionHistorical,
        TabEvaluation: TabEvaluation,
        TabTutor: TabTutor,
        TabSupervisedEvaluated: TabSupervisedEvaluated,
		TabAssing:TabAssing,
        ActionListCompanies:ActionListCompanies,
        ItineraryAssign:ItineraryAssign
    },
    plugins: [createPersistedState()]
});

