import Vue from "vue";
import {API_URL} from "@/common/config";
import vuex from "vuex";
import axios from "axios";
// import router from "../../../routes/router"
// import Global from "../Global";
Vue.use(vuex);
export default {
    state: {
        itemsPay: [],
        typeButton: false,
        processPay: false,
        payments: {},
    },
    mutations:{
        loadPayments: (state, payload) => (state.payments = payload),
        loadTypeButton: (state, payload) => (state.typeButton = payload),
        loadProcessPay: (state, payload) => (state.processPay = payload),
        loadItemsPay: (state, payload) => (state.itemsPay = payload),
    },
    actions: {
        getPayments(state) {
            let requestLogin = API_URL + "payments"

            axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    state.commit("loadPayments", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        setPayments(state, item) {
            let requestLogin = API_URL + "payments"

            console.log('hi',item);

            axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    console.log(response);
                    // if(response.data.status == '200') {
                    await this.dispatch('getPayments');
                    state.commit("loadTypeButton", false);
                    state.commit("loadProcessPay", false);
                    state.commit('loadItemsPay', []);
                    console.log(this);
                    // }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}
