var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-bottom":"20px"}},[_c('GhSelectorPayment',{attrs:{"dataBlock":{
            id: 'all_itineraries',
            name: 'all[]',
            title: ' ',
            process_pay_text: _vm.$t('paying'),
            btn_text_shop: '',
            btn_text_pay: '',
            btn_text_cancel: '',
            mapState: 'AllItinerary',
            load_type_button: ['loadAllTypeButton', 'typeAllButton'],
            load_process_pay: ['loadAllProcessPay', 'processAllPay'],
            load_items_pay: ['loadAllItemsPay', 'itemsAllPay'],
            dispatch_payment: 'setAllItinerariesPay'
        },"dataCards":_vm.AllItinerary.records_data.all},scopedSlots:_vm._u([{key:"infoBox",fn:function({itemProp}){return [_c('div',{staticClass:"T15_b title_explication"},[_vm._v(" "+_vm._s(itemProp.title_explication)+" ")]),_c('div',{staticClass:"T15"},[_vm._v(" "+_vm._s(itemProp.explication)+" ")]),_c('div',{staticClass:"T15_b title_recomendation"},[_vm._v(" "+_vm._s(itemProp.recomendation)+" ")]),_c('div',{staticClass:"T15"},[_vm._v(" "+_vm._s(itemProp.title_recomendation)+" ")])]}}],null,false,60345426)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }