import Vue from "vue";
import vuex from "vuex";
import router from "../../../routes/router"

import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        list_ambits_certificates: [],
        data_itinerary: [],
    },
    mutations: {
        loadListAmbitsCertificates(state,payload){
            state.list_ambits_certificates = payload
        },
        loadDataItinerary(state,payload){
            state.data_itinerary = payload
        },
    },
    actions: {
        async resetDataItinerary(state){
            state.commit("loadDataItinerary", []);
            state.commit("loadListAmbitsCertificates", []);
        },
        async getListAmbitsCertificates(state,item){
            let requestLogin = API_URL + 'configuration/itineraries/table';
            let formData = new FormData();

            let keys_array = Object.keys(item.ambits_certificates);

            for(var i=0;i<keys_array.length;i++){
                if(keys_array[i] !== undefined){
                    formData.append('ambit_certificate[]', keys_array[i]);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadListAmbitsCertificates", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataItinerary(state,item){
            let requestLogin = API_URL + 'creation/itineraries/'+item.id;

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadDataItinerary", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setItinerary(state, item){
            let requestLogin = API_URL + "creation/itineraries/set";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadAroundData", response.data);
                    if (response.status == 200) {
                        router.go(-1);
                    }
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};