<template>
    <div v-if="haveData">
        <GhNoDropSelector :datablock="{id:'itinerary',title: $t('itinerary'),second_title:'' }">
            <template v-slot:content>
                <form id="form_itinerary">
                    <input type="hidden" name="itinerary[id]" :value="$root._route.params.id">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'itinerary_code',
                                        name: 'itinerary[code]',
                                        style: 'width: 200px',
                                        value: code,
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{code}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'itinerary_name',
                                        name: 'itinerary[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: name,
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{name}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description') + '*',
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        class: 'T15_b',
                                    }"
                                    :datainput="{
                                        id: 'itinerary_description',
                                        name: 'itinerary[description]',
                                        value: description,
                                        required: true,
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{description}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhMultipleSearch
                                    :datalabel="{
                                        text: $t('responsible'),
                                        style: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                        class: 'T15_b',
                                        id: 'label_exercise_responsible'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'itinerary_responsible_user',
                                        name: 'itinerary[responsible][name]',
                                        nameS:'itinerary[responsible][surname]',
                                        nameId:'itinerary[responsible][id]',
                                        style: 'width: 20.5%; min-width: 200px;',
                                        value: responsible_name,
                                        valueId: responsible_id,
                                        valueS: responsible_surname,
                                        placeholder: $t('name'),
                                        placeholderS: $t('surnames'),
                                        result_1: 'first_name',
                                        result_2: 'last_name',
                                        result_id: 'id',
                                        href: dispatchUserResponsible
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{responsible_name + ' ' + responsible_surname}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_itinerary_sector',
                                        class: 'T15_b',
                                        subtitle: $t('select_sector') + ':',
                                        text: $t('sector'),
                                        label: $t('sector'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:102px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_itinerary_sector',
                                        name: 'itinerary[sector][]'
                                    }"
                                    :selected_input="selected_sector"
                                    :disabled_input="[]"
                                    :feed="getFeeds.feed_sector"
                                    @accept="filterAmbitsCertificates();"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('sector') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_sector).join()}}</label>
                            </template>
                        </div>
                        <div style="margin-bottom: 20px;">
                            <component v-bind:is="ambits_certificates" ref="selector_filtered" />
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_itinerary_bibliography',
                                        class: 'T15_b',
                                        subtitle: $t('select_bibliography') + ':',
                                        text: $t('bibliography'),
                                        label: $t('bibliography'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:71px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_itinerary_bibliography',
                                        name: 'itinerary[bibliography][]'
                                    }"
                                    :selected_input="selected_bibliography"
                                    :disabled_input="preSelecteds.pre_selected_bibliography"
                                    :feed="getFeeds.feed_bibliography"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('bibliography') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_bibliography).join()}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_itinerary_videography',
                                        class: 'T15_b',
                                        subtitle: $t('select_videography') + ':',
                                        text: $t('videography'),
                                        label: $t('videography'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:71px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_itinerary_videography',
                                        name: 'itinerary[videography][]'
                                    }"
                                    :selected_input="selected_videography"
                                    :disabled_input="preSelecteds.pre_selected_videography"
                                    :feed="getFeeds.feed_videography"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('videography') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_videography).join()}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_itinerary_example',
                                        class: 'T15_b',
                                        subtitle: $t('select_example') + ':',
                                        text: $t('examples'),
                                        label: $t('examples'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:88px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_itinerary_example',
                                        name: 'itinerary[example][]'
                                    }"
                                    :selected_input="selected_example"
                                    :disabled_input="preSelecteds.pre_selected_example"
                                    :feed="getFeeds.feed_examples"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('examples') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_example).join()}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_itinerary_support_material',
                                        class: 'T15_b',
                                        subtitle: $t('select_support_material_2') + ':',
                                        text: $t('support_material_2'),
                                        label: $t('support_material_2'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:19px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_itinerary_support_material',
                                        name: 'itinerary[support_material][]'
                                    }"
                                    :selected_input="selected_support_material"
                                    :disabled_input="preSelecteds.pre_selected_support_material"
                                    :feed="getFeeds.feed_support_material"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('support_material_2') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_support_material).join()}}</label>
                            </template>
                        </div>
                    </div>
                    <component v-bind:is="reference_thesauros" :props="{selecteds:referenceSelecteds,name:'itinerary'}"/>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered" style="margin-top: 20px;">
            <GhButton v-if="!this.$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('accept'),
                }"
                @click="postItinerary();"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhMultipleSearch from "fe-gh-multiple-search-lib";
    import {mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import ambits_certificates from "./ambits_certificates";
    import GhButton from "fe-gh-button-lib";
    import reference_thesauros from "../../../config/exercises/exercises/template/includes/reference_thesauros.vue"

    export default {
        name: "itinerary_template",
        components:{
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhMultipleSearch,
            GhDataSelector,
            GhButton,
            ambits_certificates,
            reference_thesauros,
        },
        computed: {
            ...mapState(['getFeeds','CreationItinerary','preSelecteds'])
        },
        data(){
            return {
                haveData: false,
                toUpdate: true,
                ambits_certificates: ambits_certificates,
                reference_thesauros: reference_thesauros,
                refreshSelectors: 0,
                code: '',
                name: '',
                description: '',
                responsible_id: '',
                responsible_name: '',
                responsible_surname: '',
                selected_sector: [],
                selected_bibliography: [],
                selected_videography: [],
                selected_example: [],
                selected_support_material: [],
                dispatchUserResponsible: 'getUserSearch',
                referenceSelecteds: {}
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedSector','',{root:true});
            await this.$store.dispatch('getFeedExamples','',{root:true});
            await this.$store.dispatch('getFeedBibliography','',{root:true});
            await this.$store.dispatch('getFeedVideography','',{root:true});
            await this.$store.dispatch('getFeedSupportMaterial','',{root:true});

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataItinerary',{id:this.$root._route.params.id},{root:true});

                this.code = this.CreationItinerary.data_itinerary.code;
                this.name = this.CreationItinerary.data_itinerary.name;
                this.description = this.CreationItinerary.data_itinerary.description;
                this.responsible_id = this.CreationItinerary.data_itinerary.responsible.id;
                this.responsible_name = this.CreationItinerary.data_itinerary.responsible.name;
                this.responsible_surname = this.CreationItinerary.data_itinerary.responsible.surname;

                this.selected_sector = this.CreationItinerary.data_itinerary.sector;
                this.selected_bibliography = this.CreationItinerary.data_itinerary.bibliography;
                this.selected_videography = this.CreationItinerary.data_itinerary.videography;
                this.selected_example = this.CreationItinerary.data_itinerary.example;
                this.selected_support_material = this.CreationItinerary.data_itinerary.support_material;

                await this.$store.dispatch('getFeedAmbitsCertificatesFiltered',{sector:this.CreationItinerary.data_itinerary.sector},{root:true});
            }else{
                await this.$store.dispatch('resetDataItinerary','',{root:true});
            }

            this.haveData = true;
        },
        updated(){
            if(this.$root._route.params.id !== undefined && this.toUpdate && Object.keys(this.CreationItinerary.data_itinerary.ambits_certificates).length !== 0){
                this.$refs.selector_filtered.axiosAmbitCertificate();
                this.toUpdate = false;
            }
        },
        methods:{
            back(){
                this.$router.go(-1);
            },
            postItinerary(){
                if(this.Global.checkRequired('form_itinerary') == 'OK') {
                    const formData = new FormData(document.getElementById('form_itinerary'));

                    this.$store.dispatch('setItinerary', {
                        formData: [...formData],
                    }, {root: true});
                }
            },
            async filterAmbitsCertificates(){
                var sector = document.getElementsByName('itinerary[sector][]');
                var selected_sectors = new Array();
                for(var loop_sector=0;loop_sector<sector.length;loop_sector++){
                    if(sector[loop_sector].checked == true && sector[loop_sector].parentElement.style.pointerEvents != 'none'){
                        selected_sectors[sector[loop_sector].value] = sector[loop_sector].attributes.label_required.value;
                    }
                }

                var ambit_certificate = document.getElementsByName('itinerary[ambit_certificate][]');
                var selected_ambits_certificates = new Array();
                for(var loop_ambit_certificate=0;loop_ambit_certificate<ambit_certificate.length;loop_ambit_certificate++){
                    if(ambit_certificate[loop_ambit_certificate].checked == true && ambit_certificate[loop_ambit_certificate].parentElement.style.pointerEvents != 'none'){
                        selected_ambits_certificates[ambit_certificate[loop_ambit_certificate].value] = ambit_certificate[loop_ambit_certificate].attributes.label_required.value;
                    }
                }

                await this.$store.dispatch('getFeedAmbitsCertificatesFiltered',{sector:selected_sectors},{root:true});

                selected_ambits_certificates = {...selected_ambits_certificates};
                var toCheckSelecteds = Object.keys(this.getFeeds.feed_selector_ambits_certificates);
                var checkSelecteds = Object.keys(selected_ambits_certificates);
                for(var i=0;i<checkSelecteds.length;i++){
                    if(!toCheckSelecteds.includes(checkSelecteds[i])){
                        delete selected_ambits_certificates[checkSelecteds[i]];
                    }
                }

                this.$refs.selector_filtered.selecteds = selected_ambits_certificates;

                if(Object.keys(selected_ambits_certificates).length !== 0){
                    await this.$refs.selector_filtered.axiosAmbitCertificate();
                }else{
                    this.$refs.selector_filtered.refreshAmbitsCertificates++;
                }
            }
        }
    }
</script>