var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"id":"form_business"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('Nombre') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"18%","min-width":"100px"}},[_vm._v(_vm._s(_vm.TabBusiness.records_data.name))]),_c('div',[_c('GhDataSelector',{staticClass:"none_margin",attrs:{"datablock":{
                        id: 'social_reason',
                        class: 'T15_b',
                        subtitle: _vm.$t('select_social_reason') + ':',
                        text: _vm.$t('social_reason') + '*',
                        label: _vm.$t('social_reason'),
                        type: 'radio',
                        columns: 3,
                        required: true,
                        style: 'margin-left: 63px;',
                        popupStyle: 'min-width: 900px; width: 55%;'
                    },"datainput":{
                        id: 'value_social_Reason',
                        name: 'business[social_reason][]'
                    },"feed":_vm.getFeeds.feed_social_reason,"selected_input":_vm.TabBusiness.records_data.social_reason}})],1),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('cif') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","min-width":"100px"}},[_vm._v(_vm._s(_vm.TabBusiness.records_data.cif))])]),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('full_adress') + '*',
                    style:'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'company_direction',
                    name: 'business[direction]',
                    style: 'width: 20%',
                    value: _vm.TabBusiness.records_data.direction,
                    type: 'text'
                }}}),_c('GhDataSelector',{staticClass:"none_margin",attrs:{"datablock":{
                    id: 'country',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_country') + ':',
                    text: _vm.$t('country')+'*',
                    label: _vm.$t('country'),
                    type: 'radio',
                    columns: 3,
                    required: true,
                    style: 'margin-left:86px;',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"datainput":{
                    id: 'value_country',
                    name: 'business[country][]'
                },"feed":_vm.getFeeds.feed_country,"selected_input":_vm.TabBusiness.records_data.country}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('mail') + '*',
                    style:'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'company_email',
                    name: 'business[email]',
                    style: 'width: 20%',
                    value: _vm.TabBusiness.records_data.email,
                    type: 'text'
                }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('telephone') + '*',
                    style:'width: 122px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'company_telephone',
                    name: 'business[telephone]',
                    style: 'width: 20%',
                    value: _vm.TabBusiness.records_data.telephone,
                    type: 'text'
                }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                    type: 'radio',
                    id: 'business_activity',
                    label: _vm.$t('activity_sector') + '*',
                    title: _vm.$t('activity_sector'),
                    text: _vm.$t('select_activity_sector') + ':',
                    name: 'business[activity][]',
                    id_value: 'value',
                    name_label: 'text',
                    child_key: 'children',
                    required: true,
                    checkparentdata: false,
                    style: 'margin-left: 16px; width:100%',
                    popupStyle: 'min-width: 900px;width: 55%;'
                },"selected_input":_vm.TabBusiness.records_data.activity,"items":_vm.getFeeds.feed_activity}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{staticClass:"none_margin",attrs:{"datablock":{
                      id: 'num_employee',
                      class: 'T15_b',
                      subtitle: _vm.$t('select_number_employees') + ':',
                      text: _vm.$t('number_employees') + '*',
                      label: _vm.$t('number_employees'),
                      type: 'radio',
                      columns: 3,
                      required: true,
                      label_required: _vm.$t('number_employees'),
                      style: 'margin-left: -5px;',
                      popupStyle: 'min-width: 900px;width: 55%;'
                  },"datainput":{
                      id: 'value_num_employee',
                      name: 'business[num_employee][]'
                  },"feed":_vm.getFeeds.feed_num_employers,"selected_input":_vm.TabBusiness.records_data.num_employee}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }