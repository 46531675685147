import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
// import router from "@/routes/router";
Vue.use(vuex);
export default {
    state: {
        data_company: [],
        data_historic_actions: [],
        data_historic: [],
        sections:[],
        records:{
            added:[],
            deleted:[],
            selected_state_call:[],
        }
    },
    mutations: {
        loadActionCompanyData(state,payload){
            state.data_company = payload;
        },
        loadActionCompanyHistoricActions(state,payload){
            state.data_historic_actions = payload;
        },
        loadActionDataHistoric(state,payload){
            state.data_historic = payload;
        },
        loadSectionsActions(state, payload) {
            state.sections = payload;
        }
    },
    actions: {
        async getSectionsActions(state){
            await axios.get(
                API_URL + "commercial-management/company-states"
            ).then(
                response => {
                    state.commit('loadSectionsActions', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        exportResultActionHistorical(state,items){
            let requestLogin = API_URL + 'commercial-management/search-export';
            let params = {};
            var count_state = 0;
            items.formData.forEach((item) => {
                if(item[0] == 'historical[state][]'){
                    params['historical[state]['+count_state+']'] = item[1];
                    count_state++;
                }else {
                    params[item[0]] = item[1];
                }
            });
            axios({
                method: "get",
                url: requestLogin,
                params: params,
                responseType: 'blob'
            }).then(
                response => {
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '');
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getActionCompanyData(state,items){
            let requestLogin = API_URL + "commercial-management/companies/"+items.id;

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadActionCompanyData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getActionCompanyHistoricActions(state,items){
            let requestLogin = API_URL + "commercial-management/companies/"+items.id+"/actions";

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadActionCompanyHistoricActions", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getActionDataHistoric(state,items){
            let requestLogin = API_URL + "commercial-management/companies/"+items.id_company+"/actions/"+items.id_register;

            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadActionDataHistoric", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setHistoricCompany(state,items){
            let requestLogin = API_URL + "commercial-management/companies/"+items.id + "/actions";
            let formData = new FormData();

            items.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status == 200) {
                        await this.dispatch('getActionCompanyHistoricActions',{id:items.id});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}
