var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.haveData,staticClass:"float_window",staticStyle:{"padding-bottom":"15px"}},[(_vm.showTodo)?_c('GhDropSelector',{attrs:{"datablock":{
            id: 'section_todo',
            title: _vm.$t('my_tasks'),
            second_title: _vm.$t('questions_to_answer'),
            num_results: _vm.TabTodo.counter,
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticStyle:{"padding-bottom":"15px","padding-right":"15px"}},_vm._l((_vm.TabTodo.records_data),function(item,itemIndex){return _c('div',{key:itemIndex,staticStyle:{"padding-bottom":"5px"}},[_c('GhDropDownField',{attrs:{"dataBlock":{
                            id: 'drop_itinerary_' + item.id,
                            style: 'margin-left: 20px;',
                            title: item.text,
                            subTitle: item.data.length + ' ' + _vm.$t('questions_to_answer'),
                            isDrop: false
                        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                                    id: 'table_itinerary_' + item.id,
                                },"header":_vm.header,"data":item.data.length == 0 ? [] : item.data},scopedSlots:_vm._u([(!_vm.$root._route.params.view)?{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'ejecute_itinerary_id', params: {id: item.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                                id: 'delete_holiday',
                                                text: _vm.$t('execute'),
                                                icon: require('../../assets/gh_new_dessign/edit.svg'),
                                                item_prop: itemProp
                                            }}})],1)]}}:null],null,true)})]},proxy:true}],null,true)})],1)}),0)]},proxy:true}],null,false,322401266)}):_vm._e(),(_vm.showCompanies)?_c('GhDropSelector',{attrs:{"datablock":{
            id: 'section_company',
            title: _vm.$t('companies'),
            second_title: _vm.$t('results'),
            num_results: _vm.TabTodo.records_data_companies.length,
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                    id: 'company',
                },"header":_vm.headerCompany,"data":_vm.TabTodo.records_data_companies.length === 0 ? [] : _vm.TabTodo.records_data_companies},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'company_assing_id', params: { id: itemProp['id']}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'edit_company_'+itemProp['id'],
                                text: _vm.$t('modify'),
                                icon: require('../../assets/gh_new_dessign/edit.svg'),
                                item_prop: itemProp
                            }}})],1)]}}],null,false,3711420029)})]},proxy:true}],null,false,2921766941)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }