<template>
    <div class="float_window" :key="haveData" style="padding-bottom: 15px;">
        <GhDropSelector v-if="showTodo"
            :datablock="{
                id: 'section_todo',
                title: $t('my_tasks'),
                second_title: $t('questions_to_answer'),
                num_results: TabTodo.counter,
            }"
        >
            <template v-slot:content>
                <div style="padding-bottom: 15px; padding-right: 15px;">
                    <div v-for="(item, itemIndex) in TabTodo.records_data" :key="itemIndex" style="padding-bottom: 5px;">
                        <GhDropDownField
                            :dataBlock="{
                                id: 'drop_itinerary_' + item.id,
                                style: 'margin-left: 20px;',
                                title: item.text,
                                subTitle: item.data.length + ' ' + $t('questions_to_answer'),
                                isDrop: false
                            }"
                        >
                            <template v-slot:content>
                                <GhTableCommon
                                    :extratable="{
                                        id: 'table_itinerary_' + item.id,
                                    }"
                                    :header="header"
                                    :data="item.data.length == 0 ? [] : item.data"
                                >
                                    <template v-slot:actions="{itemProp}"  v-if="!$root._route.params.view">
                                        <router-link :to="{name: 'ejecute_itinerary_id', params: {id: item.id}}">
                                            <GhAction
                                                :dataction="{
                                                    id: 'delete_holiday',
                                                    text: $t('execute'),
                                                    icon: require('../../assets/gh_new_dessign/edit.svg'),
                                                    item_prop: itemProp
                                                }"
                                            />
                                        </router-link>
                                    </template>
                                </GhTableCommon>
                            </template>
                        </GhDropDownField>
                    </div>
                </div>
            </template>
        </GhDropSelector>
        <GhDropSelector v-if="showCompanies"
            :datablock="{
                id: 'section_company',
                title: $t('companies'),
                second_title: $t('results'),
                num_results: TabTodo.records_data_companies.length,
            }"
        >
            <template v-slot:content>
                <GhTableCommon
                    :extratable="{
                        id: 'company',
                    }"
                    :header="headerCompany"
                    :data="TabTodo.records_data_companies.length === 0 ? [] : TabTodo.records_data_companies"
                >
                    <template v-slot:actions="{itemProp}">
                        <router-link :to="{name: 'company_assing_id', params: { id: itemProp['id']}}">
                            <GhAction
                                :dataction="{
                                    id: 'edit_company_'+itemProp['id'],
                                    text: $t('modify'),
                                    icon: require('../../assets/gh_new_dessign/edit.svg'),
                                    item_prop: itemProp
                                }"
                            />
                        </router-link>
                    </template>
                </GhTableCommon>
            </template>
        </GhDropSelector>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import GhDropDownField from 'fe-gh-drop-down-field-lib';
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';
    import {mapState} from "vuex";

    export default {
        name: "list_todo",
        components: {
            GhDropSelector,
            GhDropDownField,
            GhTableCommon,
            GhAction
        },
        data(){
            return {
                haveData: false,
                showTodo: false,
                showCompanies: false,
                header: [
                    {text: this.$t('description'), field: 'text', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 85%;'},
                    {text: this.$t('state'), field: 'state', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                headerCompany:[
                    {text: this.$t('company_name'), field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 25%;'},
                    {text: this.$t('validate'), field: 'validar', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 25%;'},
                    {text: this.$t('tutorships'), field: 'tutoría', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 25%;'},
                    {text: this.$t('support'), field: 'soporte', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 25%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
            }
        },
        computed: {
            ...mapState(['TabTodo','Login'])
        },
        async beforeMount() {
            // await this.$store.dispatch('getTodoCounter', '', {root: true});
            // await this.$store.dispatch('getListTodo', '', {root: true});
            await this.$store.dispatch('getListCompanyGr', '', {root: true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('todo')){
                    that.Login.tabs[(route.id - 1)]['sub-modules'].forEach((subroute,subkey) => {
                        if(subkey == 0){
                            that.showTodo = subroute.have_permission;
                        }else{
                            that.showCompanies = subroute.have_permission;
                        }
                    });
                }
            });

            this.haveData = true;
        }
    }
</script>

<style>
    @import "~fe-gh-drop-down-field-lib/dist/fe-gh-drop-down-field-lib.css";

    .section > table{
        min-width: 900px !important;
    }
</style>
