var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.ConfigExercisesTable.type_exercise.close_one_correct.responsible.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                    id: 'label_exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_one_correct_responsible',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_responsible') + ':',
                    text: _vm.$t('responsible') + (_vm.ConfigExercisesTable.type_exercise.close_one_correct.responsible.required ? '*' : ''),
                    label: _vm.$t('responsible'),
                    type: 'radio',
                    columns: 3,
                    style: '',
                    required: _vm.ConfigExercisesTable.type_exercise.close_n_correct.responsible.required,
                },"datainput":{
                    id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_one_correct_responsible',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_one_correct][responsible]'
                },"selected_input":!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.close_n_correct !== undefined && _vm.ConfigExercisesTable.data.close_n_correct.responsible ? _vm.ConfigExercisesTable.data.close_n_correct.responsible : []) : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined && _vm.ConfigExercisesTable.data.close_one_correct.responsible ? _vm.ConfigExercisesTable.data.close_one_correct.responsible : []),"feed":_vm.getFeeds.feed_responsible}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('responsible') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.close_n_correct !== undefined && _vm.ConfigExercisesTable.data.close_n_correct.responsible ? Object.values(_vm.ConfigExercisesTable.data.close_n_correct.responsible).join() : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined && _vm.ConfigExercisesTable.data.close_one_correct.responsible ? Object.values(_vm.ConfigExercisesTable.data.close_one_correct.responsible).join() : '')))])]],2):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('statement') + '*',
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_one_correct_statement',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_one_correct][statement]',
                    value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.close_n_correct !== undefined ? _vm.ConfigExercisesTable.data.close_n_correct.statement : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.statement : ''),
                    required: true,
                    edited: true,
                    image: {
                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                    }
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('statement') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.close_n_correct !== undefined ? _vm.ConfigExercisesTable.data.close_n_correct.statement : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.statement : ''))}})]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('information'),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_' + (!_vm.showSubQuestion ? '_subquestion_' : '') + 'close_one_correct_information',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_one_correct][information]',
                    value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.close_n_correct !== undefined ? _vm.ConfigExercisesTable.data.close_n_correct.information : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.information : ''),
                    required: false
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('information') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.close_n_correct !== undefined ? _vm.ConfigExercisesTable.data.close_n_correct.information : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.information : '')))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('score_legend'),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_' + (!_vm.showSubQuestion ? '_subquestion_' : '') + 'close_one_correct_score_legend',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_one_correct][score_legend]',
                    value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.close_n_correct !== undefined ? _vm.ConfigExercisesTable.data.close_n_correct.score_legend : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.score_legend : ''),
                    required: false
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('score_legend') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.close_n_correct !== undefined ? _vm.ConfigExercisesTable.data.close_n_correct.score_legend : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.score_legend : '')))])]],2),_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('GhTableCommon',{attrs:{"extratable":{
                id: 'answers'
            },"header":_vm.header,"data":_vm.possible_answer},scopedSlots:_vm._u([{key:"field_component",fn:function({itemProp, labelProp}){return [(labelProp.field == 'answer')?_c('div',[_c('GhInputTextField',{attrs:{"datalabel":{},"datainput":{
                            required: labelProp.required,
                            id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_one_correct_answer_'+itemProp.id,
                            name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_one_correct][answer]['+itemProp.id+']',
                            value: itemProp.answer,
                        }}})],1):_vm._e(),(labelProp.field == 'correct')?_c('div',{staticClass:"centered",staticStyle:{"margin-top":"-5px","margin-left":"-10px"}},[_c('GhChecks',{attrs:{"datalabel":{},"datainput":{
                            required: labelProp.required,
                            type: 'radio',
                            id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_one_correct_correct_' + itemProp.id,
                            value: itemProp.id,
                            name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_one_correct][correct]',
                            selected: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_one_correct.possible_answers.selected == itemProp.id ? true : false : false) : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.possible_answers.selected == itemProp.id ? true : false : false),
                            disabled: _vm.$root._route.params.view ? true : false
                        }}})],1):_vm._e()]}}])})],1),(_vm.ConfigExercisesTable.type_exercise.close_one_correct.feedback.view)?_vm._l((_vm.ConfigExercisesTable.type_exercise.close_one_correct.possible_answers.answers),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                        text: _vm.$t('feedback') + ' ' + item.id + (_vm.ConfigExercisesTable.type_exercise.close_one_correct.feedback.required ? '*' : ''),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    },"datainput":{
                        id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_one_correct_feedback',
                        name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_one_correct][feedback]['+item.id+']',
                        value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_one_correct.possible_answers.answers[index].feedback : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.possible_answers.answers[index].feedback : ''),
                        required: _vm.ConfigExercisesTable.type_exercise.close_one_correct.feedback.required
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('feedback') + ' ' + item.id + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_one_correct.possible_answers.answers[index].feedback : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.possible_answers.answers[index].feedback : '')))])]],2)}):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('punctuation'),
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_one_correct_puntuation',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_one_correct][puntuation]',
                    style: 'width: 100px',
                    value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_one_correct.puntuation : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.puntuation : ''),
                    type: 'text',
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('punctuation') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_one_correct.puntuation : '') : (_vm.ConfigExercisesTable.data.close_one_correct !== undefined ? _vm.ConfigExercisesTable.data.close_one_correct.puntuation : '')))])]],2),(_vm.showSubQuestion && _vm.ConfigExercisesTable.type_exercise.close_n_correct.subquestion !== undefined && _vm.ConfigExercisesTable.type_exercise.close_n_correct.subquestion.view)?[_c('div',{staticClass:"section_line",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('subquestion')))])]),_c('GhTableCommon',{key:'A'+_vm.refresh_table_no_condition,attrs:{"extratable":{
                id: 'subquestions',
            },"header":_vm.header_subquestions,"data":_vm.table_no_condition},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(itemProp.new)?_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_subquestions',
                        text: _vm.$t('modify'),
                        icon: require('../../../../../../assets/gh_new_dessign/edit.svg'),
                    }},on:{"click":function($event){return _vm.editNewSubquestion(itemProp.id)}}}):_vm._e(),_c('GhAction',{attrs:{"dataction":{
                        id: 'delete_subquestions',
                        text: _vm.$t('delete'),
                        icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm',itemProp.new ? 'SubquestionNew' : 'Subquestion', itemProp.id,{'name': itemProp.name, 'type_of_exercise': itemProp.type}, _vm.$t('delete_subquestion'), _vm.$t('preparing_delete_subquestion'), 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_new_subquestions',
                            text: _vm.$t('add_new')
                        }},on:{"click":_vm.newSubquestion}}),(_vm.getFeeds.feed_excerises_no_subquestions.length !== 0)?_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_old_subquestions',
                            text: _vm.$t('add_existing')
                        }},on:{"click":function($event){return _vm.Global.openPopUp('subquestion_exist_data_selector_popup')}}}):_vm._e()],1)]},proxy:true}],null,false,1245719269)}),_c('div',{staticStyle:{"display":"none"}},_vm._l((_vm.table_no_condition),function(item,index){return _c('div',{key:'B'+index},[(item.new)?_c('input',{attrs:{"type":"hidden","name":"exercise[close_numeric][subquestion][]"},domProps:{"value":item.id}}):_vm._e()])}),0),_c('GhDataSelector',{key:'C'+_vm.refresh_no_condition,ref:"subquestion_exist_no_condition",attrs:{"datablock":{
                id: 'subquestion_exist',
                class: 'T15_b',
                subtitle: _vm.$t('select_existing_exercise') + ':',
                text: _vm.$t('add_existing'),
                label: _vm.$t('add_existing'),
                type: 'checkbox',
                columns: 3,
                style: 'width: 149px;display: none;',
                required: false,
            },"datainput":{
                id: 'value_subquestion_exist',
                name: 'exercise[close_one_correct][subquestion][]'
            },"selected_input":_vm.selected_no_condition,"feed":_vm.getFeeds.feed_excerises_no_subquestions},on:{"accept":_vm.addExerciseExist}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }