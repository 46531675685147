import BillingList from "@/view/billing/list_billing";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/list_billing',
        name: i18n.t('billing'),
        component: BillingList,
        props: {showMenu: true},
    }
];

export default routes;