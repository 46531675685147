<template>
    <GhTableCommon v-if="haveData"
        :extratable="{
            id: 'videography'
        }"
        :header="header"
        :data="ConfigVideographies.records_data.length == 0 ? [] : ConfigVideographies.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <router-link :to="{name: 'config_videography_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
        </template>
        <template v-slot:actions="{itemProp}">
            <router-link :to="{name: 'config_videography_id', params: {id: itemProp.id}}">
                <GhAction
                    :dataction="{
                        id: 'edit_config_videography',
                        text: $t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    }"
                />
            </router-link>
            <GhAction
                :dataction="{
                    id: 'delete_config_videography',
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','Videography', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, $t('delete_videography'), $t('preparing_delete_videography'), 'delete')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <router-link :to="{name: 'config_videography'}">
                    <GhButton
                        :datainput="{
                            id: 'btn_add_config_videography',
                            text: $t('add')
                        }"
                    />
                </router-link>
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    export default {
        name: "list_videography",
        components: {
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
          ...mapState(['ConfigVideographies'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: this.$t('code'), field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; width: 100px;'},
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 200px; width: 20%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 200px; min-width: 200px; width: 80%;'},
                    {text: '', field: 'actions', sorting: false}
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getVideographies', '', {root: true});
            this.haveData = true;
        }
    }
</script>