var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('GhChecks',{ref:"GhCheckConfigEvidence",attrs:{"datalabel":{text: _vm.$t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.evidence !== undefined && _vm.ConfigTypeExercise.data.config.evidence[0] == 1 ? true : false,
                    disabled: !_vm.$root._route.params.view ? false : true,
                    type: 'checkbox',
                    id: 'value_type_exercise_evidence',
                    value: 1,
                    name: 'type_exercise[config][evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)}}}),_c('GhChecks',{ref:"GhCheckConfigEvidenceRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.evidence !== undefined && _vm.ConfigTypeExercise.data.config.evidence[1] == 2 ? true : false,
                    disabled: !_vm.$root._route.params.view ? false : true,
                    type: 'checkbox',
                    id: 'value_type_exercise_required_evidence',
                    value: 2,
                    name: 'type_exercise[config][evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)}}})],1)]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('subquestion'),
                style: 'width: 130px',
                id: 'label_type_exercise_subquestion'
            },"datainput":{
                required: false,
                type: 'checkbox',
                id: 'value_type_exercise_subquestion',
                value: 1,
                name: 'type_exercise[subquestion][]',
                selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.subquestion && _vm.ConfigTypeExercise.data.config.subquestion[0] == 1 ? true : false,
                disabled: !_vm.$root._route.params.view ? false : true
            }}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"10px"}},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                    id: 'label_type_exercise_conditions',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_conditions') + ':',
                    text: _vm.$t('conditions'),
                    label: _vm.$t('conditions'),
                    type: 'checkbox',
                    columns: 3,
                    style: 'margin-left:60px;',
                    required: false
                },"datainput":{
                    id: 'value_type_exercise_conditions',
                    name: 'type_exercise[conditions][]'
                },"selected_input":_vm.ConfigTypeExercise.data.conditions ? _vm.ConfigTypeExercise.data.conditions : [],"feed":_vm.getFeeds.feed_condition}})]:[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('conditions') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.text_conditions))])])]],2),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('feedback'),
                style: 'width: 130px',
                id: 'label_type_exercise_feedback'
            },"datainput":{
                required: false,
                type: 'checkbox',
                id: 'value_type_exercise_feedback',
                value: 1,
                name: 'type_exercise[feedback][]',
                selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.feedback && _vm.ConfigTypeExercise.data.config.feedback[0] == 1 ? true : false,
                disabled: !_vm.$root._route.params.view ? false : true
            }}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('subquestion'),
                style: 'width: 130px',
                id: 'label_type_exercise_subquestion_conditional'
            },"datainput":{
                required: false,
                type: 'checkbox',
                id: 'value_type_exercise_subquestion_conditional',
                value: 1,
                name: 'type_exercise[subquestion_conditional][]',
                selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.subquestion_conditional && _vm.ConfigTypeExercise.data.config.subquestion_conditional[0] == 1 ? true : false,
                disabled: !_vm.$root._route.params.view ? false : true
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }