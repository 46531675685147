import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import i18n from "@/plugins/i18n";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        list_groups: [],
        list_profiles: [],
        list_group_users:[],
        list_associated_users:[],
        data_perm_group: [],
        data_perm_profiles: [],
        data_tree_companies: [],
        data_company_group: [],
        result_search_data_groups:[],
        data_general_companies:[],
        data:[],
    },
    mutations: {
        loadListGroups(state, payload){
            state.list_groups = payload;
        },
        loadListProfiles(state, payload){
            state.list_profiles = payload;
        },
        loadCompanyDataGeneral(state,payload){
            state.data_general_companies = payload;
        },
        loadPermsGroup(state, payload){
            state.data_perm_group = payload;
        },
        loadPermsProfiles(state, payload){
            state.data_perm_profiles = payload;
        },
        loadTreeCompanies(state, payload){
            state.data_tree_companies = payload;
        },
        loadCompanyGroups(state, payload){
            state.data_company_group = payload;
        },
        loadResultSearchGroups(state, payload){
            state.result_search_data_groups = payload;
        },
        loadCompanyGroupData(state, payload){
            state.data = payload;
        },
        loadGroupUsers(state, payload){
            state.list_group_users = payload;
        },
        loadAssociatedUsers(state, payload){
            state.list_associated_users = payload;
        },
    },
    actions: {
        async getGroupUsers(state,items){
            let  requestGroupUsers = API_URL + "user/workers/company/" + items.id;
            let formData = new FormData();
            formData.append("id", items.id);
            await axios({
                method: "get",
                url: requestGroupUsers,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadGroupUsers", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSearchGroups(state,item){
            let requestSearchCompanies = API_URL + "search/users";
            let params = {};
            item.formData.forEach((item) => {
                let _key = item[0];
                params[_key] = item[1];
            });
            axios({
                method: "get",
                url: requestSearchCompanies,
                params: params,
            }).then(
                response => {
                    state.commit("loadResultSearchGroups", response.data.data);
                    response.data.data.forEach((data)=>{
                        if(Array.isArray(item.selected)==true) {
                            if (item.selected.findIndex((item) => item.id == data.id) !== -1) {
                                data['check'] = true
                            }else{
                                if(data['check']){
                                    delete data['check']
                                }
                            }
                        }else{
                            data['check'] = data.id == item.selected.id ? true : false;
                        }
                    })
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getListGroups(state){
            let  requestLogin = API_URL + "groups";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadListGroups", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getListProfiles(state){
            let  requestLogin = API_URL + "profiles";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadListProfiles", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getTreeCompanies(state){
            let  requestLogin = API_URL + "tree_company";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadTreeCompanies", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPermsGroup(state,item){
            if(item.id === undefined){
                state.commit("loadPermsGroup", []);
            }else{
                let  requestLogin = API_URL + "groups/"+item.id;
                await axios({
                    url: requestLogin,
                }).then(
                    response => {
                        state.commit("loadPermsGroup", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async getPermsProfiles(state,item){
            if(item.id === undefined){
                state.commit("loadPermsProfiles", []);
            }else{
                let  requestLogin = API_URL + "profile/"+item.id;
                await axios({
                    url: requestLogin,
                }).then(
                    response => {
                        state.commit("loadPermsProfiles", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        async setCompaniesGroup(state,item){
            let requestCompaniesGroup =  item.id ? API_URL + "user/company/"+item.id+"/groups" : API_URL + "company";
            let formData = new FormData();

            var defaultArray = [];
            var formArray = [];

            item.defaultData.forEach((i) => {
                defaultArray[i.id] = [];
                i.selected.forEach((x) => {
                    defaultArray[i.id].push(x);
                });

                item.formData.forEach((f) => {
                    var spliter = f[0].split('company[groups][')[1].split(']')[0];
                    if(formArray[spliter] === undefined){
                        formArray[spliter] = [];
                    }

                    if(formArray[spliter].find((a) => a == f[1]) === undefined) {
                        formArray[spliter].push(f[1]);
                    }
                })

            });

            Object.keys(defaultArray).forEach((key) => {
                defaultArray[key].forEach((d) => {
                    if(formArray[key].find((f) => f == d) === undefined){
                        formData.append('remove[company][groups][' + key + '][]', d);
                    }
                })
            });

            Object.keys(formArray).forEach((key) => {
                formArray[key].forEach((d) => {
                    if(defaultArray[key].find((f) => f == d) === undefined){
                        formData.append('add[company][groups][' + key + '][]', d);
                    }
                })
            });

            await axios({
                method: "post",
                url: requestCompaniesGroup,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == 200){
                        state.commit("loadCompanyGroupData", []);
                        this.dispatch('getTreeCompanies');
                        this.dispatch('getListGroups');
                    }
                    router.push({name: i18n.t('perms')});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPermsGroup(state,item){
            let requestCompaniesGroup =  item.id ? API_URL + "groups/"+item.id : API_URL + "groups";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });
            await axios({
                method: "post",
                url: requestCompaniesGroup,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadPermsGroup", response.data.data);
                    this.dispatch('getTreeCompanies');
                    router.push({name: i18n.t('perms')});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCompanyData(state, items){
            let requestLogin = API_URL + "user/company/"+items.id+"/groups";
            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadCompanyDataGeneral", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeProfile(state, item){
            let requestLogin = API_URL + "profiles/" + item.id;
            let formData = new FormData();

            formData.append('profile[id]', item.id);
            formData.append('profile[type]', item.type);

            axios({
                method: "delete",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.data.status == '200') {
                        await this.dispatch('getListProfiles');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setPermsProfile(state,item){
            let request =  item.id ? API_URL + "profiles/"+item.id : API_URL + "profiles";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: request,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == '200') {
                        router.push({name: i18n.t('perms')});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async checkAssociatedUsers(state, item){
            let request =  API_URL + 'search/permissions';
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });
            let params = new URLSearchParams(formData);
            await axios({
                method: "get",
                url: request,
                params: params,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == '200') {
                        state.commit("loadAssociatedUsers", response.data.data);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
