<template>
    <div>
        <div class="centered" style="margin-top: 40px;">
            <div class="gh-content-global centered T15_b" style="border: 2px solid black; width: 80%;display: flex;min-height: 300px;">
                {{MainItinerary.text_main}}
            </div>
        </div>
        <div class="gh-content-global centered" style="display: flex;">
            <div v-for="(item, index) in MainItinerary.array_quizz" :key="index" style="display: block;padding: 40px;">
                <div style="display: flex;justify-content: center;margin-bottom: 20px;">
                    <div class="icon_selector_quizz">
                        <img :src="item.icon != '' ? require('@/assets/img/itinerary/' + item.icon) : ''">
                    </div>
                </div>
                <div class="T15_b name_quizz" style="display: flex;justify-content: center;max-width: 150px;text-align: center;">{{item.name}}</div>
                <div style="display: flex;justify-content: center;margin-top: 20px;">
                    <GhButton
                        :datainput="{id: 'btn_back',text: $t('start'),class: '', style: 'margin-right: 0px!important'}"
                        @click="startQuizz(item.id);"
                    />
                </div>
            </div>
        </div>
        <div class="centered" style="margin-top: 20px;">
            <GhButton
                :datainput="{id: 'btn_back',text: $t('go_out'),class: 'container-md_button text-inline t7', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    export default {
        name: "main_itinerary",
        components:{
            GhButton
        },
        computed: {
            ...mapState(["MainItinerary"]),
        },
        async mounted() {
            await this.$store.dispatch('LOAD_LIST_QUIZZ','',{root:true});
            await this.$store.dispatch('GET_TEXT_MAIN','',{root:true});

            this.$router.options.GhSubTabs = this.MainItinerary.array_quizz;

            var r = document.querySelector(':root');
            var color = '#DDDDDD';

            r.style.setProperty('--body_color',color);

            var name_quizz = document.getElementsByClassName('name_quizz');
            var heigh_label = 0;

            for(var i=0;i<name_quizz.length;i++){
                heigh_label = name_quizz[i].offsetHeight > heigh_label ? name_quizz[i].offsetHeight : heigh_label;
            }
            for(var t=0;t<name_quizz.length;t++){
                name_quizz[t].style.height = heigh_label+"px";
            }
        },
        methods: {
            back(){
                this.$store.dispatch('TO_LOGIN','',{root:true});
            },
            async startQuizz(id){
                await this.$store.dispatch('getInitialQuizzes',id,{root:true});
            }
        }
    }
</script>