<template>
    <GhTableCommon v-if="haveData"
        :extratable="{id: 'schedule_table'}"
        :header="header"
        :data="ConfigSchedule.records_data.length == 0 ? [] : ConfigSchedule.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <router-link :to="{name: 'schedule_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
        </template>
        <template v-slot:actions="{itemProp}">
            <router-link :to="{name: 'schedule_id', params: {id: itemProp.id}}">
                <GhAction
                    :dataction="{
                        id: 'edit_conf_supp_schedule',
                        text: $t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    }"
                />
            </router-link>
        </template>
    </GhTableCommon>
</template>
<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    export default {
        name: "list_schedule",
        components:{
            GhAction,
            GhTableCommon
        },
        computed:{
           ...mapState(['ConfigSchedule'])
        },
        data(){
            return{
                haveData:false,
                header:[
                  {text: this.$t('assignment_group'), urlRedirect: true, field: 'assignment_group', sorting: true,typeSort: 'string', style: ' max-width: 150px; width: 20%; '},
                  {text: this.$t('working_hours'), field: 'working_hours', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 80%; '},
                  {text: '', field: 'actions', style: 'width:10%;'},
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getSchedule', '', {root: true});
            this.haveData = true;
        }
    }
</script>
