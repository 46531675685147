import Vue from "vue";
import vuex from "vuex";

import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        list_c_activity: [],
        data_certificate: [],
    },
    mutations: {
        loadListCActivity(state,payload){
            state.list_c_activity = payload
        },
        loadDataCertificate(state,payload){
            state.data_certificate = payload
        },
    },
    actions: {
        async resetDataCertificate(state){
            state.commit("loadDataCertificate", []);
            state.commit("loadListCActivity", []);
        },
        async getListCActivities(state,item){
            let requestLogin = API_URL + 'creation/activities/table';

            let formData = new FormData();

            for(var i=0;i<item.actividades.length;i++){
                if(item.actividades[i] !== undefined){
                    formData.append('activity[]', i);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadListCActivity", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataCertificate(state,item){
            let requestLogin = API_URL + 'creation/certificates/'+item.id;
            console.log(item);

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response.data.data);
                    state.commit("loadDataCertificate", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setCertificate(state, item){
            let requestLogin = API_URL + "creation/certificates/set";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadAroundData", response.data);
                    if (response.status == 200) {
                        router.go(-1);
                    }
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};