<template>
    <div style="padding-left: 12px; padding-right: 12px;" v-if="haveData">
        <form id="to_register">
            <GhNoDropSelector :datablock="{id:'register_company',title: $t('company'),second_title:'' }">
                <template v-slot:content>
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('company_name') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_name',
                                    name: 'register[company][name]',
                                    style: 'width: 20%',
                                    value: '',
                                    type: 'text',
                                }"
                            />
                            <GhDataSelector
                                :datablock="{
                                    id: 'social_reason',
                                    class: 'T15_b',
                                    subtitle: $t('select_social_reason') + ':',
                                    text: $t('social_reason')+'*',
                                    label: $t('social_reason'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: 'margin-left:84px;',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                }"
                                :datainput="{
                                    id: 'value_social_Reason',
                                    name: 'register[company][social_reason][]'
                                }"
                                :feed="getFeeds.feed_social_reason"
                                :selected_input="[]"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('cif') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_cif',
                                    name: 'register[company][cif]',
                                    style: 'width: 20%',
                                    value: '',
                                    type: 'text',
                                }"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('full_address') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_direction',
                                    name: 'register[company][direction]',
                                    style: 'width: 20%',
                                    value: '',
                                    type: 'text',
                                }"
                            />
                            <GhDataSelector
                                :datablock="{
                                    id: 'country',
                                    class: 'T15_b',
                                    subtitle: $t('select_country') + ':',
                                    text: $t('country')+'*',
                                    label: $t('country'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: 'margin-left:84px;',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                }"
                                :datainput="{
                                    id: 'value_country',
                                    name: 'register[company][country][]'
                                }"
                                :feed="getFeeds.feed_country"
                                :selected_input="[]"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('email') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_email',
                                    name: 'register[company][email]',
                                    style: 'width: 20%',
                                    value: '',
                                    type: 'text',
                                }"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('telephone') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'company_telephone',
                                    name: 'register[company][telephone]',
                                    style: 'width: 20%',
                                    value: '',
                                    type: 'text',
                                }"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhTreeSelectorBlock
                                :dataBlock="{
                                    type: 'radio',
                                    id: 'activity',
                                    label: $t('activity_sector') + '*',
                                    title: $t('activity_sector'),
                                    text: $t('select_activity_sector') + ':',
                                    name: 'register[company][activity][]',
                                    id_value: 'value',
                                    name_label: 'text',
                                    child_key: 'children',
                                    required: true,
                                    checkparentdata: false,
                                    style: 'margin-left: 16px; width:100%',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                }"
                                :selected_input="[]"
                                :items="getFeeds.feed_activity"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhDataSelector
                                :datablock="{
                                    id: 'num_employee',
                                    class: 'T15_b',
                                    subtitle: $t('select_number_employees') + ':',
                                    text: $t('number_employees')+'*',
                                    label: $t('number_employees'),
                                    type: 'radio',
                                    columns: 3,

                                    required: true,
                                    style: 'margin-left: -5px;',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                }"
                                :datainput="{
                                    id: 'value_num_employee',
                                    name: 'register[company][num_employee][]'
                                }"
                                :feed="getFeeds.feed_num_employers"
                                :selected_input="[]"
                            />
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>
            <GhNoDropSelector :datablock="{id:'register_contact',title: $t('contact_person'),second_title:'' }">
                <template v-slot:content>
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('name') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'contact_name',
                                    name: 'register[contact][name]',
                                    style: 'width: 20%',
                                    value: '',
                                    type: 'text',
                                }"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('surnames') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'contact_surnames',
                                    name: 'register[contact][surnames]',
                                    style: 'width: 20%',
                                    value: '',
                                    type: 'text',
                                }"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                      text: $t('email') + '*',
                                      style:'width: 150px; text-align: right;',
                                      class: 'T15_b'
                                  }"
                                :datainput="{
                                      required: true,
                                      id: 'contact_email',
                                      name: 'register[contact][email]',
                                      style: 'width: 20%',
                                      value: '',
                                      type: 'text',
                                  }"
                            />
                            <GhDataSelector
                                :datablock="{
                                    id: 'position',
                                    class: 'T15_b',
                                    subtitle: $t('select_position') + ':',
                                    text: $t('position')+'*',
                                    label: $t('position'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: 'margin-left: 106px;',
                                    popupStyle: 'min-width: 900px;width: 55%;',
                                }"
                                :datainput="{
                                    id: 'value_num_employee',
                                    name: 'register[contact][position][]'
                                }"
                                :feed="getFeeds.feed_positions"
                                :selected_input="[]"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('contact_telephone') + '*',
                                    style:'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'contact_telephone',
                                    name: 'register[contact][telephone]',
                                    style: 'width: 20%',
                                    value: '',
                                    type: 'text',
                                }"
                            />
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>
            <GhNoDropSelector :datablock="{id:'register_treatment_data',title: $t('data_treatment'),second_title:'' }">
                <template v-slot:content>
                    <div class="gh-content-global">
                        <div class="gh-row-content" style="white-space: pre-wrap;">
                            {{getFeeds.data_treatment_text.text}}
                        </div>
                        <div class="gh-row-content">
                            <GhChecks
                                :datalabel="{text:$t('politic_privacity'),style:'',class:'',id:''}"
                                :datainput="{required:true,selected:false,type:'checkbox',id:'',value:'all',name:'register[politic_privacity]'}"
                            />
                        </div>
                        <div class="gh-row-content" style="white-space: pre-wrap;">
                            {{getFeeds.data_treatment_text.text_confirmation}}
                        </div>
                        <div class="gh-row-content">
                            <GhChecks
                                :datalabel="{text:$t('legal_advise'),style:'',class:'',id:''}"
                                :datainput="{required:true,selected:false,type:'checkbox',id:'',value:'all',name:'register[legal_advise]'}"
                            />
                        </div>
                    </div>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 10px;">
                <GhButton
                    :datainput="{id: 'btn_send',text: $t('send_request'),class: 'container-md_button text-inline t7', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                    @click="sendRegister();"
                />
                <GhButton
                    :datainput="{id: 'btn_back',text: $t('go_out'),class: 'container-md_button text-inline t7', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                    @click="back();"
                />
            </div>
        </form>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import {mapState} from "vuex";
    export default {
        name: "register_view",
        components:{
            GhButton,
            GhNoDropSelector,
            GhInputTextField,
            GhDataSelector,
            GhTreeSelectorBlock,
            GhChecks,
        },
        computed: {
            ...mapState(['getFeeds']),
        },
        data(){
            return{
                haveData: false,
            }
        },
        async mounted() {
            await this.$store.dispatch('getSocialReason', '', {root:true});
            await this.$store.dispatch('getCountry', '', {root:true});
            await this.$store.dispatch('getActivity', '', {root:true});
            await this.$store.dispatch('getNumEmployers', '', {root:true});
            await this.$store.dispatch('getPosition', '', {root:true});
            await this.$store.dispatch('getTreatmentText', '', {root:true});

            var r = document.querySelector(':root');
            var color = '#DDDDDD';

            r.style.setProperty('--body_color',color);
            this.haveData = true;
        },
        methods:{
            back(){
                this.$store.dispatch('TO_LOGIN','',{root:true});
            },
            sendRegister(){
                if(this.Global.checkRequired('to_register') == 'OK') {
                    const formData = new FormData(document.getElementById('to_register'));
                    this.$store.dispatch('SET_REGISTER', {
                        formData: [...formData],
                    }, {root: true});
                }
            }
        }
    }
</script>