import ConfigCategories from "@/view/config/support/categories/template/category";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/support/category',
        name: 'category',
        component: ConfigCategories,
        props: {id: null, showMenu: true,background: true, otherTitle: i18n.t('category')},
        children: [
            {
                path: '/config/support/category/:id',
                name: 'category_id',
                component: ConfigCategories,
                props: {id: null, showMenu: true, background:true, otherTitle: i18n.t('category')},
            },
            {
                path: '/config/support/category/:id/:view/',
                name: 'category_view',
                component: ConfigCategories,
                props: {id: null, showMenu: true, background:true, otherTitle: i18n.t('category')},
            },
        ]
    }
];
export default routes;
