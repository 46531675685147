var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('code') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'procedure_code',
                    name: 'procedure[code]',
                    style: 'width: 200px',
                    value: _vm.ConfigProcedures.data.code,
                    type: 'text',
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigProcedures.data.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('name') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'procedure_name',
                    name: 'procedure[name]',
                    style: 'min-width: 200px; width: 20%',
                    value: _vm.ConfigProcedures.data.name,
                    type: 'text',
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigProcedures.data.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('description'),
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'procedure_description',
                    name: 'procedure[description]',
                    value: _vm.ConfigProcedures.data.description
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigProcedures.data.description))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhMultipleSearch',{attrs:{"datalabel":{
                    text: _vm.$t('responsible') + '*',
                    style: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                    class: 'T15_b',
                    id: 'label_procedure_responsible'
                },"datainput":{
                    required: true,
                    id: 'procedure_responsible_user',
                    name: 'procedure[responsible][name]',
                    nameS:'procedure[responsible][surname]',
                    nameId:'procedure[responsible][id]',
                    style: 'width: 20.5%; min-width: 200px;',
                    value: _vm.ConfigProcedures.data.responsible ? _vm.ConfigProcedures.data.responsible.first_name : '',
                    valueId: _vm.ConfigProcedures.data.responsible ? _vm.ConfigProcedures.data.responsible.id : '',
                    valueS: _vm.ConfigProcedures.data.responsible ? _vm.ConfigProcedures.data.responsible.last_name : '',
                    placeholder: _vm.$t('name'),
                    placeholderS: _vm.$t('surnames'),
                    result_1: 'first_name',
                    result_2: 'last_name',
                    result_id: 'id',
                    href: _vm.dispatchUserResponsible
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('responsible') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigProcedures.data.responsible ? _vm.ConfigProcedures.data.responsible.first_name + ' ' + _vm.ConfigProcedures.data.responsible.last_name : ''))])]],2),(_vm.$root._route.params.id !== undefined && !_vm.$root._route.path.includes('exercise'))?_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('exercises') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigProcedures.data.text_exercises))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }