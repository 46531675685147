import Vue from "vue";
import vuex from "vuex";

import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        list_exercises: [],
        data_activity: [],
    },
    mutations: {
        loadListExcercises(state,payload){
            state.list_exercises = payload
        },
        loadDataActivity(state,payload){
            state.data_activity = payload
        },
    },
    actions: {
        async resetDataActivity(state){
            state.commit("loadListExcercises", []);
            state.commit("loadDataActivity", []);
        },
        async getListExcercises(state,item){
            let requestLogin = API_URL + 'configuration/exercises/table';
            let formData = new FormData();

            for(var i=0;i<item.ejercicios.length;i++){
                if(item.ejercicios[i] !== undefined){
                    formData.append('exercise[]', i);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadListExcercises", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getDataActivity(state,item){
            let requestLogin = API_URL + 'creation/activities/'+item.id;
            console.log(item);

            await axios({
                method: "get",
                url: requestLogin,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response.data.data);
                    state.commit("loadDataActivity", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setActivity(state, item){
            let requestLogin = API_URL + "creation/activities/set";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadAroundData", response.data);
                    if (response.status == 200) {
                        router.go(-1);
                    }
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};