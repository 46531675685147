var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[(_vm.ConfigExercisesTable.type_exercise.close_numeric.responsible !== undefined && _vm.ConfigExercisesTable.type_exercise.close_numeric.responsible.view)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                    id: 'label_exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_numeric_responsible',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_responsible') + ':',
                    text: _vm.$t('responsible') + (_vm.ConfigExercisesTable.type_exercise.close_numeric.responsible.required ? '*' : ''),
                    label: _vm.$t('responsible'),
                    type: 'radio',
                    columns: 3,
                    style: '',
                    required: _vm.ConfigExercisesTable.type_exercise.close_numeric.responsible.required,
                },"datainput":{
                    id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_numeric_responsible',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_numeric][responsible]'
                },"selected_input":!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.responsible ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.responsible : []) : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.responsible ? _vm.ConfigExercisesTable.data.close_numeric.responsible : []),"feed":_vm.getFeeds.feed_responsible}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('responsible') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.responsible ? Object.values(_vm.ConfigExercisesTable.data.subquestion_close_numeric.responsible).join() : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.responsible ? Object.values(_vm.ConfigExercisesTable.data.close_numeric.responsible).join() : '')))])]],2):_vm._e(),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericStatement' : 'RefCloseNumericStatement',attrs:{"datalabel":{
                    text: _vm.$t('statement') + '*',
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_numeric_statement',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_numeric][statement]',
                    value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.statement : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.statement : ''),
                    required: true,
                    edited: true,
                    image: {
                        subscript: require('../../../../../../assets/img/text/subscript.svg'),
                        superscript: require('../../../../../../assets/img/text/superscript.svg'),
                        bold: require('../../../../../../assets/img/text/text-bold.svg'),
                        italic: require('../../../../../../assets/img/text/text-italic.svg'),
                        underline: require('../../../../../../assets/img/text/text-underline.svg'),
                    }
                }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'statement', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericStatement' : 'RefCloseNumericStatement', 'text')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('statement') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"},domProps:{"innerHTML":_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.statement : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.statement : ''))}})]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('information'),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_' + (!_vm.showSubQuestion ? '_subquestion_' : '') + 'close_numeric_information',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_numeric][information]',
                    value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.information : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.information : ''),
                    required: false
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('information') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.information : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.information : '')))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('score_legend'),
                    class: 'T15_b',
                    styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_' + (!_vm.showSubQuestion ? '_subquestion_' : '') + 'close_numeric_score_legend',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_numeric][score_legend]',
                    value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.score_legend : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.score_legend : ''),
                    required: false
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('score_legend') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.score_legend : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.score_legend : '')))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericValueMin' : 'RefCloseNumericValueMin',attrs:{"datalabel":{
                    text: _vm.$t('minimum_value') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_numeric_value_min',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_numeric][value][min]',
                    style: 'width: 100px',
                    value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.value !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.value.min : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.value !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.value.min : ''),
                    type: 'text',
                }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'value', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericValueMin' : 'RefCloseNumericValueMin', 'text', 'min')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('minimum_value') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.value !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.value.min : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.value !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.value.min : '')))])],(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericValueMax' : 'RefCloseNumericValueMax',attrs:{"datalabel":{
                    text: _vm.$t('maximum_value') + '*',
                    style: 'width: 160px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'exercise_' + (!_vm.showSubQuestion ? 'subquestion_' : '') + 'close_numeric_value_max',
                    name: 'exercise' + (!_vm.showSubQuestion ? '[subquestion]' : '') + '[close_numeric][value][max]',
                    style: 'width: 100px',
                    value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.value !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.value.max : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.value !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.value.max : ''),
                    type: 'text',
                }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'value', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericValueMax' : 'RefCloseNumericValueMax', 'text', 'max')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('maximum_value') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.value.max : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.value.max : '')))])]],2),(_vm.showSubQuestion && _vm.ConfigExercisesTable.type_exercise.close_numeric.subquestion !== undefined && _vm.ConfigExercisesTable.type_exercise.close_numeric.subquestion.view)?[_c('div',{staticClass:"section_line",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('subquestion')))])]),_c('GhTableCommon',{key:'A'+_vm.refresh_table_no_condition,attrs:{"extratable":{
                id: 'subquestions',
            },"header":_vm.header,"data":_vm.table_no_condition},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(itemProp.new)?_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_subquestions',
                        text: _vm.$t('modify'),
                        icon: require('../../../../../../assets/gh_new_dessign/edit.svg'),
                    }},on:{"click":function($event){return _vm.editNewSubquestion(itemProp.id)}}}):_vm._e(),_c('GhAction',{attrs:{"dataction":{
                        id: 'delete_subquestions',
                        text: _vm.$t('delete'),
                        icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                    }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm',itemProp.new ? 'SubquestionNew' : 'Subquestion', itemProp.id,{'name': itemProp.name, 'type_of_exercise': itemProp.type}, _vm.$t('delete_subquestion'), _vm.$t('preparing_delete_subquestion'), 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_new_subquestions',
                            text: _vm.$t('add_new')
                        }},on:{"click":_vm.newSubquestion}}),(_vm.getFeeds.feed_excerises_no_subquestions.length !== 0)?_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_old_subquestions',
                            text: _vm.$t('add_existing')
                        }},on:{"click":function($event){return _vm.Global.openPopUp('subquestion_exist_data_selector_popup')}}}):_vm._e()],1)]},proxy:true}],null,false,1245719269)}),_c('div',{staticStyle:{"display":"none"}},_vm._l((_vm.table_no_condition),function(item,index){return _c('div',{key:'B'+index},[(item.new)?_c('input',{attrs:{"type":"hidden","name":"exercise[close_numeric][subquestion][]"},domProps:{"value":item.id}}):_vm._e()])}),0),_c('GhDataSelector',{key:'C'+_vm.refresh_no_condition,ref:"subquestion_exist_no_condition",attrs:{"datablock":{
                id: 'subquestion_exist',
                class: 'T15_b',
                subtitle: _vm.$t('select_existing_exercise') + ':',
                text: _vm.$t('add_existing'),
                label: _vm.$t('add_existing'),
                type: 'checkbox',
                columns: 3,
                style: 'width: 149px;display: none;',
                required: false,
            },"datainput":{
                id: 'value_subquestion_exist',
                name: 'exercise[close_numeric][subquestion][]'
            },"selected_input":_vm.selected_no_condition,"feed":_vm.getFeeds.feed_excerises_no_subquestions},on:{"accept":_vm.addExerciseExist}})]:_vm._e(),(_vm.ConfigExercisesTable.type_exercise.close_numeric.conditions !== undefined && _vm.ConfigExercisesTable.type_exercise.close_numeric.conditions.length != 0)?[_c('div',{staticClass:"section_line",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('conditions')))])]),_vm._l((_vm.for_table_condition),function(item,index){return _c('div',{key:'NC-'+item},[_vm._l((_vm.ConfigExercisesTable.type_exercise.close_numeric.conditions),function(subitem,subindex){return [(subitem == 1)?_c('div',{key:'condition_'+subindex+'_'+index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionHigherThan' : 'RefCloseNumericConditionHigherThan',refInFor:true,attrs:{"datalabel":{
                                text: _vm.$t('value_greather_than') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'exercise_close_numeric_condition_higher_than_'+index,
                                name: 'exercise[close_numeric][condition]['+index+'][higher_than]',
                                style: 'width: 100px',
                                value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][1] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][1].higher_than !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][1].higher_than : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][1] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][1].higher_than !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][1].higher_than : ''),
                                type: 'text',
                            }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionHigherThan' : 'RefCloseNumericConditionHigherThan', 'text', 'higher_than', 1)}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('value_greather_than') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][1].higher_than : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index] !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][1].higher_than : '')))])]],2):_vm._e(),(subitem == 2)?_c('div',{key:'condition_'+subindex+'_'+index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionMinorThan' : 'RefCloseNumericConditionMinorThan',refInFor:true,attrs:{"datalabel":{
                                text: _vm.$t('value_less_than') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'exercise_close_numeric_condition_minor_than_'+index,
                                name: 'exercise[close_numeric][condition]['+index+'][minor_than]',
                                style: 'width: 100px',
                                value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][2] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][2].minor_than !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][2].minor_than : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][2] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][2].minor_than !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][2].minor_than : ''),
                                type: 'text',
                            }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionMinorThan' : 'RefCloseNumericConditionMinorThan', 'text', 'minor_than', 2)}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('value_less_than') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][2].minor_than : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][2].minor_than : '')))])]],2):_vm._e(),(subitem == 3)?_c('div',{key:'condition_'+subindex+'_'+index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionEqual' : 'RefCloseNumericConditionEqual',refInFor:true,attrs:{"datalabel":{
                                text: _vm.$t('value_same_as') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'exercise_close_numeric_condition_equal_'+index,
                                name: 'exercise[close_numeric][condition]['+index+'][equal]',
                                style: 'width: 100px',
                                value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][3] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][3]['equal'] !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][3]['equal'] : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][3] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][3]['equal'] !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][3]['equal'] : ''),
                                type: 'text',
                            }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionEqual' : 'RefCloseNumericConditionEqual', 'text', 'equal', 3)}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('value_same_as') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][3]['equal'] : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][3]['equal'] : '')))])]],2):_vm._e(),(subitem == 4)?_c('div',{key:'condition_'+subindex+'_'+index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionBetween1' : 'RefCloseNumericConditionBetween1',refInFor:true,attrs:{"datalabel":{
                                text: _vm.$t('value_between') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'exercise_close_numeric_condition_between_1_'+index,
                                name: 'exercise[close_numeric][condition]['+index+'][between][1]',
                                style: 'width: 100px',
                                value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][1] !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][1] : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][4] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][4][1] !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][4][1] : ''),
                                type: 'text',
                                label_required: _vm.$t('value_between') + '*'
                            }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionBetween1' : 'RefCloseNumericConditionBetween1', 'text', 1, 4)}}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"18px","min-width":"18px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('and')))]),_c('GhInputTextField',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionBetween2' : 'RefCloseNumericConditionBetween2',refInFor:true,attrs:{"datalabel":{
                                text: '',
                                style: 'width: 18px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'exercise_close_numeric_condition_between_2_'+index,
                                name: 'exercise[close_numeric][condition]['+index+'][between][2]',
                                style: 'width: 100px',
                                value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][2] !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][2] : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][4] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][4][2] !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][4][2] : ''),
                                type: 'text',
                                label_required: _vm.$t('value_between') + '*'
                            }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionBetween2' : 'RefCloseNumericConditionBetween2', 'text', 2, 4)}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('value_between') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][1] : '' + _vm.$t('and') + ' ' + _vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][4][2] : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[4][1] : '' + _vm.$t('y') + ' ' + _vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[4][2] : '')))])]],2):_vm._e(),(_vm.ConfigExercisesTable.type_exercise.close_numeric.feedback !== undefined && _vm.ConfigExercisesTable.type_exercise.close_numeric.feedback.view)?_c('div',{key:'feedback_'+subindex+'_'+index,staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionFeedback'+subitem : 'RefCloseNumericConditionFeedback'+subitem,refInFor:true,attrs:{"datalabel":{
                                text: _vm.$t('feedback') + (_vm.ConfigExercisesTable.type_exercise.close_numeric.feedback.required ? '*' : ''),
                                class: 'T15_b',
                                styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                            },"datainput":{
                                id: 'exercise_close_numeric_feedback_'+index,
                                name: 'exercise[close_numeric][feedback]['+index+']['+subitem+']',
                                value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][subitem] !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][subitem].feedback !== undefined ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.condition[index][subitem].feedback : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][subitem] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.condition[index][subitem].feedback !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][subitem].feedback : ''),
                                required: _vm.ConfigExercisesTable.type_exercise.close_numeric.feedback.required
                            }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'condition', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionFeedback'+item : 'RefCloseNumericConditionFeedback'+item, 'text', 'feedback', item)}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('feedback') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][subitem].feedback : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined ? _vm.ConfigExercisesTable.data.close_numeric.condition[index][subitem].feedback : '')))])]],2):_vm._e()]}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:!_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionPuntuation' : 'RefCloseNumericConditionPuntuation',refInFor:true,attrs:{"datalabel":{
                            text: _vm.$t('punctuation') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        },"datainput":{
                            required: true,
                            id: 'exercise_close_numeric_puntuation_'+index,
                            name: 'exercise[close_numeric][puntuation]['+index+']',
                            style: 'width: 100px',
                            value: !_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] !== undefined  && _vm.ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] !== null ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.puntuation[index] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.puntuation[index] !== null ? _vm.ConfigExercisesTable.data.close_numeric.puntuation[index] : ''),
                            type: 'text',
                        }},on:{"keyup":function($event){return _vm.persistance(!_vm.showSubQuestion ? 'subquestion_close_numeric' : 'close_numeric', 'puntuation', !_vm.showSubQuestion ? 'RefSubquestionCloseNumericConditionPuntuation' : 'RefCloseNumericConditionPuntuation', 'text')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('punctuation') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(!_vm.showSubQuestion ? (_vm.ConfigExercisesTable.data.subquestion_close_numeric !== undefined && _vm.ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] !== _vm.undefine && _vm.ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] !== null ? _vm.ConfigExercisesTable.data.subquestion_close_numeric.puntuation[index] : '') : (_vm.ConfigExercisesTable.data.close_numeric !== undefined && _vm.ConfigExercisesTable.data.close_numeric.puntuation[index] !== undefined && _vm.ConfigExercisesTable.data.close_numeric.puntuation[index] !== null ? _vm.ConfigExercisesTable.data.close_numeric.puntuation[index] : '')))])]],2),(_vm.for_table_condition.length > 1)?_c('GhAction',{attrs:{"dataction":{
                    id: 'delete_condition_'+index,
                    text: _vm.$t('delete_condition'),
                }},on:{"click":function($event){return _vm.deleteBlockCondition(index)}}}):_vm._e(),(_vm.showSubQuestionCondition && _vm.ConfigExercisesTable.type_exercise.close_numeric.subquestion_condition !== undefined && _vm.ConfigExercisesTable.type_exercise.close_numeric.subquestion_condition.view)?_c('div',{staticClass:"section_line",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"T15_b"},[_vm._v(_vm._s(_vm.$t('subquestion')))])]):_vm._e(),(_vm.showSubQuestionCondition && _vm.ConfigExercisesTable.type_exercise.close_numeric.subquestion_condition !== undefined && _vm.ConfigExercisesTable.type_exercise.close_numeric.subquestion_condition.view)?_c('GhTableCommon',{key:'T'+_vm.refresh_table_condition,attrs:{"extratable":{
                    id: 'subquestions_contitions_'+index,
                },"header":_vm.header,"data":_vm.table_condition[index]},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(itemProp.new)?_c('GhAction',{attrs:{"dataction":{
                            id: 'edit_subquestions_condition_'+index,
                            text: _vm.$t('modify'),
                            icon: require('../../../../../../assets/gh_new_dessign/edit.svg'),
                        }},on:{"click":function($event){return _vm.editNewSubquestionCondition(itemProp.id)}}}):_vm._e(),_c('GhAction',{attrs:{"dataction":{
                            id: 'delete_subquestions_condition_'+index,
                            text: _vm.$t('delete'),
                            icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                        }},on:{"click":function($event){_vm.levelCondition(index);_vm.Global.deleteConfirm('delete_confirm',itemProp.new ? 'SubquestionNewCondition' : 'SubquestionCondition', itemProp.id,{'name': itemProp.name, 'type_of_exercise': itemProp.type}, _vm.$t('delete_subquestion'), _vm.$t('preparing_delete_subquestion'), 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                id: 'btn_add_new_subquestions_condition_'+index,
                                text: _vm.$t('add_new')
                            }},on:{"click":function($event){return _vm.newSubquestionCondition(index)}}}),(_vm.getFeeds.feed_excerises_no_subquestions.length !== 0)?_c('GhButton',{attrs:{"datainput":{
                                id: 'btn_add_old_subquestions_condition_'+index,
                                text: _vm.$t('add_existing')
                            }},on:{"click":function($event){return _vm.Global.openPopUp('subquestion_exist_condition_'+index+'_data_selector_popup')}}}):_vm._e()],1)]},proxy:true}],null,true)}):_vm._e(),_c('div',{staticStyle:{"display":"none"}},_vm._l((_vm.table_condition[index]),function(subitem,subindex){return _c('div',{key:'VC'+subindex},[(subitem.new)?_c('input',{attrs:{"type":"hidden","name":'exercise[close_numeric][condition]['+index+'][subquestion][]'},domProps:{"value":subitem.id}}):_vm._e()])}),0),_c('GhDataSelector',{key:'WC'+index+'-'+_vm.refresh_condition,ref:"subquestion_exist_condition",refInFor:true,staticClass:"hidden_block_summary",attrs:{"datablock":{
                    id: 'subquestion_exist_condition_'+index,
                    class: 'T15_b',
                    subtitle: _vm.$t('select_existing_exercise') + ':',
                    text: _vm.$t('add_existing'),
                    label: _vm.$t('add_existing'),
                    type: 'checkbox',
                    columns: 3,
                    style: 'width: 149px;display: none;',
                    required: false,
                },"datainput":{
                    id: 'value_subquestion_exist_condition_'+index,
                    name: 'exercise[close_numeric][condition]['+index+'][subquestion][]'
                },"selected_input":_vm.selected_condition[index],"feed":_vm.getFeeds.feed_excerises_no_subquestions},on:{"accept":function($event){return _vm.addExerciseExistCondition(index)}}})],2)})]:_vm._e(),_c('GhAction',{attrs:{"dataction":{
            id: 'new_condition',
            text: _vm.$t('add_another_condition'),
        }},on:{"click":_vm.newBlockCondition}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }