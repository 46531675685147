<template>
    <div class="gh-content-global">
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabBusiness.records_data.name}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('social_reason') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabBusiness.records_data.text_social_reason}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('cif') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 100px;">{{TabBusiness.records_data.cif}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('full_adress') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabBusiness.records_data.direction}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('country') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabBusiness.records_data.text_country}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('mail') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabBusiness.records_data.email}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('telephone') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabBusiness.records_data.telephone}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('activity_sector') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabBusiness.records_data.text_activity}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('number_employees') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabBusiness.records_data.text_num_employee}}</label>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    export default {
        name: "view_business",
        computed: {
            ...mapState(['TabBusiness']),
        },
    }
</script>