<template>
    <GhTableCommon v-if="haveData"
        :extratable="{id: 'priority_table'}"
        :header="ConfigPriorities.header"
        :data="ConfigPriorities.records_data.length == 0 ? [] : ConfigPriorities.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <router-link :to="{name: 'priority_view', params: {id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
        </template>
        <template v-slot:actions="{itemProp}">
            <router-link :to="{name: 'priority_id', params: {id: itemProp.id}}">
                <GhAction
                    :dataction="{
                        id: 'edit_conf_supp_priority',
                        text: $t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    }"
                />
            </router-link>
            <GhAction
                :dataction="{
                    id: 'delete_conf_supp_priority',
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','Priority', itemProp.id,{'priority': itemProp.name, 'description' : itemProp.description}, $t('delete_priority'), $t('prepare_delete_priority') + ':', 'delete')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <router-link :to="{name: 'priority'}">
                    <GhButton
                        :datainput="{
                            id: 'btn_add_conf_supp_priority',
                            text: $t('add')
                        }"
                    />
                </router-link>
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";
    export default {
        name: "list_priorities",
        computed:{
            ...mapState(['ConfigPriorities'])
        },
        components:{
            GhAction,
            GhTableCommon,
            GhButton
        },
        data(){
            return{
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPriorities', '', {root: true});
            this.haveData = true;
        }
    }
</script>
