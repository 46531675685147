import router from "../../routes/router";
import Vue from "vue";
import vuex from "vuex";
import i18n from "@/plugins/i18n";
import {API_URL} from "@/common/config";
import axios from "axios";
Vue.use(vuex);

export default {
    state: {
        subTabActive: '',
        nameSubTabActive:'',
        searchData:'',
        resultTitle: i18n.t('no_results_found')
    },
    mutations: {
        loadSubActiveItem(state, payload) {
            state.subTabActive = payload
        },
        loadNameSubActiveItem(state,payload){
            state.nameSubTabActive = payload
        },
        loadSearchData(state,payload){
            state.searchData = payload
        },
        loadTitleResult(state, payload){
            state.resultTitle = payload
        }
    },
    actions:{
        destroyedResultData(state){
            state.commit("loadSearchData", '');
            state.commit('loadTitleResult', i18n.t('no_results_found'));
        },
        exportTotal(state,items){
            let requestLogin = API_URL + 'exports/excel';
            let params = {};

            params['export[type]'] = items.data;
            params['export[type_export]'] = 'all';

            axios({
                method: "get",
                url: requestLogin,
                params: params,
                responseType: 'blob'
            }).then(
                response => {
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '');
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        },
        exportResult(state,items){
            let requestLogin = API_URL + 'exports/excel';
            let params = {};

            // formData.append('export[ho]', items.data);
            params['export[type_export]'] = 'results';

            items.formData.forEach((item) => {
                params[item[0].replace('search', 'export')] = item[1];
            });

            axios({
                method: "get",
                url: requestLogin,
                params: params,
                responseType: 'blob'
            }).then(
                response => {
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '');
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        },
        async searchData(state,items){
            let requestLogin = API_URL +'search';

            let params = {};

            items.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    console.log(response.data);
                    state.commit("loadSearchData", response.data);
                    state.commit("loadTitleResult", response.data.length);

                },
                error => {
                    console.log(error);
                }
            )
        },
        GhSubTabItem(state, item) {
            let idtabsearch=''
            let name=''
            router.options.routes.forEach((items,index) => {
                if ((item.call == items.name && item.call)  || items.name == item.value){
                    idtabsearch = router.options.routes[index].component;
                    name = items.name
                }
                console.log(name);
                state.commit("loadSubActiveItem", idtabsearch!=''?idtabsearch:'');
                state.commit("loadNameSubActiveItem", name != '' ? name:'');
            });
        },
    }
}
