var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'seal',title: _vm.$t('stamp')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_seal"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"15%"}},[_c('div',{staticStyle:{"width":"250px","height":"135px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"135px","width":"auto"},attrs:{"src":_vm.image,"width":"250"}})]),_c('div',{staticClass:"other-image"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                                    id: 'seal_image',
                                    class: '',
                                    name_hidden: 'seal[image]',
                                    id_hidden: 'seal_image',
                                    icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                },"datalabel":{
                                    id: 'seal_image',
                                    style: 'margin-right: -15px'
                                },"datainput":{
                                    view: _vm.$root._route.params.view,
                                    accept: '.png, .jpg, .jpeg',
                                    text: _vm.$t('attach_image'),
                                    show_image: false,
                                    delete: false,
                                    href: 'easyUpload',
                                    downloadHref: 'easyDownload',
                                    file_name: _vm.ConfigSeals.data.file_name,
                                    file_path: _vm.ConfigSeals.data.file_path,
                                    file_download: _vm.ConfigSeals.data.file_download,
                                    required: false
                                }}})],1)]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"85%"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'seal_code',
                                        name: 'seal[code]',
                                        style: 'width: 200px',
                                        value: _vm.ConfigSeals.data.code,
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigSeals.data.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'seal_name',
                                        name: 'seal[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: _vm.ConfigSeals.data.name,
                                        type: 'text'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigSeals.data.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('description'),
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                    },"datainput":{
                                        id: 'seal_description',
                                        name: 'seal[description]',
                                        value: _vm.ConfigSeals.data.description
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigSeals.data.description))])]],2)])])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            }},on:{"click":_vm.submitSeal}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }