<template>
    <GhTableCommon v-if="haveData"
       :extratable="{ id: 'assignment_table' }"
       :header="header"
       :data="ConfigAssignment.records_data.length == 0 ? [] : ConfigAssignment.records_data"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <router-link :to="{name: 'assignment_view', params: {id: itemProp.id, view: true}}"><a :href="itemProp[labelProp]" class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
        </template>
        <template v-slot:actions="{itemProp}">
            <router-link :to="{name: 'assignment_id', params: {id: itemProp.id}}">
                <GhAction
                    :dataction="{
                        id: 'edit_config_support_assignment',
                        text: $t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    }"
                />
            </router-link>
            <GhAction
                :dataction="{
                    id: 'delete_config_support_assignment',
                    text: $t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }"
                @click="Global.deleteConfirm('delete_confirm','Assignment', itemProp.id,{'assignment_group':itemProp.name, 'description':itemProp.description}, $t('delete_assignment'), $t('prepare_delete_assignment') + ':', 'delete')"
            />
        </template>
        <template v-slot:footer>
            <div class="centered">
                <router-link :to="{name: 'assignment'}">
                    <GhButton
                        :datainput="{
                          id: 'btn_add_config_support_assignment',
                          text: $t('add')
                        }"
                    />
                </router-link>
            </div>
        </template>
    </GhTableCommon>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";
    export default {
        name: "list_assignment",
        computed:{
            ...mapState(['ConfigAssignment'])
        },
        components:{
            GhAction,
            GhTableCommon,
            GhButton
        },
        data(){
            return{
                haveData:false,
                header:[
                    {text: this.$t('assignment_group'), field: 'name', urlRedirect: true,sorting: true,typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: this.$t('description'), field: 'description', sorting: true,typeSort: 'string', style: 'max-width: 150px; width: 80%; '},
                    {text: '', field: 'actions', style: 'width:10%;'},
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getAssignment', '', {root: true});
            this.haveData = true;
        }
    }
</script>
