<template>
    <div v-if="haveData">
        <GhDropSelector
            :datablock="{
                id: 'admin_companies',
                title: $t('companies_2'),
                second_title: $t('results'),
                num_results: ListCompanies.list_companies.length,
            }"
        >
            <template v-slot:content>
                <GhTableCommon
                    :extratable="{
                        id: 'companies',
                    }"
                    :header="header"
                    :data="ListCompanies.list_companies"
                >
                    <template v-slot:actions="{itemProp}">
                        <GhAction
                            :dataction="{
                                id: 'edit_company_'+itemProp['id'],
                                text: $t('modify'),
                                icon: require('../../assets/gh_new_dessign/edit.svg'),
                            }"
                            @click="edit_tabs_company(itemProp);"
                        />
                    </template>
                </GhTableCommon>
            </template>
        </GhDropSelector>
        <GhPopup v-show="false"
            :generalConfiguration="{
                id: 'edit_tabs_companies',
                title: $t('assign_tabs'),
                type: 'add',
                style: 'width: 35%; min-width:400px;',
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'max-height: 30vh; min-height: 4vh; overflow-y: auto;'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <form id="form_edit_tabs_company">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <GhDataSelector :key="refreshSelector"
                                :datablock="{
                                    id: 'tabs_company',
                                    class: 'T15_b',
                                    subtitle: $t('selection_tabs') + ':',
                                    text: $t('tabs')+'*',
                                    label: $t('tabs'),
                                    type: 'checkbox',
                                    columns: 2,
                                    required: true,
                                    style: 'margin-left:84px;',
                                    popupStyle: 'min-width: 35%;max-width: 35%;',
                                }"
                                :datainput="{
                                    id: 'value_tabs_company',
                                    name: 'tabs[]'
                                }"
                                :feed="getFeeds.feed_tabs"
                                :selected_input="ListCompanies.selected_companies"
                            />
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_add_system_popup',
                        text: $t('save'),
                        class: '',
                        style: ''
                    }"
                    @click="save_edit_tabs();"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_system_popup',
                        text: $t('close'),
                    }"
                    @click="Global.closePopUp('edit_tabs_companies');"
                />
            </template>
        </GhPopup>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhPopup from "fe-gh-popup-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_companies",
        components:{
            GhDropSelector,
            GhTableCommon,
            GhAction,
            GhPopup,
            GhDataSelector,
            GhButton
        },
        computed: {
            ...mapState(['ListCompanies','getFeeds']),
        },
        data(){
            return{
                header: [
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 50%;'},
                    {text: this.$t('tabs'), field: 'tabs', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 50%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                haveData:false,
                activeId: '',
                refreshSelector:0,
            }
        },
        async mounted(){
            await this.$store.dispatch('getFeedTabs','',{root:true});
            await this.$store.dispatch('GET_LIST_COMPANIES','',{root:true});

            this.haveData = true;
        },
        methods:{
            async edit_tabs_company(item){
                await this.$store.dispatch('GET_TABS_COMPANY',item.client_uuid,{root:true});
                this.activeId = item.client_uuid;
                this.refreshSelector++;
                this.Global.openPopUp('edit_tabs_companies');
            },
            save_edit_tabs(){
                const formData = new FormData(document.getElementById('form_edit_tabs_company'));
                this.$store.dispatch('SAVE_COMPANY_TABS', {
                    formData: [...formData],
                    id: this.activeId,
                }, {root: true});
            },
        }
    }
</script>