import Evaluation from "@/view/evaluation/template/evaluation.vue";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/evaluations/:id',
        name: 'evaluations_id',
        component: Evaluation,
        props: {id: null, showMenu: false,background:true, otherTitle: i18n.t('evaluation')},
    },
];

export default routes;