<template>
    <div style="padding-bottom: 12px;">
        <div v-for="(label, labelIndex) in data" :key="labelIndex">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: 'exercises'+label.id,
                    title: $t(label['name']),
                    second_title: $t(label['second_text']),
                    num_results: ConfigExercises.counters[label['result']],
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
            </GhDropSelector>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import list_exercises from "@/view/config/exercises/exercises/list_exercises";
    import {mapState} from "vuex";
    export default {
        name: "list_exercises",
        components: {
            GhDropSelector,
        },
        computed:{
            ...mapState(['ConfigExercises','Login']),
        },
        data(){
            return {
                data: [
                    {id: 1, name: this.$t('exercises'), component: list_exercises, result: 'exercises', show: false, second_text: this.$t('results')}
                ],
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getConfigExercisesCounters','',{root:true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('configuration')){
                    that.Login.tabs[route.id]['sub-modules'][1]['sub-modules'].forEach((subroute,subkey) => {
                        that.data[subkey].show = subroute.have_permission;
                    });
                }
            });
        },
    }
</script>