var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{
              id: 'drop_selector_section_situation',
              title: _vm.$t('Cuestionario'),
          }},scopedSlots:_vm._u([(_vm.haveData)?{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global",staticStyle:{"margin-top":"20px"}})]},proxy:true}:null],null,true)}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_ejecute_itinerary_finish',
                text: _vm.$t('Finalizar'),
            },"type":"submit"},on:{"click":function($event){return _vm.submitUser(1)}}}):_vm._e(),(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_ejecute_itinerary_save',
                text: _vm.$t('Guardar'),
            },"type":"submit"},on:{"click":function($event){return _vm.submitUser(0)}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_ejecute_itinerary_return',
                text: _vm.$t('Volver'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }