import PermstList from "@/view/perms/list_perms";
import GroupPerm from "@/view/perms/templates/new_edit";
import ProfilesPerm from "@/view/perms/templates/view_profiles.vue";
import CompanyGroup from "@/view/perms/templates/company_groups";
import Formsearchcompany from "@/view/perms/templates/form_search_companies"
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/perms',
        name: i18n.t('perms'),
        component: PermstList,
        props: {showMenu: true}
    },
    {
        path: '/Formsearchcompany',
        name: 'Formsearchcompany',
        component: Formsearchcompany,
        props: {showMenu: true}
    },
    {
        path: '/perms/group',
        name: 'perms_groups',
        component: GroupPerm,
        props: {id: null, showMenu: true, otherTitle: i18n.t('group')},
        children: [
            {
                path: '/perms/group/:id',
                name: 'perms_groups_id',
                component: GroupPerm,
                props: {id: null, showMenu: true, otherTitle: i18n.t('group')},
            },
            {
                path: '/perms/group/:id/:view',
                name: 'perms_groups_id_view',
                component: GroupPerm,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('group')},
            },
        ]
    },
    {
        path: '/perms/profile',
        name: 'perms_profiles',
        component: ProfilesPerm,
        props: {id: null, showMenu: true, otherTitle: i18n.t('profiles')},
        children: [
            {
                path: '/perms/profile/:id',
                name: 'perms_profiles_id',
                component: ProfilesPerm,
                props: {id: null, showMenu: true, otherTitle: i18n.t('profiles')},
            },
            {
                path: '/perms/profile/:id/:view',
                name: 'perms_profiles_id_view',
                component: ProfilesPerm,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('profiles')},
            },
        ]
    },
    {
        path: '/perms/company',
        name: 'company_group',
        component: CompanyGroup,
        props: {id: null, showMenu: true, otherTitle: i18n.t('company')},
        children: [
            {
                path: '/perms/company/:id',
                name: 'company_group_id',
                component: CompanyGroup,
                props: {id: null, showMenu: true, otherTitle: i18n.t('company')},
            },
        ]
    },
];

export default routes;
