import Tutor from "@/view/tutor/template/tutor.vue";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/tutor/:id',
        name: 'tutor_id',
        component: Tutor,
        props: {id: null, showMenu: false,background:true, otherTitle: i18n.t('tutor')},
    },
];

export default routes;