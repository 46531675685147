var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showGroups)?_c('GhDropSelector',{attrs:{"datablock":{
            id: 'section_groups',
            title: _vm.$t('groups'),
            second_title: '',
            num_results: '',
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                    id: 'groups',
                },"header":_vm.header_groups,"data":_vm.ListGroups.list_groups.length === 0 ? [] : _vm.ListGroups.list_groups},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'perms_groups_id_view', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'perms_groups_id', params: {id: itemProp['id']}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'edit_group_'+itemProp['id'],
                                text: _vm.$t('modify'),
                                key_show_condition: 'toShow',
                                value_show_condition: false,
                                icon: require('../../assets/gh_new_dessign/edit.svg'),
                                item_prop: itemProp
                            }}})],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'perms_groups'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_add_group',
                                    text: _vm.$t('add')
                                }}})],1)],1)]},proxy:true}],null,false,2583115729)})]},proxy:true}],null,false,4016936706)}):_vm._e(),(_vm.showProfiles)?_c('GhDropSelector',{attrs:{"datablock":{
            id: 'section_profiles',
            title: _vm.$t('profiles'),
            second_title: '',
            num_results: '',
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                    id: 'profiles',
                },"header":_vm.header_profiles,"data":_vm.ListGroups.list_profiles.length === 0 ? [] : _vm.ListGroups.list_profiles},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'perms_profiles_id_view', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'perms_profiles_id', params: {id: itemProp['id']}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'btn_edit_profile_'+itemProp['id'],
                                text: _vm.$t('modify'),
                                key_show_condition: 'toShow',
                                value_show_condition: false,
                                icon: require('../../assets/gh_new_dessign/edit.svg'),
                                item_prop: itemProp
                            }}})],1),_c('GhAction',{attrs:{"dataction":{
                            id: 'btn_delete_profile_'+itemProp['id'],
                            text: _vm.$t('delete'),
                            icon: require('../../assets/gh_new_dessign/trash.svg'),
                        }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Profile', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, _vm.$t('delete_profile'), _vm.$t('preparing_delete_profile'), 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'perms_profiles'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_add_profile',
                                    text: _vm.$t('add')
                                }}})],1)],1)]},proxy:true}],null,false,442435657)})]},proxy:true}],null,false,3737555883)}):_vm._e(),(_vm.showCompanies)?_c('GhDropSelector',{attrs:{"datablock":{
              id: 'section_companies',
              title: _vm.$t('companies'),
              second_title: '',
              num_results: '',
          }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticStyle:{"padding-left":"15px","padding-bottom":"15px","padding-right":"15px"}},[_c('GhTreeActionBlock',{attrs:{"dataBlock":{
                        id: 'tree_companies',
                        actionWithoutChildren: true,
                        actionWithChildren: true,
                        extraLabelNumber: false,
                        extraLabelText: '',
                    },"data":_vm.ListGroups.data_tree_companies},scopedSlots:_vm._u([{key:"Action",fn:function(Action){return [_c('router-link',{attrs:{"to":{name: 'company_group_id', params: {id: Action.Action.Action.Action.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                    id: 'btn_modify_company',
                                    text: _vm.$t('modify'),
                                    key_show_condition: '',
                                    value_show_condition: '',
                                    icon: require('../../assets/gh_new_dessign/edit.svg'),
                                }}})],1)]}}],null,false,2903469217)})],1)]},proxy:true}],null,false,2600863342)}):_vm._e(),(_vm.showAssociatedUsers)?_c('GhDropSelector',{staticStyle:{"padding-bottom":"10px"},attrs:{"datablock":{
            id: 'section_associated_users',
            title: _vm.$t('associated_users'),
            second_title: '',
            num_results: '',
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"T14 link table-header"},[_vm._v(_vm._s(_vm.$t('perms')))]),_c('form',{attrs:{"id":"form_associated_users"}},[_c('div',{key:_vm.refreshAssociatedUsers,staticStyle:{"margin-right":"10px"}},_vm._l((_vm.getFeeds.feed_perms),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content",staticStyle:{"padding-bottom":"3px !important","width":"100%","flex-wrap":"wrap","padding-left":"10px","padding-top":"2px"},style:(index % 2 ? '' : 'background-color: #d9edfc;')},[_c('div',_vm._l((item),function(itemChildren,indexChildren){return _c('div',{key:indexChildren,staticStyle:{"width":"calc((100% - 240px) - (((100% - 280px) * 12) / 15))"},attrs:{"title":itemChildren.description}},[_c('GhChecks',{attrs:{"datalabel":{
                                        text: itemChildren.name,
                                        style: '',
                                        class: '',
                                        id: ''
                                    },"datainput":{
                                        disabled: _vm.$root._route.params.view ? true : false,
                                        required: false,
                                        selected: '',
                                        type: 'checkbox',
                                        id: itemChildren.id,
                                        value: itemChildren.id,
                                        name: 'associated[perms][]'
                                    }}})],1)}),0)])}),0)]),_c('div',{staticStyle:{"background-color":"#c5d1df","height":"46px"}},[_c('div',{staticClass:"centered",staticStyle:{"padding-top":"9px"}},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_accept',
                            text: this.$t('accept')
                        }},on:{"click":_vm.acceptAssociatedUsers}}),_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_clean',
                            text: this.$t('clean')
                        }},on:{"click":_vm.cleanAssociatedUsers}})],1)]),(_vm.ListGroups.list_associated_users !== undefined && _vm.ListGroups.list_associated_users.users !== undefined)?[_c('GhTableCommon',{staticStyle:{"margin-top":"30px"},attrs:{"extratable":{
                        id: 'profiles',
                    },"header":_vm.header_associated_users,"data":_vm.ListGroups.list_associated_users.users}}),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"10px"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('groups') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ListGroups.list_associated_users.groups))])]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('profiles') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ListGroups.list_associated_users.profiles))])])]:_vm._e()]},proxy:true}],null,false,3742877645)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }