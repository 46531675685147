import ListCreation from "@/view/creation/list_creation";
import Actividades from "@/routes/creation/activities/route";
import Ambitos from "@/routes/creation/ambits/route";
import Certificados from "@/routes/creation/certificates/route";
import Itinerarios from "@/routes/creation/itineraries/route";
import i18n from "@/plugins/i18n";

const route = [
    {
        path: '/list_creation',
        name: i18n.t('create'),
        props: {showMenu: true},
        component: ListCreation
    },
];

const routes = [
    ...route,
    ...Actividades,
    ...Ambitos,
    ...Certificados,
    ...Itinerarios,
]

export default routes;