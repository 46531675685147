<template>
    <GhTableCommon
        v-if="haveData"
        :extratable="{id: 'actions_state_'+id}"
        :header="header"
        :data="ActionListCompanies.data[id].length == 0 ? [] : ActionListCompanies.data[id]"
    >
        <template v-slot:field_redirect="{itemProp, labelProp}">
            <router-link :to="{name: 'company_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
        </template>
        <template v-slot:actions="{itemProp}">
            <router-link :to="{name: 'company_id', params: {id:itemProp.id}}">
                <GhAction
                    :dataction="{
                        id: 'execute_acient_process',
                        text: $t('action'),
                        icon: require('../../../assets/gh_new_dessign/edit.svg'),
                    }"
                />
            </router-link>
        </template>
    </GhTableCommon>
</template>
<script>
    import {mapState} from "vuex";
    import GhTableCommon from "fe-gh-table-common-lib"
    import GhAction from "fe-gh-action-lib";
    export default {
        name: "list_companies",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['ActionListCompanies'])
        },
        props:['id'],
        data(){
            return {
                haveData:false,
                header: [
                    {text: this.$t('company_name'), field: 'name', contentAlt: 'user',urlRedirect: true, title: 'Test', sorting: true, typeSort: 'string', style:'min-width: 474px; width: 474px;'},
                    {text: this.$t('type_of_company'), field: 'type', sorting: true,typeSort: 'string', style: 'width:21%;'},
                    {text: this.$t('company_status'), field: 'state', sorting: true, typeSort: 'string', style: 'width:21%;'},
                    {text: this.$t('last_login'), field: 'last_login', sorting: true,typeSort: 'string', style: 'width:17%;'},
                    {text: this.$t('last_action_date'), field: 'last_action_date', sorting: true,typeSort: 'string', style: 'width:15%;'},
                    {text: '', field: 'actions', style: ''},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getListCompanyState', {'id':this.id}, {root: true});
            this.haveData = true;
        },
    }
</script>
