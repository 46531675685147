<template>
    <div class="float_window" style="padding-bottom: 20px;">
        <template v-for="(label, labelIndex) in data">
            <div :key="labelIndex" v-show="true">
                <GhDropSelector
                    :datablock="{
                        id: 'SelectionAlfa'+label.id,
                        title: $t(label['name']),
                        second_title: $t(label['second_text']),
                        num_results: TabGlobal.counters ? TabGlobal.counters[label['result']]['buyed'] + '/' + TabGlobal.counters[label['result']]['total'] : 0,
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="label.component" />
                    </template>
                </GhDropSelector>
            </div>
        </template>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import recomended_itinerary from './template/recomended_itinerary';
    import all_itinerary from "@/view/global/template/all_itinerary";
    import {mapState} from "vuex";
    export default {
        name: "list_global",
        components: {
            GhDropSelector,
        },
        computed: {
            ...mapState(["TabGlobal"]),
        },
        data(){
            return {
                data: [
                    {id: 1, name: this.$t('recomended_itineraries'), component: recomended_itinerary, result: this.$t('recomended'), second_text: this.$t('itineraries')},
                    {id: 2, name: this.$t('all_itineraries'), component: all_itinerary, result: 'all', second_text: this.$t('itineraries')},
                ],
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getGlobalCounters','',{root:true});
        }
    }
</script>

<style scoped>

</style>