<template>
    <div style="max-width: 100%; margin-right: auto; margin-left: auto;">
        <template v-if="this.$route.matched[0] !== undefined && this.$route.matched[0].props.default.showMenu">
            <GhMenu>
                <template v-slot:cabezera>
                    <div class="logo_cabecera_square">
                        <img :src="require('./assets/main/logo-club-2.png')" />
                    </div>
                </template>
                <template v-slot:header-platform_info>
                    <GhUserIcon :generalConfiguration="{class: 'flex-row-textt', icon: require('./assets/gh_new_dessign/user.svg'), title: '', style: 'top: 53px; left: 1609px; position:fixed;', id: 'Icon_User'}" :bodyConfiguration="{class: 'flex-row-text', title: '', style: '', id: 'Icon_User_Body'}"/>
                    <GhHelpIcon :dataBlock="{icon: require('./assets/gh_new_dessign/help.svg')}"></GhHelpIcon>
                    <GhLanguage v-if="haveData==true"
                        :dataBlock="{
                            title: $t('select_language'),
                            id:'select_lang',
                            icon: require('./assets/gh_new_dessign/arrowdown.svg')
                        }"
                        :language="getFeeds.feed_language"
                        :selected="idioma"
                    ></GhLanguage>
                    <div class="header-phone T4">
                        {{$t('help_phone')+': (+34) xx yyy zz tt'}}
                    </div>
                    <div class="header-email T4">
                        {{$t('help_email')+': notificaciones@globalholdings2000.es'}}
                    </div>
                </template>
            </GhMenu>
            <div style="margin-top: 93px;"></div>
            <GhLocation
                :dataLocation="{showButton: true, secondWindow: false, showLoader: Tabs.TabLoading, text: this.$route.matched[0] !== undefined && this.$route.matched[0].props.default.otherTitle !== undefined ? this.$route.matched[0].props.default.otherTitle : Tabs.TabActive}"
                :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
                @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{add:true}),'new_incidence')"
            />
            <div id="content_page" :class="this.$route.path == '/' ? '' : 'content_page'">
                <router-view/>
                <GhWarningTimeOut
                    :datablock="{
                        time: 1,
                    }"
                />
                <GhDeleteConfirm
                    :visible_fields="Global.state.state_confirm.item_confirm"
                    :id_popup="'delete_confirm'"
                    :content_warning="Global.state.state_confirm.subheader_confirm"
                    v-on:function="setDelete"
                />

            </div>
        </template>
        <template v-else>
            <router-view/>
        </template>
        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'warning_confirm',
                title: $t('required_fields'),
                type: 'warning',
                style: 'width: 35%; min-width: 430px'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'padding:2px;',
                text: $t('must_be_follow_fields_complete') + ':',
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; margin-right: 40px; padding-bottom: 15px;">
                    <div class="T15_b" v-for="(item, index) in Global.state.alert_required.requireds" :key="index" style="padding-left: 20px;">
                        {{item}}
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.closePopUp('warning_confirm');"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>

import GhMenu from 'fe-gh-menu-lib';
import GhLocation from 'fe-gh-location-lib';
import GhUserIcon from 'fe-gh-user-icon-lib'
import GhHelpIcon from 'fe-gh-help-lib';
import GhLanguage from 'fe-gh-language-lib';
import GhDeleteConfirm from 'fe-gh-delete-confirm-lib';
import GhButton from "fe-gh-button-lib";
import {mapState} from "vuex";
import GhPopUp from "fe-gh-popup-lib";
import GhWarningTimeOut from "fe-gh-warning-timeout-lib";
export default {
    components: {
        GhMenu,
        GhLocation,
        GhUserIcon,
        GhHelpIcon,
        GhLanguage,
        GhPopUp,
        GhButton,
        GhWarningTimeOut,
        GhDeleteConfirm
    },
    data(){
        return{
            haveData:true,
            idioma: localStorage.getItem(`Language-selected-select_lang_hidden_popup`)? JSON.parse(localStorage.getItem(`Language-selected-select_lang_hidden_popup`)):[{id:'es',extra_value_lang:'Español'}],
        }
    },
    async beforeMount(){
        // await this.$store.dispatch('getTabsWithPerm', '', {root: true});
        this.checkPermissions();
        await this.$store.dispatch('getLanguagesData', '',{root:true});
        this.haveData = true;
    },
    mounted() {
        this.updateBackColor();
        this.checkPermissions();
    },
    beforeUpdate() {
        this.updateBackColor();
        this.checkPermissions();
    },
    computed: {
        ...mapState(["TabItem","TabCustom","TabHeader",'Tabs','getFeeds','Login']),
    },
    methods:{
        updateBackColor(){
            var r = document.querySelector(':root');
            var color = '#FFFFFF';

            if(this.$route.matched[0] !== undefined && (this.$route.matched[0].props.default.showMenu || this.$route.matched[0].props.default.background)){
                color = '#DDDDDD';
            }
            r.style.setProperty('--body_color',color);
        },
        setDelete(){
            this.$store.dispatch('remove'+this.Global.state.state_confirm.where_confirm,{
              id: this.Global.state.state_confirm.id_delete_confirm,
              type: this.Global.state.state_confirm.type_confirm
            },{root:true});
            this.Global.closePopUp('delete_confirm');
        },
        checkPermissions(){
            if(this.$route.matched !== undefined && this.$route.matched[0] !== undefined && this.$route.matched[0].props.default.showMenu) {
                this.$router.options.GhTabs.forEach((route, keyRoute) => {
                    this.Login.tabs.forEach((permRoute) => {
                        if (route.value == this.$t(permRoute.name) && !permRoute.have_permission) {
                            delete this.$router.options.GhTabs[keyRoute];
                        }

                        if (permRoute.have_permission && permRoute.name == this.$t('searcher')) {
                            permRoute['sub-modules'].forEach((subModuleExplotation) => {
                                this.$router.options.GhSubTabsSearch.filter((routeExplotation, keyExplotation) => {
                                    if (routeExplotation.value == subModuleExplotation.name && !subModuleExplotation.have_permission) {
                                        delete this.$router.options.GhSubTabsSearch[keyExplotation];
                                    }
                                });
                            });
                        }

                        if (permRoute.have_permission && permRoute.name == this.$t('configuration')) {
                            permRoute['sub-modules'].forEach((subModuleExplotation) => {
                                this.$router.options.GhSubTabsConfiguration.filter((routeExplotation, keyExplotation) => {
                                    if (routeExplotation.value == subModuleExplotation.name && !subModuleExplotation.have_permission) {
                                        delete this.$router.options.GhSubTabsConfiguration[keyExplotation];
                                    }
                                });
                            });
                        }

                        if (permRoute.have_permission && permRoute.name == this.$t('create')) {
                            permRoute['sub-modules'].forEach((subModuleExplotation) => {
                                this.$router.options.GhSubTabsCreation.filter((routeExplotation, keyExplotation) => {
                                    if (routeExplotation.value == subModuleExplotation.name && !subModuleExplotation.have_permission) {
                                        delete this.$router.options.GhSubTabsCreation[keyExplotation];
                                    }
                                });
                            });
                        }
                    });
                });

                this.$router.options.GhTabs = this.$router.options.GhTabs.filter(function (item) {
                    return item !== undefined;
                });

                this.$router.options.GhSubTabsSearch = this.$router.options.GhSubTabsSearch.filter(function (item) {
                    return item !== undefined;
                });

                this.$router.options.GhSubTabsConfiguration = this.$router.options.GhSubTabsConfiguration.filter(function (item) {
                    return item !== undefined;
                });

                this.$router.options.GhSubTabsCreation = this.$router.options.GhSubTabsCreation.filter(function (item) {
                    return item !== undefined;
                });
            }
        },
    },
}
</script>

<style>
    :root{
      --body_color: #FFFFFF;
    }

    @import './assets/css/styles.css';
    @import '~fe-gh-menu-lib/dist/fe-gh-menu-lib.css';
    @import '~fe-gh-data-selector-lib/dist/fe-gh-data-selector-lib.css';
    @import '~fe-gh-tabs-lib/dist/fe-gh-tabs-lib.css';
    @import '~fe-gh-location-lib/dist/fe-gh-location-lib.css';
    @import '~fe-gh-user-icon-lib/dist/fe-gh-user-icon-lib.css';
    @import "~fe-gh-popup-lib/dist/fe-gh-popup-lib.css";
    @import '~fe-gh-button-lib/dist/fe-gh-button-lib.css';
    @import "~fe-gh-inputtextfield-lib/dist/fe-gh-inputtextfield-lib.css";
    @import "~fe-gh-help-lib/dist/fe-gh-help-lib.css";
    @import "~fe-gh-language-lib/dist/fe-gh-language-lib.css";
    @import "~fe-gh-checks-lib/dist/fe-gh-checks-lib.css";
    @import "~fe-gh-table-common-lib/dist/fe-gh-table-common-lib.css";
    @import "~fe-gh-subtab-lib/dist/fe-gh-subtab-lib.css";
    @import "~fe-gh-chart-radar-lib/dist/fe-gh-chart-radar-lib.css";
    @import "~fe-gh-chart-table-lib/dist/fe-gh-chart-table-lib.css";
    @import "~fe-gh-input-slider-lib/dist/fe-gh-input-slider-lib.css";
    @import "~fe-gh-selector-payment-lib/dist/fe-gh-selector-payment-lib.css";
    @import "~fe-gh-dropselector-lib/dist/fe-gh-dropselector-lib.css";
    @import "~fe-gh-delete-confirm-lib/dist/fe-gh-delete-confirm-lib.css";
    @import "~fe-gh-tree-action-block-lib/dist/fe-gh-tree-action-block-lib.css";
    @import "~fe-gh-tree-action-lib/dist/fe-gh-tree-action-lib.css";
    @import "~fe-gh-input-hour-lib/dist/fe-gh-input-hour-lib.css";
    @import "~fe-gh-color-lib/dist/fe-gh-color-lib.css";
    @import "~fe-gh-textarea-lib/dist/fe-gh-textarea-lib.css";
    @import "~fe-gh-quiz-lib/dist/fe-gh-quiz-lib.css";
    @import "~fe-gh-easy-upload-lib/dist/fe-gh-easy-upload-lib.css";

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }

    * {
        padding: 0;
        margin: 0;
    }

    body{
        background-color: var( --body_color);
    }

    .content_page{
        padding-left: 12px;
        padding-right: 12px;
    }

    .gh_text_field-label {
        height: 14px;
        min-width: 10px !important;
        margin-left: 0px;
        margin-right: 10px;
        text-align: left;
        white-space: nowrap;
        cursor: default;
    }
</style>
