var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
            text: _vm.$t('name'),
            id:' group_label_name',
            class:'gh_colspan-6',
            style:'width: 85px; text-align: right;'
        },"datainput":{
            id:'group_last_nombre',
            name:'user[first_name]',
            style:'width:280px; min-width:280px;',
            type:'text',
        }}}),_c('GhInputTextField',{attrs:{"datalabel":{
            text:_vm.$t('surname'),
            id:'group_label_last_name',
            class:'gh_colspan-6',
            style:'width: 100px; text-align: right;'
        },"datainput":{
            class: '',
            id:'group_nombre',
            name:'user[worker_surname]',
            style:'width:280px; min-width:280px;',
            type:'text',
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }