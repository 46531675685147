import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import i18n from "@/plugins/i18n";
import { API_URL } from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        records_data_companies: '',
        list_groups_todo: [],
        list_groups_todo_delete: [],
        counter: 0,
    },
    mutations: {
        loadListTodoData(state, payload){
            state.records_data = payload;
        },
        loadTodoCounters(state, payload){
            state.counter = payload;
        },
        loadListCompanyGr(state, payload){
            state.records_data_companies = payload;
        },
        loadListGroupsBusinessData(state, payload){
            state.list_groups_todo['group_' + payload.item.group_id] = payload.response;
        },
        loadAssignTemporalUser(state, payload) {
            state.list_groups_todo['group_' + payload.type].push(payload.params[0]);
        },
        loadAssignTemporalDeleteUser(state, payload) {
            var count = state.list_groups_todo_delete['group_' + payload.type] === undefined ? 0 : state.list_groups_todo_delete['group_' + payload.type].length;
            if(count == 0){
                state.list_groups_todo_delete['group_' + payload.type] = new Array();
            }
            var putArray = {
                type: payload.params[0].type,
                id: payload.params[0].id.id,
            }
            state.list_groups_todo_delete['group_' + payload.type][count] = putArray;
        },
        loadmodifyTemporalUser(state,payload){
            state.list_groups_todo['group_' + payload.type].findIndex(data => data.id == payload.oldId) !==-1 ? state.list_groups_todo['group_' + payload.type][state.list_groups_todo['group_' + payload.type].findIndex(data => data.id == payload.oldId)] = payload.params[0] : '';
        }
    },
    actions: {
        async getTodoCounter(state){
            await axios.get(
                API_URL + "counter_todo"
            ).then(
                response => {
                    state.commit('loadTodoCounters', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getListTodo(state){
            await axios.get(
                API_URL + "list_todo"
            ).then(
                response => {
                    state.commit('loadListTodoData', response.data.data);

                }
            )
        },
        async getListCompanyGr(state){
            await axios.get(
                API_URL + "companys-unassociated/feed"
            ).then(
                response => {
                    state.commit('loadListCompanyGr', response.data.data);
                }
            )
        },
        async getListGroupsBusinessData(state,item){
            await axios.get(
                API_URL + "users/group/"+item.group_id+"/company/"+item.company_id
            ).then(
                response => {
                    state.commit('loadListGroupsBusinessData', {item: item, response: response.data.data});
                }
            )
        },
        async getListValidators(state,item){
            await axios.get(
                API_URL + "users/group/"+item.group_id+"/company/"+item.company_id
            ).then(
                response => {
                    state.commit('loadListValidators', response.data.data);
                }
            )
        },
        async getListTutors(state,item){
            await axios.get(
                API_URL + "users/group/"+item.group_id+"/company/"+item.company_id
            ).then(
                response => {
                    state.commit('loadListTutors', response.data.data);

                }
            )
        },
        async getListSupports(state,item){
            await axios.get(
                API_URL + "users/group/"+item.group_id+"/company/"+item.company_id
            ).then(
                response => {
                    state.commit('loadListSupports', response.data.data);
                }
            )
        },
        async getListComercials(state,item){
            await axios.get(
                API_URL + "users/group/"+item.group_id+"/company/"+item.company_id
            ).then(
                response => {
                    state.commit('loadListComercials', response.data.data);
                }
            )
        },
        async modifyTemporalUser(state,item){
            let requestLogin =  API_URL + "user/admin/search"
            let formData = new FormData();
            formData.append('id', item.id);
            formData.append('type', item.list);
            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200){
                        state.commit('loadmodifyTemporalUser',{type:item.list,oldId:item.oldId,params:response.data.data});

                        var putArray = {
                            type: item.list,
                            id: {
                                id: item.oldId,
                                temporal: item.temporal
                            }
                        }
                        this.dispatch('removeDesAssigmentTemporal',putArray);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async assignTemporalUser(state,item){
            let requestLogin =  API_URL + "user/admin/search"
            let formData = new FormData();
            formData.append('id',item.id);
            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200){
                        state.commit('loadAssignTemporalUser', {type: item.list, params: response.data.data});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async removeDesAssigmentTemporal(state,item){
            await state.state.list_groups_todo['group_' + item.type].findIndex(data => data.id.id == item.id.id) !== - 1 ? state.state.list_groups_todo['group_' + item.type].splice(state.state.list_groups_todo['group_' + item.type].findIndex(data => data.id.id == item.id.id),1) : '';

            if(!item.id.temporal || item.id.temporal == ''){
                await state.commit('loadAssignTemporalDeleteUser', {type: item.type, params: [item]});
            }
        },
        async setCompanyAssign(state,item){
            let requestLogin =  API_URL + "user/company/" + item.id + "/groups";
            let formData = new FormData();

            for(let i=0;i <= item.params.groups.length;i++){
                if (item.params.groups[i] !== undefined){
                    if(state.state.list_groups_todo['group_' + item.params.groups[i].id].length !== 0) {
                        for (let a = 0; a <= state.state.list_groups_todo['group_' + item.params.groups[i].id].length; a++) {
                            if (state.state.list_groups_todo['group_' + item.params.groups[i].id][a] !== undefined) {
                                if(state.state.list_groups_todo['group_' + item.params.groups[i].id][a].temporal){
                                    formData.append('add[company][groups][' + item.params.groups[i].id + '][]', state.state.list_groups_todo['group_' + item.params.groups[i].id][a].id);
                                }
                            }
                        }
                    }
                }
            }

            for(let i=0;i <= item.params.groups.length;i++){
                if (item.params.groups[i] !== undefined){
                    if(state.state.list_groups_todo_delete['group_' + item.params.groups[i].id] !== undefined) {
                        if (state.state.list_groups_todo_delete['group_' + item.params.groups[i].id].length !== 0) {
                            for (let a = 0; a <= state.state.list_groups_todo_delete['group_' + item.params.groups[i].id].length; a++) {
                                if (state.state.list_groups_todo_delete['group_' + item.params.groups[i].id][a] !== undefined) {
                                    formData.append('remove[company][groups][' + item.params.groups[i].id + '][]', state.state.list_groups_todo_delete['group_' + item.params.groups[i].id][a].id);
                                }
                            }
                        }
                    }
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if (response.status == 200) {
                        state.list_groups_todo = [];
                        state.list_groups_todo_delete = [];
                        state.records_data = '';
                        router.push({name: i18n.t('todo')});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
