import GlobalList from "@/view/global/list_global";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/global',
        name: i18n.t('global'),
        component: GlobalList,
        props: {showMenu: true},
    },
];

export default routes;