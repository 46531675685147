import SupervisedEvaluated from "@/view/supervised_evaluated/list_supervised_evaluated.vue";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/supervised_evaluated',
        name: i18n.t('data_evaluated_tutored'),
        component: SupervisedEvaluated,
        props: {showMenu: true}
    }
];

export default routes;