<template>
    <div>
        <div lass="gh-content-global" style="margin-top: 20px;display: flex;justify-content: end;">
            <GhAction
                :dataction="{
                    id: 'change_view',
                    text: !stateView ? $t('see_more_detail') : $t('see_less_detail'),
                }"
                @click="changeState"
            />
        </div>
        <GhChartRadar v-if="!stateView" :key="haveData"
            :dataBlock="{
                id: '',
                class: '',
                style: ''
            }"
            :chartConfig="{
                margin: {
                    top: 100,
                    right: 100,
                    bottom: 100,
                    left: 100
                },
                color: ['#e82315', '#4158ef'],
                maxValue: 5,
                levels: 5,
                roundStrokes: false,
                labelFactor: 1.25,
                wrapWidth: 110,
                opacityArea: 0.2,
                dotRadius: 4,
                opacityCircles: 0.1,
                strokeWidth: 2,
                blur: 5
            }"
            :chartData="ResultQuizz.chart_radio">
        </GhChartRadar>
        <GhChartTable v-if="stateView"
            :extratable="{
                id: 'club'
            }"
            :header="headerChart"
            :data="ResultQuizz.table_radio.dataChartTable"
            :footer="ResultQuizz.table_radio.footerChartTable"
            @advice="advice"
        >
        </GhChartTable>
        <GhPopUp v-show="false"
            :generalConfiguration="{
               id: 'tips_popup',
               title: $t('tips_and_recommendations'),
               type: 'info',
               style: 'width: 35%; min-width: 430px'
            }"
            :bodyConfiguration="{
               id: '',
               class: '',
               style: 'padding:2px;',
               text: '',
            }"
            :footerConfiguration="{
               id: '',
               class: '',
               style: '',
               content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; margin-right: 40px; padding-bottom: 15px;">
                    <div class="T15_b" v-for="(item, index) in showTips" :key="index" style="padding-left: 60px;">
                        <li>{{item}}</li>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'button',
                        text: $t('close'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="Global.closePopUp('tips_popup');"
                />
            </template>
        </GhPopUp>
        <div class="centered" style="margin-top: 20px;">
            <GhButton
                :datainput="{id: 'btn_start',text: $t('want_start_excelence'),class: '', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                @click="toBuy();"
            />
            <GhButton
                :datainput="{id: 'btn_back',text: $t('back'),class: '', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhChartRadar from "fe-gh-chart-radar-lib";
    import GhChartTable from "fe-gh-chart-table-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    import GhPopUp from "fe-gh-popup-lib";

    export default {
        name: "result_quizz",
        components:{
            GhButton,
            GhChartRadar,
            GhAction,
            GhChartTable,
            GhPopUp,
        },
        data(){
            return{
                stateView: false,
                haveData: false,
                headerChart: [
                    {text: this.$t('title'), field: 'title', style: 'max-width: 50px; width: 25%;', show_drag: true},
                    {text: '', field: 'chart', chart: true, ruler: true, value_ruler: {0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5}, style: 'max-width: 50px; width: 30%;'},
                    {text: '', field: 'advice', advice: true, style: 'min-width: 50px; width: 1%;'},
                    {text: this.$t('title_column_1'), field: 'col1', sup: 'sup1', style: 'max-width: 50px; width: 15%;', class: 'centered'},
                    {text: this.$t('title_column_2'), field: 'col2', sup: 'sup2', style: 'max-width: 50px; width: 15%;', class: 'centered'},
                    {text: this.$t('title_column_3'), field: 'col3', sup: 'sup3', style: 'max-width: 50px; width: 15%;', class: 'centered'},
                ],
                showTips: []
            }
        },
        computed: {
            ...mapState(["ResultQuizz"]),
        },
        async mounted() {
            await this.$store.dispatch('GET_RADIO_CHART_RESULTS','',{root:true});
            await this.$store.dispatch('GET_TABLE_CHART_RESULTS','',{root:true});

            var r = document.querySelector(':root');
            var color = '#DDDDDD';

            r.style.setProperty('--body_color',color);
            this.haveData = true;
        },
        methods:{
            toBuy(){
                this.$store.dispatch('TO_PAY','',{root:true});
            },
            changeState(){
                this.stateView = !this.stateView;
            },
            advice(item){
                if(item.advice == 'warning'){
                    this.showTips = item.tips;
                    this.Global.openPopUp('tips_popup');
                }
            },
            back(){
              this.$router.go(-1);
            },
        }
    }
</script>