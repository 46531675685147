import ConfigExercise from "@/view/config/exercises/exercises/template/exercise";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/exercise/exercise',
        name: 'config_exercise',
        component: ConfigExercise,
        props: {id: null, showMenu: true, otherTitle: i18n.t('exercise')},
        children: [
            {
                path: '/config/exercise/exercise/:id',
                name: 'config_exercise_id',
                component: ConfigExercise,
                props: {id: null, showMenu: true, otherTitle: i18n.t('exercise')},
            },
            {
                path: '/config/exercise/exercise/:id/:view',
                name: 'config_exercise_id_view',
                component: ConfigExercise,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('exercise')},
            }
        ]
    },
    {
        path: '/config/exercise/:subquestion/:level/:condition',
        name: 'config_exercise_subquestion',
        component: ConfigExercise,
        props: {id: null, showMenu: false, background: true, otherTitle: i18n.t('exercise')},
        children: [
            {
                path: '/config/exercise/:subquestion/:level/:condition/:id',
                name: 'config_exercise_subquestion_id',
                component: ConfigExercise,
                props: {id: null, showMenu: false, background: true, otherTitle: i18n.t('exercise')},
            }
        ]
    }
];

export default routes;