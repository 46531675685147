var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'videography',title: _vm.$t('videography')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_videography"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"15%"}},[_c('div',{staticStyle:{"width":"250px","height":"135px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"135px","width":"auto"},attrs:{"src":_vm.image,"width":"250"}})]),_c('div',{staticClass:"other-image"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                                    id: 'videography_other_image',
                                    class: '',
                                    name_hidden: 'videography[other_image]',
                                    id_hidden: 'videography_other_image',
                                    icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                },"datalabel":{
                                    id: 'videography_other_image',
                                    style: 'margin-right: -15px'
                                },"datainput":{
                                    view: _vm.$root._route.params.view,
                                    accept: '.png, .jpg, .jpeg',
                                    text: _vm.$t('modify_image'),
                                    show_image: false,
                                    delete: false,
                                    href: 'easyUpload',
                                    downloadHref: 'easyDownload',
                                    file_name: _vm.ConfigVideographies.data.other !== undefined && _vm.ConfigVideographies.data.other.file_name ? _vm.ConfigVideographies.data.other.file_name : '',
                                    file_path: _vm.ConfigVideographies.data.other !== undefined && _vm.ConfigVideographies.data.other.file_path ? _vm.ConfigVideographies.data.other.file_path : '',
                                    file_download: _vm.ConfigVideographies.data.other !== undefined && _vm.ConfigVideographies.data.other.file_download ? _vm.ConfigVideographies.data.other.file_download : '',
                                    required: false
                                }}})],1)]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"85%"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefCode",attrs:{"datalabel":{
                                        text: _vm.$t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'videography_code',
                                        name: 'videography[code]',
                                        style: 'width: 200px',
                                        value: _vm.code,
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigVideographies.data.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'videography_name',
                                        name: 'videography[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: _vm.name,
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigVideographies.data.name))])]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'margin-bottom: -5px')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                        id: 'label_videography_sector',
                                        class: 'T15_b',
                                        subtitle: _vm.$t('select_sector') + ':',
                                        text: _vm.$t('sector') + '*',
                                        label: _vm.$t('sector'),
                                        type: 'radio',
                                        columns: 3,
                                        style: 'margin-left:94px;',
                                        required: true,
                                    },"datainput":{
                                        id: 'value_videography_sector',
                                        name: 'videography[sector]'
                                    },"selected_input":_vm.ConfigVideographies.data.sector ? _vm.ConfigVideographies.data.sector : [],"feed":_vm.getFeeds.feed_sector}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('sector') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigVideographies.data.text_sector))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                                        text: _vm.$t('description'),
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                    },"datainput":{
                                        id: 'videography_description',
                                        name: 'videography[description]',
                                        value: _vm.description
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigVideographies.data.description))])]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'flex-flow: row-reverse;')},[(_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('attached_file') + ' (Video)' + ':'))])]:_vm._e(),_c('GhEasyUpload',{key:_vm.refreshUpload,attrs:{"extrablock":{
                                    id: 'videography_attach',
                                    class: '',
                                    name_hidden: 'videography[document]',
                                    id_hidden: 'videography_attach',
                                    icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                                },"datalabel":{
                                    id: 'label_import_block',
                                    style: 'margin-right: -15px'
                                },"datainput":{
                                    view: _vm.$root._route.params.view,
                                    accept: 'video/mp4',
                                    text: _vm.$t('attached_file') + ' (Video)',
                                    show_image: false,
                                    delete: false,
                                    href: 'easyUpload',
                                    downloadHref: 'easyDownload',
                                    file_name: _vm.ConfigVideographies.data.file_name,
                                    file_path: _vm.ConfigVideographies.data.file_path,
                                    file_download: _vm.ConfigVideographies.data.file_download,
                                    required: _vm.link_http !== '' ? false : true
                                }}})],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefLink",attrs:{"datalabel":{
                                        text: _vm.$t('link'),
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: false,
                                        id: 'videography_link',
                                        name: 'videography[link]',
                                        style: 'width: 500px',
                                        value: _vm.link_http,
                                        type: 'text',
                                    }},on:{"keyup":function($event){return _vm.setLink()},"change":function($event){return _vm.setLink()}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('link') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.link_http))])]],2)])])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('Guardar'),
            }},on:{"click":_vm.submitVideography}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('Volver'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }