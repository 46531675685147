var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData == true)?_c('div',{staticStyle:{"padding-bottom":"12px"}},[_vm._l((_vm.section),function(label){return _c('div',{key:label.id},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: label.id,
                title: _vm.$t(label.title),
                num_results: '',
                label: label.name == 'historical' ? `${_vm.TabAction.data !== undefined && _vm.TabAction.data.historical_company !== undefined && _vm.TabAction.data.historical_company.length !== 0 ? _vm.TabAction.data.historical_company.length : 0 } ${'results'}` : '',
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData == true)?_c(label.component,{tag:"component",attrs:{"id":_vm.$root._route.params.id}}):_vm._e()]},proxy:true}],null,true)})],1)}),(!_vm.$root._route.params.view)?_vm._l((_vm.sectionPopup),function(popup,index){return _c('div',{key:index},[_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
                    id: popup.id,
                    title: _vm.$t(popup.title),
                    type: 'add',
                    style: 'width: 50%; min-width:920px;',
                },"bodyConfiguration":{
                    id: '',
                    class: '',
                    style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
                },"footerConfiguration":{
                    id: '',
                    class: '',
                    style: '',
                    content: ''
                }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{attrs:{"id":popup.form}},[_c(popup.component,{tag:"component"})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_situation',
                            text: _vm.$t('add'),
                        }},on:{"click":function($event){return _vm.saveAction(popup.id,popup.form)}}}),_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_situation',
                            text: _vm.$t('close'),
                        }},on:{"click":function($event){return _vm.Global.closePopUp(popup.id)}}})]},proxy:true}],null,true)})],1)}):_vm._e(),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[_c('router-link',{attrs:{"to":{name: 'my_situation_view', params: {id:_vm.$root._route.params.id,view:true}}}},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_situation',
                    text: _vm.$t('my_situation'),
                }}})],1),(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_call',
                text: _vm.$t('call'),
            }},on:{"click":function($event){return _vm.Global.openPopUp('action_company_file_call_popup')}}}):_vm._e(),(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_mail',
                text: _vm.$t('mail'),
            }},on:{"click":function($event){return _vm.Global.openPopUp('action_company_file_mail_popup')}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_mail',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }