import Itinerary from "@/view/creation/itineraries/templates/itinerary";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/creation_itineraries/itinerary',
        name: 'creation_itineraries',
        component: Itinerary,
        props: {id: null, showMenu: true,otherTitle: i18n.t('itineraries')},
        children: [
            {
                path: '/creation_itineraries/itinerary/:id',
                name: 'creation_itineraries_id',
                component: Itinerary,
                props: {id: null, showMenu: true, otherTitle: i18n.t('itineraries')},
            },
            {
                path: '/creation_itineraries/itinerary/:id/:view',
                name: 'creation_itineraries_id_view',
                component: Itinerary,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('itineraries')},
            }
        ]
    },
];

export default routes;