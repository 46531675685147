import Vue from "vue";
import {API_URL, LoginUrl} from "@/common/config";
import vuex from "vuex";
import axios from "axios";
import router from "../../routes/router"
import Global from "../Global";
Vue.use(vuex);
export default {
    state: {
        tabs:[],
        username:'',
        password:'',
        error_cif:'',
        error_mail:'',
        email:'',
        error:'',
        errormailcif:'',
        email_recover:'',
        token: '',
        token_time: 0
    },
    mutations:{
        LOAD_USERNAME(state,payload){
            state.username = payload;
            payload != '' ? localStorage.setItem('username',payload):localStorage.removeItem('username');
        },
        LOAD_ERROR: (state, payload) => (state.error = payload),
        LOAD_EMAIL_REMEMBER(state,payload){
            state.email_recover = payload;
        },
        Close_Messages_Login(state){
            state.error_cif = '';
            state.error_mail = '';
            state.email_recover = '';
            state.errormailcif = '';
        },
        LOAD_ERROR_MAIL(state,payload){
            if(payload.code == 'cif') {
                state.error_cif = payload.msg;
            }
            if(payload.code == 'email') {
                state.error_mail = payload.msg;
            }
            if(payload.code=='cifemail'){
                let ErrorMsgMail = payload.msg.split(",");
                state.error_cif = ErrorMsgMail[0];
                state.error_mail = ErrorMsgMail[1];
            }
            if(payload.code==''){
                state.errormailcif = payload.msg;
            }
            state.error_mail = payload;
        },
        LOAD_TOKEN(state,payload){
            state.token = payload.token;
        },
        LOAD_TOKEN_TIME(state,payload){
            state.token_time = payload;
        },
        loadTabsWithPerm(state,payload){
            state.tabs = payload;
        },
    },
    actions:{
        REGISTER(){
            router.push({name: 'register'});
        },
        async SET_REGISTER(state, item){
            let requestLogin = API_URL + 'user/company/add';
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    console.log(response);
                    router.push({name: 'login'});
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getTabsWithPerm(state){
            await axios.get(
                LoginUrl + "tabs"
            ).then(
                response => {
                    var enter = false;
                    response.data.configuracion_perms.forEach((item) => {
                        if(item.have_permission && !enter){
                            localStorage.setItem('TabItem', item.name);
                            enter = true;
                        }
                    });
                    state.commit('loadTabsWithPerm', response.data.configuracion_perms);
                }
            )
        },
        TO_LOGIN(){
            router.push({name: 'login'});
        },
        async setUserAuthData(state,auth_data){
            state.commit("LOAD_TOKEN", auth_data);
            localStorage.setItem('user',auth_data.username);
            localStorage.setItem('token',auth_data.token);
            localStorage.setItem('expire_date', auth_data.expire_date);
        },
        async setTokenExpiration(state,auth_data){
            var dformat = '';
            const date = new Date();
            dformat = [date.getFullYear(),('0' + (date.getMonth()+1)).slice(-2),('0' + date.getDate()).slice(-2)].join('-')+' ' + [('0' + date.getHours()).slice(-2),('0' + date.getMinutes()).slice(-2),].join(':');

            var diff =(new Date(auth_data.expire_date).getTime() - new Date(dformat).getTime()) / 1000;
            diff /= (60 * 60);
            state.commit("LOAD_TOKEN_TIME", diff);
        },
        GET_USER(state, credentials) {
            console.log("GET USER");
            let requestLogin = LoginUrl + "auth/login"
            let formData = new FormData();
            formData.append("username", credentials.username);
            formData.append("password", credentials.password);
            formData.append("submit", "Submit");
            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    console.log("RESPONSE");
                    console.log(response);
                    if (response.data.data) {
                        state.commit("LOAD_TOKEN", response.data.data);
                        localStorage.setItem('user',response.data.data.username);
                        localStorage.setItem('token',response.data.data.token);
                        localStorage.setItem('expire_date',response.data.data.expire_date);

                        await this.dispatch('getTabsWithPerm');

                        console.log(response.data);

                        if(response.data.data.questionary_done === 1){
                            router.push({path: localStorage.getItem('TabItem') ? router.options.routes[router.options.routes.findIndex((route) => route.name == localStorage.getItem('TabItem'))].path : '/config'});
                        }else{
                            router.push({name: 'initial_itinerary'});
                        }
                        state.commit("LOAD_ERROR", '');

                        var dformat = '';
                        const date = new Date();
                        dformat = [date.getFullYear(),('0' + (date.getMonth()+1)).slice(-2),('0' + date.getDate()).slice(-2)].join('-')+' ' + [('0' + date.getHours()).slice(-2),('0' + date.getMinutes()).slice(-2),].join(':');

                        var diff =(new Date(response.data.data.expire_date).getTime() - new Date(dformat).getTime()) / 1000;
                        diff /= (60 * 60);
                        state.commit("LOAD_TOKEN_TIME", diff);

                    } else if (response.data.error) {
                        state.commit("LOAD_ERROR", response.data.error);
                    }
                },
                async error => {
                    // TODO : ELIMINAR EL PUSH DE LOGUEO
                    // router.push({name: 'initial_itinerary'});
                    // await this.dispatch('getTabsWithPerm');
                    // router.push({path: localStorage.getItem('TabItem') ? router.options.routes[router.options.routes.findIndex((route)=> route.name == localStorage.getItem('TabItem'))].path : '/config'});
                    let the_error = {error: {code: error.code, msg: error.error}};
                    state.commit("LOAD_ERROR", the_error);
                }
            )
        },
        CloseMessagesLogin(state){
            state.commit("Close_Messages_Login")
        },
        GET_REMEMBER_PASSWORD(state, credentials) {
            let requestLogin = LoginUrl + "rmbpwd"
            let formData = new FormData();
            formData.append("cif", credentials.cif);
            formData.append("email", credentials.email);
            formData.append("submit", "Submit");
            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if(response.data.data) {
                        Global.openPopUp('rememeber_password_success');
                        state.commit("LOAD_EMAIL_REMEMBER", response.data.data.returnmsg)
                    } else if (response.data.error) {
                        if(response.data.error.code.Normpwd){
                            Global.openPopUp('remember_password_error');
                        }
                        state.commit("LOAD_ERROR_MAIL", response.data.error)
                    }
                },
                error => {
                    let the_error = {error: {code: error.code, msg: error.error}};
                    state.commit("LOAD_ERROR", the_error);
                    console.log(error.name);
                }
            )
        },
        async SET_LOGOUT(state){
            let requestLogin = LoginUrl + "auth/logout";

            try {
                const response = await axios.post(requestLogin, null, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response.data.status == 200) {
                    state.commit("LOAD_TOKEN", "");
                    state.commit("LOAD_TOKEN_TIME", "");

                } else if (response.data.error) {
                    state.commit("LOAD_ERROR", response.data.error);
                }
                localStorage.clear();
                this.dispatch('resetAppTabs');
                if (response.data.logout_callback) {
                    window.location.replace(response.data.logout_callback);
                }else{
                    router.push({name: 'login'});
                }

            } catch (error) {
                let the_error = { error: { code: error.code, msg: error.error } };
                state.commit("LOAD_ERROR", the_error);
            }
        },
        refreshToken(state){
            let requestLogin = LoginUrl + "/auth/refresh"
            axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    if (response.data.data.token !== undefined) {
                        var dformat = '';
                        const date = new Date();
                        dformat = [date.getFullYear(),('0' + (date.getMonth()+1)).slice(-2),('0' + date.getDate()).slice(-2)].join('-')+' ' + [('0' + date.getHours()).slice(-2),('0' + date.getMinutes()).slice(-2),].join(':');

                        var diff =(new Date(response.data.data.expire_date).getTime() - new Date(dformat).getTime()) / 1000;
                        diff /= (60 * 60);
                        state.commit("LOAD_TOKEN_TIME", diff);
                        state.commit("LOAD_TOKEN", response.data.data);
                        localStorage.setItem('token',response.data.data.token);
                        localStorage.setItem('expire_date',response.data.data.expire_date);
                    } else if (response.data.error) {
                        state.commit("LOAD_ERROR", response.data.error);
                    }
                },
                error => {
                    let the_error = {error: {code: error.code, msg: error.error}}
                    state.commit("LOAD_ERROR", the_error)
                }
            )
        }
    }
}
