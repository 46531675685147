import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        data: []
    },
    mutations: {
        loadListCompanyState(state,payload){
            state.data[payload.id] = payload.data;
        }
    },
    actions: {
        async getListCompanyState(state,item){
            await axios.get(
                API_URL + "commercial-management/companies?state_id="+item.id
            ).then(
                response => {
                    state.commit('loadListCompanyState', {'data':response.data.data,'id':item.id});
                }
            )
        },
    }
}
