<template>
    <div :style="this.$store.state.ActionHistorical.searchData && this.$store.state.ActionHistorical.searchData != [] ? 'margin-top: 12px;' : ''">
        <GhTableCommon
            :extratable="{ id:'result_action_historical_data'}"
            :data="this.$store.state.ActionHistorical.searchData"
            :header="header"
        >
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'company_view', params: {id:itemProp.id,view:true}}">
                    <GhAction
                        :dataction="{
                            id: 'eject_support_pending_requests_groups',
                            text: $t('view'),
                            icon: require('../../../../assets/gh_new_dessign/view.svg'),
                        }"
                    />
                </router-link>
            </template>
        </GhTableCommon>
    </div>
</template>
<script>
    import GhTableCommon from 'fe-gh-table-common-lib'
    import GhAction from 'fe-gh-action-lib'
    import {mapState} from "vuex";
    export default {
        name: "result_action_historical",
        computed:{
            ...mapState(['ActionHistorical'])
        },
        components:{
            GhAction,
            GhTableCommon
        },
        data(){
            return{
                header: [
                  {text: this.$t('company_name'), field: 'name', contentAlt: 'user', title: 'Test', sorting: true, typeSort: 'string', style:'min-width: 474px; width: 474px;'},
                  {text: this.$t('type_of_company'), field: 'type', sorting: true,typeSort: 'string', style: 'width:21%;'},
                  {text: this.$t('company_status'), field: 'state', sorting: true, typeSort: 'string', style: 'width:21%;'},
                  {text: this.$t('last_login'), field: 'last_login', sorting: true,typeSort: 'string', style: 'width:17%;'},
                  {text: this.$t('last_action_date'), field: 'last_action_date', sorting: true,typeSort: 'string', style: 'width:15%;'},
                  {text: '', field: 'actions', style: ''},
                ],
            }
        },
    }
</script>
