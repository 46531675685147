<template>
    <div :id="'_float_window'">
        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'crm_priority',
                    title: $t('priority'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <form id="form_priority" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_name_priority',
                                            text: $t('name_priority') + '*',
                                            style: 'margin-right: 10px; width: 192px; min-width: 192px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'input_name_priority',
                                            name: 'priority[name]',
                                            type: 'text',
                                            value: ConfigPriorities.data && ConfigPriorities.data.name ? ConfigPriorities.data.name : '',
                                            required: true
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t('name_priority') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 50%; min-width: 100px;">{{ConfigPriorities.data && ConfigPriorities.data.name ? ConfigPriorities.data.name : ''}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!$root._route.params.view">
                                    <GhInputTextField
                                        :datalabel="{
                                            id: 'label_description_priority',
                                            text: $t('description_priority') + '*',
                                            style: 'margin-right: 10px; width: 192px; min-width: 192px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'input_description_priority',
                                            name: 'priority[description]',
                                            type: 'text',
                                            value: ConfigPriorities.data && ConfigPriorities.data.description ? ConfigPriorities.data.description : '',
                                            required: true
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t('description_priority') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 50%; min-width: 100px;">{{ConfigPriorities.data && ConfigPriorities.data.description ? ConfigPriorities.data.description : ''}}</label>
                                </template>
                            </div>
                            <div v-if="ConfigPriorities.data && ConfigPriorities.data.groups && ConfigPriorities.data.groups.length !== 0">
                                <div v-for="(item,index) in ConfigPriorities.data.groups" :key="index">
                                    <div class="gh-row-content">
                                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t(item.name)}}</label>
                                    </div>
                                    <div class="gh-row-content">
                                        <template v-if="!$root._route.params.view">
                                            <GhInputTextField
                                                :datalabel="{
                                                    id: 'time_priority_response_' + item.id,
                                                    text: $t('response_time') + ' (h) *',
                                                    style: 'margin-right: 10px; width: 192px; min-width: 192px; text-align: right;',
                                                    class: 'T15_b'
                                                }"
                                                :datainput="{
                                                    name: 'priority[groups]['+item.id+'][response_time]',
                                                    style: 'width:20%; ',
                                                    type: 'int',
                                                    value: item.response_time !== undefined ? item.response_time : '',
                                                    required:true
                                                }"
                                            />
                                            <GhInputTextField
                                                :datalabel="{
                                                    id: 'time_priority_resolution_'+item.id,
                                                    text: $t('time_resolution')+ ' (h) *',
                                                    style: 'width: 88px; min-width: 208px; text-align: right;',
                                                    class: 'T15_b'
                                                }"
                                                :datainput="{
                                                    name: 'priority[groups]['+item.id+'][solution_time]',
                                                    style: 'width:20%;',
                                                    type: 'int',
                                                    value: item.solution_time !== undefined ? item.solution_time : '',
                                                    required: true
                                                }"
                                            />
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t('response_time') + ' (h)'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 44%; min-width: 100px;">{{item.response_time !==undefined ? item.response_time : ''}}</label>
                                            <label class="gh_text_field-label T15" style="width: 88px; min-width: 208px; text-align: right; margin-right: 10px;">{{$t('time_resolution')+ ' (h)'}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 44%; min-width: 100px;">{{item.solution_time !== undefined ? item.solution_time : ''}}</label>
                                        </template>
                                    </div>
                                    <div class="gh-row-content">
                                        <template v-if="!$root._route.params.view">
                                            <GhInputTextField
                                                :datalabel="{
                                                    id:'time_priority_percentage_'+item.id,
                                                    text: $t('percentage') + '*',
                                                    style: 'width: 192px; min-width: 192px; text-align: right;',
                                                    class: 'T15_b'
                                                }"
                                                :datainput="{
                                                    id: 'input_priority_percentage_'+item.id,
                                                    name: 'priority[groups]['+item.id+'][percentage]',
                                                    style: 'width:20%; max-height: 17px;',
                                                    type: 'int',
                                                    limitNumMin: 0,
                                                    limitNumMax: 100,
                                                    value: item.percentage !== undefined ? item.percentage : ''
                                                }"
                                            />
                                        </template>
                                        <template v-else>
                                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 192px; min-width: 192px; text-align: right;">{{$t('percentage')}}</label>
                                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 50%; min-width: 100px;">{{item.percentage !== undefined ? item.percentage : ''}}</label>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <GhButton v-if="!$root._route.params.view"
                    :datainput="{
                        id: 'btn_priority_save',
                        class: 'T19 container-md_button_content',
                        text: $t('save')
                    }"
                    @click="setPriority()"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_priority_close',
                        class: 'T19 container-md_button_content',
                        text: $t('back')
                    }"
                    @click="router.go(-1)" />
            </div>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";

    export default {
        name: "priority_template",
        components:{
            GhNoDropSelector,
            GhInputTextField,
            GhButton
        },
        computed:{
            ...mapState(['ConfigPriorities'])
        },
        data(){
            return{
                haveData: false
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getPrioritiesData', {id: this.$root._route.params.id}, {root: true});
            this.haveData = true;
        },
        methods:{
            setPriority(){
                if(this.Global.checkRequired('form_priority') == 'OK') {
                    const formData = new FormData(document.getElementById('form_priority'));
                    this.$store.dispatch('setPrioritiesData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>
