<template>
    <div>
        <GhTableCommon
            :extratable="{
                id: 'flows_table',
            }"
            :header="header"
            :data="ConfigFlows.records_data.length == 0 ? [] : ConfigFlows.records_data"
        >
            <template v-slot:actions="{itemProp}">
                <GhAction
                    :dataction="{
                        id: 'edit_conf_supp_flow',
                        text: $t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                        item_prop: itemProp
                    }"
                    @click="changeFlow(itemProp)"
                />
            </template>
        </GhTableCommon>

        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'update_config_flow',
                title: titlePopup,
                type: 'add',
                style: 'width: 50%; min-width:920px;',
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh-content-global">
                    <span class="T15_b">{{$t('select_group') + ':'}}</span>
                </div>
                <form id="form_update_flow">
                    <div class="gh-content-global" :key="refresh" style="display: flex">
                        <div style="display: block;width: 50%;">
                            <div v-for="(item,index) in preGroupsArray" :key="index">
                                <GhChecks
                                    v-bind:datainput="{
                                        id: 'groups_selection_' + item.id,
                                        value: item.id,
                                        type: ConfigFlows.data.selection_type == 'single' ? 'radio' : 'checkbox',
                                        name: 'groups[]',
                                        style: 'margin-left:48px; cursor:pointer;',
                                        selected: selectedsGroups.includes(item.id.toString()),
                                    }"
                                    v-bind:datalabel="{
                                        style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                                        class: 'T15 input_label',
                                        id: 'label_groups_selection_' + item.id,
                                        text: item.name
                                    }"
                                />
                            </div>
                        </div>
                        <div style="display: block;width: 50%;">
                            <div v-for="(item,index) in postGroupsArray" :key="index">
                                <GhChecks
                                    v-bind:datainput="{
                                        id: 'groups_selection_' + item.id,
                                        value: item.id,
                                        type: ConfigFlows.data.selection_type == 'single' ? 'radio' : 'checkbox',
                                        name: 'groups[]',
                                        style: 'margin-left:48px; cursor:pointer;',
                                        selected: selectedsGroups.includes(item.id.toString()),
                                    }"
                                        v-bind:datalabel="{
                                        style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                                        class: 'T15 input_label',
                                        id: 'label_groups_selection_' + item.id,
                                        text: item.name
                                    }"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_save_flow',
                        text: $t('save'),
                    }"
                    @click="saveFlow"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_flow',
                        text: $t('close'),
                    }"
                    @click="Global.closePopUp( 'update_config_flow')"
                />
            </template>
        </GhPopUp>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import GhChecks from "fe-gh-checks-lib";
    export default {
        name: "list_flows",
        data(){
            return{
                haveData: false,
                titlePopup: '',
                idSelected: '',
                refresh: 0,
                selectedsGroups: [],
                preGroupsArray: [],
                postGroupsArray: [],
                header: [
                    {text: this.$t('tasks'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: this.$t('groups'), field: 'groups', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 80%;'},
                    {text: '', field: 'actions', sorting: false,style: 'width:10%;'},
                ],
            }
        },
        components:{
          GhChecks,
            GhPopUp,
            GhButton,
            GhTableCommon,
            GhAction
        },
        computed:{
            ...mapState(['ConfigFlows','getFeeds'])
        },
        async beforeMount(){
            await this.$store.dispatch('getFlows', '', {root: true});
            await this.$store.dispatch('getFeedSuppGroups', '', {root: true});

            var divide = Math.round(this.getFeeds.feed_supp_groups.groups.length/2);

            for(var i = 0;i < this.getFeeds.feed_supp_groups.groups.length; i++){
                if(i < divide){
                    this.preGroupsArray.push(this.getFeeds.feed_supp_groups.groups[i]);
                }else{
                    this.postGroupsArray.push(this.getFeeds.feed_supp_groups.groups[i]);
                }
            }

            this.haveData = true;
        },
        methods:{
            async changeFlow(item){
                this.titlePopup = item.name;
                this.idSelected = item.id;
                await this.$store.dispatch('getFlowsData', {id:item.id}, {root: true});
                this.selectedsGroups = Object.keys(this.ConfigFlows.data.groups);
                this.refresh++;
                this.Global.openPopUp( 'update_config_flow');
            },
            async saveFlow(){
                const formData = new FormData(document.getElementById('form_update_flow'));
                await this.$store.dispatch('setFlowData', {
                   formData: [...formData],
                   id: this.idSelected
                }, {root: true});

                this.Global.closePopUp( 'update_config_flow');
            }
        }
    }
</script>