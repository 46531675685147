<template>
    <div>
        <div class="gh-content-global">
            <input type="hidden" name="type" value="email">
            <div class="gh-row-content">
                <GhTextarea
                    :datalabel="{
                        text: $t('note') + ':',
                        class: ' T15',
                        styles:'width: 66px; min-width: 74px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_open_question_statement',
                        name: 'note',
                        value:  '',
                    }"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import GhTextarea from "fe-gh-textarea-lib";
    export default {
        name: "mail_template",
        components: {GhTextarea},
    }
</script>
