<template>
    <div class="float_window">
        <GhNoDropSelector
            :datablock="{
                  id: 'drop_selector_section_situation',
                  title: $t('Cuestionario'),
              }"
        >
            <template v-slot:content v-if="haveData">
                <div class="gh-content-global" style="margin-top: 20px;">

                </div>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_ejecute_itinerary_finish',
                    text: $t('Finalizar'),
                }"
                type="submit"
                @click="submitUser(1)"
            />
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_ejecute_itinerary_save',
                    text: $t('Guardar'),
                }"
                type="submit"
                @click="submitUser(0)"
            />
            <GhButton
                :datainput="{
                    id: 'btn_ejecute_itinerary_return',
                    text: $t('Volver'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhButton from 'fe-gh-button-lib';
    export default {
        name: "ejecute_itinerary",
        components: {
            GhNoDropSelector,
            GhButton
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            this.haveData = true;
        },
        methods: {
          submitUser(type){
              console.log(type);
          }
        }
    }
</script>