import ConfigPriorities from "@/view/config/support/priorities/template/priority";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/support/priority',
        name: 'priority',
        component: ConfigPriorities,
        props: {id: null, showMenu: true, otherTitle: i18n.t('priority')},
        children: [
            {
                path: '/config/support/priority/:id',
                name: 'priority_id',
                component: ConfigPriorities,
                props: {id: null, showMenu: true,  otherTitle: i18n.t('priority')},
            },
            {
                path: '/config/support/priority/:id/:view/',
                name: 'priority_view',
                component: ConfigPriorities,
                props: {id: null, showMenu: true,  otherTitle: i18n.t('priority')},
            },
        ]
    }
];
export default routes;
