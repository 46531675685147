<template>
    <div>
        <div class="gh-row-content" v-if="ConfigExercisesTable.type_exercise.close_n_correct.responsible.view">
            <template v-if="!$root._route.params.view">
                <GhDataSelector
                    :datablock="{
                        id: 'label_exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_n_correct_responsible',
                        class: 'T15_b',
                        subtitle: $t('select_responsible') + ':',
                        text: $t('responsible') + (ConfigExercisesTable.type_exercise.close_n_correct.responsible.required ? '*' : ''),
                        label: $t('responsible'),
                        type: 'radio',
                        columns: 3,
                        style: '',
                        required: ConfigExercisesTable.type_exercise.close_n_correct.responsible.required,
                    }"
                    :datainput="{
                        id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_n_correct_responsible',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][responsible]'
                    }"
                    :selected_input="!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined && ConfigExercisesTable.data.subquestion_close_n_correct.responsible ? ConfigExercisesTable.data.subquestion_close_n_correct.responsible : []) : (ConfigExercisesTable.data.close_n_correct !== undefined && ConfigExercisesTable.data.close_n_correct.responsible ? ConfigExercisesTable.data.close_n_correct.responsible : [])"
                    :feed="getFeeds.feed_responsible"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined && ConfigExercisesTable.data.subquestion_close_n_correct.responsible ? Object.values(ConfigExercisesTable.data.subquestion_close_n_correct.responsible).join() : '') : (ConfigExercisesTable.data.close_n_correct !== undefined && ConfigExercisesTable.data.close_n_correct.responsible ? Object.values(ConfigExercisesTable.data.close_n_correct.responsible).join() : '')}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhTextarea
                    :datalabel="{
                        text: $t('statement') + '*',
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_n_correct_statement',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][statement]',
                        value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined ? ConfigExercisesTable.data.subquestion_close_n_correct.statement : '') : (ConfigExercisesTable.data.close_n_correct !== undefined ? ConfigExercisesTable.data.close_n_correct.statement : ''),
                        required: true,
                        edited: true,
                        image: {
                            subscript: require('../../../../../../assets/img/text/subscript.svg'),
                            superscript: require('../../../../../../assets/img/text/superscript.svg'),
                            bold: require('../../../../../../assets/img/text/text-bold.svg'),
                            italic: require('../../../../../../assets/img/text/text-italic.svg'),
                            underline: require('../../../../../../assets/img/text/text-underline.svg'),
                        }
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('statement') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;" v-html="!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined ? ConfigExercisesTable.data.subquestion_close_n_correct.statement : '') : (ConfigExercisesTable.data.close_n_correct !== undefined ? ConfigExercisesTable.data.close_n_correct.statement : '')"></label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhTextarea
                    :datalabel="{
                        text: $t('information'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_' + (!showSubQuestion ? '_subquestion_' : '') + 'close_n_correct_information',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][information]',
                        value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined ? ConfigExercisesTable.data.subquestion_close_n_correct.information : '') : (ConfigExercisesTable.data.close_n_correct !== undefined ? ConfigExercisesTable.data.close_n_correct.information : ''),
                        required: false
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('information') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined ? ConfigExercisesTable.data.subquestion_close_n_correct.information : '') : (ConfigExercisesTable.data.close_n_correct !== undefined ? ConfigExercisesTable.data.close_n_correct.information : '')}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhTextarea
                    :datalabel="{
                        text: $t('score_legend'),
                        class: 'T15_b',
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_' + (!showSubQuestion ? '_subquestion_' : '') + 'close_n_correct_score_legend',
                        name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][score_legend]',
                        value: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined ? ConfigExercisesTable.data.subquestion_close_n_correct.score_legend : '') : (ConfigExercisesTable.data.close_n_correct !== undefined ? ConfigExercisesTable.data.close_n_correct.score_legend : ''),
                        required: false
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('score_legend') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined ? ConfigExercisesTable.data.subquestion_close_n_correct.score_legend : '') : (ConfigExercisesTable.data.close_n_correct !== undefined ? ConfigExercisesTable.data.close_n_correct.score_legend : '')}}</label>
            </template>
        </div>
        <div style="margin-bottom: 10px;">
            <GhTableCommon
                :extratable="{
                    id: 'answers'
                }"
                :header="header"
                :data="possible_answer"
            >
                <template v-slot:field_component="{itemProp, labelProp}">
                    <div v-if="labelProp.field == 'answer'">
                        <GhInputTextField
                            :datalabel="{}"
                            :datainput="{
                                required: labelProp.required,
                                id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_n_correct_answer_'+itemProp.id,
                                name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][answer]['+itemProp.id+']',
                                style: 'margin-right: 10px;',
                                value: itemProp.answer,
                            }"
                        />
                    </div>
                    <div v-if="labelProp.field == 'correct'" class="centered" style="margin-top: -5px; margin-left: -10px;" >
                        <GhChecks
                            :datalabel="{}"
                            :datainput="{
                                required: labelProp.required,
                                type: 'checkbox',
                                id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_n_correct_correct_' + itemProp.id,
                                value: itemProp.id,
                                name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][correct][]',
                                selected: !showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined ? itemProp.selected : false) : (ConfigExercisesTable.data.close_n_correct !== undefined ? itemProp.selected : false),
                                disabled: $root._route.params.view ? true : false
                            }"
                            @click="disabledChecks('exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][correct][]');"
                        />
                    </div>
                    <div v-else-if="labelProp.field == 'score'">
                        <GhInputTextField
                            :datalabel="{}"
                            :datainput="{
                                  required: labelProp.required,
                                  id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_n_correct_score_'+itemProp.id,
                                  name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][score]['+itemProp.id+']',
                                  style: 'margin-right: 10px;',
                                  value: itemProp.score,
                              }"
                        />
                    </div>
                </template>
            </GhTableCommon>
        </div>
        <template v-if="ConfigExercisesTable.type_exercise.close_n_correct.feedback.view">
            <div class="gh-row-content" v-for="(item,index) in ConfigExercisesTable.type_exercise.close_n_correct.possible_answers.answers" :key="index">
                <template v-if="!$root._route.params.view">
                    <GhTextarea
                        :datalabel="{
                            text: $t('feedback') + ' ' + item.id + (ConfigExercisesTable.type_exercise.close_n_correct.feedback.required ? '*' : ''),
                            class: 'T15_b',
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'exercise_' + (!showSubQuestion ? 'subquestion_' : '') + 'close_n_correct_feedback',
                            name: 'exercise' + (!showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][feedback]['+item.id+']',
                            value: !showSubQuestion ? (ConfigExercisesTable.data.close_n_correct !== undefined ? ConfigExercisesTable.data.close_n_correct.possible_answers.answers[index].feedback : '') : (ConfigExercisesTable.data.close_n_correct !== undefined ? ConfigExercisesTable.data.close_n_correct.possible_answers.answers[index].feedback : ''),
                            required: ConfigExercisesTable.type_exercise.close_n_correct.feedback.required
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('feedback') + ' ' + item.id + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{!showSubQuestion ? (ConfigExercisesTable.data.subquestion_close_n_correct !== undefined ? ConfigExercisesTable.data.subquestion_close_n_correct.possible_answers.answers[index].feedback : '') : (ConfigExercisesTable.data.close_n_correct !== undefined ? ConfigExercisesTable.data.close_n_correct.possible_answers.answers[index].feedback : '')}}</label>
                </template>
            </div>
        </template>
        <template v-if="showSubQuestion && ConfigExercisesTable.type_exercise.close_n_correct.subquestion.view">
            <div class="section_line" style="margin-bottom: 15px;">
                <div class="T15_b">{{$t('subquestion')}}</div>
            </div>
            <GhTableCommon :key="'A'+refresh_table_no_condition"
                :extratable="{
                    id: 'subquestions',
                }"
                :header="header_subquestions"
                :data="table_no_condition"
            >
                <template v-slot:actions="{itemProp}">
                    <GhAction v-if="itemProp.new"
                        :dataction="{
                            id: 'edit_subquestions',
                            text: $t('modify'),
                            icon: require('../../../../../../assets/gh_new_dessign/edit.svg'),
                        }"
                        @click="editNewSubquestion(itemProp.id)"
                    />
                    <GhAction
                        :dataction="{
                            id: 'delete_subquestions',
                            text: $t('delete'),
                            icon: require('../../../../../../assets/gh_new_dessign/trash.svg'),
                        }"
                        @click="Global.deleteConfirm('delete_confirm',itemProp.new ? 'SubquestionNew' : 'Subquestion', itemProp.id,{'name': itemProp.name, 'type_of_exercise': itemProp.type}, $t('delete_subquestion'), $t('preparing_delete_subquestion'), 'delete')"
                    />
                </template>
                <template v-slot:footer>
                    <div class="centered">
                        <GhButton
                            :datainput="{
                                id: 'btn_add_new_subquestions',
                                text: $t('add_new')
                            }"
                            @click="newSubquestion"
                        />
                        <GhButton v-if="getFeeds.feed_excerises_no_subquestions.length !== 0"
                            :datainput="{
                                id: 'btn_add_old_subquestions',
                                text: $t('add_existing')
                            }"
                            @click="Global.openPopUp('subquestion_exist_data_selector_popup')"
                        />
                    </div>
                </template>
            </GhTableCommon>

            <div style="display: none;">
                <div v-for="(item,index) in table_no_condition" :key="'B'+index"><input type="hidden" name="exercise[close_numeric][subquestion][]" v-if="item.new" :value="item.id"></div>
            </div>

            <GhDataSelector ref="subquestion_exist_no_condition" :key="'C'+refresh_no_condition"
                :datablock="{
                    id: 'subquestion_exist',
                    class: 'T15_b',
                    subtitle: $t('select_existing_exercise') + ':',
                    text: $t('add_existing'),
                    label: $t('add_existing'),
                    type: 'checkbox',
                    columns: 3,
                    style: 'width: 149px;display: none;',
                    required: false,
                }"
                :datainput="{
                    id: 'value_subquestion_exist',
                    name: 'exercise[close_n_correct][subquestion][]'
                }"
                :selected_input="selected_no_condition"
                :feed="getFeeds.feed_excerises_no_subquestions"
                @accept="addExerciseExist"
            />
        </template>
    </div>
</template>

<script>
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhChecks from 'fe-gh-checks-lib';
    import {mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: "type_close_n_correct",
        components: {
            GhAction,
            GhButton,
            GhDataSelector,
            GhTextarea,
            GhTableCommon,
            GhInputTextField,
            GhChecks
        },
        computed: {
            ...mapState(['getFeeds', 'ConfigExercisesTable'])
        },
        data(){
            return {
                header: [
                    {text: '', field: 'id', title: 'Test', sorting: false, typeSort: 'int', style: 'max-width: 10px; width: 3%;'},
                    {text: this.$t('answers'), field: 'answer', field_component: this.$root._route.params.view ? false : true, required: true, style: 'max-width: 10px; width: 87%;'},
                    {text: this.$t('correct'), field: 'correct', field_component: true, required: true, style: 'min-width: 60px; width: 5%;'},
                    {text: this.$t('punctuation'), field: 'score', field_component: this.$root._route.params.view ? false : true, required: true, style: 'min-width: 60px; width: 5%;'},
                ],
                possible_answer: [],
                showSubQuestion: false,
                refresh_no_condition: 0,
                actual_level: this.level === undefined ? 0 : parseInt(this.level),
                refresh_table_no_condition: 0,
                selected_no_condition: [],
                table_no_condition: [],
                header_subquestions:[
                    {text: this.$t('statement'), field: 'name', sorting: true, typeSort: 'string', style: 'width: 80%;'},
                    {text: this.$t('type_of_exercise'), field: 'type', sorting: true, typeSort: 'string', style: 'max-width: 200px; width: 20%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        watch:{
            'ConfigExercisesTable.to_remove_key_table'(data){
                delete this.$refs.subquestion_exist_no_condition.checkdata[data];
                this.$refs.subquestion_exist_no_condition.countdata--;
                this.selected_no_condition = this.$refs.subquestion_exist_no_condition.checkdata;
                var to_uncheck = document.querySelectorAll('input[name="exercise[close_n_correct][subquestion][]"][value="'+data+'"]')[0];
                to_uncheck.checked = false;
                this.refresh_no_condition++;
                this.addExerciseExist();
            },
        },
        async beforeMount() {
            if(this.ConfigExercisesTable.data.close_n_correct !== undefined || this.ConfigExercisesTable.data.subquestion_close_n_correct !== undefined) {
                var tmpMap = !this.showSubQuestion ? this.ConfigExercisesTable.data.subquestion_close_n_correct.possible_answers.answers : this.ConfigExercisesTable.data.close_n_correct.possible_answers.answers;
                this.possible_answer = {
                    ...this.ConfigExercisesTable.type_exercise.close_n_correct.possible_answers.answers,
                    ...tmpMap
                };
            }else if (this.ConfigExercisesTable.type_exercise.close_n_correct.possible_answers.answers.length != 0){
                this.possible_answer = this.ConfigExercisesTable.type_exercise.close_n_correct.possible_answers.answers;
            }
        },
        beforeUpdate() {
            if(this.ConfigExercisesTable.data.close_n_correct !== undefined || this.ConfigExercisesTable.data.subquestion_close_n_correct !== undefined) {
                var tmpMap = !this.showSubQuestion ? this.ConfigExercisesTable.data.subquestion_close_n_correct.possible_answers.answers : this.ConfigExercisesTable.data.close_n_correct.possible_answers.answers;
                this.possible_answer = {
                  ...this.ConfigExercisesTable.type_exercise.close_n_correct.possible_answers.answers,
                  ...tmpMap
                };
            }else if (this.ConfigExercisesTable.type_exercise.close_n_correct.possible_answers.answers.length != 0){
                this.possible_answer = this.ConfigExercisesTable.type_exercise.close_n_correct.possible_answers.answers;
            }
        },
        async mounted() {
            if(this.ConfigExercisesTable.data.close_n_correct !== undefined) {
                this.disabledChecks('exercise' + (!this.showSubQuestion ? '[subquestion]' : '') + '[close_n_correct][correct][]');
            }

            this.showSubQuestion = this.$parent.$parent.showSubQuestionChildren;

            await this.$store.dispatch('getFeedExercisesWithoutSubquestions','',{root:true});
            this.selected_no_condition = this.ConfigExercisesTable.data.close_n_correct.subquestion.selecteds !== undefined && this.ConfigExercisesTable.data.close_n_correct.subquestion.selecteds.length !== 0 ? this.ConfigExercisesTable.data.close_n_correct.subquestion.selecteds : {};
            var selecteds = this.selected_no_condition;
            var tmp_array = [];
            for(var e=0;e<Object.keys(selecteds).length;e++){
                tmp_array.push(Object.keys(selecteds)[e]);
            }

            await this.$store.dispatch('getExercisesLikeSubquestion',{exercises:tmp_array,state:'old',level:this.actual_level},{root:true});
            this.table_no_condition = [];

            if(this.ConfigExercisesTable.table_subquestions[this.actual_level].old !== undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].old.length !== 0){
                for(var i=0;i<this.ConfigExercisesTable.table_subquestions[this.actual_level].old.length;i++){
                    this.table_no_condition.push(this.ConfigExercisesTable.table_subquestions[this.actual_level].old[i]);
                }
            }

            if(this.ConfigExercisesTable.table_subquestions[this.actual_level].new !== undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].new.length !== 0){
                for(var t=0;t<this.ConfigExercisesTable.table_subquestions[this.actual_level].new.length;t++){
                    this.table_no_condition.push(this.ConfigExercisesTable.table_subquestions[this.actual_level].new[t]);
                }
            }
            this.refresh_table_no_condition++;
        },
        methods:{
            disabledChecks(name){
                if(document.querySelectorAll('input[name="'+name+'"]:checked').length === this.ConfigExercisesTable.type_exercise.close_n_correct.possible_answers.correct){
                    document.querySelectorAll('input[name="'+name+'"]').forEach(item => {
                        if(!item.checked){
                            item.parentElement.style.opacity = 0.5;
                            item.parentElement.style.pointerEvents = 'none';
                        }
                    });
                }else{
                    document.querySelectorAll('input[name="'+name+'"]').forEach(item => {
                        item.parentElement.style.opacity = '';
                        item.parentElement.style.pointerEvents = '';
                    });
                }
            },
            async addExerciseExist(){
                var selecteds = document.querySelectorAll('input[name="exercise[close_n_correct][subquestion][]"]:checked');
                var tmp_array = [];
                for(var i=0;i<selecteds.length;i++){
                    tmp_array.push(selecteds[i].value);
                }

                await this.$store.dispatch('getExercisesLikeSubquestion',{exercises:tmp_array,state:'old',level:this.actual_level},{root:true});
                this.table_no_condition = [];

                if(this.ConfigExercisesTable.table_subquestions[this.actual_level].old !== undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].old.length !== 0){
                    for(var n=0;n<this.ConfigExercisesTable.table_subquestions[this.actual_level].old.length;n++){
                        this.table_no_condition.push(this.ConfigExercisesTable.table_subquestions[this.actual_level].old[n]);
                    }
                }

                if(this.ConfigExercisesTable.table_subquestions[this.actual_level].new !== undefined && this.ConfigExercisesTable.table_subquestions[this.actual_level].new.length !== 0){
                    for(var t=0;t<this.ConfigExercisesTable.table_subquestions[this.actual_level].new.length;t++){
                        this.table_no_condition.push(this.ConfigExercisesTable.table_subquestions[this.actual_level].new[t]);
                    }
                }
                this.refresh_table_no_condition++;
            },
            newSubquestion(){
                var level_send = this.level === undefined ? 1 : parseInt(this.level)+1;
                this.Global.windowOpen(this.Global.openSecondWindow('config_exercise_subquestion', {subquestion:'subquestion',level:level_send,condition:'n'}),'new_subquestion_'+level_send);
            },
            editNewSubquestion(id){
                var level_send = this.level === undefined ? 1 : parseInt(this.level)+1;
                this.Global.windowOpen(this.Global.openSecondWindow('config_exercise_subquestion_id', {subquestion:'subquestion',level:level_send,id:id,condition:'n'}),'new_subquestion_'+level_send);
            },
        }
    }
</script>

<style>
    #label_exercise_close_n_correct_responsible_selector_label, #label_exercise_subquestion_close_n_correct_responsible_selector_label{
        width: 150px;
        text-align: end;
    }

    #subquestion_exist_data_select_SUMMARY{
        display: none !important;
    }
</style>