import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        counters: []
    },
    mutations: {
        loadUserCounters(state, payload) {
            state.counters = payload;
        },
        loadUsers(state, payload) {
            state.records_data = payload;
        },
    },
    actions: {
        async getUserCounters(state){
            await axios.get(
                API_URL + "user/worker/count"
            ).then(
                response => {
                    state.commit('loadUserCounters', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUsers(state){
            await axios.get(
                API_URL + "user/workers/company"
            ).then(
                response => {
                    state.commit('loadUsers', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async toBuyUsers(state, items){
            console.log(items.items);
        }
    }
};
