<template>
    <div v-if="haveData" style="margin-top: 20px;">
        <div>
            <GhSelectorPayment
                :dataBlock="{
                    id: 'pago',
                    name: 'test[]',
                    title: $t('start_way_excelence'),
                    process_pay_text: $t('paying'),
                    btn_text_shop: '',
                    btn_text_pay: '',
                    btn_text_cancel: '',
                    mapState: 'ToPay',
                    load_type_button: ['loadTypeButton', 'typeButton'],
                    load_process_pay: ['loadProcessPay', 'processPay'],
                    load_items_pay: ['loadItemsPay', 'itemsPay'],
                    dispatch_payment: 'setPayments'
                }"
                :dataCards="ToPay.payments"
            >
                <template v-slot:infoBox="{itemProp}">
                    <div class="T15_b title_explication">
                        {{itemProp.title_explication}}
                    </div>
                    <div class="T15">
                        {{itemProp.explication}}
                    </div>
                    <div class="T15_b title_recomendation">
                        {{itemProp.recomendation}}
                    </div>
                    <div class="T15">
                        {{itemProp.title_recomendation}}
                    </div>
              </template>
            </GhSelectorPayment>
        </div>
        <div class="centered" style="margin-top: 20px;">
            <GhButton
                :datainput="{id: 'btn_back',text: $t('back'),class: '', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";
    import GhSelectorPayment from "fe-gh-selector-payment-lib";
    import {mapState} from "vuex";

    export default {
        name: "to_payment",
        components:{
            GhButton,
            GhSelectorPayment,
        },
        computed: {
            ...mapState(["ToPay"]),
        },
        data(){
            return{
                haveData: false
            }
        },
        async mounted() {
            await this.$store.dispatch('getPayments','',{root:true});

            var r = document.querySelector(':root');
            var color = '#DDDDDD';

            r.style.setProperty('--body_color',color);
            this.haveData = true;
        },
        methods:{
            back(){
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>