<template>
    <div v-if="haveData">
        <GhDropSelector v-if="show"
            :datablock="{
                id: 'ambits_section',
                title: $t('ambits'),
                second_title: $t('results'),
                num_results: TabCreation.listAmbits.length,
            }"
        >
            <template v-slot:content>
                <GhTableCommon
                    :extratable="{
                        id: 'ambits_table',
                    }"
                    :header="header"
                    :data="TabCreation.listAmbits"
                >
                    <template v-slot:field_redirect="{itemProp, labelProp}">
                        <router-link :to="{name: 'creation_ambits_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
                    </template>
                    <template v-slot:actions="{itemProp}">
                        <router-link :to="{name: 'creation_ambits_id', params: {id: itemProp.id}}">
                            <GhAction
                                :dataction="{
                                    id: 'edit_creation_ambit',
                                    text: $t('modify'),
                                    icon: require('../../../assets/gh_new_dessign/edit.svg'),
                                }"
                            />
                        </router-link>
                        <GhAction
                            :dataction="{
                                id: 'delete_creation_ambit',
                                text: $t('delete'),
                                icon: require('../../../assets/gh_new_dessign/trash.svg'),
                            }"
                            @click="Global.deleteConfirm('delete_confirm','Ambit', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, $t('delete_ambit'), $t('preparing_delete_ambit'), 'delete')"
                        />
                    </template>
                    <template v-slot:footer>
                        <div class="centered">
                            <router-link :to="{name: 'creation_ambits'}">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_add_creation_ambit',
                                        text: $t('add')
                                    }"
                                />
                            </router-link>
                        </div>
                    </template>
                </GhTableCommon>
            </template>
        </GhDropSelector>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_ambits",
        components:{
            GhTableCommon,
            GhDropSelector,
            GhAction,
            GhButton,
        },
        computed: {
            ...mapState(['TabCreation','Login'])
        },
        data(){
            return{
                header: [
                    {text: this.$t('code'), field: 'code', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; width: 100px;'},
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 200px; width: 20%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'width: 80%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false,
                show: false,
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getListAmbits', '', {root: true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('create')){
                    that.Login.tabs[route.id]['sub-modules'][2]['sub-modules'].forEach((subroute) => {
                        that.show = subroute.have_permission;
                    });
                }
            });

            this.haveData = true;
        }
    }
</script>