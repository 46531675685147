var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{id: 'type_exercise',title: _vm.$t('type_of_exercise')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_type_exercise"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'type_exercise_code',
                                        name: 'type_exercise[code]',
                                        style: 'width: 200px',
                                        value: _vm.ConfigTypeExercise.data.code,
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        required: true,
                                        id: 'type_exercise_name',
                                        name: 'type_exercise[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: _vm.ConfigTypeExercise.data.name,
                                        type: 'text',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.name))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('description'),
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                    },"datainput":{
                                        id: 'type_exercise_description',
                                        name: 'type_exercise[description]',
                                        value: _vm.ConfigTypeExercise.data.description
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.description))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                                        type: 'radio',
                                        id: 'type_exercise_type',
                                        label: _vm.$t('type') + '*',
                                        title: _vm.$t('type'),
                                        text: _vm.$t('select_type') + ':',
                                        name: 'type_exercise[type][]',
                                        id_value: 'value',
                                        name_label: 'text',
                                        child_key: 'children',
                                        required: true,
                                        checkparentdata: false,
                                        style: '',
                                        label_style: 'width: 148px',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    },"selected_input":_vm.ConfigTypeExercise.data.type ? _vm.ConfigTypeExercise.data.type : [],"items":_vm.getFeeds.feed_type},on:{"accept":function($event){return _vm.changeType()}}})]:[_c('div',{staticStyle:{"display":"flex"}},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.text_type))])])]],2),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","width":"400px"}},[_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('statement'), style: 'width: 130px', id: 'label_type_exercise_statement'},"datainput":{required: false, selected: true, disabled: true, type: 'checkbox', id: 'value_type_exercise_statement', value: 1, name: 'type_exercise[config][statement][]'}}}),_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_statement'},"datainput":{required: false, selected: true, disabled: true, type: 'checkbox', id: 'value_type_exercise_required_statement', value: 2, name: 'type_exercise[config][statement][]'}}})],1),_c('div',{staticStyle:{"display":"flex","width":"400px"}},[_c('GhChecks',{ref:"GhCheckConfigResponsible",attrs:{"datalabel":{text: _vm.$t('responsible'), style: 'width: 130px', id: 'label_type_exercise_responsible'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_responsible', value: 1, name: 'type_exercise[config][responsible][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.responsible && _vm.ConfigTypeExercise.data.config.responsible[0] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigResponsible', 'GhCheckConfigResponsibleRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigResponsibleRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_responsible'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_responsible', value: 2, name: 'type_exercise[config][responsible][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.responsible && _vm.ConfigTypeExercise.data.config.responsible[1] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigResponsible', 'GhCheckConfigResponsibleRequired', true)}}})],1)])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","width":"400px"}},[_c('GhChecks',{ref:"GhCheckConfigMetrics",attrs:{"datalabel":{text: _vm.$t('metrics'), style: 'width: 130px', id: 'label_type_exercise_metrics'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_responsible', value: 1, name: 'type_exercise[config][metrics][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.metrics && _vm.ConfigTypeExercise.data.config.metrics[0] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigMetrics', 'GhCheckConfigMetricsRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigMetricsRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_metrics'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_metrics', value: 2, name: 'type_exercise[config][metrics][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.metrics && _vm.ConfigTypeExercise.data.config.metrics[1] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigMetrics', 'GhCheckConfigMetricsRequired', true)}}})],1)])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","width":"400px"}},[_c('GhChecks',{ref:"GhCheckConfigExamples",attrs:{"datalabel":{text: _vm.$t('examples'), style: 'width: 130px', id: 'label_type_exercise_examples'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_examples', value: 1, name: 'type_exercise[config][examples][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.examples && _vm.ConfigTypeExercise.data.config.examples[0] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigExamples', 'GhCheckConfigExamplesRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigExamplesRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_examples'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_examples', value: 2, name: 'type_exercise[config][examples][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.examples && _vm.ConfigTypeExercise.data.config.examples[1] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigExamples', 'GhCheckConfigExamplesRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","width":"400px"}},[_c('GhChecks',{ref:"GhCheckConfigBibliography",attrs:{"datalabel":{text: _vm.$t('bibliography'), style: 'width: 130px', id: 'label_type_exercise_bibliography'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_bibliography', value: 1, name: 'type_exercise[config][bibliography][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.bibliography && _vm.ConfigTypeExercise.data.config.bibliography[0] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigBibliography', 'GhCheckConfigBibliographyRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigBibliographyRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_bibliography'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_bibliography', value: 2, name: 'type_exercise[config][bibliography][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.bibliography && _vm.ConfigTypeExercise.data.config.bibliography[1] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigBibliography', 'GhCheckConfigBibliographyRequired', true)}}})],1)])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","width":"400px"}},[_c('GhChecks',{ref:"GhCheckConfigVideography",attrs:{"datalabel":{text: _vm.$t('videography'), style: 'width: 130px', id: 'label_type_exercise_videography'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_videography', value: 1, name: 'type_exercise[config][videography][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.videography && _vm.ConfigTypeExercise.data.config.videography[0] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigVideography', 'GhCheckConfigVideographyRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigVideographyRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_videography'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_videography', value: 2, name: 'type_exercise[config][videography][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.videography && _vm.ConfigTypeExercise.data.config.videography[1] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigVideography', 'GhCheckConfigVideographyRequired', true)}}})],1),_c('div',{staticStyle:{"display":"flex","width":"400px"}},[_c('GhChecks',{ref:"GhCheckConfigSupportMaterial",attrs:{"datalabel":{text: _vm.$t('support_material_2'), style: 'width: 130px', id: 'label_type_exercise_support_material'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_support_material', value: 1, name: 'type_exercise[config][support_material][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.support_material && _vm.ConfigTypeExercise.data.config.support_material[0] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigSupportMaterial', 'GhCheckConfigSupportMaterialRequired', false)}}}),_c('GhChecks',{ref:"GhCheckConfigSupportMaterialRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_support_material'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_required_support_material', value: 2, name: 'type_exercise[config][support_material][]',
                                            selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.support_material && _vm.ConfigTypeExercise.data.config.support_material[1] ? true : false,
                                            disabled: !_vm.$root._route.params.view ? false : true
                                        }},on:{"click":function($event){return _vm.checkGroupChecks('GhCheckConfigSupportMaterial', 'GhCheckConfigSupportMaterialRequired', true)}}})],1)])]),_c('div',{key:_vm.refresh},[_c(_vm.include_component,{ref:"GhChildrenTypesBlock",tag:"component"})],1)])]):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')}})]:_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_save',
                    text: _vm.$t('save'),
                }},on:{"click":_vm.submitTypeExercise}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_return',
                    text: _vm.$t('back'),
                }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }