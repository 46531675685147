import ConfigSupportMaterial from "@/view/config/general/support_material/template/support_material";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/general/support_material',
        name: 'config_support_material',
        component: ConfigSupportMaterial,
        props: {id: null, showMenu: true, otherTitle: i18n.t('support_material_2')},
        children: [
            {
                path: '/config/general/support_material/:id',
                name: 'config_support_material_id',
                component: ConfigSupportMaterial,
                props: {id: null, showMenu: true, otherTitle: i18n.t('support_material_2')},
            },
            {
                path: '/config/general/support_material/:id/:view',
                name: 'config_support_material_id_view',
                component: ConfigSupportMaterial,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('support_material_2')},
            }
        ]
    }
];

export default routes;