import ItineraryList from "@/view/itinerary/client/list_itinerary";
import ItineraryAssignment from "@/view/itinerary/client/template/assignment";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/my_itineraryes',
        name: i18n.t('my_itineraries'),
        component: ItineraryList,
        props: {showMenu: true},
    },
    {
        path: '/my_itineraryes/assignment',
        name: 'Assignment',
        component: ItineraryAssignment,
        props: {id: null, view: false, showMenu: true, otherTitle: i18n.t('user')},
    },
];

export default routes;