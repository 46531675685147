import ListAssign from "@/view/assign/list_assign.vue";
import ListItineraryAssign from "@/view/assign/itinerary/list_assign_itineraries.vue";
import ItineraryAssign from "@/view/assign/itinerary/templates/itinerary_assign.vue";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/assign',
        name: i18n.t('assignment'),
        component: ListAssign,
        props: {showMenu: true},
    },
    {
        path: '/list_assign_itineraries',
        name: 'list_assign_itineraries',
        component: ListItineraryAssign,
        props: {id: null, showMenu: true,  otherTitle: i18n.t('itinerary_assignment')},
    },
    {
        path: '/itinerary_assign',
        name: 'itinerary_assign',
        component: ItineraryAssign,
        props: {id: null, showMenu: true,  otherTitle: i18n.t('itinerary_assignment')},
    }
];

export default routes;
