import Ambit from "@/view/creation/ambits/templates/ambit";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/creation_ambits/ambit',
        name: 'creation_ambits',
        component: Ambit,
        props: {id: null, showMenu: true,otherTitle: i18n.t('ambit')},
        children: [
            {
                path: '/creation_ambits/ambit/:id',
                name: 'creation_ambits_id',
                component: Ambit,
                props: {id: null, showMenu: true, otherTitle: i18n.t('ambit')},
            },
            {
                path: '/creation_ambits/ambit/:id/:view',
                name: 'creation_ambits_id_view',
                component: Ambit,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('ambit')},
            }
        ]
    },
];

export default routes;