var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData == true)?_c('div',[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('telephone') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"20%","min-width":"100px"}},[_vm._v(_vm._s(_vm.TabAction && _vm.TabAction.data_company && _vm.TabAction.data_company.telephone ? _vm.TabAction.data_company.telephone : ''))])]),_c('input',{attrs:{"type":"hidden","name":"type","value":"call"}}),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                    id: 'label_action_call_state',
                    class: 'T15_b',
                    subtitle: _vm.$t('select_call_status') + ':',
                    text: _vm.$t('call_status'),
                    label: _vm.$t('call_status'),
                    type: 'radio',
                    columns: 3,
                    style: 'margin-left:14px;',
                },"datainput":{
                    id: 'value_around_language',
                    name: 'state'
                },"feed":_vm.getFeeds.feed_state_phone_number}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('comment') ,
                    class: ' T15',
                    styles:'width: 150px; min-width: 150px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_open_question_statement',
                    name: 'note',
                    value:  '',
                }}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }