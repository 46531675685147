<template>
    <div v-if="haveData" style="padding-bottom: 12px;">
      <GhSubTab v-bind:items="$router.options.GhSubTabAssign" :dataBlock="{id: 'assign_tab_', calldispatch: 'getAssingTabActive'}"/>
      <component v-bind:is="TabAssing.subTabActiveAssign" :dataBlock="{id:TabAssing.idActiveItineraryAssign,name:TabAssing.valueActiveItineraryAssign}"/>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import GhSubTab from "fe-gh-subtab-lib";

    export default {
        name: "list_assign",
        components: {
            GhSubTab
        },
        computed:{
            ...mapState(['TabAssing']),
        },
        data(){
            return{
                haveData: false,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getTabsAssign', '', {root: true});
            this.$router.options.GhSubTabAssign = this.TabAssing.tabs_assign;
            this.haveData = true;
        }
    }
</script>