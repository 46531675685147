var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showBlock > 0)?_c('div',[_c('GhDropSelector',{attrs:{"datablock":{
            id: 'assign_itinerary_'+_vm.dataBlock.id,
            title: _vm.dataBlock.name,
            second_title: _vm.$t('results'),
            num_results: 0,
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                      id: 'itineraries_table',
                  },"header":_vm.header,"data":_vm.TabAssing.tableAssignItinerary},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'itinerary_assign', params: {id: itemProp.id,id_itinerary:_vm.dataBlock.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_assingn_itinerary_'+_vm.dataBlock.id+'_'+itemProp.id,
                                text: _vm.$t('assign'),
                                icon: require('../../../assets/gh_new_dessign/edit.svg'),
                            }}})],1)]}}],null,false,450436144)})]},proxy:true}],null,false,2638307956)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }