var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":'_float_window'}},[_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'crm_support_incidence',
                title: _vm.$t('category'),
                num_results: '',
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_category"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        id: 'label_config_support_category_name',
                                        text: _vm.$t('name') + '*',
                                        style: 'width: 88px; min-width: 123px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'input_config_support_category_name',
                                        name: 'category[name]',
                                        style: 'max-height: 17px;',
                                        type: 'text',
                                        value: _vm.ConfigCategories.data && _vm.ConfigCategories.data.name ? _vm.ConfigCategories.data.name : '',
                                        required: true,
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"20%","min-width":"100px"}},[_vm._v(_vm._s(_vm.ConfigCategories.data && _vm.ConfigCategories.data.name ? _vm.ConfigCategories.data.name : ''))])]],2)])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(!_vm.$root._route.params.view && _vm.haveData)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('save')
                }},on:{"click":function($event){return _vm.setCategorie()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('back')
                }},on:{"click":function($event){return _vm.back()}}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }