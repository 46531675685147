var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData==true)?_c('div',{attrs:{"id":'_float_window'}},[_c('div',{staticClass:"content_page"},[_c('GhNoDropSelector',{attrs:{"datablock":{
                id: 'crm_support_incidence',
                title: _vm.$t('assignment') ,
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_assignment"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        id: 'label_group_assignment_name',
                                        text: _vm.$t('assignment_group') + '*',
                                        style: 'width: 138px; min-width: 138px; text-align: right;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'input_group_assignment_name',
                                        name: 'group[name]',
                                        style: 'max-height: 17px;',
                                        type: 'text',
                                        value: _vm.ConfigAssignment.data && _vm.ConfigAssignment.data.name ? _vm.ConfigAssignment.data.name : '',
                                        required: true,
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"138px","min-width":"138px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('assignment_group')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.ConfigAssignment.data && _vm.ConfigAssignment.data.name ? _vm.ConfigAssignment.data.name : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                                        text: _vm.$t('description') ,
                                        class: 'T15',
                                        styles: 'width: 136px; min-width: 136px !important; text-align: right; margin-right:12px;',
                                    },"datainput":{
                                        id: 'textarea_group_assignment_description',
                                        name: 'group[description]',
                                        value:  _vm.ConfigAssignment.data && _vm.ConfigAssignment.data.description ? _vm.ConfigAssignment.data.description : '',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"138px","min-width":"138px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.ConfigAssignment.data && _vm.ConfigAssignment.data.description ? _vm.ConfigAssignment.data.description : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                        id: 'label_group_assignment_email',
                                        text: _vm.$t('mail'),
                                        style: 'width: 138px; min-width: 138px; text-align: right;',
                                        class: 'T15'
                                    },"datainput":{
                                        id: 'input_group_assignment_email',
                                        name: 'group[email]',
                                        style: 'max-height: 17px;',
                                        type: 'text',
                                        value: _vm.ConfigAssignment.data && _vm.ConfigAssignment.data.email ? _vm.ConfigAssignment.data.email : '',
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"138px","min-width":"138px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('mail')))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.ConfigAssignment.data && _vm.ConfigAssignment.data.email ? _vm.ConfigAssignment.data.email : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('div',{staticStyle:{"margin-right":"40px"}},[_c('GhDropDownField',{attrs:{"dataBlock":{
                                        id: 'drop_users_assignment',
                                        style:'width: 100%; margin-right: 0px;',
                                        title: _vm.$t('people'),
                                        subTitle: _vm.$root._route.params.id ? _vm.ConfigAssignment.data && _vm.ConfigAssignment.data.users && _vm.ConfigAssignment.data.users.length !== 0 ? _vm.ConfigAssignment.data.users.length + ' ' + _vm.$t('Resultados') :  0 + ' ' + _vm.$t('Resultados'):_vm.users.length!==0? _vm.users.length+' '+ _vm.$t('Resultados'):0+' '+ _vm.$t('Resultados'),
                                        isDrop: true
                                    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{ id: 'conf_supp_assignment_users' },"header":_vm.header,"data":_vm.$root._route.params.id ? _vm.ConfigAssignment.data && _vm.ConfigAssignment.data.users && _vm.ConfigAssignment.data.users.length !== 0 ? _vm.ConfigAssignment.data.users : [] : _vm.users},scopedSlots:_vm._u([(!_vm.$root._route.params.view)?{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                                            id: 'btn_add_assignment_users',
                                                            text: _vm.$t('add_users')
                                                        }},on:{"click":function($event){return _vm.searchUsers(_vm.$root._route.params.id ? _vm.ConfigAssignment.data && _vm.ConfigAssignment.data.users && _vm.ConfigAssignment.data.users.length !== 0 ? _vm.ConfigAssignment.data.users : [] : _vm.users)}}})],1)]},proxy:true}:null],null,true)}):_vm._e()]},proxy:true}],null,false,265189028)})],1)])])])]},proxy:true}],null,false,3710028698)}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"8px","padding-bottom":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('save')
                }},on:{"click":function($event){return _vm.setAssignment()}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close',
                    class: 'T19 container-md_button_content',
                    text: _vm.$t('back')
                }},on:{"click":function($event){return _vm.router.go(-1)}}})],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }