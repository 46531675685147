<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'list_config_group',title: $t('holidays')}">
            <template v-slot:content>
                <div style="margin-top: 15px;">
                    <GhTableCommon
                        :extratable="{
                            id: 'holidays',
                        }"
                        :header="header"
                        :data="ConfigHolidays.data.length == 0 ? [] : ConfigHolidays.data"
                    >
                        <template v-slot:actions="{itemProp}" v-if="!$root._route.params.view">
                            <GhAction
                                :dataction="{
                                    id: 'delete_holiday',
                                    text: $t('delete'),
                                    icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                                    item_prop: itemProp
                                }"
                                @click="Global.deleteConfirm('delete_confirm','TemporaryHoliday', itemProp.id,{'description': itemProp.description}, $t('delete_holiday'), $t('prepare_delete_holiday'), 'delete')"
                            />
                        </template>
                        <template v-slot:footer v-if="!$root._route.params.view">
                            <div class="centered">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_add_user',
                                        text: $t('add')
                                    }"
                                    @click="Global.openPopUp('add_new_holiday_popup');"
                                />
                            </div>
                        </template>
                    </GhTableCommon>
                </div>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton
                v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                type="submit"
                @click="submitHoliday"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
        <template v-if="!$root._route.params.view">
              <GhPopUp v-show="false"
                   :generalConfiguration="{
                      id: 'add_new_holiday_popup',
                      title: $t('new_holiday'),
                      type: 'add',
                      style: 'width: 60%; min-width:300px;',
                   }"
                   :bodyConfiguration="{
                      id: '',
                      class: '',
                      style: 'max-height: 30vh; min-height: 8vh; overflow-y: auto;'
                   }"
                   :footerConfiguration="{
                      id: '',
                      class: '',
                      style: '',
                      content: ''
                   }"
              >
                  <template v-slot:body>
                      <div class="gh-content-global" :key="refresh">
                          <div class="gh-row-content">
                              <GhTextarea
                                  :datalabel="{
                                      text: $t('description') + '*',
                                      class: 'T15_b',
                                      styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                  }"
                                  :datainput="{
                                      id: 'holiday_description',
                                      name: 'holiday[description]',
                                      required: true,
                                      value: ''
                                  }"
                                  ref="ref_holiday_description"
                              />
                          </div>
                          <div class="gh-row-content">
                              <GhCalendar
                                  :datalabel="{
                                      id: 'label_date_holiday',
                                      text: $t('data'),
                                      style: 'width: 150px; min-width: 150px; text-align: right; margin-right: 12px;'
                                  }"
                                  :datainput="{
                                      id:'holiday_date',
                                      name:'holiday[date]',
                                      value: '',
                                      required: true,
                                  }"
                                  ref="ref_holiday_date"
                              />
                          </div>
                      </div>
                  </template>
                  <template v-slot:footer>
                      <GhButton
                          :datainput="{
                              id: 'button',
                              text: $t('save'),
                              class: '',
                              style: ''
                          }"
                          extra-attr="test"
                          @click="setHoliday(); Global.closePopUp('add_new_holiday_popup');"
                      />
                      <GhButton
                          :datainput="{
                              id: 'btn_close_holiday_popup',
                              text: $t('close'),
                          }"
                          @click="Global.closePopUp('add_new_holiday_popup');"
                      />
                  </template>
              </GhPopUp>
        </template>
    </div>
</template>
<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from 'fe-gh-action-lib';
    import GhPopUp from 'fe-gh-popup-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhCalendar from 'fe-gh-input-calendar-lib';
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    export default {
        name: "holiday_template",
        components: {
            GhNoDropSelector,
            GhTableCommon,
            GhAction,
            GhPopUp,
            GhTextarea,
            GhCalendar,
            GhButton,
        },
        data(){
            return {
                haveData:false,
                header: [
                  {text: this.$t('Descripción'), field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 85%;'},
                  {text: this.$t('day'), field: 'day', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 5%;'},
                  {text: this.$t('month'), field: 'month', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 5%;'},
                  {text: this.$t('year'), field: 'year', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 5%;'},
                  {text: '', field: 'actions', sorting: false},
                ],
                refresh: 0
            }
        },
        computed: {
            ...mapState(['ConfigHolidays']),
        },
        async beforeMount() {
            this.$root._route.params.id? await this.$store.dispatch('getHolidaysData', {id: this.$root._route.params.id}, {root: true}):'';
            this.haveData=true;
        },
        methods: {
            setHoliday(){
                var spliter_date = this.$refs.ref_holiday_date.$el.children[1].children[0].children[0].value.split('/');
                var params = {
                    id: Date.now(),
                    description: this.$refs.ref_holiday_description.$el.children[1].value,
                    date: this.$refs.ref_holiday_date.$el.children[1].children[0].children[0].value,
                    day: spliter_date[0],
                    month: spliter_date[1],
                    year: spliter_date[2]
                }
                this.$store.dispatch('setTemporalHolidayData',{tmp_holidays: params},{root:true});
                this.refresh++;
            },
            async submitHoliday() {
                this.$store.dispatch('setHolidayData', {id: this.$root._route.params.id,}, {root: true});
            }
        }
    }
</script>