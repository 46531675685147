import UsersList from "@/view/users/list_users";
import User from "@/view/users/template/user";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/users',
        name: i18n.t('users'),
        component: UsersList,
        props: {showMenu: true},
    },
    {
        path: '/users/user',
        name: 'user',
        component: User,
        props: {id: null, view: false, showMenu: true, otherTitle: i18n.t('user')},
        children: [
            {
                path: '/users/user/:id',
                name: 'user_id',
                component: User,
                props: {id: null, showMenu: true, otherTitle: i18n.t('user')},
            },
            {
                path: '/users/user/:id/:view',
                name: 'user_id_view',
                component: User,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('user')},
            }
        ]
    },
];

export default routes;