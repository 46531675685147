import ListCertificates from "@/view/creation/certificates/list_certificates";
import Certificado from "@/routes/creation/certificates/certificate/route";

const route = [
    {
        path: '/list_creation_certificates',
        name: 'list_creation_certificates',
        component: ListCertificates,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Certificado,
]


export default routes;