<template>
    <form id="form_business">
        <div class="gh-content-global">
            <div class="gh-row-content">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('Nombre') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabBusiness.records_data.name}}</label>
                <div>
                    <GhDataSelector
                        :datablock="{
                            id: 'social_reason',
                            class: 'T15_b',
                            subtitle: $t('select_social_reason') + ':',
                            text: $t('social_reason') + '*',
                            label: $t('social_reason'),
                            type: 'radio',
                            columns: 3,
                            required: true,
                            style: 'margin-left: 63px;',
                            popupStyle: 'min-width: 900px; width: 55%;'
                        }"
                        :datainput="{
                            id: 'value_social_Reason',
                            name: 'business[social_reason][]'
                        }"
                        :feed="getFeeds.feed_social_reason"
                        :selected_input="TabBusiness.records_data.social_reason"
                        class="none_margin"
                    />
                </div>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('cif') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 100px;">{{TabBusiness.records_data.cif}}</label>
            </div>
            <div class="gh-row-content">
                <GhInputTextField
                    :datalabel="{
                        text: $t('full_adress') + '*',
                        style:'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'company_direction',
                        name: 'business[direction]',
                        style: 'width: 20%',
                        value: TabBusiness.records_data.direction,
                        type: 'text'
                    }"
                />
                <GhDataSelector
                    :datablock="{
                        id: 'country',
                        class: 'T15_b',
                        subtitle: $t('select_country') + ':',
                        text: $t('country')+'*',
                        label: $t('country'),
                        type: 'radio',
                        columns: 3,
                        required: true,
                        style: 'margin-left:86px;',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :datainput="{
                        id: 'value_country',
                        name: 'business[country][]'
                    }"
                    :feed="getFeeds.feed_country"
                    :selected_input="TabBusiness.records_data.country"
                    class="none_margin"
                />
            </div>
            <div class="gh-row-content">
                <GhInputTextField
                    :datalabel="{
                        text: $t('mail') + '*',
                        style:'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'company_email',
                        name: 'business[email]',
                        style: 'width: 20%',
                        value: TabBusiness.records_data.email,
                        type: 'text'
                    }"
                />
                <GhInputTextField
                    :datalabel="{
                        text: $t('telephone') + '*',
                        style:'width: 122px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'company_telephone',
                        name: 'business[telephone]',
                        style: 'width: 20%',
                        value: TabBusiness.records_data.telephone,
                        type: 'text'
                    }"
                />
            </div>
            <div class="gh-row-content">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'radio',
                        id: 'business_activity',
                        label: $t('activity_sector') + '*',
                        title: $t('activity_sector'),
                        text: $t('select_activity_sector') + ':',
                        name: 'business[activity][]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: true,
                        checkparentdata: false,
                        style: 'margin-left: 16px; width:100%',
                        popupStyle: 'min-width: 900px;width: 55%;'
                    }"
                    :selected_input="TabBusiness.records_data.activity"
                    :items="getFeeds.feed_activity"
                />
            </div>
            <div class="gh-row-content">
                <GhDataSelector
                    :datablock="{
                          id: 'num_employee',
                          class: 'T15_b',
                          subtitle: $t('select_number_employees') + ':',
                          text: $t('number_employees') + '*',
                          label: $t('number_employees'),
                          type: 'radio',
                          columns: 3,
                          required: true,
                          label_required: $t('number_employees'),
                          style: 'margin-left: -5px;',
                          popupStyle: 'min-width: 900px;width: 55%;'
                      }"
                    :datainput="{
                          id: 'value_num_employee',
                          name: 'business[num_employee][]'
                      }"
                    :feed="getFeeds.feed_num_employers"
                    :selected_input="TabBusiness.records_data.num_employee"
                    class="none_margin"
                />
            </div>
        </div>
    </form>
</template>

<script>
    import {mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    export default {
        name: "edit_business",
        components:{
            GhDataSelector,
            GhTreeSelectorBlock,
            GhInputTextField
        },
        computed: {
          ...mapState(['TabBusiness', 'getFeeds']),
        }
    }
</script>

<style>
    .none_margin {
        min-width: 100px !important;
        width: 29% !important;
    }
</style>