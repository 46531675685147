<template>
    <div class="float_window">
        <GhNoDropSelector v-if="show" :datablock="{id: 'around',title: $t('environment')}">
            <template v-slot:content>
                <form id="form_around" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content" style="margin-bottom: -10px">
                            <GhDataSelector
                                :datablock="{
                                    id: 'label_around_language',
                                    class: 'T15_b',
                                    subtitle: $t('select_environment_language') + ':',
                                    text: $t('environment_language') + '*',
                                    label: $t('environment_language'),
                                    type: 'radio',
                                    columns: 3,
                                    style: 'margin-left:94px;',
                                    required: true,
                                }"
                                :datainput="{
                                    id: 'value_around_language',
                                    name: 'around[language]'
                                }"
                                :selected_input="ConfigAround.data.language ? ConfigAround.data.language : []"
                                :feed="getFeeds.feed_languages"
                            />
                        </div>
                        <div class="gh-row-content" style="margin-bottom: -10px">
                            <GhDataSelector
                                :datablock="{
                                    id: 'label_around_pallette',
                                    class: 'T15_b',
                                    subtitle: $t('select_environment_palette') + ':',
                                    text: $t('environment_palette') + '*',
                                    label: $t('environment_palette'),
                                    type: 'radio',
                                    columns: 3,
                                    style: 'margin-left:99px;',
                                    required: true,
                                }"
                                :datainput="{
                                    id: 'value_around_pallette',
                                    name: 'around[pallette]'
                                }"
                                :selected_input="ConfigAround.data.pallette ? ConfigAround.data.pallette : []"
                                :feed="getFeeds.feed_pallette"
                            />
                        </div>
                        <div class="gh-row-content" style="margin-bottom: -10px">
                            <GhDataSelector
                                :datablock="{
                                    id: 'label_around_font',
                                    class: 'T15_b',
                                    subtitle: $t('select_environment_font') + ':',
                                    text: $t('environment_font') + '*',
                                    label: $t('environment_font'),
                                    type: 'radio',
                                    columns: 3,
                                    style: 'margin-left:96px;',
                                    required: true,
                                }"
                                :datainput="{
                                    id: 'value_around_font',
                                    name: 'around[font]'
                                }"
                                :selected_input="ConfigAround.data.font ? ConfigAround.data.font : []"
                                :feed="getFeeds.feed_font"
                            />
                        </div>
                        <div class="gh-row-content">
                            <GhEasyUpload
                                :extrablock="{
                                    id: 'example',
                                    class: '',
                                    name_hidden: 'around[image]',
                                    id_hidden: 'example',
                                    icon_attach: require('../../../assets/gh_new_dessign/attach.svg')
                                }"
                                :datalabel="{
                                    id: 'label_import_block',
                                    style: 'margin-left:65px;'
                                }"
                                :datainput="{
                                    text: $t('attach_company_logo'),
                                    show_image: true,
                                    delete: false,
                                    href: 'easyUpload',
                                    downloadHref: 'easyDownload',
                                    file_name: ConfigAround.data.file_name,
                                    file_path: ConfigAround.data.file_path,
                                    file_download: ConfigAround.data.file_download,
                                    image_path: ConfigAround.data.image_path,
                                    required: true
                                }"
                            />
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                @click="submitAround"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import GhEasyUpload from 'fe-gh-easy-upload-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_around",
        components: {
            GhNoDropSelector,
            GhDataSelector,
            GhEasyUpload,
            GhButton
        },
        computed: {
            ...mapState(['ConfigAround','getFeeds','Login'])
        },
        data(){
            return {
                haveData: false,
                show: false,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getLanguages', '',{root:true});
            await this.$store.dispatch('getPallette', '',{root:true});
            await this.$store.dispatch('getFont', '',{root:true});
            await this.$store.dispatch('getAroundData', '',{root:true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('configuration')){
                    that.Login.tabs[route.id]['sub-modules'][2]['sub-modules'].forEach((subroute) => {
                        that.show = subroute.have_permission;
                    });
                }
            });

            this.haveData = true;
        },
        methods: {
            async submitAround(){
                if(this.Global.checkRequired('form_around') == 'OK') {
                    const formData = new FormData(document.getElementById('form_around'));
                    this.$store.dispatch('setAroundData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        }
    }
</script>