import BusinessList from "@/view/business/list_business";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/business',
        name: i18n.t('company'),
        component: BusinessList,
        props: {showMenu: true},
    }
];

export default routes;