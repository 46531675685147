<template>
    <div class="float_window">
        <GhNoDropSelector v-if="show"
            :datablock="{
                id: 'drop_selector_section_itinerary',
                title: $t('itineraries'),
            }"
        >
            <template v-slot:content v-if="haveData">
                <div class="gh-content-global centered" style="display: flex;">
                    <div v-for="(item, index) in TabMyItinerary.records_data" :key="index" style="display: block;padding: 40px;">
                        <div style="display: flex;justify-content: center;margin-bottom: 20px;">
                            <div class="icon_selector_quizz">
                                <img :src="require('@/assets/img/itinerary/' + item.icon)">
                            </div>
                        </div>
                        <div class="T15_b name_quizz" style="display: flex;justify-content: center;max-width: 150px;text-align: center;">{{item.name}}</div>
                        <div style="display: flex;justify-content: center;margin-top: 20px;">
                            <router-link :to="{name: 'Assignment', params: { id: item.id, view: true}}">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_assign_' + item.id,
                                        text: $t('assign'),
                                        style: 'margin-right: 0px!important'
                                    }"
                                />
                            </router-link>
                        </div>
                    </div>
                </div>
            </template>
        </GhNoDropSelector>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    export default {
        name: "list_itinerary",
        components: {
            GhNoDropSelector,
            GhButton
        },
        data(){
            return{
                haveData: false,
                show: false,
            }
        },
        computed: {
            ...mapState(['TabMyItinerary','Login'])
        },
        async beforeMount(){
            await this.$store.dispatch('getMyItinerary', '', {root:true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('my_itineraries')){
                    that.Login.tabs[route.id]['sub-modules'].forEach((subroute) => {
                        that.show = subroute.have_permission;
                    });
                }
            });

            this.haveData = true;
        },
        mounted(){
            var name_quizz = document.getElementsByClassName('name_quizz');
            var heigh_label = 0;

            for(var i=0;i<name_quizz.length;i++){
                heigh_label = name_quizz[i].offsetHeight > heigh_label ? name_quizz[i].offsetHeight : heigh_label;
            }
            for(var t=0;t<name_quizz.length;t++){
                name_quizz[t].style.height = heigh_label+"px";
            }
        },
    }
</script>