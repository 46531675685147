import ListCompanies from "@/view/gh_admin/list_companies";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/admin',
        name: i18n.t('gh_admin'),
        component: ListCompanies,
        props: {showMenu: true}
    },
];

export default routes;