import Vue from 'vue'
import Router from 'vue-router'
import i18n from "@/plugins/i18n";

Vue.use(Router);

import Login from './login/route';
import Todo from './todo/route';
import Global from './global/route';
import MisItinerarios from './itinerary/client/route';
import ItinerariosIniciales from './itinerary/initial/route';
import MiSituacion from './situation/route';
import Empresa from './business/route';
import Usuarios from './users/route';
import Configuracion from './config/route';
import Buscador from './search/route';
import GHAdmin from './gh_admin/route';
import Soporte from './support/route';
import Permisos from './perms/route';
import Creacion from './creation/route';
import Facturacion from './billing/route';
import FichaUsuario from './user_file/route';
import Acciones from './actions/route'
import Evaluacion from './evaluation/route';
import Tutoria from './tutor/route';
import DatosEvaluadosTutelados from './supervised_evaluated/route';
import Floatwindow from './floatwindow/route';
import Asignar from './assign/route'

const routes = [
    ...ItinerariosIniciales,
    ...Floatwindow,
    ...Login,
    ...Todo,
    ...Global,
    ...MisItinerarios,
    ...MiSituacion,
    ...Empresa,
    ...Usuarios,
    ...Configuracion,
    ...Buscador,
    ...GHAdmin,
    ...Soporte,
    ...Permisos,
    ...Creacion,
    ...Facturacion,
    ...FichaUsuario,
    ...Acciones,
    ...Evaluacion,
    ...Tutoria,
    ...DatosEvaluadosTutelados,
	...Asignar
];

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    GhSubTabAssign:[],
    GhSubTabs:[
        {id:1, value:i18n.t('Liderazgo'), slug: 'quiz-liderazgo', call: 'initial_itinerary'},
        {id:2, value:i18n.t('Estrategia'), slug: 'quiz-estrategia', call: 'initial_itinerary'},
        {id:3, value:i18n.t('Personas'), slug: 'quiz-personas', call: 'initial_itinerary'},
        {id:4, value:i18n.t('Alianzas y recursos'), slug: 'quiz-alianza-recursos', call: 'initial_itinerary'},
        {id:5, value:i18n.t('Procesos, productos y servicios'), slug: 'quiz-procesos', call: 'initial_itinerary'},
        {id:6, value:i18n.t('Indicadores y resultados'), slug: 'quiz-indicadores-resultados', call: 'initial_itinerary'},
    ],
    GhSubTabsSearch:[
        {id:1,value:i18n.t('itineraries'), call: 'result_itineraries'}
    ],
    GhSubTabsConfiguration:[
        {id:1, value:i18n.t('general'), call: 'config_general'},
        {id:2, value:i18n.t('exercises'), call: 'config_exercises'},
        {id:3, value:i18n.t('environment'), call: 'config_around'},
        {id:4, value:i18n.t('support'), call: 'config_support'},
    ],
    GhSubTabsCreation:[
        {id:1, value:i18n.t('activities'), call: 'list_creation_activities'},
        {id:2, value:i18n.t('certificates'), call: 'list_creation_certificates'},
        {id:3, value:i18n.t('ambits'), call: 'list_creation_ambits'},
        {id:4, value:i18n.t('itineraries'), call: 'list_creation_itineraries'},
    ],
    GhTabs:[
        {id: 1, value: i18n.t('todo')},
        {id: 2, value: i18n.t('global')},
        {id: 3, value: i18n.t('my_itineraries')},
        {id: 4, value: i18n.t('my_situation')},
        {id: 5, value: i18n.t('company')},
        {id: 6, value: i18n.t('users')},
        {id: 7, value: i18n.t('configuration')},
        {id: 8, value: i18n.t('searcher')},
        {id: 9, value: i18n.t('gh_admin')},
        {id: 10, value: i18n.t('support')},
        {id: 11, value: i18n.t('perms')},
        {id: 12, value: i18n.t('create')},
        {id: 13, value: i18n.t('billing')},
        {id: 14, value: i18n.t('user_file')},
        {id: 15, value: i18n.t('actions')},
        {id: 16, value: i18n.t('evaluation')},
        {id: 17, value: i18n.t('tutorships')},
        {id: 18, value: i18n.t('data_evaluated_tutored')},
		{id: 19, value: i18n.t('assignment')}
    ],
    routes
});

export default router;
