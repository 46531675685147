<template>
    <div class="gh-content-global">
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabAction.data_company && TabAction.data_company.name ? TabAction.data_company.name : ''}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('social_reason') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabAction.data_company && TabAction.data_company.text_social_reason ? TabAction.data_company.text_social_reason : ''}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('cif') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; min-width: 100px;">{{ TabAction.data_company && TabAction.data_company.cif?TabAction.data_company.cif :''}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('full_address') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabAction.data_company && TabAction.data_company.direction ? TabAction.data_company.direction : ''}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('country') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{  TabAction.data_company && TabAction.data_company.text_country ? TabAction.data_company.text_country : ''}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('email') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{ TabAction.data_company && TabAction.data_company.email ? TabAction.data_company.email : ''}}</label>
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('telephone') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabAction.data_company && TabAction.data_company.telephone ? TabAction.data_company.telephone : ''}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('activity_sector') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 18%; min-width: 100px;">{{TabAction.data_company && TabAction.data_company.text_activity ? TabAction.data_company.text_activity : ''}}</label>
        </div>
        <div class="gh-row-content">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('number_employees') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{ TabAction.data_company && TabAction.data_company.text_num_employee ? TabAction.data_company.text_num_employee : ''}}</label>
        </div>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    export default {
          name: "view_company_file",
          computed:{
              ...mapState(['TabAction'])
          },
          data(){
              return{
                haveData:true,
              }
          },
    }
</script>

