import ConfigProcedure from "@/view/config/general/procedure/template/procedure";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/general/procedure',
        name: 'config_procedure',
        component: ConfigProcedure,
        props: {id: null, showMenu: true, otherTitle: i18n.t('procedure')},
        children: [
            {
                path: '/config/general/procedure/:id',
                name: 'config_procedure_id',
                component: ConfigProcedure,
                props: {id: null, showMenu: true, otherTitle: i18n.t('procedure')},
            },
            {
                path: '/config/general/procedure/:id/:view',
                name: 'config_procedure_id_view',
                component: ConfigProcedure,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('procedure')},
            }
        ]
    }
];

export default routes;