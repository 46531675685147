import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
Vue.use(vuex);
export default {
    state: {
        tabs_assign: '',
        subTabActiveAssign: '',
        nameSubTabActiveAssign: '',
        idActiveItineraryAssign: '',
        valueActiveItineraryAssign: '',
        tableAssignItinerary: [],
    },
    mutations: {
        loadTabsAssign(state,payload){
            state.tabs_assign = payload;
        },
        loadSubActiveAssignItem(state, payload) {
            state.subTabActiveAssign = payload
        },
        loadNameSubActiveAssignItem(state,payload){
            state.nameSubTabActiveAssign = payload
        },
        loadidActiveItineraryAssign(state,payload){
            state.idActiveItineraryAssign = payload
        },
        loadValueActiveItineraryAssign(state,payload){
            state.valueActiveItineraryAssign = payload
        },
        loadTableAssignItinerary(state,payload){
            state.tableAssignItinerary = payload
        },
    },
    actions: {
        async getTabsAssign(state){
            await axios.get(
                API_URL + "tabs_assign"
            ).then(
                response => {
                    console.log(response);
                    state.commit('loadTabsAssign', response.data.data);
                }
            )
        },
        async getAssignItinerary(state,item){
            console.log(item);
            await axios.get(
                API_URL + "assign_itinerary"
            ).then(
                response => {
                    console.log(response);
                    state.commit('loadTableAssignItinerary', response.data.data);
                }
            )
        },
        getAssingTabActive(state,item){
            let idTab = '';
            let name = '';
            let id = item.id;
            let value = item.value;

            router.options.routes.forEach((items,index) => {
                if ((item.call == items.name && item.call)  || items.name == item.value){
                    idTab = router.options.routes[index].component;
                    name = items.name
                }
            });

            state.commit("loadSubActiveAssignItem", idTab != '' ? idTab : '');
            state.commit("loadNameSubActiveAssignItem", name != '' ? name : '');
            state.commit("loadidActiveItineraryAssign", id != '' ? id : '');
            state.commit("loadValueActiveItineraryAssign", value != '' ? value : '');
        },
    }
}
