import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);
export default{
    state: {
        counters: '',
        data:'',
    },
    mutations: {
        loadCounters(state, payload) {
            state.counters = payload;
        },
        loadPetitionData(state, payload) {
            state.data = payload;
        },
    },
    actions: {
        setResolveIncidence(state,items){
            let requestLogin = API_URL + "support/tickets/" + items.id + "/review";
            let formData = new FormData();

            items.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: 'post',
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == 200) {
                        state.commit("loadPetitionData", '');
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setSupportIncidence(state,items){
            let apiState= items.state !== undefined && items.state !== '' ? '/' + items.state : '';

            let requestLogin = items.id ? API_URL + "support/tickets/" + items.id + apiState : API_URL + "support/tickets";

            let formData = new FormData();

            formData.append('incidence[id]', items.id);
            items.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });
      
            axios({
                method: 'post',
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status == 200) {
                        state.commit("loadPetitionData", '');
                        if(items.origin_id){
                            window.opener.document.getElementById('label_incidence_reference').innerText = response.data.data.ticket_reference;
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPetitionData(state,items){
            if(items.id === undefined && !items.add){
                state.commit("loadPetitionData", '');
            }else {
                let requestLogin = items.add ? API_URL + "support/tickets/parameters" : API_URL + "support/tickets/"+items.id;
                let formData = new FormData();
                await axios({
                    method: "get",
                    url: requestLogin,
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(
                    response => {
                        state.commit("loadPetitionData", response.data.data);
                    },
                    error => {
                        console.log(error);
                    }
                )
            }
        },
        exportTypeExcel(state, items){
            let requestLogin = '';
            if(items.where == 'support'){
                requestLogin = items.type ? API_URL + 'support/indicators/export-monthly-reports' : API_URL + 'support/indicators/export-annual-reports';
            }else{
                requestLogin = items.type ? API_URL + 'actions/indicators/export-monthly-reports' : API_URL + 'actions/indicators/export-annual-reports';
            }
            axios({
                method: "get",
                url: requestLogin,
                responseType: 'blob'
            }).then(
                response => {
                    const url = window.URL.createObjectURL(new Blob([response.data],{
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '');
                    document.body.appendChild(link);
                    link.click();
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSupportCounters(state){
            await axios.get(
                API_URL + "support/counters"
            ).then(
                response => {
                    state.commit('loadCounters', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
};
