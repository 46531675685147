import Todo from "@/view/todo/list_todo";
import EjecuteItinerary from "@/view/todo/template/ejecute_itinerary";
import CompanyAssing from "@/view/todo/template/company_assing";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/todo',
        name: i18n.t('todo'),
        component: Todo,
        props: {showMenu: true},
    },
    {
        path: '/todo/ejecute_itinerary/:id',
        name: 'ejecute_itinerary_id',
        component: EjecuteItinerary,
        props: {id: null, showMenu: true, otherTitle: i18n.t('questionary')},
    },
    {
        path: '/todo/company_assing/:id',
        name: 'company_assing_id',
        component: CompanyAssing,
        props: {id: null, showMenu: true, otherTitle: i18n.t('assign')},
    },
];

export default routes;