import ConfigHolidays from "@/view/config/support/holidays/template/holidays";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/support/holidays',
        name: 'holidays',
        component: ConfigHolidays,
        props: {id: null, showMenu: true, otherTitle: i18n.t('holidays')},
        children: [
            {
                path: '/config/support/holidays/:id',
                name: 'holidays_id',
                component: ConfigHolidays,
                props: {id: null, showMenu: true,  otherTitle: i18n.t('holidays')},
            },
            {
                path: '/config/support/holidays/:id/:view/',
                name: 'holidays_view',
                component: ConfigHolidays,
                props: {id: null, showMenu: true,  otherTitle: i18n.t('holidays')},
            },
        ]
    }
];
export default routes;
