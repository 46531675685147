var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[(_vm.show)?_c('GhNoDropSelector',{attrs:{"datablock":{id: 'around',title: _vm.$t('environment')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveData)?_c('form',{attrs:{"id":"form_around"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-bottom":"-10px"}},[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'label_around_language',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_environment_language') + ':',
                                text: _vm.$t('environment_language') + '*',
                                label: _vm.$t('environment_language'),
                                type: 'radio',
                                columns: 3,
                                style: 'margin-left:94px;',
                                required: true,
                            },"datainput":{
                                id: 'value_around_language',
                                name: 'around[language]'
                            },"selected_input":_vm.ConfigAround.data.language ? _vm.ConfigAround.data.language : [],"feed":_vm.getFeeds.feed_languages}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-bottom":"-10px"}},[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'label_around_pallette',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_environment_palette') + ':',
                                text: _vm.$t('environment_palette') + '*',
                                label: _vm.$t('environment_palette'),
                                type: 'radio',
                                columns: 3,
                                style: 'margin-left:99px;',
                                required: true,
                            },"datainput":{
                                id: 'value_around_pallette',
                                name: 'around[pallette]'
                            },"selected_input":_vm.ConfigAround.data.pallette ? _vm.ConfigAround.data.pallette : [],"feed":_vm.getFeeds.feed_pallette}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-bottom":"-10px"}},[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'label_around_font',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_environment_font') + ':',
                                text: _vm.$t('environment_font') + '*',
                                label: _vm.$t('environment_font'),
                                type: 'radio',
                                columns: 3,
                                style: 'margin-left:96px;',
                                required: true,
                            },"datainput":{
                                id: 'value_around_font',
                                name: 'around[font]'
                            },"selected_input":_vm.ConfigAround.data.font ? _vm.ConfigAround.data.font : [],"feed":_vm.getFeeds.feed_font}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                                id: 'example',
                                class: '',
                                name_hidden: 'around[image]',
                                id_hidden: 'example',
                                icon_attach: require('../../../assets/gh_new_dessign/attach.svg')
                            },"datalabel":{
                                id: 'label_import_block',
                                style: 'margin-left:65px;'
                            },"datainput":{
                                text: _vm.$t('attach_company_logo'),
                                show_image: true,
                                delete: false,
                                href: 'easyUpload',
                                downloadHref: 'easyDownload',
                                file_name: _vm.ConfigAround.data.file_name,
                                file_path: _vm.ConfigAround.data.file_path,
                                file_download: _vm.ConfigAround.data.file_download,
                                image_path: _vm.ConfigAround.data.image_path,
                                required: true
                            }}})],1)])]):_vm._e()]},proxy:true}],null,false,304875471)}):_vm._e(),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[(!_vm.$root._route.params.view)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save'),
            }},on:{"click":_vm.submitAround}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }