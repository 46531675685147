var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"12px"}},[_c('GhTableCommon',{attrs:{"extratable":{id: 'actions_acient_process'},"header":_vm.header,"data":_vm.TabAction.data_historic_actions !== undefined && _vm.TabAction.data_historic_actions.length !== 0 ? _vm.TabAction.data_historic_actions : _vm.TabAction.records && _vm.TabAction.records.added && _vm.TabAction.records.added.length !== 0 ? _vm.TabAction.records.added : ''},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('a',{staticClass:"table-cell_link T13",on:{"click":function($event){return _vm.openHistoricCompany(_vm.id,itemProp.id)}}},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])]}}])}),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'view_company_historical_popup',
            title: _vm.$t('state'),
            type: 'add',
            style: 'width: 50%; min-width:920px;'
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global"},_vm._l((_vm.TabAction.data_historic),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(item.name + ':'))]),(item.type == 'text')?_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","width":"auto","min-width":"100px"}},[_vm._v(_vm._s(item.value))]):_c('GhAction',{attrs:{"dataction":{id:'_download_' + index, href: item.file_url, text: item.file_name}}})],1)}),0)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_situation',
                    text: _vm.$t('close')
                }},on:{"click":function($event){return _vm.Global.closePopUp( 'view_company_historical_popup')}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }