import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: {
            all: ''
        },
        typeAllButton: false,
        processAllPay: false,
        itemsAllPay: []
    },
    mutations: {
        loadAllItinerariesData(state, payload){
            state.records_data.all = payload;
        },
        loadAllTypeButton: (state, payload) => (state.typeAllButton = payload),
        loadAllProcessPay: (state, payload) => (state.processAllPay = payload),
        loadAllItemsPay: (state, payload) => (state.itemsAllPay = payload),
    },
    actions: {
        async getAllItinerariesToBuy(state){
            let requestLogin = API_URL + "all_payments";
            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadAllItinerariesData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        setAllItinerariesPay(state, item) {
            let requestLogin = API_URL + "payments"

            console.log('hi',item);

            axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    console.log(response);
                    await this.dispatch('getAllItinerariesToBuy');
                    state.commit("loadAllTypeButton", false);
                    state.commit("loadAllProcessPay", false);
                    state.commit('loadAllItemsPay', []);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};