import Vue from 'vue'
import App from './App.vue'
import router from './routes/router'
import store from "./store";
import Global from './store/Global.js';
import i18n from '@/plugins/i18n';
import axios from 'axios';

Vue.prototype.Global = Global;
Vue.prototype.router = router;
Vue.prototype.i18n = i18n;
Vue.config.productionTip = false

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

store.subscribe( (mutation, state) => {
    localStorage.setItem('MainItinerary', JSON.stringify(state.MainItinerary));
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
