<template>
    <div style="padding-bottom: 12px;">
        <GhSubTab v-bind:items="$router.options.GhSubTabsCreation" :dataBlock="{id: 'creation_tab_', calldispatch: 'getCreationTabActive'}"/>
        <component v-bind:is="TabCreation.subTabActive" />
    </div>
</template>

<script>
    import GhSubTab from "fe-gh-subtab-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_creation",
        components:{
          GhSubTab,
        },
        computed:{
          ...mapState(['TabCreation']),
        }
    }
</script>