var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'drop_selector_section_itinerary',
            title: _vm.$t('assignment'),
        }},scopedSlots:_vm._u([(_vm.haveData)?{key:"content",fn:function(){return [_c('GhChartTable',{attrs:{"extratable":{
                    id: 'itinerary_table_assign'
                },"header":_vm.header,"data":_vm.AssignItinerary.records_data ? _vm.AssignItinerary.records_data.data : [],"footer":_vm.AssignItinerary.records_data ? _vm.AssignItinerary.records_data.footer : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                            id: 'action_assign_' + itemProp.id,
                            text: _vm.$t('assignment'),
                            key_show_condition: 'action',
                            value_show_condition: false,
                            item_prop: itemProp
                        }},on:{"click":function($event){return _vm.openAssignPopup(itemProp)}}})]}}],null,false,683917648)})]},proxy:true}:null],null,true)}),_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_user_return',
                text: _vm.$t('back'),
            }},on:{"click":function($event){return _vm.router.go(-1)}}})],1),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
            id: 'assign_popup',
            title: _vm.$t('assignment'),
            type: 'warning',
            style: 'width: 600px; min-width:600px;',
        },"bodyConfiguration":{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important;'
        },"footerConfiguration":{
            id: '',
            class: '',
            style: '',
            content: ''
        }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"T21",staticStyle:{"margin-left":"15px","margin-right":"20px"}},[_vm._v(" "+_vm._s(_vm.$t('to_assign_itinerary') + ':')+" ")]),_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"padding":"0px","margin-left":"-10px","margin-right":"-10px"}},[_c('div',{staticStyle:{"display":"flex","margin-top":"3px","padding-top":"10px","padding-bottom":"10px"}},[_c('div',{staticClass:"T15 label_span-2",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.$t('itinerary') + ':')+" ")]),_c('div',{staticClass:"T18",staticStyle:{"padding-left":"10px","width":"calc(100% / 4 * 3)"}},[_vm._v(" "+_vm._s(_vm.itinerario.title)+" ")])])]),_c('form',{key:_vm.cleanPopUp,attrs:{"id":"form_assign_users"}},[_c('div',{staticClass:"gh-content-global"},[_c('input',{attrs:{"type":"hidden","name":"assign[id]"},domProps:{"value":_vm.itinerario.id}}),_c('div',{staticClass:"gh-row-content"},[_c('GhMultipleSearch',{attrs:{"datalabel":{
                                text: _vm.$t('execution') + '*',
                                style: 'min-width: 95px; width: 95px; text-align: right',
                                class: 'T15_b',
                                id: 'label_assign_ejecution'
                            },"datainput":{
                                required: true,
                                id: 'assign_execution_user',
                                name: 'assign[execution][name]',
                                nameS:'assign[execution][surname]',
                                nameId:'assign[execution][id]',
                                style: 'width: 40%',
                                value: _vm.AssignItinerary.popup_data ? _vm.AssignItinerary.popup_data.execution.first_name : '',
                                valueId: _vm.AssignItinerary.popup_data ? _vm.AssignItinerary.popup_data.execution.id : '',
                                valueS: _vm.AssignItinerary.popup_data ? _vm.AssignItinerary.popup_data.execution.last_name : '',
                                placeholder: _vm.$t('name'),
                                placeholderS: _vm.$t('surnames'),
                                result_1: 'first_name',
                                result_2: 'last_name',
                                result_id: 'id',
                                href: _vm.dispatchUserExecution
                            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhMultipleSearch',{attrs:{"datalabel":{
                                text: _vm.$t('check_2') + '*',
                                style: 'min-width: 95px; width: 95px; text-align: right',
                                class: 'T15_b',
                                id: 'label_assign_revision'
                            },"datainput":{
                                required: true,
                                id: 'assign_revision_user',
                                name:'assign[revision][name]',
                                nameS:'assign[revision][surname]',
                                nameId:'assign[revision][id]',
                                style: 'width: 40%',
                                value: _vm.AssignItinerary.popup_data ? _vm.AssignItinerary.popup_data.revision.first_name : '',
                                valueId: _vm.AssignItinerary.popup_data ? _vm.AssignItinerary.popup_data.revision.id : '',
                                valueS: _vm.AssignItinerary.popup_data ? _vm.AssignItinerary.popup_data.revision.last_name : '',
                                placeholder: _vm.$t('name'),
                                placeholderS: _vm.$t('surnames'),
                                result_1: 'first_name',
                                result_2: 'last_name',
                                result_id: 'id',
                                href: _vm.dispatchUserRevision
                            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhMultipleSearch',{attrs:{"datalabel":{
                                text: _vm.$t('validation') + '*',
                                style: 'min-width: 95px; width: 95px; text-align: right',
                                class: 'T15_b',
                                id: 'label_assign_validation'
                            },"datainput":{
                                required: true,
                                id: 'assign_validation_user',
                                name:'assign[validation][name]',
                                nameS:'assign[validation][surname]',
                                nameId:'assign[validation][id]',
                                style: 'width: 40%',
                                value: _vm.AssignItinerary.popup_data ? _vm.AssignItinerary.popup_data.validation.first_name : '',
                                valueId: _vm.AssignItinerary.popup_data ? _vm.AssignItinerary.popup_data.validation.id : '',
                                valueS: _vm.AssignItinerary.popup_data ? _vm.AssignItinerary.popup_data.validation.last_name : '',
                                placeholder: _vm.$t('name'),
                                placeholderS: _vm.$t('surnames'),
                                result_1: 'first_name',
                                result_2: 'last_name',
                                result_id: 'id',
                                href: _vm.dispatchUserValidation
                            }}})],1)])])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_accept_assign_popup',
                    text: _vm.$t('assign')
                }},on:{"click":_vm.saveAsignation}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_close_assign_popup',
                    text: _vm.$t('close')
                }},on:{"click":function($event){return _vm.Global.closePopUp('assign_popup');}}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }