<template>
    <div v-if="haveData == true" style="padding-bottom: 12px;">
        <div v-for="label in section" :key="label.id">
            <GhNoDropSelector
                :datablock="{
                    id: label.id,
                    title: $t(label.title),
                    num_results: '',
                    label: label.name == 'historical' ? `${TabAction.data !== undefined && TabAction.data.historical_company !== undefined && TabAction.data.historical_company.length !== 0 ? TabAction.data.historical_company.length : 0 } ${'results'}` : '',
                }"
            >
                <template v-slot:content>
                    <component v-if="haveData == true" v-bind:is="label.component" :id="$root._route.params.id" />
                </template>
            </GhNoDropSelector>
        </div>
        <template  v-if="!$root._route.params.view">
            <div v-for="(popup,index) in sectionPopup" :key="index">
                <GhPopUp v-show="false"
                    :generalConfiguration="{
                        id: popup.id,
                        title: $t(popup.title),
                        type: 'add',
                        style: 'width: 50%; min-width:920px;',
                    }"
                    :bodyConfiguration="{
                        id: '',
                        class: '',
                        style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
                    }"
                    :footerConfiguration="{
                        id: '',
                        class: '',
                        style: '',
                        content: ''
                    }"
                >
                    <template v-slot:body>
                        <form :id="popup.form">
                            <component v-bind:is="popup.component" />
                        </form>
                    </template>
                    <template v-slot:footer>
                        <GhButton
                            :datainput="{
                                id: 'btn_situation',
                                text: $t('add'),
                            }"
                            @click="saveAction(popup.id,popup.form)"
                        />
                        <GhButton
                            :datainput="{
                                id: 'btn_situation',
                                text: $t('close'),
                            }"
                            @click="Global.closePopUp(popup.id)"
                        />
                    </template>
                </GhPopUp>
            </div>
        </template>
        <div class="centered_footer" style="margin-top: 8px;">
            <router-link :to="{name: 'my_situation_view', params: {id:$root._route.params.id,view:true}}">
                <GhButton
                    :datainput="{
                        id: 'btn_situation',
                        text: $t('my_situation'),
                    }"
                />
            </router-link>
            <GhButton
                v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_call',
                    text: $t('call'),
                }"
                @click="Global.openPopUp('action_company_file_call_popup')"
            />
            <GhButton
                v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_mail',
                    text: $t('mail'),
                }"
                @click="Global.openPopUp('action_company_file_mail_popup')"
            />
            <GhButton
                :datainput="{
                    id: 'btn_mail',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>
<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import CompanyFile from './company_file/view_company_file.vue'
    import CompanyHistoric from './company_historic/view_company_historic.vue'
    import GhButton from "fe-gh-button-lib";
    import GhPopUp from "fe-gh-popup-lib";
    import Llamada from './popup/llamada.vue'
    import Mail from './popup/mail.vue'
    import {mapState} from "vuex";
    export default {
        name: "company_file",
        components: {
            GhPopUp,
            GhButton,
            GhNoDropSelector
        },
        computed:{
            ...mapState(['TabAction'])
        },
        data(){
            return{
                haveData:true,
                sectionPopup:[
                    {id:'action_company_file_call_popup', title: this.$t('call_2'), component: Llamada, form:'form_call', name: 'call'},
                    {id:'action_company_file_mail_popup', title: this.$t('mail'), component: Mail, form:'form_mail', name: 'mail'}
                ],
                section:[
                    {id:'action_company_file', title: this.$t('company'), component: CompanyFile, name: 'company'},
                    {id:'action_company_historic_file', title: this.$t('historical'), component: CompanyHistoric, name: 'historical'}
                ]
            }
        },
        async beforeMount() {
            await this.$store.dispatch('getActionCompanyData', {id:this.$root._route.params.id},{root:true});
            await this.$store.dispatch('getActionCompanyHistoricActions', {id:this.$root._route.params.id},{root:true});
            this.haveData = true;
        },
        methods:{
            saveAction(id,form){
                if(this.Global.checkRequired(id) == 'OK') {
                    const formData = new FormData(document.getElementById(form));
                    this.$store.dispatch('setHistoricCompany', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                    this.Global.closePopUp(id);
                }
            },
        }
    }
</script>

