<template>
    <div v-if="haveData">
        <div class="section_line" style="margin-bottom: 15px">
            <div class="T15_b">{{$t('references')}}</div>
        </div>
        <div class="gh-row-content" v-for="(item,index) in thesauros" :key="index">
            <div v-for="(subitem,subindex) in item" :key="'sub_' + subindex" class="no-default-width">
                <GhTreeSelectorBlock
                    :dataBlock="{
                        type: 'checkbox',
                        id: 'label_reference_' + subitem.name,
                        label: $t(subitem.text),
                        title: $t(subitem.text),
                        text: $t('select') + ' ' + subitem.text + ':',
                        name: props.name + '[' + subitem.name + '][]',
                        id_value: 'value',
                        name_label: 'text',
                        child_key: 'children',
                        required: false,
                        checkparentdata: true,
                        style: '',
                        label_style: 'width: 149px',
                        popupStyle: 'min-width: 900px;width: 55%;',
                    }"
                    :selected_input="props.selecteds[subitem.name] !== undefined ? props.selecteds[subitem.name] : []"
                    :items="subitem.feed"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import GhTreeSelectorBlock from "fe-gh-tree-selector-block-lib";

    export default {
        name: "reference_thesauros",
        props:['props'],
        components: {GhTreeSelectorBlock},
        computed: {
            ...mapState(['getFeeds'])
        },
        data(){
            return {
                haveData: false,
                thesauros: [],
                tmp:[
                    {
                        'id': 1,
                        'name': 'ejes',
                        'text': 'Ejes',
                        'feed': [
                            {'value':1,'text':'Eje 1 - Nivel 1','children':[{'value':2,'text':'Eje 1 - Nivel 2'}]},
                            {'value':3,'text':'Eje 2 - Nivel 1','children':[{'value':4,'text':'Eje 2 - Nivel 2','children':[{'value':5,'text':'Eje 3 - Nivel 2'}]}]}
                        ]
                    },
                    {
                        'id': 2,
                        'name': 'sectores',
                        'text': 'Sectores',
                        'feed': [
                            {'value':1,'text':'Sector 1 - Nivel 1','children':[{'value':2,'text':'Sector 1 - Nivel 2'}]},
                            {'value':3,'text':'Sector 2 - Nivel 1','children':[{'value':4,'text':'Sector 2 - Nivel 2','children':[{'value':5,'text':'Sector 3 - Nivel 2'}]}]}
                        ]
                    },
                    {
                        'id': 3,
                        'name': 'referenciales',
                        'text': 'Referenciales',
                        'feed': [
                            {'value':1,'text':'Referenical 1 - Nivel 1','children':[{'value':2,'text':'Referenical 1 - Nivel 2'}]},
                            {'value':3,'text':'Referenical 2 - Nivel 1','children':[{'value':4,'text':'Referenical 2 - Nivel 2','children':[{'value':5,'text':'Referenical 3 - Nivel 2'}]}]}
                        ]
                    },
                    {
                        'id': 4,
                        'name': 'arboresencias',
                        'text': 'Arboresencias',
                        'feed': [
                            {'value':1,'text':'Arboresencia 1 - Nivel 1','children':[{'value':2,'text':'Arboresencia 1 - Nivel 2'}]},
                            {'value':3,'text':'Arboresencia 2 - Nivel 1','children':[{'value':4,'text':'Arboresencia 2 - Nivel 2','children':[{'value':5,'text':'Arboresencia 3 - Nivel 2'}]}]}
                        ]
                    }
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedExercisesWithoutSubquestions','',{root:true});

            var array_tmp = [];
            var array = [];
            for(var i = 0;i < this.getFeeds.feed_reference_thesauros.length;i++){
                array_tmp.push(this.tmp[i]);
                if(i%3 == 2 || i == this.getFeeds.feed_reference_thesauros.length - 1){
                    array.push(array_tmp);
                    array_tmp = [];
                }
            }

            this.thesauros = array;
            this.haveData = true;
        }
    }
</script>

<style>
    .no-default-width > div{
        min-width: 200px!important;
        width: 30%;
    }
</style>