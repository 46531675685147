var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"15%"}},[_c('div',{staticStyle:{"width":"250px","height":"135px"}},[_c('img',{staticStyle:{"border-radius":"6px","max-height":"135px","width":"auto"},attrs:{"src":_vm.image,"width":"250"}})]),_c('div',{staticClass:"other-image"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                    id: 'example_other_image',
                    class: '',
                    name_hidden: 'example[other_image]',
                    id_hidden: 'example_other_image',
                    icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                },"datalabel":{
                    id: 'example_other_image',
                    style: 'margin-right: -15px'
                },"datainput":{
                    view: _vm.$root._route.params.view,
                    accept: '.png, .jpg, .jpeg',
                    text: _vm.$t('modify_image'),
                    show_image: false,
                    delete: false,
                    href: 'easyUpload',
                    downloadHref: 'easyDownload',
                    file_name: '',
                    file_path: '',
                    file_download: '',
                    required: false
                }}})],1)]),_c('div',{staticClass:"gh-content-global",staticStyle:{"width":"85%"}},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                        text: _vm.$t('code') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: true,
                        id: 'example_code',
                        name: 'example[code]',
                        style: 'width: 200px',
                        value: _vm.ConfigExamples.data.code,
                        type: 'text'
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigExamples.data.code))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                        text: _vm.$t('name') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: true,
                        id: 'example_name',
                        name: 'example[name]',
                        style: 'min-width: 200px; width: 20%',
                        value: _vm.ConfigExamples.data.name,
                        type: 'text',
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigExamples.data.name))])]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'margin-bottom: -5px')},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                        id: 'label_example_sector',
                        class: 'T15_b',
                        subtitle: _vm.$t('select_sector') + ':',
                        text: _vm.$t('sector') + '*',
                        label: _vm.$t('sector'),
                        type: 'radio',
                        columns: 3,
                        style: 'margin-left:94px;',
                        required: true,
                    },"datainput":{
                        id: 'value_example_sector',
                        name: 'example[sector]'
                    },"selected_input":_vm.ConfigExamples.data.sector ? _vm.ConfigExamples.data.sector : [],"feed":_vm.getFeeds.feed_sector}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('sector') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigExamples.data.text_sector))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhTextarea',{attrs:{"datalabel":{
                        text: _vm.$t('description'),
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    },"datainput":{
                        id: 'example_description',
                        name: 'example[description]',
                        value: _vm.ConfigExamples.data.description
                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigExamples.data.description))])]],2),_c('div',{staticClass:"gh-row-content",style:(_vm.$root._route.params.view ? '' : 'flex-flow: row-reverse;')},[(_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('attached_file') + ' (PDF)' + ':'))])]:_vm._e(),_c('GhEasyUpload',{attrs:{"extrablock":{
                    id: 'example',
                    class: '',
                    name_hidden: 'example[document]',
                    id_hidden: 'example',
                    icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                },"datalabel":{
                    id: 'label_import_block',
                    style: 'margin-right: -15px'
                },"datainput":{
                    view: _vm.$root._route.params.view,
                    accept: '.pdf, .png, .jpg, .jpeg',
                    text: _vm.$t('attached_file')  + ' (PDF/Img)',
                    show_image: false,
                    delete: false,
                    href: 'easyUpload',
                    downloadHref: 'easyDownload',
                    file_name: _vm.ConfigExamples.data.file_name,
                    file_path: _vm.ConfigExamples.data.file_path,
                    file_download: _vm.ConfigExamples.data.file_download,
                    required: _vm.link_http !== '' ? false : true
                }}})],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefLink",attrs:{"datalabel":{
                        text: _vm.$t('link'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: false,
                        id: 'example_link',
                        name: 'example[link]',
                        style: 'width: 500px',
                        value: _vm.link_http,
                        type: 'text',
                    }},on:{"keyup":function($event){return _vm.setLink()}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('link') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.link_http))])]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }