<template>
    <div class="float_window">
        <form id="form_search" :key="refresh">
            <div class="gh-content-global">
                <input type="hidden" name="search[type]" :value="this.hiddenvalue[this.$store.state.TabSearch.nameSubTabActive] ? this.hiddenvalue[this.$store.state.TabSearch.nameSubTabActive]:''">
                <div class="gh-row-content">
                    <GhInputTextField
                        :datalabel="{
                            id: 'label_search_name',
                            text: $t('name'),
                            style: 'width: 88px; min-width: 88px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            id: 'input_search_name',
                            name: 'search[name]',
                            style: 'width:280px; min-width:280px; max-height: 17px;',
                            type: 'text',
                        }"
                    />
                    <GhCalendar
                        :datalabel="{
                            id: 'label_search_start_date',
                            text: $t('date_start'),
                            style: 'width:230px; min-width:230px; text-align: right;'
                        }"
                        :datainput="{
                            id: 'input_search_start_date',
                            name: 'search[start_date]',
                            value: '',
                        }"
                    />
                    <GhCalendar
                        :datalabel="{
                            id: 'label_search_date_end',
                            text: $t('date_end'),
                            style: 'width:85px;text-align: right;'
                        }"
                        :datainput="{
                            id: 'input_search_end_date',
                            name: 'search[end_date]',
                            value: '',
                        }"
                    />
                </div>
                <div class="gh-row-content" style="padding-top: 15px; padding-bottom: 20px;">
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_and',
                            value: 'and',
                            type: 'radio',
                            name: 'search[criterio]',
                            style: 'margin-left:35px;',
                        }"
                        v-bind:datalabel="{
                            style: 'width: calc((100% - 183px) - (((100% - 280px) * 8) / 15));',
                            class: 'T15 input_label',
                            id: 'label_and',
                            text: $t('all_search_criteria_entered')
                        }"
                        @click="selectRadio('GhRadioAND')"
                        ref="GhRadioAND"
                    />
                    <GhChecks
                        v-bind:datainput="{
                            id: 'radio_or',
                            value: 'or',
                            type: 'radio',
                            name: 'search[criterio]',
                        }"
                        v-bind:datalabel="{
                            style: 'margin-left: 4px;',
                            class: 'T15 input_label',
                            id: 'label_or',
                            text: $t('any_search_criteria_entered')
                        }"
                        ref="GhRadioOR"
                        @click="selectRadio('GhRadioOR')"
                    />
                </div>
            </div>
        </form>
        <div class="centered" style="padding-bottom: 10px;">
            <GhButton :datainput="{id: 'btn_save', class: 'T19 container-md_button_content', text: $t('search')}" @click="search()"/>
            <GhButton :datainput="{id: 'btn_return',class: 'T19 container-md_button_content', text: $t('clean')}" @click="clean()"/>
        </div>
    </div>
</template>
<script>
    import GhCalendar from 'fe-gh-input-calendar-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhChecks from 'fe-gh-checks-lib';
    import GhButton from 'fe-gh-button-lib'
    import {mapState} from "vuex";
    export default {
        name: "view_search",
        components:{GhInputTextField,GhChecks,GhButton,GhCalendar},
        data(){
            return{
                refresh: 0,
                hiddenvalue:{
                    'Itinerarios': 'itineraries',
                }
            }
        },
        computed:{
            ...mapState['TabSearch']
        },
        methods:{
            selectRadio(value){
                if (this.$refs[value].$el.children[0].children[1].checked != true){
                    this.$refs[value].checkUncheck();
                }
            },
            clean(){
                this.$store.dispatch('destroyedResultData','',{root:true});
                document.getElementById("form_search").reset();
                this.refresh++;
            },
            async search(){
                const formData = new FormData(document.getElementById('form_search'));
                await this.$store.dispatch('searchData',{formData: [...formData]},{root:true});
            }
        }
    }
</script>

<style>
    @import "~fe-gh-input-calendar-lib/dist/fe-gh-input-calendar-lib.css";
</style>
