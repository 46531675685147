<template>
    <div style="padding-top: 12px;">
        <GhTableCommon
            :extratable="{id: 'actions_acient_process'}"
            :header="header"
            :data="TabAction.data_historic_actions !== undefined && TabAction.data_historic_actions.length !== 0 ? TabAction.data_historic_actions : TabAction.records && TabAction.records.added && TabAction.records.added.length !== 0 ? TabAction.records.added : ''"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}" >
                <a class="table-cell_link T13" @click="openHistoricCompany(id,itemProp.id)"> {{itemProp[labelProp]}} </a>
            </template>
        </GhTableCommon>
        <GhPopUp v-show="false"
            :generalConfiguration="{
                id: 'view_company_historical_popup',
                title: $t('state'),
                type: 'add',
                style: 'width: 50%; min-width:920px;'
            }"
            :bodyConfiguration="{
                id: '',
                class: '',
                style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
            }"
            :footerConfiguration="{
                id: '',
                class: '',
                style: '',
                content: ''
            }"
        >
            <template v-slot:body>
                <div class="gh-content-global">
                    <div class="gh-row-content" v-for="(item,index) in TabAction.data_historic" :key="index">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{item.name + ':'}}</label>
                        <label v-if="item.type == 'text'" class="gh_text_field-label T15_b" style="margin-right: 10px; width: auto; min-width: 100px;">{{item.value}}</label>
                        <GhAction v-else :dataction="{id:'_download_' + index, href: item.file_url, text: item.file_name}"/>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <GhButton
                    :datainput="{
                        id: 'btn_situation',
                        text: $t('close')
                    }"
                    @click="Global.closePopUp( 'view_company_historical_popup')"
                />
            </template>
        </GhPopUp>
    </div>
</template>
<script>
    import GhPopUp from 'fe-gh-popup-lib'
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from 'fe-gh-button-lib'
    import {mapState} from "vuex";
    import GhAction from "fe-gh-action-lib";
    export default {
        name: "view_company_historic",
        components: {
            GhAction,
            GhTableCommon,
            GhPopUp,
            GhButton
        },
        computed:{
            ...mapState(['TabAction'])
        },
        data(){
            return{
                haveData:false,
                note:'',
                state:'',
                header: [
                    {text: this.$t('date'), field: 'date', contentAlt: 'user', urlRedirect: true,title: 'Test', sorting: true, typeSort: 'string', style:'max-width: 150px; width: 150px;'},
                    {text: this.$t('user'), field: 'user', sorting: true,typeSort: 'string', style: 'width:15%;'},
                    {text: this.$t('note'), field: 'note', sorting: true,typeSort: 'string', style: 'width:60%;'},
                    {text: this.$t('file'), field: 'file_name', sorting: true, typeSort: 'string',urlDocument:'file_download', style: 'width:15%;'},
                ],
            }
        },
        props: ['id'],
        methods:{
            async openHistoricCompany(id_company,id_register){
                await this.$store.dispatch('getActionDataHistoric', {id_company:id_company,id_register:id_register},{root:true});
                this.Global.openPopUp('view_company_historical_popup');
            }
        }
    }
</script>

