<template>
    <div>
        <GhDropDownField
            :dataBlock="{
                id: 'drop_metrics',
                style: 'width: 100%; margin-right: 0px;',
                title: $t('metrics'),
                subTitle: ConfigExercisesTable.data.metrics !== undefined ? ConfigExercisesTable.data.metrics.length + ' ' + $t('Resultados') :  0 + ' ' + $t('Resultados'),
                isDrop: false
            }"
        >
            <template v-slot:content>
                <GhTableCommon v-if="haveHeader"
                    :extratable="{
                        id: 'metrics_',
                    }"
                    :header="header"
                    :data="ConfigExercisesTable.data.metrics"
                >
                    <template v-slot:actions="{itemProp}"  v-if="!$root._route.params.view">
                        <GhAction
                            :dataction="{
                                id: 'delete_metrics',
                                text: $t('delete'),
                                icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                                item_prop: itemProp
                            }"
                            @click="Global.deleteConfirm('delete_confirm','TemporalMetrics', itemProp.id,{'Nombre': itemProp.name, 'Descripción': itemProp.description}, 'Eliminar métrica', 'Se dispone a eliminar la siguiente métrica', 'delete')"
                        />
                    </template>
                    <template v-slot:footer>
                        <div class="centered" v-if="!$root._route.params.view">
                            <GhButton
                                :datainput="{
                                    id: 'btn_add_metrics',
                                    text: $t('add')
                                }"
                                @click="openMetrics"
                            />
                        </div>
                    </template>
                </GhTableCommon>
            </template>
        </GhDropDownField>

        <component v-bind:is="metrics_popup" />
    </div>
</template>

<script>
    import metrics_popup from './popup/metrics';
    import GhDropDownField from 'fe-gh-drop-down-field-lib';
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from 'fe-gh-action-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    export default {
        name: "metrics_template",
        components: {
            metrics_popup,
            GhDropDownField,
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapState(['ConfigExercisesTable'])
        },
        data(){
            return {
                metrics_popup: metrics_popup,
                haveHeader: false,
                header: [
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 85%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
            }
        },
        mounted() {
            if(this.$root._route.params.view){
                this.header = [
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 10%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 85%;'},
                ];
            }
            this.haveHeader = true;
        },
        methods: {
            openMetrics(){
                document.getElementById('form_metrics_data').reset();
                this.Global.openPopUp('metrics_popup');
            }
        },
    }
</script>

<style>
    #drop_metrics > div > div > .definition-field_header-text{
        color: black !important;
    }

    #drop_metrics > div > div > .definition-field_header-text {
      width: 151px;
      display: flex;
      flex-flow: row-reverse;
    }
</style>