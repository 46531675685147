import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
        popup_data: '',
    },
    mutations: {
        loadAssignData(state, payload){
            state.popup_data = payload;
        },
        loadListAssignData(state, payload){
            state.records_data = payload;
        }
    },
    actions: {
        async getListAssignment(state){
            await axios.get(
                API_URL + "list_assignment"
            ).then(
                response => {
                    state.commit('loadListAssignData', response.data.data);
                }
            )
        },
        async getAssign(state, item){
            await axios.get(
                API_URL + "assign/"+item.id
            ).then(
                response => {
                    state.commit('loadAssignData', response.data.data);
                }
            )
        },
        async setUserAssign(state, item){
            let requestLogin = API_URL + "assign_save";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    return response.statusText;
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};