<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'type_exercise',title: $t('type_of_exercise')}">
            <template v-slot:content>
                <form id="form_type_exercise" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'type_exercise_code',
                                        name: 'type_exercise[code]',
                                        style: 'width: 200px',
                                        value: ConfigTypeExercise.data.code,
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data.code}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'type_exercise_name',
                                        name: 'type_exercise[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: ConfigTypeExercise.data.name,
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data.name}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                    }"
                                    :datainput="{
                                        id: 'type_exercise_description',
                                        name: 'type_exercise[description]',
                                        value: ConfigTypeExercise.data.description
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data.description}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTreeSelectorBlock
                                    :dataBlock="{
                                        type: 'radio',
                                        id: 'type_exercise_type',
                                        label: $t('type') + '*',
                                        title: $t('type'),
                                        text: $t('select_type') + ':',
                                        name: 'type_exercise[type][]',
                                        id_value: 'value',
                                        name_label: 'text',
                                        child_key: 'children',
                                        required: true,
                                        checkparentdata: false,
                                        style: '',
                                        label_style: 'width: 148px',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    }"
                                    :selected_input="ConfigTypeExercise.data.type ? ConfigTypeExercise.data.type : []"
                                    :items="getFeeds.feed_type"
                                    @accept="changeType()"
                                />
                            </template>
                            <template v-else>
                                <div style="display: flex">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data.text_type}}</label>
                                </div>
                            </template>
                        </div>
                        <div class="gh-row-content" style="margin-left: 160px;">
                            <div style="display: flex; margin-left: 160px;">
                                <div style="display: flex; width: 400px;">
                                    <GhChecks
                                        :datalabel="{text: $t('statement'), style: 'width: 130px', id: 'label_type_exercise_statement'}"
                                        :datainput="{required: false, selected: true, disabled: true, type: 'checkbox', id: 'value_type_exercise_statement', value: 1, name: 'type_exercise[config][statement][]'}"
                                    />
                                    <GhChecks
                                        :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_statement'}"
                                        :datainput="{required: false, selected: true, disabled: true, type: 'checkbox', id: 'value_type_exercise_required_statement', value: 2, name: 'type_exercise[config][statement][]'}"
                                    />
                                </div>
                                <div style="display: flex; width: 400px;">
                                    <GhChecks
                                        :datalabel="{text: $t('responsible'), style: 'width: 130px', id: 'label_type_exercise_responsible'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_responsible', value: 1, name: 'type_exercise[config][responsible][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.responsible && ConfigTypeExercise.data.config.responsible[0] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigResponsible"
                                        @click="checkGroupChecks('GhCheckConfigResponsible', 'GhCheckConfigResponsibleRequired', false)"
                                    />
                                    <GhChecks
                                        :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_responsible'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_responsible', value: 2, name: 'type_exercise[config][responsible][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.responsible && ConfigTypeExercise.data.config.responsible[1] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigResponsibleRequired"
                                        @click="checkGroupChecks('GhCheckConfigResponsible', 'GhCheckConfigResponsibleRequired', true)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content" style="margin-left: 160px;">
                            <div style="display: flex; margin-left: 160px;">
                                <div style="display: flex; width: 400px;">
                                    <GhChecks
                                        :datalabel="{text: $t('metrics'), style: 'width: 130px', id: 'label_type_exercise_metrics'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_responsible', value: 1, name: 'type_exercise[config][metrics][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.metrics && ConfigTypeExercise.data.config.metrics[0] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigMetrics"
                                        @click="checkGroupChecks('GhCheckConfigMetrics', 'GhCheckConfigMetricsRequired', false)"
                                    />
                                    <GhChecks
                                        :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_metrics'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_metrics', value: 2, name: 'type_exercise[config][metrics][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.metrics && ConfigTypeExercise.data.config.metrics[1] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigMetricsRequired"
                                        @click="checkGroupChecks('GhCheckConfigMetrics', 'GhCheckConfigMetricsRequired', true)"
                                    />
                                </div>
<!--                                <div v-if="showProcedimientos" style="display: flex; width: 400px;">-->
<!--                                    <GhChecks-->
<!--                                        :datalabel="{text: $t('Procedimientos'), style: 'width: 130px', id: 'label_type_exercise_procedures'}"-->
<!--                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_procedures', value: 1, name: 'type_exercise[config][procedures][]',-->
<!--                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.procedures && ConfigTypeExercise.data.config.procedures[0] ? true : false,-->
<!--                                            disabled: !$root._route.params.view ? false : true-->
<!--                                        }"-->
<!--                                        ref="GhCheckConfigProcedures"-->
<!--                                        @click="checkGroupChecks('GhCheckConfigProcedures', 'GhCheckConfigProceduresRequired', false)"-->
<!--                                    />-->
<!--                                    <GhChecks-->
<!--                                        :datalabel="{text: $t('Obligatorio'), style: 'width: 130px', id: 'label_type_exercise_required_procedures'}"-->
<!--                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_procedures', value: 2, name: 'type_exercise[config][procedures][]',-->
<!--                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.procedures && ConfigTypeExercise.data.config.procedures[1] ? true : false,-->
<!--                                            disabled: !$root._route.params.view ? false : true-->
<!--                                        }"-->
<!--                                        ref="GhCheckConfigProceduresRequired"-->
<!--                                        @click="checkGroupChecks('GhCheckConfigProcedures', 'GhCheckConfigProceduresRequired', true)"-->
<!--                                    />-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="gh-row-content" style="margin-left: 160px;">
                            <div style="display: flex; margin-left: 160px;">
                                <div style="display: flex; width: 400px;">
                                    <GhChecks
                                        :datalabel="{text: $t('examples'), style: 'width: 130px', id: 'label_type_exercise_examples'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_examples', value: 1, name: 'type_exercise[config][examples][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.examples && ConfigTypeExercise.data.config.examples[0] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigExamples"
                                        @click="checkGroupChecks('GhCheckConfigExamples', 'GhCheckConfigExamplesRequired', false)"
                                    />
                                    <GhChecks
                                        :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_examples'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_examples', value: 2, name: 'type_exercise[config][examples][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.examples && ConfigTypeExercise.data.config.examples[1] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigExamplesRequired"
                                        @click="checkGroupChecks('GhCheckConfigExamples', 'GhCheckConfigExamplesRequired', true)"
                                    />
                                </div>
                                <div style="display: flex; width: 400px;">
                                    <GhChecks
                                        :datalabel="{text: $t('bibliography'), style: 'width: 130px', id: 'label_type_exercise_bibliography'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_bibliography', value: 1, name: 'type_exercise[config][bibliography][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.bibliography && ConfigTypeExercise.data.config.bibliography[0] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigBibliography"
                                        @click="checkGroupChecks('GhCheckConfigBibliography', 'GhCheckConfigBibliographyRequired', false)"
                                    />
                                    <GhChecks
                                        :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_bibliography'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_bibliography', value: 2, name: 'type_exercise[config][bibliography][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.bibliography && ConfigTypeExercise.data.config.bibliography[1] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigBibliographyRequired"
                                        @click="checkGroupChecks('GhCheckConfigBibliography', 'GhCheckConfigBibliographyRequired', true)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content" style="margin-left: 160px;">
                            <div style="display: flex; margin-left: 160px;">
                                <div style="display: flex; width: 400px;">
                                    <GhChecks
                                        :datalabel="{text: $t('videography'), style: 'width: 130px', id: 'label_type_exercise_videography'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_videography', value: 1, name: 'type_exercise[config][videography][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.videography && ConfigTypeExercise.data.config.videography[0] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigVideography"
                                        @click="checkGroupChecks('GhCheckConfigVideography', 'GhCheckConfigVideographyRequired', false)"
                                    />
                                    <GhChecks
                                        :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_videography'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_videography', value: 2, name: 'type_exercise[config][videography][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.videography && ConfigTypeExercise.data.config.videography[1] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigVideographyRequired"
                                        @click="checkGroupChecks('GhCheckConfigVideography', 'GhCheckConfigVideographyRequired', true)"
                                    />
                                </div>
                                <div style="display: flex; width: 400px;">
                                    <GhChecks
                                        :datalabel="{text: $t('support_material_2'), style: 'width: 130px', id: 'label_type_exercise_support_material'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_support_material', value: 1, name: 'type_exercise[config][support_material][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.support_material && ConfigTypeExercise.data.config.support_material[0] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigSupportMaterial"
                                        @click="checkGroupChecks('GhCheckConfigSupportMaterial', 'GhCheckConfigSupportMaterialRequired', false)"
                                    />
                                    <GhChecks
                                        :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_support_material'}"
                                        :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_support_material', value: 2, name: 'type_exercise[config][support_material][]',
                                            selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.support_material && ConfigTypeExercise.data.config.support_material[1] ? true : false,
                                            disabled: !$root._route.params.view ? false : true
                                        }"
                                        ref="GhCheckConfigSupportMaterialRequired"
                                        @click="checkGroupChecks('GhCheckConfigSupportMaterial', 'GhCheckConfigSupportMaterialRequired', true)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div :key="refresh">
                            <component v-bind:is="include_component" ref="GhChildrenTypesBlock"/>
                        </div>
                    </div>
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                @click="submitTypeExercise"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import type_open_question from "./includes/type_open_question";
    import type_close_one_correct from "./includes/type_close_one_correct";
    import type_close_n_correct from "./includes/type_close_n_correct";
    import type_close_numeric from "./includes/type_close_numeric";
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhTreeSelectorBlock from 'fe-gh-tree-selector-block-lib';
    import GhChecks from 'fe-gh-checks-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    export default {
        name: "type_exercise",
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhTreeSelectorBlock,
            GhChecks,
            GhButton
        },
        computed: {
            ...mapState(['ConfigTypeExercise', 'getFeeds'])
        },
        data(){
            return {
                haveData: false,
                showProcedimientos: true,
                refresh: 0,
                include_component: ''
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedType', '',{root:true});
            await this.$store.dispatch('getFeedCondition', '',{root:true});
            await this.$store.dispatch('getTypeExerciseData', {id: this.$root._route.params.id},{root:true});

            this.haveData = true;

            if(this.ConfigTypeExercise.data.type !== undefined){
                switch(this.ConfigTypeExercise.data.type[0].value){
                    case 1:
                        this.include_component = type_open_question;
                        this.showProcedimientos = false;
                        break;
                    case 3:
                        this.include_component = type_close_numeric;
                        this.showProcedimientos = false;
                        break;
                    case 4:
                        this.include_component = type_close_one_correct;
                        this.showProcedimientos = true;
                        break;
                    case 5:
                        this.include_component = type_close_n_correct;
                        this.showProcedimientos = true;
                        break;
                }
            }
        },
        methods: {
            changeType(){
                if(this.$root._route.params.id === undefined){
                    var newValues = {
                        'code': document.getElementById('type_exercise_code').value,
                        'name': document.getElementById('type_exercise_name').value,
                        'description': document.getElementById('type_exercise_description').value
                    }
                    this.ConfigTypeExercise.data = newValues;
                }else {
                    this.ConfigTypeExercise.data.code = document.getElementById('type_exercise_code').value;
                    this.ConfigTypeExercise.data.name = document.getElementById('type_exercise_name').value;
                    this.ConfigTypeExercise.data.description = document.getElementById('type_exercise_description').value;
                }

                document.querySelectorAll('input[name="type_exercise[type][]"]').forEach(item => {
                    if(item.checked){
                        switch (item.value){
                            case '1':
                                this.include_component = type_open_question;
                                this.showProcedimientos = false;
                                break;
                            case '3':
                                this.include_component = type_close_numeric;
                                this.showProcedimientos = false;
                                break;
                            case '4':
                                this.include_component = type_close_one_correct;
                                this.showProcedimientos = true;
                                break;
                            case '5':
                                this.include_component = type_close_n_correct;
                                this.showProcedimientos = true;
                                break;
                        }

                        this.refresh++;
                    }
                });
            },
            checkGroupChecks(refType, refRequired, required, children){
                var startCheck = children ? this.$refs['GhChildrenTypesBlock'] : this;
                if (required) {
                    if (startCheck.$refs[refRequired].$el.children[0].children[1].checked) {
                        if (!startCheck.$refs[refType].$el.children[0].children[1].checked) {
                            startCheck.$refs[refType].checkUncheck();
                        }
                    }
                } else {
                    if (!startCheck.$refs[refType].$el.children[0].children[1].checked) {
                        if (startCheck.$refs[refRequired].$el.children[0].children[1].checked) {
                            startCheck.$refs[refRequired].checkUncheck();
                        }
                    }
                }
            },
            async submitTypeExercise(){
                if(this.Global.checkRequired('form_type_exercise') == 'OK') {
                    const formData = new FormData(document.getElementById('form_type_exercise'));
                    this.$store.dispatch('setTypeExerciseData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        },
    }
</script>