import ConfigSeal from "@/view/config/general/seals/template/seal.vue";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/general/seal',
        name: 'config_seal',
        component: ConfigSeal,
        props: {id: null, showMenu: true, otherTitle: i18n.t('stamp')},
        children: [
            {
                path: '/config/general/seal/:id',
                name: 'config_seal_id',
                component: ConfigSeal,
                props: {id: null, showMenu: true, otherTitle: i18n.t('stamp')},
            },
            {
                path: '/config/general/seal/:id/:view',
                name: 'config_seal_id_view',
                component: ConfigSeal,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('stamp')},
            }
        ]
    }
];

export default routes;