import Vue from "vue";
import vuex from "vuex";
import router from "@/routes/router";
Vue.use(vuex);

export default{
    state: {
        subTabActive: '',
        nameSubTabActive:'',
    },
    mutations: {
        loadSubActiveItem(state, payload) {
            state.subTabActive = payload
        },
        loadNameSubActiveItem(state,payload){
            state.nameSubTabActive = payload
        },
    },
    actions: {
        getConfigTabActive(state, item) {
            let idTab = ''
            let name = ''
            router.options.routes.forEach((items,index) => {
                if ((item.call == items.name && item.call)){
                    idTab = router.options.routes[index].component;
                    name = items.name
                }
                state.commit("loadSubActiveItem", idTab != '' ? idTab : '');
                state.commit("loadNameSubActiveItem", name != '' ? name : '');
            });
        },
    }
};
