import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        feed_language: [],
        feed_languages: [],
        feed_pallette: [],
        feed_font: [],
        feed_social_reason: [],
        feed_country: [],
        feed_activity: [],
        feed_num_employers: [],
        feed_tabs: [],
        feed_positions: [],
        feed_perms: [],
        feed_groups: [],
        feed_profiles: [],
        feed_sector: [],
        feed_procedures: [],
        feed_examples: [],
        feed_bibliography: [],
        feed_videography: [],
        feed_support_material: [],
        feed_type: [],
        feed_type_exercise: [],
        feed_supp_groups: [],
        feed_type_exercise_by_parent: [],
        feed_condition: [],
        feed_exercises: [],
        feed_selector_activities: [],
        feed_selector_ambits_certificates: [],
        data_treatment_text: '',
        feed_support_categories: '',
        feed_support_priority: '',
        feed_support_asigned: '',
        feed_incidence_priority: [],
        feed_incidence_category: [],
        feed_incidence_asigned: [],
        feed_company: [],
        feed_organizational_unit: [],
        feed_level: [],
        feed_state_phone_number: [],
        feed_state_client: [],
        feed_responsible: [],
        feed_excerises_no_subquestions: [],
        feed_reference_thesauros: [],
    },
    mutations: {
        loadLanguagesData(state,payload){
            state.feed_language = payload;
        },
        loadFeedExercisesWithoutSubquestions(state,payload){
            state.feed_excerises_no_subquestions = payload;
        },
        loadFeedReferenceThesauros(state,payload){
            state.feed_reference_thesauros = payload;
        },
        loadLanguages(state, payload){
            state.feed_languages = payload;
        },
        loadExercises(state, payload){
            state.feed_exercises = payload;
        },
        loadPallette(state, payload){
            state.feed_pallette = payload;
        },
        loadFont(state, payload){
            state.feed_font = payload;
        },
        loadSocialReason(state, payload){
            state.feed_social_reason = payload;
        },
        loadCountry(state, payload){
            state.feed_country = payload;
        },
        loadActivity(state, payload){
            state.feed_activity = payload;
        },
        loadNumEmployers(state, payload){
            state.feed_num_employers = payload;
        },
        loadFeedTabs(state, payload){
            state.feed_tabs = payload;
        },
        loadPositions(state, payload){
            state.feed_positions = payload;
        },
        loadPerms(state, payload){
            state.feed_perms = payload;
        },
        loadGroups(state, payload){
            state.feed_groups = payload;
        },
        loadProfiles(state, payload){
            state.feed_profiles = payload;
        },
        loadSector(state, payload){
            state.feed_sector = payload;
        },
        loadProcedures(state, payload){
            state.feed_procedures = payload;
        },
        loadExamples(state, payload){
            state.feed_examples = payload;
        },
        loadBibliography(state, payload){
            state.feed_bibliography = payload;
        },
        loadVideography(state, payload){
            state.feed_videography = payload;
        },
        loadSupportMaterial(state, payload){
            state.feed_support_material = payload;
        },
        loadTreatmentText(state, payload){
            state.data_treatment_text = payload;
        },
        loadType(state, payload){
            state.feed_type = payload;
        },
        loadTypeExercise(state, payload){
            state.feed_type_exercise = payload;
        },
        loadSuppGroups(state, payload){
            state.feed_supp_groups = payload;
        },
        loadTypeExerciseByParent(state, payload){
            state.feed_type_exercise_by_parent = payload;
        },
        loadCondition(state, payload){
            state.feed_condition = payload;
        },
        loadSelectorActivities(state, payload){
            state.feed_selector_activities = payload;
        },
        loadFeedAmbitsCertificatesFiltered(state, payload){
            state.feed_selector_ambits_certificates = payload;
        },
        loadFeedSupportCategory(state,payload){
            state.feed_support_categories = payload
        },
        loadFeedSupportPriority(state,payload){
            state.feed_support_priority = payload
        },
        loadFeedSupportAsigned(state,payload){
            state.feed_support_asigned = payload
        },
        loadPriorityIncidence(state,payload){
            state.feed_incidence_priority = payload
        },
        loadCategoryIncidence(state,payload){
            state.feed_incidence_category = payload
        },
        loadAsignedIncidence(state,payload){
            state.feed_incidence_asigned = payload
        },
        loadCompany(state,payload){
            state.feed_company = payload;
        },
        loadOrganizationalUnit(state,payload){
            state.feed_organizational_unit = payload;
        },
        loadLevel(state,payload){
            state.feed_level = payload;
        },
        loadStatePhoneNumber(state,payload){
            state.feed_state_phone_number = payload;
        },
        loadStateClient(state,payload){
            state.feed_state_client = payload;
        },
        loadResponsible(state,payload){
            state.feed_responsible = payload;
        }
    },
    actions: {
        async getLanguagesData(state){
            let  requestLogin = API_URL + "feed/languageselector";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadLanguagesData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getLanguages(state){
            let  requestLogin = API_URL + "feed/languages";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadLanguages", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPallette(state){
            let  requestLogin = API_URL + "feed/pallette";
            await axios({
                method:"GET",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadPallette", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFont(state){
            let  requestLogin = API_URL + "feed/font";
            await axios({
                url: requestLogin,
                method:"GET"
            }).then(
                response => {
                    state.commit("loadFont", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSocialReason(state){
            let  requestLogin = API_URL + "feed/social_reason";
            await axios({
                method: "get",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSocialReason", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getCountry(state){
            let  requestLogin = API_URL + "feed/country";
            await axios({
                method: "get",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadCountry", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getActivity(state){
            let  requestLogin = API_URL + "feed/activity";
            await axios({
                method:'GET',
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadActivity", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPosition(state){
            let  requestLogin = API_URL + "feed/position";
            await axios({
                method:"GET",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadPositions", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getPerms(state){
            let  requestLogin = API_URL + "perms";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadPerms", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getGroups(state){
            let  requestLogin = API_URL + "groups/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadGroups", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getProfiles(state){
            let  requestLogin = API_URL + "profiles/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadProfiles", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedType(state){
            let  requestLogin = API_URL + "subtype-exercise/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadType", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTypeExercise(state){
            let  requestLogin = API_URL + "type-exercises/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadTypeExercise", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSuppGroups(state){
            let  requestLogin = API_URL + "support/groups/feeds";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSuppGroups", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedCondition(state){
            let  requestLogin = API_URL + "condition/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadCondition", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSector(state){
            let  requestLogin = API_URL + "sector/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSector", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedProcedures(state, item){
            let  requestLogin = API_URL + "procedure/feed"; // Review 3ul3r
            return await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadProcedures", response.data.data);

                    if(item.returned){
                        return response.data.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedExercises(state){
            let  requestLogin = API_URL + "feed/exercises";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadExercises", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedExamples(state, item){
            let  requestLogin = API_URL + "example/feed"; // Review 3ul3r
            return await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadExamples", response.data.data);

                    if(item.returned){
                        return response.data.data;
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedBibliography(state){
            let  requestLogin = API_URL + "bibliography/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadBibliography", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedVideography(state){
            let  requestLogin = API_URL + "videography/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadVideography", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getSelectorActivities(state){
            let  requestLogin = API_URL + "feed/creation/activities";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSelectorActivities", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSupportMaterial(state){
            let  requestLogin = API_URL + "support-material/feed"; // Review 3ul3r
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadSupportMaterial", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getTreatmentText(state){
            let  requestLogin = API_URL + "data_treatment_text";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadTreatmentText", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getNumEmployers(state){
            let  requestLogin = API_URL + "feed/num_employee";
            await axios({
                method:"GET",
                url: requestLogin,
            }).then(
                response => {
                    state.commit("loadNumEmployers", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedTabs(state){
            let  requestLogin = API_URL + "feed_tabs";
            await axios({
                url: requestLogin,
            }).then(
                response => {
                    console.log(response.data.data)
                    state.commit("loadFeedTabs", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedAmbitsCertificatesFiltered(state,item){
            let  requestLogin = API_URL + "feed/creation/scope_certificate";
            let formData = new FormData();
            let keys_array = Object.keys(item.sector);

            for(var i=0;i<keys_array.length;i++){
                if(keys_array[i] !== undefined){
                    formData.append('itinerary[sector][]', keys_array[i]);
                }
            }

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
            }).then(
                response => {
                    state.commit("loadFeedAmbitsCertificatesFiltered", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getExecutionUserSearch(state, item){
            let requestLogin = API_URL + 'users/search/execution';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getRevisionUserSearch(state, item){
            let requestLogin = API_URL + 'users/search/revision';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getValidationUserSearch(state, item){
            let requestLogin = API_URL + 'users/search/validation';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUserSearch(state, item){
            let requestLogin = API_URL + 'responsible/feed'; // Review 3ul3r
            // let requestLogin = API_URL + 'users/group/1/company/1'; // Review 3ul3r
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUserSearchByGroupAndCompany(state, item){
            let company_id = document.getElementById('assign_company_search').value;
            let group_id = document.getElementById('assign_group_search').value;
            let requestLogin = API_URL + 'user/group/' + group_id + '/company/' + company_id;
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getUserSupervisedEvaluated(state, item){
            let request = API_URL + 'users/search/supervised_evaluated'

            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: 'get',
                url: request,
                params: params
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getBusinessSearch(state, item){
            let requestLogin = API_URL + 'business/search';
            let params = {};
            item.formData.forEach((item) => {
                params[item[0]] = item[1];
            });

            return await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    return response.data;
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedSupportCategory(state){
            await axios.get(
                API_URL + "support/categories/feeds"
            ).then(
                response => {
                    state.commit('loadFeedSupportCategory', response.data.data);
                }
            )
        },
        async getFeedSupportPriority(state){
            await axios.get(
                API_URL + "support/priorities/feeds"
            ).then(
                response => {
                    state.commit('loadFeedSupportPriority', response.data.data);
                }
            )
        },
        async getFeedSupportAsigned(state){
            await axios.get(
                API_URL + "support/groups/tickets-feeds"
            ).then(
                response => {
                    state.commit('loadFeedSupportAsigned', response.data.data);
                }
            )
        },
        async getCompany(state){
            await axios.get(
                API_URL + "feed_company"
            ).then(
                response => {
                    state.commit('loadCompany', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getOrganizationalUnit(state){
            await axios.get(
                API_URL + "feed_organizational_unit"
            ).then(
                response => {
                    state.commit('loadOrganizationalUnit', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedLevel(state){
            await axios.get(
                API_URL + 'feed_level'
            ).then(
                response => {
                    state.commit('loadLevel', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getStatePhoneNumber(state){
            await axios.get(
                API_URL + "commercial-management/actions-types"
            ).then(
                response => {
                    state.commit('loadStatePhoneNumber', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getStateClient(state){
            await axios.get(
                API_URL + "feed_state_client"
            ).then(
                response => {
                    state.commit('loadStateClient', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedResponsibles(state){
            await axios.get(
                API_URL + "responsible/feed"
            ).then(
                response => {
                    state.commit('loadResponsible', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedExercisesWithoutSubquestions(state){
            await axios.get(
                API_URL + "exercises/no_subquestions/feed"
            ).then(
                response => {
                    state.commit('loadFeedExercisesWithoutSubquestions', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getFeedReferenceThesauros(state){
            await axios.get(
                API_URL + "reference/thesauros/feeds"
            ).then(
                response => {
                    state.commit('loadFeedReferenceThesauros', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        getTypeExerciseByParent(state, item){
            var params = [];
            state.state.feed_type_exercise.forEach((props) => {
                var newProp = [];
                if(props.value != item.id){
                    newProp = {...props};
                    params.push(newProp);
                    if(props.children !== undefined){
                        props.children.forEach(function(i, k){
                            if(i.value == item.id){
                                params[params.length - 1].children.splice(k, 1);
                            }
                        });
                    }

                }
            });

            state.commit('loadTypeExerciseByParent', params);
            state.dispatch('getFeedTypeExercise');

        },
    }
};
