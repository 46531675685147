import Certificate from "@/view/creation/certificates/templates/certificate";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/creation_certificates/certificate',
        name: 'creation_certificates',
        component: Certificate,
        props: {id: null, showMenu: true,otherTitle: i18n.t('certificates')},
        children: [
            {
                path: '/creation_certificates/certificate/:id',
                name: 'creation_certificates_id',
                component: Certificate,
                props: {id: null, showMenu: true, otherTitle: i18n.t('certificates')},
            },
            {
                path: '/creation_certificates/certificate/:id/:view',
                name: 'creation_certificates_id_view',
                component: Certificate,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('certificates')},
            }
        ]
    },
];

export default routes;