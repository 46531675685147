<template>
    <div>
        <GhDropSelector v-if="showGroups"
            :datablock="{
                id: 'section_groups',
                title: $t('groups'),
                second_title: '',
                num_results: '',
            }"
        >
            <template v-slot:content>
                <GhTableCommon
                    :extratable="{
                        id: 'groups',
                    }"
                    :header="header_groups"
                    :data="ListGroups.list_groups.length === 0 ? [] : ListGroups.list_groups"
                >
                    <template v-slot:field_redirect="{itemProp, labelProp}">
                        <router-link :to="{name: 'perms_groups_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
                    </template>
                    <template v-slot:actions="{itemProp}">
                        <router-link :to="{name: 'perms_groups_id', params: {id: itemProp['id']}}">
                            <GhAction
                                :dataction="{
                                    id: 'edit_group_'+itemProp['id'],
                                    text: $t('modify'),
                                    key_show_condition: 'toShow',
                                    value_show_condition: false,
                                    icon: require('../../assets/gh_new_dessign/edit.svg'),
                                    item_prop: itemProp
                                }"
                            />
                        </router-link>
                    </template>
                    <template v-slot:footer>
                        <div class="centered">
                            <router-link :to="{name: 'perms_groups'}">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_add_group',
                                        text: $t('add')
                                    }"
                                />
                            </router-link>
                        </div>
                    </template>
                </GhTableCommon>
            </template>
        </GhDropSelector>
        <GhDropSelector v-if="showProfiles"
            :datablock="{
                id: 'section_profiles',
                title: $t('profiles'),
                second_title: '',
                num_results: '',
            }"
        >
            <template v-slot:content>
                <GhTableCommon
                    :extratable="{
                        id: 'profiles',
                    }"
                    :header="header_profiles"
                    :data="ListGroups.list_profiles.length === 0 ? [] : ListGroups.list_profiles"
                >
                    <template v-slot:field_redirect="{itemProp, labelProp}">
                        <router-link :to="{name: 'perms_profiles_id_view', params: { id: itemProp.id, view: true}}"><a class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
                    </template>
                    <template v-slot:actions="{itemProp}">
                        <router-link :to="{name: 'perms_profiles_id', params: {id: itemProp['id']}}">
                            <GhAction
                                :dataction="{
                                    id: 'btn_edit_profile_'+itemProp['id'],
                                    text: $t('modify'),
                                    key_show_condition: 'toShow',
                                    value_show_condition: false,
                                    icon: require('../../assets/gh_new_dessign/edit.svg'),
                                    item_prop: itemProp
                                }"
                            />
                        </router-link>
                        <GhAction
                            :dataction="{
                                id: 'btn_delete_profile_'+itemProp['id'],
                                text: $t('delete'),
                                icon: require('../../assets/gh_new_dessign/trash.svg'),
                            }"
                            @click="Global.deleteConfirm('delete_confirm','Profile', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, $t('delete_profile'), $t('preparing_delete_profile'), 'delete')"
                        />
                    </template>
                    <template v-slot:footer>
                        <div class="centered">
                            <router-link :to="{name: 'perms_profiles'}">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_add_profile',
                                        text: $t('add')
                                    }"
                                />
                            </router-link>
                        </div>
                    </template>
                </GhTableCommon>
            </template>
        </GhDropSelector>
        <GhDropSelector v-if="showCompanies"
            :datablock="{
                  id: 'section_companies',
                  title: $t('companies'),
                  second_title: '',
                  num_results: '',
              }"
        >
            <template v-slot:content>
                <div style="padding-left: 15px; padding-bottom: 15px; padding-right: 15px;">
                    <GhTreeActionBlock
                        :dataBlock="{
                            id: 'tree_companies',
                            actionWithoutChildren: true,
                            actionWithChildren: true,
                            extraLabelNumber: false,
                            extraLabelText: '',
                        }"
                        :data="ListGroups.data_tree_companies"
                    >
                        <template v-slot:Action="Action">
                            <router-link :to="{name: 'company_group_id', params: {id: Action.Action.Action.Action.id}}">
                                <GhAction
                                    :dataction="{
                                        id: 'btn_modify_company',
                                        text: $t('modify'),
                                        key_show_condition: '',
                                        value_show_condition: '',
                                        icon: require('../../assets/gh_new_dessign/edit.svg'),
                                    }"
                                />
                            </router-link>
                        </template>
                    </GhTreeActionBlock>
                </div>
            </template>
        </GhDropSelector>
        <GhDropSelector v-if="showAssociatedUsers" style="padding-bottom: 10px;"
            :datablock="{
                id: 'section_associated_users',
                title: $t('associated_users'),
                second_title: '',
                num_results: '',
            }"
        >
            <template v-slot:content>
                <div class="T14 link table-header">{{$t('perms')}}</div>
                <form id="form_associated_users">
                    <div style="margin-right: 10px" :key="refreshAssociatedUsers">
                        <div class="gh-row-content" style="padding-bottom: 3px !important; width: 100%; flex-wrap: wrap; padding-left: 10px; padding-top: 2px;" v-for="(item,index) in getFeeds.feed_perms" :key="index" :style="index % 2 ? '' : 'background-color: #d9edfc;'">
                            <div>
                                <div style="width: calc((100% - 240px) - (((100% - 280px) * 12) / 15))" v-for="(itemChildren,indexChildren) in item" :key="indexChildren" :title="itemChildren.description">
                                    <GhChecks
                                        :datalabel="{
                                            text: itemChildren.name,
                                            style: '',
                                            class: '',
                                            id: ''
                                        }"
                                        :datainput="{
                                            disabled: $root._route.params.view ? true : false,
                                            required: false,
                                            selected: '',
                                            type: 'checkbox',
                                            id: itemChildren.id,
                                            value: itemChildren.id,
                                            name: 'associated[perms][]'
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div style="background-color: #c5d1df; height: 46px;">
                    <div class="centered" style="padding-top: 9px;">
                        <GhButton
                            :datainput="{
                                id: 'btn_accept',
                                text: this.$t('accept')
                            }"
                            @click="acceptAssociatedUsers"
                        />
                        <GhButton
                            :datainput="{
                                id: 'btn_clean',
                                text: this.$t('clean')
                            }"
                            @click="cleanAssociatedUsers"
                        />
                    </div>
                </div>

                <template v-if="ListGroups.list_associated_users !== undefined && ListGroups.list_associated_users.users !== undefined">
                    <GhTableCommon style="margin-top: 30px;"
                        :extratable="{
                            id: 'profiles',
                        }"
                        :header="header_associated_users"
                        :data="ListGroups.list_associated_users.users"
                    />

                    <div class="gh-row-content" style="margin-top: 10px;">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('groups') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ListGroups.list_associated_users.groups}}</label>
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('profiles') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ListGroups.list_associated_users.profiles}}</label>
                    </div>
                </template>
            </template>
        </GhDropSelector>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";
    import GhTreeActionBlock from "fe-gh-tree-action-block-lib";

    import {mapState} from "vuex";
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: "list_perms",
        components:{
            GhChecks,
            GhDropSelector,
            GhTableCommon,
            GhAction,
            GhTreeActionBlock,
            GhButton
        },
        computed: {
            ...mapState(['getFeeds', 'ListGroups','Login']),
        },
        data(){
            return{
                header_groups: [
                    {text: this.$t('name'), field: 'name',urlRedirect: true, sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 30%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 70%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                header_profiles: [
                    {text: this.$t('name'), field: 'name', urlRedirect: true, sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 30%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 70%;'},
                    {text: '', field: 'actions', sorting: false},
                ],
                header_associated_users: [
                    {text: this.$t('name'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: this.$t('surnames'), field: 'surname', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                    {text: this.$t('email'), field: 'email', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 60%;'},
                ],
                haveData: false,
                showGroups: false,
                showProfiles: false,
                showCompanies: false,
                showAssociatedUsers: false,
                refreshAssociatedUsers: 0
            }
        },
        async mounted() {
            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('perms')){
                    that.Login.tabs[route.id]['sub-modules'].forEach((subroute) => {
                        if(subroute.name == that.$t('groups')){
                            that.showGroups = subroute.have_permission;
                        }else if(subroute.name == that.$t('profiles')){
                            that.showProfiles = subroute.have_permission;
                        }else if(subroute.name == that.$t('companies')){
                            that.showCompanies = subroute.have_permission;
                        }else if(subroute.name == that.$t('associated_users')){
                            that.showAssociatedUsers = subroute.have_permission;
                        }
                    });
                }
            });

            if(this.showGroups) {
                await this.$store.dispatch('getListGroups', '', {root: true});
            }

            if(this.showProfiles) {
                await this.$store.dispatch('getListProfiles', '', {root: true});
            }

            if(this.showCompanies) {
                await this.$store.dispatch('getTreeCompanies', '', {root: true});
            }

            await this.$store.dispatch('getPerms', '', {root:true});

            this.haveData = true;
        },
        methods:{
            cleanAssociatedUsers(){
                this.ListGroups.list_associated_users = [];
                this.refreshAssociatedUsers++;
            },
            acceptAssociatedUsers(){
                const formData = new FormData(document.getElementById('form_associated_users'));
                this.$store.dispatch('checkAssociatedUsers', {
                    formData: [...formData],
                }, {root: true});
            }
        }
    }
</script>
