<template>
    <div>
        <GhTableCommon
            :extratable="{
                id: 'holidays_table',
            }"
            :header="header"
            :data="ConfigHolidays.records_data.length == 0 ? [] : ConfigHolidays.records_data"
        >
            <template v-slot:field_redirect="{itemProp, labelProp}">
                <router-link :to="{name: 'holidays_view', params: {id: itemProp.id, view: true}}"><a :href="itemProp[labelProp]" class="table-cell_link T13"> {{itemProp[labelProp]}} </a></router-link>
            </template>
            <template v-slot:actions="{itemProp}">
                <router-link :to="{name: 'holidays_id', params: { id: itemProp.id, view: false}}">
                    <GhAction
                        :dataction="{
                            id: 'edit_conf_supp_holiday',
                            text: $t('modify'),
                            icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                            item_prop: itemProp
                        }"
                    />
                </router-link>
            </template>
        </GhTableCommon>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";
    import {mapState} from "vuex";
    export default {
        name: "list_holidays",
        data(){
            return{
                haveData:false,
                header: [
                  {text: this.$t('assignment_group'), field: 'assignment_group', urlRedirect: true, sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 20%;'},
                  {text: this.$t('holidays'), field: 'working_days', sorting: true, typeSort: 'string', style: 'max-width: 150px; width: 80%;'},
                  {text: '', field: 'actions', sorting: false,style: 'width:10%;'},
                ],
            }
        },
        components:{
            GhTableCommon,
            GhAction
        },
        computed:{
            ...mapState(['ConfigHolidays'])
        },
        async beforeMount(){
            await this.$store.dispatch('getHolidays', '', {root: true});
            this.haveData = true;
        }
    }
</script>