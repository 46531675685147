<template>
    <div class="gh-content-global">
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('code') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'procedure_code',
                        name: 'procedure[code]',
                        style: 'width: 200px',
                        value: ConfigProcedures.data.code,
                        type: 'text',
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigProcedures.data.code}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('name') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'procedure_name',
                        name: 'procedure[name]',
                        style: 'min-width: 200px; width: 20%',
                        value: ConfigProcedures.data.name,
                        type: 'text',
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigProcedures.data.name}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhTextarea
                    :datalabel="{
                        text: $t('description'),
                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'procedure_description',
                        name: 'procedure[description]',
                        value: ConfigProcedures.data.description
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigProcedures.data.description}}</label>
            </template>
        </div>
        <div class="gh-row-content">
            <template v-if="!$root._route.params.view">
                <GhMultipleSearch
                    :datalabel="{
                        text: $t('responsible') + '*',
                        style: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                        class: 'T15_b',
                        id: 'label_procedure_responsible'
                    }"
                    :datainput="{
                        required: true,
                        id: 'procedure_responsible_user',
                        name: 'procedure[responsible][name]',
                        nameS:'procedure[responsible][surname]',
                        nameId:'procedure[responsible][id]',
                        style: 'width: 20.5%; min-width: 200px;',
                        value: ConfigProcedures.data.responsible ? ConfigProcedures.data.responsible.first_name : '',
                        valueId: ConfigProcedures.data.responsible ? ConfigProcedures.data.responsible.id : '',
                        valueS: ConfigProcedures.data.responsible ? ConfigProcedures.data.responsible.last_name : '',
                        placeholder: $t('name'),
                        placeholderS: $t('surnames'),
                        result_1: 'first_name',
                        result_2: 'last_name',
                        result_id: 'id',
                        href: dispatchUserResponsible
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigProcedures.data.responsible ? ConfigProcedures.data.responsible.first_name + ' ' + ConfigProcedures.data.responsible.last_name : ''}}</label>
            </template>
        </div>
        <div class="gh-row-content" v-if="$root._route.params.id !== undefined && !$root._route.path.includes('exercise')">
            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('exercises') + ':'}}</label>
            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigProcedures.data.text_exercises}}</label>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhMultipleSearch from 'fe-gh-multiple-search-lib';
    import {mapState} from "vuex";
    export default {
        name: "form_procedure",
        components: {
            GhInputTextField,
            GhTextarea,
            GhMultipleSearch,
        },
        computed: {
            ...mapState(['ConfigProcedures'])
        },
        data(){
            return{
                dispatchUserResponsible: 'getUserSearch',
            }
        }
    }
</script>