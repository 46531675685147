import InitialItinerary from "@/view/itinerary/initial/main";
import ListQuizz from "@/view/itinerary/initial/list_quizz";
import InitialQuizz from "@/view/itinerary/initial/quizz";
import ResultQuizz from "@/view/itinerary/initial/result_quizz";
import ToPay from "@/view/itinerary/initial/to_payment";


const routes = [
    {
        path: '/initial_itinerary',
        name: 'initial_itinerary',
        props: {showMenu: false},
        component: InitialItinerary
    },
    {
        path: '/list_quizz',
        name: 'list_quizz',
        props: {showMenu: false},
        component: ListQuizz,
        children: [
            {
                path: ':slug',
                name: 'quizz_detail',
                component: InitialQuizz
            },
        ]
    },
    {
        path: '/initial_quizz',
        name: 'initial_quizz',
        props: {showMenu: false},
        component: InitialQuizz
    },
    {
        path: '/result_quizz',
        name: 'result_quizz',
        props: {showMenu: false},
        component: ResultQuizz
    },
    {
        path: '/to_pay',
        name: 'to_pay',
        props: {showMenu: false},
        component: ToPay
    },
];

export default routes;