var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhDropDownField',{attrs:{"dataBlock":{
            id: 'drop_metrics',
            style: 'width: 100%; margin-right: 0px;',
            title: _vm.$t('metrics'),
            subTitle: _vm.ConfigExercisesTable.data.metrics !== undefined ? _vm.ConfigExercisesTable.data.metrics.length + ' ' + _vm.$t('Resultados') :  0 + ' ' + _vm.$t('Resultados'),
            isDrop: false
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.haveHeader)?_c('GhTableCommon',{attrs:{"extratable":{
                    id: 'metrics_',
                },"header":_vm.header,"data":_vm.ConfigExercisesTable.data.metrics},scopedSlots:_vm._u([(!_vm.$root._route.params.view)?{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                            id: 'delete_metrics',
                            text: _vm.$t('delete'),
                            icon: require('../../../../../assets/gh_new_dessign/trash.svg'),
                            item_prop: itemProp
                        }},on:{"click":function($event){return _vm.Global.deleteConfirm('delete_confirm','TemporalMetrics', itemProp.id,{'Nombre': itemProp.name, 'Descripción': itemProp.description}, 'Eliminar métrica', 'Se dispone a eliminar la siguiente métrica', 'delete')}}})]}}:null,{key:"footer",fn:function(){return [(!_vm.$root._route.params.view)?_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                id: 'btn_add_metrics',
                                text: _vm.$t('add')
                            }},on:{"click":_vm.openMetrics}})],1):_vm._e()]},proxy:true}],null,true)}):_vm._e()]},proxy:true}])}),_c(_vm.metrics_popup,{tag:"component"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }