var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('GhChecks',{ref:"GhCheckConfigEvidence",attrs:{"datalabel":{text: _vm.$t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.evidence !== undefined && _vm.ConfigTypeExercise.data.config.evidence[0] == 1 ? true : false,
                    disabled: !_vm.$root._route.params.view ? false : true,
                    type: 'checkbox',
                    id: 'value_type_exercise_evidence',
                    value: 1,
                    name: 'type_exercise[config][evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)}}}),_c('GhChecks',{ref:"GhCheckConfigEvidenceRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.evidence !== undefined && _vm.ConfigTypeExercise.data.config.evidence[1] == 2 ? true : false,
                    disabled: !_vm.$root._route.params.view ? false : true,
                    type: 'checkbox',
                    id: 'value_type_exercise_required_evidence',
                    value: 2,
                    name: 'type_exercise[config][evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)}}})],1)]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('feedback'), style: 'width: 130px', id: 'label_type_exercise_feedback'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_feedback', value: 1, name: 'type_exercise[feedback][]',
                selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.feedback && _vm.ConfigTypeExercise.data.config.feedback[0] == 1 ? true : false,
                disabled: !_vm.$root._route.params.view ? false : true
            }}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('subquestion'), style: 'width: 130px', id: 'label_type_exercise_subquestion'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_subquestion', value: 1, name: 'type_exercise[subquestion][]',
                selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.subquestion && _vm.ConfigTypeExercise.data.config.subquestion[0] == 1 ? true : false,
                disabled: !_vm.$root._route.params.view ? false : true,
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }