import ListConfig from "@/view/config/list_config";

import General from './general/route';
import Ejercicios from './exercises/route';
import Entorno from './around/route';
import Soporte from './support/route'
import i18n from "@/plugins/i18n";
const route = [
    {
        path: '/config',
        name: i18n.t('configuration'),
        component: ListConfig,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...General,
    ...Ejercicios,
    ...Entorno,
    ...Soporte,
]

export default routes;
