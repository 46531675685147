var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[_c('div',[_c('GhDataSelector',{key:_vm.refreshExercises,ref:"exercises",attrs:{"datablock":{
                id: 'label_activity_exercise',
                class: 'T15_b',
                subtitle: _vm.$t('select_exercise') + ':',
                text: _vm.$t('exercise'),
                label: _vm.$t('exercise'),
                type: 'checkbox',
                columns: 3,
                style: 'margin-left: 93px;display: none;',
                required: false,
            },"datainput":{
                id: 'value_activity_exercise',
                name: 'activity[exercise][]'
            },"selected_input":_vm.selecteds,"feed":_vm.getFeeds.feed_exercises},on:{"accept":function($event){return _vm.axiosExercise()}}})],1),_c('div',[_c('GhDropDownField',{attrs:{"dataBlock":{
                id: 'drop_exercises',
                style: 'width: 100%; margin-right: 0px;',
                title: _vm.$t('exercise'),
                subTitle: _vm.CreationActivity.list_exercises.length + ' ' + _vm.$t('results'),
                isDrop: true
            }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                        id: 'activities_table',
                    },"header":_vm.header,"data":_vm.CreationActivity.list_exercises.length === 0 ? [] : _vm.CreationActivity.list_exercises},scopedSlots:_vm._u([(!_vm.$parent.$parent.$root._route.params.view)?{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                                id: 'edit_config_exercises',
                                text: _vm.$t('delete'),
                                icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                            }},on:{"click":function($event){return _vm.deleteExercise(itemProp);}}})]}}:null,{key:"footer",fn:function(){return [(!_vm.$parent.$parent.$root._route.params.view)?_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_add_exercise',
                                    text: _vm.$t('add')
                                }},on:{"click":function($event){return _vm.addExercise();}}})],1):_vm._e()]},proxy:true}],null,true)})]},proxy:true}],null,false,2245340185)})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }