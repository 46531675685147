import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {},
    'es': {
        user:'Usuario',
        users:'Usuarios',
        name:'Nombre',
        surnames:'Apellidos',
        email:'Email',
        save:'Guardar',
        back:'Volver',
        modify:'Modificar',
        add:'Añadir',
        more_users:'Más usuarios',
        add_more_users:'Añadir mas usuarios',
        total_pay:'Total a pagar',
        number_desired_users:'Numero de usuarios deseados',
        total:'Total',
        make_payment:'Realizar pago',
        close:'Cerrar',
        results:'Resultados',
        company:'Empresa',
        companies:'Empresas',
        companies_2:'Compañias',
        description:'Descripción',
        state:'Estado',
        group_profile_name:'Nombre del grupo / perfil',
        birthday_date:'Fecha de nacimiento',
        dni:'DNI',
        mail:'Correo',
        telephone:'Teléfono',
        rol:'Rol',
        perms:'Permisos',
        assignments:'Asignaciones',
        assignment:'Asignación',
        user_file:'Ficha Usuario',
        date:'Fecha',
        tutorships:'Tutoría',
        support:'Soporte',
        petition:'Petición',
        answer:'Respuesta',
        finish:'Finalizar',
        company_file:'Ficha de empresa',
        social_reason:'Razón social',
        cif:'CIF',
        full_address:'Dirección completa',
        country:'País',
        activity_sector:'Sector de actividad',
        number_employees:'Número de empleados',
        delete:'Eliminar',
        assign:'Asignar',
        accept:'Aceptar',
        unassign_user:'Desasignar usuario',
        delete_unassign_user:'Se dispone a desasignar el siguiente usuario',
        my_tasks:'Mis tareas',
        questions_to_answer:'Preguntas a responder',
        execute:'Ejecutar',
        validate:'Validar',
        company_name:'Nombre empresa',
        todo:'Todo',
        see:'Ver',
        reopen:'Reabrir',
        reopen_incidence:'Se dispone a reabrir la siguiente incidencia',
        assign_to:'Asignado a',
        identifier:'Identificador',
        priority:'Prioridad',
        fe:'FE',
        ua:'UA',
        date_from:'Fecha desde',
        date_until:'Fecha hasta',
        open_by:'Abierta por',
        category:'Categoría',
        select_category:'Seleccione categoría',
        select_assignment:'Seleccione asignación',
        select_priority:'Seleccione prioridad',
        search: 'Buscar',
        clean: 'Limpiar',
        fp: 'FP',
        incidence: 'Incidencia',
        opening_by: 'Apertura por',
        center: 'Centro',
        date_creation: 'Fecha creación',
        attach: 'Adjuntar',
        download: 'Descargar',
        in: 'en',
        date_start_task: 'Fecha inicio tarea',
        date_end_task: 'Fecha final tarea',
        note: 'Nota',
        new_note: 'Nueva nota',
        attachments: 'Adjuntos',
        attach_document: 'Adjuntar documento',
        close_incidence: 'cerrar incidencia',
        processed_by: 'procesada por',
        technical_support: 'Soporte tecnico',
        closed_by: 'cerrada por',
        create_incidence: 'Crear Incidencia',
        limite_date: 'Fecha límite',
        open_incidence_dependent: 'Abrir incidencia dependiente',
        incidence_dependent: 'Incidencia dependiente',
        check: 'Revisar',
        check_2: 'Revisión',
        resolved: 'Resuelta',
        no_resolved: 'No resuelta',
        comment: 'Comentario',
        pending_request_my_group: 'Peticiones pendientes de mi grupo',
        pending_request: 'Peticiones pendientes',
        indicators: 'Indicadores',
        export_month: 'Exportar Mensual',
        export_year: 'Exportar Anual',
        historic: 'Histórico',
        date_start: 'Fecha inicio',
        date_end: 'Fecha fin',
        evaluated_tutored_by: 'Evaluado/Tutelado por',
        searcher: 'Buscador',
        my_situation: 'Mi situación',
        to_assign_question: 'Se dispone a asignar la siguiente pregunta',
        question: 'Pregunta',
        execution: 'Ejecución',
        validation: 'Validación',
        title: 'Titulo',
        title_column_1: 'Titulo columna 1',
        title_column_2: 'Titulo columna 2',
        title_column_3: 'Titulo columna 3',
        projects: 'Proyectos',
        phase: 'Fase',
        cost: 'Coste',
        estimated_time: 'Tiempo estimado',
        spent_time: 'Tiempo empleado',
        export_total: 'Exportar(total)',
        export_results: 'Exportar resultados',
        groups: 'Grupos',
        surname: 'Apellido',
        people: 'Personas',
        group: 'Grupo',
        profiles: 'Perfiles',
        select_profile: 'Seleccione perfil',
        add_users: 'Añadir usuarios',
        select_group: 'Seleccione grupo',
        delete_profile: 'Eliminar perfil',
        preparing_delete_profile: 'Se dispone a eliminar el siguiente perfil',
        associated_users: 'Usuarios asociados',
        to_assign_itinerary: 'Se dispone a asignar el siguiente itinerario',
        itinerary: 'Itinerario',
        itineraries: 'Itinerarios',
        my_itineraries: 'Mis Itinerarios',
        all_itineraries_exercises_finish: '¡Todos los itinerarios y sus ejercicios están finalizados, puedes finalizar el cuestionario por completo!',
        exercise_finish: '¡Ejercicio finalizado!',
        to_finish_exercise: '¡Para finalizar este ejercicio, deben de estar todas sus subpreguntas respondidas!',
        start: 'Iniciar',
        go_out: 'SALIR',
        attach_here_evidence: 'Adjunte aqui la evidencia',
        access_link: 'Link de acceso',
        see_more_detail: 'Ver más detalle',
        see_less_detail: 'Ver menos detalle',
        tips_and_recommendations: 'Consejos y recomendaciones',
        want_start_excelence: '¡Quiero iniciar mi camino a la excelencia!',
        start_way_excelence: 'Aquí empieza el camino a la excelencia',
        paying: 'Pagando',
        recomended: 'Recomendados',
        recomended_itineraries: 'Itinerarios recomendados',
        all_itineraries: 'Todos los itinerarios',
        tutor: 'Tutor',
        questionary: 'Cuestionario',
        see_incidence: 'Ver incidencia',
        data_evaluated_tutored: 'Datos Evaluados/Tutelados',
        global: 'Global',
        no_results_found: 'No se ha encontrado ningún resultado',
        incidences: 'Incidencias',
        resolved_on_time: 'Resueltas en plazo',
        resolved_after_deadline: 'Resueltas fuera de plazo',
        half_time: 'Tiempo medio',
        no_applied_filter: 'No se ha aplicado ningun filtro',
        assign_tabs: 'Asignar pestañas',
        tabs: 'Pestañas',
        selection_tabs: 'Seleccione pestañas',
        gh_admin: 'GH Admin',
        evaluation: 'Evaluación',
        documents: 'Documentos',
        range: 'Rango',
        evaluation_results: 'Resultado evaluación',
        level: 'Nivel',
        select_level: 'Seleccione nivel',
        password: 'Contraseña',
        remember_password: 'Recordar contraseña',
        get_in: 'ENTRAR',
        sign_in: 'REGISTRARSE',
        help_email: 'E-mail de ayuda',
        help_phone: 'Teléfono de ayuda',
        remember_user_password: 'Recordar nombre de usuario/contraseña',
        enter_data_send_password: 'Introduzca los datos indicados en el recuadro siguiente y le enviaremos una nueva contraseña',
        nif: 'NIF',
        select_social_reason: 'Selecciona una razón social',
        select_country: 'Seleccione país',
        select_activity_sector: 'Seleccione sector de actividad',
        select_number_employees: 'Seleccione número de empleados',
        contact_person: 'Persona de contacto',
        position: 'Cargo',
        select_position: 'Seleccione cargo',
        contact_telephone: 'Teléfono de contacto',
        data_treatment: 'Tratamiento de datos',
        politic_privacity: 'Texto correspondiente politic privacity',
        legal_advise: 'Texto correspondiente legal advise',
        send_request: 'ENVIAR SOLICITUD',
        activity: 'Actividad',
        code: 'Código',
        responsible: 'Responsable',
        sector: 'Sector',
        select_sector: 'Seleccione sector',
        bibliography: 'Bibliografía',
        select_bibliography: 'Seleccione bibliografía',
        videography: 'Videografía',
        select_videography: 'Seleccione videografía',
        examples: 'Ejemplos',
        select_example: 'Seleccione ejemplo',
        exercise: 'Ejercicio',
        select_exercise: 'Seleccione ejercicio',
        activities: 'Actividades',
        delete_activities: 'Eliminar actividad',
        preparing_delete_activities: 'Se dispone a eliminar la siguiente actividad',
        create: 'Creación',
        select_activity: 'Seleccione actividad',
        ambit: 'Ámbito',
        ambits: 'Ámbitos',
        delete_ambit: 'Eliminar ambito',
        preparing_delete_ambit: 'Se dispone a eliminar el siguiente ambito',
        certificate: 'Certificado',
        certifying_element: 'Elemento certificador',
        certificates: 'Certificados',
        delete_certificate: 'Eliminar certificado',
        preparing_delete_certificate: 'Se dispone a eliminar el siguiente certificado',
        ambits_certificates: 'Ámbitos/Certificados',
        select_ambits_certificates: 'Seleccione ámbito/certificado',
        delete_itinerary: 'Eliminar itinerario',
        preparing_delete_itinerary: 'Se dispone a eliminar el siguiente itinerario',
        select_language: 'Seleccione idioma',
        required_fields: 'Campos obligatorios',
        must_be_follow_fields_complete: 'Falta cumplimentar los siguientes campos',
        all_search_criteria_entered: 'Cumplir todos los criterios de búsqueda introducidos',
        any_search_criteria_entered: 'Cumplir alguno de los criterios de búsqueda introducidos',
        view: 'Ver',
        type_of_company: 'Tipo de empresa',
        company_status: 'Estado de la empresa',
        last_login: 'Último login',
        last_action_date: 'Fecha de la última acción',
        start_date: 'Fecha inicio',
        end_date: 'Fecha fin',
        client_status: 'Estado del cliente',
        select_client_status: 'Selecciona el estado del cliente',
        file: 'Documento',
        call_status: 'Estado de la llamada',
        select_call_status: 'Selecciona el estado de la llamada',
        call: 'Llamar',
        call_2: 'Llamada',
        historical: 'Histórico',
        action: 'Acción',
        actions: 'Acciones',
        export_monthly: 'Exportar mensual',
        export_annual: 'Exportar anual',
        view_company: 'Ver empresa',
        add_user: 'Añadir usuario',
        itinerary_assignment: 'Asignación itinerario',
        request_in_progress: 'Solicitud en curso',
        your_registration_request: 'Su solicitud de registro',
        has_been_sent_correctly_on: 'ha sido enviada correctamente el',
        address: 'Dirección',
        postal_code: 'Código postal',
        town: 'Población',
        request: 'Solicita',
        payment_done: 'Pago efectuado',
        paid: 'Pagado',
        reference: 'Referencia',
        delete_pending_payment: 'Eliminar pendiente pago',
        preparing_delete_pending_payment: 'Se dispone a eliminar el siguiente pendiente pago',
        amount: 'Importe',
        pay_day: 'Fecha pago',
        outstanding: 'Pendiente de pago',
        billing: 'Facturación',
        environment_language: 'Idioma de entorno',
        select_environment_language: 'Seleccione idioma de entorno',
        environment_palette: 'Paleta de entorno',
        select_environment_palette: 'Seleccione paleta de entorno',
        environment_font: 'Fuente de entorno',
        select_environment_font: 'Seleccione fuente de entorno',
        attach_company_logo: 'Adjuntar logo empresa',
        configuration: 'Configuración',
        environment: 'Entorno',
        exercises: 'Ejercicios',
        delete_exercise: 'Eliminar ejercicio',
        preparing_delete_exercise: 'Se dispone a eliminar el siguiente ejercicio',
        select: 'Seleccione',
        references: 'Referenciales',
        responsibles: 'Responsables',
        select_responsible: 'Seleccione responsable',
        statement: 'Enunciado',
        information: 'Información',
        score_legend: 'Leyenda puntuación',
        feedback: 'Feedback',
        subquestion: 'Subpregunta',
        type_of_exercise: 'Tipo de ejercicio',
        select_type_of_exercise: 'Seleccione tipo de ejercicio',
        delete_subquestion: 'Eliminar subpregunta',
        preparing_delete_subquestion: 'Se dispone a eliminar la siguiente subpregunta',
        add_new: 'Añadir nuevo',
        add_existing: 'Añadir existente',
        select_existing_exercise: 'Seleccione ejercicio existente',
        answers: 'Respuestas',
        correct: 'Correctas',
        punctuation: 'Puntuación',
        minimum_value: 'Valor mínimo',
        maximum_value: 'Valor máximo',
        conditions: 'Condiciones',
        select_conditions: 'Seleccione condición',
        value_greather_than: 'Si valor mayor que',
        value_less_than: 'Si valor menor que',
        value_same_as: 'Si valor igual que',
        value_between: 'Si valor entre',
        and: 'y',
        delete_condition: 'Eliminar condición',
        add_another_condition: 'Añadir otra condición',
        evidence_is_necessary: 'Es necesaria una evidencia',
        add_example: 'Añadir ejemplo',
        add_metric: 'Añadir métrica',
        add_procedure: 'Añadir procedimiento',
        support_material: 'Material de apoyo',
        select_examples: 'Seleccione ejemplos',
        support_material_2: 'Material de soporte',
        select_support_material_2: 'Seleccione material de soporte',
        metrics: 'Métricas',
        delete_bibliography: 'Eliminar bibliografía',
        preparing_delete_bibliography: 'Se dispone a eliminar la siguiente bibliografía',
        modify_image: 'Modificar imagen',
        attached_file: 'Archivo adjunto',
        qualification: 'Título',
        author: 'Autor',
        editor: 'Editor',
        publication_date: 'Fecha de publicación',
        link: 'Link',
        delete_example: 'Eliminar ejemplo',
        preparing_delete_example: 'Se dispone a eliminar el siguiente ejemplo',
        example: 'Ejemplo',
        delete_procedure: 'Eliminar procedimiento',
        prepare_delete_procedure: 'Se dispone a eliminar el siguiente procedimiento',
        procedure: 'Procedimiento',
        delete_responsible: 'Eliminar responsable',
        preparing_delete_responsible: 'Se dispone a eliminar el siguiente responsable',
        delete_stamp: 'Eliminar sello',
        preparing_delete_stamp: 'Se dispone a eliminar el siguiente sello',
        attach_image: 'Adjuntar imagen',
        stamp: 'Sello',
        stamps: 'Sellos',
        delete_sector: 'Eliminar sector',
        preparing_delete_sector: 'Se dispone a eliminar el siguiente sector',
        delete_support_material: 'Eliminar material de soporte',
        preparing_delete_support_material: 'Se dispone a eliminar el siguiente material de soporte',
        example_item: 'Elemento de ejemplo',
        delete_exercise_type: 'Eliminar tipo de ejercicio',
        preparing_delete_exercise_type: 'Se dispone a eliminar el siguiente tipo de ejercicio',
        type: 'Tipo',
        select_type: 'Seleccione tipo',
        mandatory: 'Obligatorio',
        possible_answers: 'Posibles respuestas',
        correct_answers: 'Respuestas correctas',
        condition: 'Condición',
        evidence: 'Evidencias',
        delete_videography: 'Eliminar videografía',
        preparing_delete_videography: 'Se dispone a eliminar la siguiente videografía',
        sectors: 'Sectores',
        types_of_exercises: 'Tipos de ejercicios',
        general: 'General',
        assignment_group: 'Grupo asignación',
        delete_assignment: 'Eliminar asignación',
        prepare_delete_assignment: 'Se dispone a eliminar la siguiente asignación',
        selected_users: 'Usuarios seleccionados',
        no_filter_has_been_applied: 'No se ha aplicado ningún filtro',
        delete_category: 'Eliminar categoría',
        preparing_delete_category: 'Se dispone a eliminar la siguiente categoría',
        tasks: 'Tareas',
        holidays: 'Días festivos',
        delete_holiday: 'Eliminar día festivo',
        prepare_delete_holiday: 'Se dispone a eliminar el siguiente día festivo',
        new_holiday: 'Nuevo día festivo',
        data: 'Fecha',
        day: 'Día',
        month: 'Mes',
        year: 'Año',
        delete_priority: 'Eliminar prioridad',
        prepare_delete_priority: 'Se dispone a eliminar la siguiente prioridad',
        name_priority: 'Nombre prioridad',
        description_priority: 'Descripción prioridad',
        response_time: 'Tiempo Respuesta',
        time_resolution: 'Tiempo Resolución',
        percentage: 'Porcentaje',
        working_hours: 'Horas hábiles',
        schedule: 'Horario',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        start_time: 'Hora inicio',
        monday_start_time: 'Hora inicio del Lunes',
        tuesday_start_time: 'Hora inicio del Martes',
        wednesday_start_time: 'Hora inicio del Miércoles',
        thursday_start_time: 'Hora inicio del Jueves',
        friday_start_time: 'Hora inicio del Viernes',
        end_time: 'Hora fin',
        monday_end_time: 'Hora fin del Lunes',
        tuesday_end_time: 'Hora fin del Martes',
        wednesday_end_time: 'Hora fin del Miércoles',
        thursday_end_time: 'Hora fin del Jueves',
        friday_end_time: 'Hora fin del Viernes',
        category_configuration: 'Configuración Categorias',
        configuration_priorities: 'Configuración Prioridades',
        configuration_assignment: 'Configuración Asignación',
        schedule_configuration: 'Configuración Horario',
        holiday_configuration: 'Configuración Días festivos',
        flow_configuration: 'Configuración Flujos',
        help_manual_0: 'En el siguiente enlace podrá descargar el manual de ayuda:',
        help_manual_1: 'Manual de ayuda',
        help_manual_2: 'Manual de ayuda general de la aplicación Control de acceso',
        help_manual_file: 'manual de ayuda.txt',
        help: 'Ayuda',
        close_session: 'Cerrar sesión',
        modify_password: 'Modifica la contraseña',
        actual_password: 'Contraseña actual',
        new_password: 'Nueva Contraseña',
        repeat_new_password: 'Repetir Nueva Contraseña',
    }
};

const i18n = new VueI18n({
    locale: 'es', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    silentTranslationWarn: true,

});

export default i18n;

// eof: i18n.js
