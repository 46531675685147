import ConfigVideography from "@/view/config/general/videography/template/videography";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/config/general/videography',
        name: 'config_videography',
        component: ConfigVideography,
        props: {id: null, showMenu: true, otherTitle: i18n.t('videography')},
        children: [
            {
                path: '/config/general/videography/:id',
                name: 'config_videography_id',
                component: ConfigVideography,
                props: {id: null, showMenu: true, otherTitle: i18n.t('videography')},
            },
            {
                path: '/config/general/videography/:id/:view',
                name: 'config_videography_id_view',
                component: ConfigVideography,
                props: {id: null, view: true, showMenu: true, otherTitle: i18n.t('videography')},
            }
        ]
    }
];

export default routes;