var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-content-global"},[_c('input',{attrs:{"type":"hidden","name":"type","value":"email"}}),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                    text: _vm.$t('note') + ':',
                    class: ' T15',
                    styles:'width: 66px; min-width: 74px !important; text-align: right; margin-right: 12px;',
                },"datainput":{
                    id: 'exercise_open_question_statement',
                    name: 'note',
                    value:  '',
                }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }