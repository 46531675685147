<template>
    <div>
        <div class="gh-row-content" style="margin-top: 10px">
            <template v-if="!$root._route.params.view">
                <GhInputTextField
                    :datalabel="{
                        text: $t('possible_answers') + '*',
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    }"
                    :datainput="{
                        required: true,
                        id: 'type_exercise_possible_answers',
                        name: 'type_exercise[possible_answers]',
                        style: 'width: 50px',
                        value: ConfigTypeExercise.data.possible_answers,
                        type: 'int',
                        limitNumMin: 1
                    }"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('possible_answers') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data.possible_answers}}</label>
            </template>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{text: $t('feedback'), style: 'width: 130px', id: 'label_type_exercise_feedback'}"
                :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_feedback', value: 1, name: 'type_exercise[feedback][]',
                    selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.feedback && ConfigTypeExercise.data.config.feedback[0] == 1 ? true : false,
                    disabled: !$root._route.params.view ? false : true
                }"
            />
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{
                    text: $t('subquestion'),
                    style: 'width: 130px',
                    id: 'label_type_exercise_subquestion'
                }"
                :datainput="{
                    required: false,
                    type: 'checkbox',
                    id: 'value_type_exercise_subquestion',
                    value: 1,
                    name: 'type_exercise[subquestion][]',
                    selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.subquestion && ConfigTypeExercise.data.config.subquestion[0] == 1 ? true : false,
                    disabled: !$root._route.params.view ? false : true
                }"
            />
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{
                    text: $t('condition'),
                    style: 'width: 130px',
                    id: 'label_type_exercise_condition'
                }"
                :datainput="{
                    required: false,
                    type: 'checkbox',
                    id: 'value_type_exercise_condition',
                    value: 1,
                    name: 'type_exercise[condition][]',
                    selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.condition && ConfigTypeExercise.data.config.condition[0] == 1 ? true : false,
                    disabled: !$root._route.params.view ? false : true
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhChecks from 'fe-gh-checks-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import {mapState} from "vuex";
    export default {
        name: "type_close_one_correct",
        components: {
            GhChecks,
            GhInputTextField
        },
        computed: {
            ...mapState(['ConfigTypeExercise'])
        }
    }
</script>