<template>
    <GhTableCommon
        v-if="haveData"
        :extratable="{id: 'support_pending_requests_groups'}"
        :header="header"
        :data="SupportPendingRequestsGroups.records_data.length == 0 ? [] : SupportPendingRequestsGroups.records_data"
    >
        <template v-slot:actions="{itemProp}">
            <router-link :to="{name: 'incidence_id', params: {id:itemProp.id}}">
                <GhAction
                    :dataction="{
                        id: 'eject_support_pending_requests_groups',
                        text: $t('execute'),
                        icon: require('../../../assets/gh_new_dessign/edit.svg'),
                    }"
                />
            </router-link>
        </template>
    </GhTableCommon>
</template>
<script>
    import {mapState} from "vuex";
    import GhTableCommon from "fe-gh-table-common-lib"
    import GhAction from "fe-gh-action-lib";
    export default {
        name: "list_pending_requests_groups",
        components: {
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapState(['SupportPendingRequestsGroups'])
        },
        data(){
            return {
                haveData:false,
                header: [
                    {text: this.$t('identifier'), field: 'ticket_reference', contentAlt: 'user', title: 'Test', sorting: true, typeSort: 'string', style:'min-width: 90px; width: 150px;'},
                    {text: this.$t('user'), field: 'ticket_created_by', sorting: true,typeSort: 'string', style: 'min-width: 90px; width:10%;'},
                    {text: this.$t('company'), field: 'company', sorting: true, typeSort: 'string', style: 'min-width: 90px; width:10%;'},
                    {text: this.$t('description'), field: 'description', sorting: true,typeSort: 'string', style: 'max-width: 200px;width:29%;'},
                    {text: this.$t('assign_to'), field: 'group_name', sorting: true,typeSort: 'string', style: 'min-width: 90px; width:10%;'},
                    {text: this.$t('priority'), field: 'priority_name', sorting: true,typeSort: 'string', style: 'min-width: 90px; width:10%;'},
                    {text: this.$t('fe'), field: 'entry_date', sorting: true,typeSort: 'string', style: ' min-width: 40px; max-width: 90px; width:7%;'},
                    {text: this.$t('fp'), field: 'estimated_end_date', sorting: true,typeSort: 'string', style: 'min-width: 40px; max-width: 90px; width:7%;', keyFormat:'format'},
                    {text: this.$t('ua'), field: 'updated_date', sorting: true,typeSort: 'string', style: 'min-width: 40px; max-width: 90px; width:7%;'},
                    {text: '', field: 'actions', style: ''}
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getPendingRequestsGroups', '', {root: true});
            this.haveData = true;
        }
    }
</script>
