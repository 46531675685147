var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[(_vm.show)?_c('GhNoDropSelector',{attrs:{"datablock":{
            id: 'drop_selector_section_itinerary',
            title: _vm.$t('itineraries'),
        }},scopedSlots:_vm._u([(_vm.haveData)?{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global centered",staticStyle:{"display":"flex"}},_vm._l((_vm.TabMyItinerary.records_data),function(item,index){return _c('div',{key:index,staticStyle:{"display":"block","padding":"40px"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","margin-bottom":"20px"}},[_c('div',{staticClass:"icon_selector_quizz"},[_c('img',{attrs:{"src":require('@/assets/img/itinerary/' + item.icon)}})])]),_c('div',{staticClass:"T15_b name_quizz",staticStyle:{"display":"flex","justify-content":"center","max-width":"150px","text-align":"center"}},[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","margin-top":"20px"}},[_c('router-link',{attrs:{"to":{name: 'Assignment', params: { id: item.id, view: true}}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_assign_' + item.id,
                                    text: _vm.$t('assign'),
                                    style: 'margin-right: 0px!important'
                                }}})],1)],1)])}),0)]},proxy:true}:null],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }