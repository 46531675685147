<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'example_popup',
            title: $t('add_example'),
            type: 'add',
            style: 'width: 50%; min-width:920px;',
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'max-height: 40vh; min-height: 2vh; overflow-y: auto !important; padding-bottom: 0px !important'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <form id="form_example">
                <component v-bind:is="form_example" />
            </form>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'btn_example_accept_popup',
                    text: $t('save')
                }"
                @click="submitExample"
            />
            <GhButton
                :datainput="{
                    id: 'btn_example_close_popup',
                    text: $t('close')
                }"
                @click="Global.closePopUp('example_popup');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import form_example from '../../../../general/examples/template/form_example';
    import GhPopUp from 'fe-gh-popup-lib';
    import GhButton from 'fe-gh-button-lib';
    export default {
        name: "example_popup",
        components: {
            form_example,
            GhPopUp,
            GhButton
        },
        data(){
            return {
                form_example: form_example
            }
        },
        methods: {
            async submitExample(){
                if(this.Global.checkRequired('form_example') == 'OK') {
                    var pre_checked_example = new Array();

                    document.getElementsByName('exercise[example][]').forEach(datas => {
                        if(datas.checked){
                            pre_checked_example.push(datas.value);
                        }
                    });

                    const formData = new FormData(document.getElementById('form_example'));

                    await this.$store.dispatch('setExampleDataFromExercise', {
                        formData: [...formData]
                    }, {root: true}).then(async response => {
                        if(response) {
                            this.$parent.$parent.$refs.GhSelectorExample.objectfeedcolumn = [];
                            for(let elemento in this.$parent.$parent.$refs.GhSelectorExample.feed) {
                                await this.$parent.$parent.$refs.GhSelectorExample.objectfeedcolumn.push(elemento);
                            }

                            this.Global.closePopUp('example_popup');

                            var index = this.$parent.$parent.$refs.GhSelectorExample.$children[0].$children.findIndex(checks => checks._props.datainput.value == response);
                            this.$parent.$parent.$refs.GhSelectorExample.$children[0].$children[index].checkUncheck();

                            pre_checked_example.forEach(preChecks => {
                                index = this.$parent.$parent.$refs.GhSelectorExample.$children[0].$children.findIndex(checks => checks._props.datainput.value == preChecks);

                                if(!document.querySelectorAll('input[name="exercise[example][]"][value="'+preChecks+'"]')[0].checked) {
                                    this.$parent.$parent.$refs.GhSelectorExample.$children[0].$children[index].checkUncheck();
                                }
                            });
                        }
                    });
                    // this.Global.closePopUp('example_popup');
                    // this.$store.dispatch('setProcedureData', {
                    //     formData: [...formData],
                    //     id: this.$root._route.params.id
                    // }, {root: true});
                }
            }
        }
    }
</script>