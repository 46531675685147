var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData && _vm.haveDataRefreshed)?_c('div',[_c('GhSubTab',{ref:"subtab",attrs:{"items":_vm.$router.options.GhSubTabs,"dataBlock":{id: 'projects_tab_', calldispatch: 'getSpecificQuizz'}}}),_c('form',{ref:"form",attrs:{"id":"form_quizz"}},[_c('router-view',{key:_vm.refreshChildren,on:{"hook:updated":_vm.handleMounted}})],1),_c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],key:_vm.video.refresh,attrs:{"generalConfiguration":{
            id: 'video_quizz_popup',
            title: _vm.video.name,
            type: 'info',
            style: 'width: 1024px; min-width: 1024px'
        },"bodyConfiguration":{id: 'video_body_quizz_popup', class: '', style: 'overflow-y: auto;max-height: 800px;padding-left: 20px;', text: ''},"footerConfiguration":{id: 'video_footer_quizz_popup', class: '', style: ''}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('video',{attrs:{"width":"1024","height":"400","controls":""}},[_c('source',{attrs:{"src":_vm.video.url}})])]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                    id: 'video_close_btn_popup',
                    text: _vm.$t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }},on:{"click":function($event){return _vm.closeVideo()}}})]},proxy:true}],null,false,4209937488)}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[((_vm.stateQuestion === 2 && _vm.MainItinerary.finished.finish) || (_vm.stateQuestion === 1 && !_vm.pendingChildrens))?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_end',
                text: _vm.$t('finish'),
                class: '',
                style: ''
            }},on:{"click":function($event){return _vm.finish();}}}):_vm._e(),(_vm.stateQuestion === 0)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save'),
                class: '',
                style: ''
            }},on:{"click":function($event){return _vm.save();}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{id: 'btn_back',text: _vm.$t('back'),class: '', style: ''}},on:{"click":function($event){return _vm.$router.go(-1)}}})],1),_c('div',{staticClass:"gh_text_field-label T15_b centered",staticStyle:{"padding-top":"10px","height":"20px","color":"green"}},[(_vm.MainItinerary.finished.finish)?_c('span',[_vm._v(_vm._s(_vm.$t('all_itineraries_exercises_finish')))]):(_vm.stateQuestion === 2)?_c('span',[_vm._v(_vm._s(_vm.$t('exercise_finish')))]):(_vm.pendingChildrens)?_c('span',[_vm._v(_vm._s(_vm.$t('to_finish_exercise')))]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }