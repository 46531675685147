import ListActivities from "@/view/creation/activities/list_activities";
import Actividad from "@/routes/creation/activities/activity/route";

const route = [
    {
        path: '/list_creation_activities',
        name: 'list_creation_activities',
        component: ListActivities,
        props: {showMenu: true}
    },
];

const routes = [
    ...route,
    ...Actividad,
]


export default routes;