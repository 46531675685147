<template>
    <div style="display: flex;">
        <div class="gh-content-global" style="width: 15%">
            <div style="width: 250px; height: 135px;">
                <img :src="image" width="250" style="border-radius: 6px; max-height: 135px; width: auto;">
            </div>
            <div class="other-image">
                <GhEasyUpload
                    :extrablock="{
                        id: 'example_other_image',
                        class: '',
                        name_hidden: 'example[other_image]',
                        id_hidden: 'example_other_image',
                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'example_other_image',
                        style: 'margin-right: -15px'
                    }"
                    :datainput="{
                        view: $root._route.params.view,
                        accept: '.png, .jpg, .jpeg',
                        text: $t('modify_image'),
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: '',
                        file_path: '',
                        file_download: '',
                        required: false
                    }"
                />
            </div>
        </div>
        <div class="gh-content-global" style="width: 85%">
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('code') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'example_code',
                            name: 'example[code]',
                            style: 'width: 200px',
                            value: ConfigExamples.data.code,
                            type: 'text'
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExamples.data.code}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('name') + '*',
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: true,
                            id: 'example_name',
                            name: 'example[name]',
                            style: 'min-width: 200px; width: 20%',
                            value: ConfigExamples.data.name,
                            type: 'text',
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExamples.data.name}}</label>
                </template>
            </div>
            <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                <template v-if="!$root._route.params.view">
                    <GhDataSelector
                        :datablock="{
                            id: 'label_example_sector',
                            class: 'T15_b',
                            subtitle: $t('select_sector') + ':',
                            text: $t('sector') + '*',
                            label: $t('sector'),
                            type: 'radio',
                            columns: 3,
                            style: 'margin-left:94px;',
                            required: true,
                        }"
                        :datainput="{
                            id: 'value_example_sector',
                            name: 'example[sector]'
                        }"
                        :selected_input="ConfigExamples.data.sector ? ConfigExamples.data.sector : []"
                        :feed="getFeeds.feed_sector"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('sector') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExamples.data.text_sector}}</label>
                </template>
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhTextarea
                        :datalabel="{
                            text: $t('description'),
                            styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                        }"
                        :datainput="{
                            id: 'example_description',
                            name: 'example[description]',
                            value: ConfigExamples.data.description
                        }"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigExamples.data.description}}</label>
                </template>
            </div>
            <div class="gh-row-content" :style="$root._route.params.view ? '' : 'flex-flow: row-reverse;'">
                <template v-if="$root._route.params.view">
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('attached_file') + ' (PDF)' + ':'}}</label>
                </template>
                <GhEasyUpload
                    :extrablock="{
                        id: 'example',
                        class: '',
                        name_hidden: 'example[document]',
                        id_hidden: 'example',
                        icon_attach: require('../../../../../assets/gh_new_dessign/attach.svg')
                    }"
                    :datalabel="{
                        id: 'label_import_block',
                        style: 'margin-right: -15px'
                    }"
                    :datainput="{
                        view: $root._route.params.view,
                        accept: '.pdf, .png, .jpg, .jpeg',
                        text: $t('attached_file')  + ' (PDF/Img)',
                        show_image: false,
                        delete: false,
                        href: 'easyUpload',
                        downloadHref: 'easyDownload',
                        file_name: ConfigExamples.data.file_name,
                        file_path: ConfigExamples.data.file_path,
                        file_download: ConfigExamples.data.file_download,
                        required: link_http !== '' ? false : true
                    }"
                />
            </div>
            <div class="gh-row-content">
                <template v-if="!$root._route.params.view">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('link'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'example_link',
                            name: 'example[link]',
                            style: 'width: 500px',
                            value: link_http,
                            type: 'text',
                        }"
                        ref="RefLink"
                        @keyup="setLink()"
                    />
                </template>
                <template v-else>
                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('link') + ':'}}</label>
                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{link_http}}</label>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhEasyUpload from 'fe-gh-easy-upload-lib';
    import {mapState} from "vuex";
    export default {
        name: "form_example",
        components: {
            GhInputTextField,
            GhDataSelector,
            GhTextarea,
            GhEasyUpload
        },
        computed: {
            ...mapState(['ConfigExamples', 'getFeeds', 'UploadFiles'])
        },
        data(){
            return {
                image: '',
                link_http: '',
                refreshUpload: 0
            }
        },
        async beforeMount(){
            this.image = this.ConfigExamples.data.image;
            this.link_http = this.ConfigExamples.data.link_http !== undefined ? this.ConfigExamples.data.link_http : '';
        },
        watch:{
            'UploadFiles.data'(data){
                this.image = data.image;
            }
        },
        methods: {
            setLink(){
                this.link_http = this.$refs['RefLink'].datainput.value;
                this.refreshUpload++;
            }
        }
    }
</script>

<style>
    .other-image > .easy_upload_block{
        flex-direction: initial;
    }
    .other-image > div > #example_other_image_easy_download_block{
        display: none !important;
    }
</style>