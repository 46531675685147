import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
// import router from "@/routes/router";
Vue.use(vuex);
export default {
    state: {
        company_assign_itinerary: [],
    },
    mutations: {
        loadCompanyAssignItinerary(state,payload){
            state.company_assign_itinerary = payload;
        },
    },
    actions: {
        async getCompanyAssignItinerary(state,item){
            console.log(item);
            await axios.get(
                API_URL + "company_assign_itinerary"
            ).then(
                response => {
                    console.log(response);
                    state.commit('loadCompanyAssignItinerary', response.data.data);
                }
            )
        },
        async setCompanyAssingItinerary(state, item){
            let requestLogin = API_URL + "set_assign_company_itinerary";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    // state.commit("loadAroundData", response.data);
                    console.log(response);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}
