<template>
    <div style="margin-top: -3px;">
        <GhLocation
            :dataLocation="{
                showButton: true,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('tutorships')
            }"
            :dataButton="{id: 'create_incidence', text: $t('support'), style: ''}"
            @button="Global.windowOpen(Global.openSecondWindow('incidence_new',{id:1,add:true,floatWindow:true}),'new_incidence')"
        />

        <div class="content_page">
            <GhNoDropSelector
                :datablock="{
                    id: 'tutor',
                    title: $t('tutorships'),
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <form id="form_tutor" v-if="haveData">
                        <div class="gh-content-global">
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 149px; min-width: 149px; text-align: right;">{{$t('petition') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 50%; min-width: 100px;">{{TabTutor.tutorData !== undefined ? TabTutor.tutorData.petition : ''}}</label>
                            </div>
                            <div class="gh-row-content">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('answer') + '*',
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        id: 'tutor_answer',
                                        name: 'tutor[answer]',
                                        value: TabTutor.tutorData !== undefined ? TabTutor.tutorData.answer : '',
                                        required: true
                                    }"
                                />
                            </div>
                        </div>
                    </form>
                </template>
            </GhNoDropSelector>
            <div class="centered" style="margin-top: 8px; padding-bottom: 8px;">
                <template v-if="TabTutor.tutorData !== undefined">
                    <GhButton v-if="TabTutor.tutorData.finish"
                        :datainput="{
                            id: 'btn_save_tutor',
                            class: 'T19 container-md_button_content',
                            text: $t('finish')
                        }"
                        @click="saveTutor(true)"
                    />
                </template>
                <GhButton
                    :datainput="{
                        id: 'btn_save_tutor',
                        class: 'T19 container-md_button_content',
                        text: $t('save')
                    }"
                    @click="saveTutor(false)"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_close_tutor',
                        class: 'T19 container-md_button_content',
                        text: $t('close')
                    }"
                    @click="Global.windowClose()"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import {mapState} from "vuex";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "edit_tutor",
        components: {
          GhButton,
            GhLocation,
            GhNoDropSelector,
            GhTextarea,
        },
        computed: {
            ...mapState(['TabTutor'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getTutorDataById',{id: this.$root._route.params.id},{root:true});
            this.haveData = true;
        },
        methods: {
            async saveTutor(state){
                const formData = new FormData(document.getElementById('form_tutor'));
                if(state){
                    if (this.Global.checkRequired('form_tutor') == 'OK') {
                        this.$store.dispatch('setTutorData', {
                            formData: [...formData],
                            id: this.$root._route.params.id,
                            state: state
                        }, {root: true}).then((item) => {
                            this.Global.windowClose();
                            this.$store.dispatch('getTutorSections', '', {root: true});
                            return item;
                        });
                    }
                }else{
                    this.$store.dispatch('setTutorData', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        state: state
                    }, {root: true}).then((item) => {
                        return item;
                    });
                }
            }
        }
    }
</script>