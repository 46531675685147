import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
    },
    mutations: {
        loadBusinessData(state, payload){
            state.records_data = payload;
        }
    },
    actions: {
        async getBusinessData(state,item){
            let requestLogin = item.id ? API_URL + "user/company/"+item.id : API_URL + "user/company";
            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadBusinessData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setBusinessData(state, item){
            let requestLogin = API_URL + "user/company/update";
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.data !== undefined) {
                        state.dispatch('getBusinessData', '', {root: true});
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};
