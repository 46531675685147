<template>
    <div v-if="haveData">
        <div v-for="(item,index) in SupportIndicators.header_actions_indicators" :key="index" :class="index == 0 ? '' : 'section' ">
            <GhTableCommon
                v-if="haveData"
                :extratable="{id: 'support_indicator_'+index}"
                :header="item"
                :data="SupportIndicators.data_actions_indicators[index]"
            />
        </div>
    </div>
</template>

<script>
    import GhTableCommon from 'fe-gh-table-common-lib'
    import {mapState} from "vuex";
    export default {
        name: "list_indicators",
        data(){
            return{
                haveData: false,
            }
        },
        components:{
            GhTableCommon
        },
        computed:{
            ...mapState(['SupportIndicators'])
        },
        async beforeMount(){
            await this.$store.dispatch('getActionsIndicators', {id:this.$root._route.params.id, type: 'support'},{root:true});
            this.haveData = true;
        }
    }
</script>