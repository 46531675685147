var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',{staticStyle:{"padding-left":"12px","padding-right":"12px"}},[_c('form',{attrs:{"id":"to_register"}},[_c('GhNoDropSelector',{attrs:{"datablock":{id:'register_company',title: _vm.$t('company'),second_title:'' }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('company_name') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'company_name',
                                name: 'register[company][name]',
                                style: 'width: 20%',
                                value: '',
                                type: 'text',
                            }}}),_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'social_reason',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_social_reason') + ':',
                                text: _vm.$t('social_reason')+'*',
                                label: _vm.$t('social_reason'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: 'margin-left:84px;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            },"datainput":{
                                id: 'value_social_Reason',
                                name: 'register[company][social_reason][]'
                            },"feed":_vm.getFeeds.feed_social_reason,"selected_input":[]}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('cif') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'company_cif',
                                name: 'register[company][cif]',
                                style: 'width: 20%',
                                value: '',
                                type: 'text',
                            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('full_address') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'company_direction',
                                name: 'register[company][direction]',
                                style: 'width: 20%',
                                value: '',
                                type: 'text',
                            }}}),_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'country',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_country') + ':',
                                text: _vm.$t('country')+'*',
                                label: _vm.$t('country'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: 'margin-left:84px;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            },"datainput":{
                                id: 'value_country',
                                name: 'register[company][country][]'
                            },"feed":_vm.getFeeds.feed_country,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('email') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'company_email',
                                name: 'register[company][email]',
                                style: 'width: 20%',
                                value: '',
                                type: 'text',
                            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('telephone') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'company_telephone',
                                name: 'register[company][telephone]',
                                style: 'width: 20%',
                                value: '',
                                type: 'text',
                            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTreeSelectorBlock',{attrs:{"dataBlock":{
                                type: 'radio',
                                id: 'activity',
                                label: _vm.$t('activity_sector') + '*',
                                title: _vm.$t('activity_sector'),
                                text: _vm.$t('select_activity_sector') + ':',
                                name: 'register[company][activity][]',
                                id_value: 'value',
                                name_label: 'text',
                                child_key: 'children',
                                required: true,
                                checkparentdata: false,
                                style: 'margin-left: 16px; width:100%',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            },"selected_input":[],"items":_vm.getFeeds.feed_activity}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'num_employee',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_number_employees') + ':',
                                text: _vm.$t('number_employees')+'*',
                                label: _vm.$t('number_employees'),
                                type: 'radio',
                                columns: 3,

                                required: true,
                                style: 'margin-left: -5px;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            },"datainput":{
                                id: 'value_num_employee',
                                name: 'register[company][num_employee][]'
                            },"feed":_vm.getFeeds.feed_num_employers,"selected_input":[]}})],1)])]},proxy:true}],null,false,262470824)}),_c('GhNoDropSelector',{attrs:{"datablock":{id:'register_contact',title: _vm.$t('contact_person'),second_title:'' }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('name') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'contact_name',
                                name: 'register[contact][name]',
                                style: 'width: 20%',
                                value: '',
                                type: 'text',
                            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('surnames') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'contact_surnames',
                                name: 'register[contact][surnames]',
                                style: 'width: 20%',
                                value: '',
                                type: 'text',
                            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                  text: _vm.$t('email') + '*',
                                  style:'width: 150px; text-align: right;',
                                  class: 'T15_b'
                              },"datainput":{
                                  required: true,
                                  id: 'contact_email',
                                  name: 'register[contact][email]',
                                  style: 'width: 20%',
                                  value: '',
                                  type: 'text',
                              }}}),_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'position',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_position') + ':',
                                text: _vm.$t('position')+'*',
                                label: _vm.$t('position'),
                                type: 'radio',
                                columns: 3,
                                required: true,
                                style: 'margin-left: 106px;',
                                popupStyle: 'min-width: 900px;width: 55%;',
                            },"datainput":{
                                id: 'value_num_employee',
                                name: 'register[contact][position][]'
                            },"feed":_vm.getFeeds.feed_positions,"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('contact_telephone') + '*',
                                style:'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'contact_telephone',
                                name: 'register[contact][telephone]',
                                style: 'width: 20%',
                                value: '',
                                type: 'text',
                            }}})],1)])]},proxy:true}],null,false,2188259839)}),_c('GhNoDropSelector',{attrs:{"datablock":{id:'register_treatment_data',title: _vm.$t('data_treatment'),second_title:'' }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm.getFeeds.data_treatment_text.text)+" ")]),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{text:_vm.$t('politic_privacity'),style:'',class:'',id:''},"datainput":{required:true,selected:false,type:'checkbox',id:'',value:'all',name:'register[politic_privacity]'}}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" "+_vm._s(_vm.getFeeds.data_treatment_text.text_confirmation)+" ")]),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datalabel":{text:_vm.$t('legal_advise'),style:'',class:'',id:''},"datainput":{required:true,selected:false,type:'checkbox',id:'',value:'all',name:'register[legal_advise]'}}})],1)])]},proxy:true}],null,false,3593170759)}),_c('div',{staticClass:"centered",staticStyle:{"margin-top":"10px"}},[_c('GhButton',{attrs:{"datainput":{id: 'btn_send',text: _vm.$t('send_request'),class: 'container-md_button text-inline t7', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.sendRegister();}}}),_c('GhButton',{attrs:{"datainput":{id: 'btn_back',text: _vm.$t('go_out'),class: 'container-md_button text-inline t7', style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'}},on:{"click":function($event){return _vm.back();}}})],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }