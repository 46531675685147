<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'procedure',title: $t('procedure')}">
            <template v-slot:content>
                <form id="form_procedure" v-if="haveData">
                    <component v-bind:is="form_procedure" />
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                @click="submitProcedure"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import form_procedure from "@/view/config/general/procedure/template/form_procedure";
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhButton from 'fe-gh-button-lib';
    import {mapState} from "vuex";
    export default {
        name: "procedure_template",
        components: {
            form_procedure,
            GhNoDropSelector,
            GhButton
        },
        computed: {
          ...mapState(['ConfigProcedures'])
        },
        data(){
            return {
                form_procedure: form_procedure,
                haveData: false,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getProcedureData',{id: this.$root._route.params.id},{root:true});
            this.haveData = true;
        },
        methods: {
            async submitProcedure(){
                if(this.Global.checkRequired('form_procedure') == 'OK') {
                    const formData = new FormData(document.getElementById('form_procedure'));
                    this.$store.dispatch('setProcedureData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        },
    }
</script>