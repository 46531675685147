<template>
    <div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <div style="display: flex; margin-left: 160px;">
                <GhChecks
                    :datalabel="{text: $t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.evidence !== undefined && ConfigTypeExercise.data.config.evidence[0] == 1 ? true : false,
                        disabled: !$root._route.params.view ? false : true,
                        type: 'checkbox',
                        id: 'value_type_exercise_evidence',
                        value: 1,
                        name: 'type_exercise[config][evidence][]'
                    }"
                    ref="GhCheckConfigEvidence"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)"
                />
                <GhChecks
                    :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'}"
                    :datainput="{
                        required: false,
                        selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.evidence !== undefined && ConfigTypeExercise.data.config.evidence[1] == 2 ? true : false,
                        disabled: !$root._route.params.view ? false : true,
                        type: 'checkbox',
                        id: 'value_type_exercise_required_evidence',
                        value: 2,
                        name: 'type_exercise[config][evidence][]'
                    }"
                    ref="GhCheckConfigEvidenceRequired"
                    @click="$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)"
                />
            </div>
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{text: $t('feedback'), style: 'width: 130px', id: 'label_type_exercise_feedback'}"
                :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_feedback', value: 1, name: 'type_exercise[feedback][]',
                    selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.feedback && ConfigTypeExercise.data.config.feedback[0] == 1 ? true : false,
                    disabled: !$root._route.params.view ? false : true
                }"
            />
        </div>
        <div class="gh-row-content" style="margin-left: 160px;">
            <GhChecks
                :datalabel="{text: $t('subquestion'), style: 'width: 130px', id: 'label_type_exercise_subquestion'}"
                :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_subquestion', value: 1, name: 'type_exercise[subquestion][]',
                    selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.subquestion && ConfigTypeExercise.data.config.subquestion[0] == 1 ? true : false,
                    disabled: !$root._route.params.view ? false : true,
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhChecks from 'fe-gh-checks-lib';
    import {mapState} from "vuex";
    export default {
        name: "type_open_question",
        components: {
            GhChecks,
        },
        computed: {
            ...mapState(['ConfigTypeExercise'])
        }
    }
</script>