<template>
    <div :style="haveData ? 'padding-bottom: 15px; padding-right: 15px;' : ''">
        <template v-if="haveData">
            <div v-for="(item, itemIndex) in TabUserFile.records_data.assignments" :key="itemIndex" style="padding-bottom: 5px;">
                <GhDropDownField
                    :dataBlock="{
                        id: 'drop_itinerary_' + item.id,
                        style: 'margin-left: 20px;',
                        title: item.text,
                        subTitle: item.data.length + ' ' + $t('results'),
                        isDrop: false
                    }"
                >
                    <template v-slot:content>
                        <GhTableCommon
                            :extratable="{
                                id: 'table_itinerary_' + item.id,
                            }"
                            :header="header"
                            :data="item.data.length == 0 ? [] : item.data"
                        >
                        </GhTableCommon>
                    </template>
                </GhDropDownField>
            </div>
        </template>
        <template v-else-if="!haveData">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
        </template>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhDropDownField from "fe-gh-drop-down-field-lib";
    import {mapState} from "vuex";

    export default {
        name: "list_assignments",
        components: {
            GhDropDownField,
            GhTableCommon
        },
        computed: {
            ...mapState(['TabUserFile'])
        },
        data(){
            return {
                haveData: false,
                header: [
                    {text: this.$t('company'), field: 'business', sorting: true, typeSort: 'string', style: 'min-width: 200px; width: 10%;'},
                    {text: this.$t('description'), field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 85%;'},
                    {text: this.$t('state'), field: 'state', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 5%;'},
                ],
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getUserAssignmentsData', '', {root: true})
            this.haveData = true;
        }
    }
</script>