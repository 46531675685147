import TutorList from "@/view/tutor/list_tutor";
import Tutor from './template/route';
import i18n from "@/plugins/i18n";

const route = [
    {
        path: '/tutor',
        name: i18n.t('tutorships'),
        component: TutorList,
        props: {showMenu: true},
    }
];

const routes = [
    ...route,
    ...Tutor
];

export default routes;