<template>
    <div v-if="haveData" style="padding-bottom: 12px;">
        <div v-for="label in data" :key="label.id">
            <GhDropSelector v-if="label.show"
                :datablock="{
                    id: label.id,
                    title: $t(label.name),
                    second_title:label.result ? TabSupport.counters[label.result] + ' ' + $t(label['second_text']) : '',
                    num_results: '',
                }"
            >
                <template v-slot:content>
                    <component v-bind:is="label.component" />
                </template>
                <template v-slot:actions v-if="label.actions">
                    <GhAction
                        v-for="action in label.actions"
                        :key="action.id"
                        :dataction="{id: action.id, class: 'T15_action',text:$t(action.name)}"
                        @click="action.id == 'export_monthly_support_indicator_data' ? exportTypeExcel(1) : '';  action.id == 'export_annual_support_indicator_data' ? exportTypeExcel(0) : ''"
                    />
                </template>
            </GhDropSelector>
        </div>
        <GhNoDropSelector
            v-if="SupportHistorical && SupportHistorical.haveResult==true"
            :datablock="{
                id: 'result_search_historical',
                title:typeof SupportHistorical.resultTitle == 'number' ? SupportHistorical.resultTitle + ' ' + $t('results') : $t(SupportHistorical.resultTitle),
                num_results: '',
            }"
        >
            <template v-slot:content>
                <component v-bind:is="result_search" />
            </template>
        </GhNoDropSelector>
    </div>
</template>
<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib'
    import GhDropSelector from "fe-gh-dropselector-lib";
    import GhAction from "fe-gh-action-lib"
    import result_search from './historical/result_content/result_historical'
    import list_pending_requests_groups from './pending_requests_groups/list_pending_requests_groups';
    import list_pending_requests from "./pending_requests/list_pending_requests";
    import list_indicators from "./indicators/list_indicators";
    import list_historical from "./historical/list_historical";
    import {mapState} from "vuex";
    export default {
        name: "list_support",
        components:{
            GhNoDropSelector,
            GhDropSelector,
            GhAction,
        },
        computed:{
            ...mapState(['TabSupport','SupportHistorical','Login'])
        },
        data(){
            return {
                haveData:false,
                result_search:result_search,
                data:[
                    {
                        id: 'support_pending_my_group',
                        name: this.$t('pending_request_my_group'),
                        result: 'support_pending_my_group',
                        second_text: this.$t('results'),
                        show: false,
                        component:list_pending_requests_groups,
                    },
                    {
                        id: 'support_pending',
                        name: this.$t('pending_request'),
                        result: 'support_pending',
                        second_text: this.$t('results'),
                        show: false,
                        component:list_pending_requests,
                    },
                    {
                        id: 'support_indicator',
                        name: this.$t('indicators'),
                        show: false,
                        component:list_indicators,
                        actions:[
                            {
                                id:'export_monthly_support_indicator_data',
                                name:this.$t('export_month'),
                            },
                            {
                                id:'export_annual_support_indicator_data',
                                name:this.$t('export_year')
                            }
                        ]
                    },
                    {
                        id: 'support_historic',
                        name: this.$t('historic'),
                        show: false,
                        component:list_historical,
                    },
                ],
              }
        },
        async beforeMount() {
            await this.$store.dispatch('getSupportCounters', '', {root: true});

            var that = this;
            this.$router.options.GhTabs.forEach((route) => {
                if(route.value == that.$t('Soporte')){
                    that.Login.tabs[route.id]['sub-modules'].forEach((subroute,subkey) => {
                        that.data[subkey].show = subroute.have_permission;
                    });
                }
            });

            this.haveData = true;
        },
        methods:{
            exportTypeExcel(type){
                this.$store.dispatch('exportTypeExcel', {where: 'support', type: type}, {root: true});
            }
        },
        destroyed() {
            this.$store.dispatch('destroyedHistoricalResultData',{},{root:true})
        }
    }
</script>
