<template>
    <div v-if="showBlock > 0">
        <GhDropSelector
            :datablock="{
                id: 'assign_itinerary_'+dataBlock.id,
                title: dataBlock.name,
                second_title: $t('results'),
                num_results: 0,
            }"
        >
            <template v-slot:content>
                <GhTableCommon
                    :extratable="{
                          id: 'itineraries_table',
                      }"
                    :header="header"
                    :data="TabAssing.tableAssignItinerary"
                >
                    <template v-slot:actions="{itemProp}">
                        <router-link :to="{name: 'itinerary_assign', params: {id: itemProp.id,id_itinerary:dataBlock.id}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_assingn_itinerary_'+dataBlock.id+'_'+itemProp.id,
                                    text: $t('assign'),
                                    icon: require('../../../assets/gh_new_dessign/edit.svg'),
                                }"
                            />
                        </router-link>
                    </template>
                </GhTableCommon>
            </template>
        </GhDropSelector>
    </div>
</template>

<script>
    import GhDropSelector from "fe-gh-dropselector-lib";
    import {mapState} from "vuex";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    export default {
        name: "list_assign_itineraries",
        components: {
          GhAction,
          GhTableCommon,
            GhDropSelector
        },
        props:['dataBlock'],
        computed:{
          ...mapState(['TabAssing']),
        },
        data(){
            return{
                showBlock: 0,
                actualId: '',
                header: [
                    {text: this.$t('cif'), field: 'cif', sorting: true, typeSort: 'string', style: 'min-width: 100px; width: 100px;'},
                    {text: this.$t('company'), field: 'name', sorting: true, typeSort: 'string', style: 'max-width: 200px; width: 100%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
            }
        },
        async beforeMount(){
            this.actualId = this.$props.dataBlock.id;
            await this.$store.dispatch('getAssignItinerary', {id:this.$props.dataBlock.id}, {root: true});
            this.showBlock++;
        },
        async updated(){
            if(this.actualId != this.$props.dataBlock.id){
                this.actualId = this.$props.dataBlock.id;
                await this.$store.dispatch('getAssignItinerary', {id:this.$props.dataBlock.id}, {root: true});
                this.showBlock++;
            }
        }
    }
</script>