<template>
    <div style="padding-bottom: 12px;">
        <GhSubTab v-bind:items="$router.options.GhSubTabsSearch" :dataBlock="{id: 'search_tab_', calldispatch: 'GhSubTabItem'}"/>
        <GhDropSelector :datablock="{ id: 'search', title: $t('searcher'), second_title: ''}">
            <template v-slot:content>
                <component v-bind:is="searchgeneral.component" />
            </template>
        </GhDropSelector>
        <GhNoDropSelector :datablock="{id: 'result' ,title: typeof TabSearch.resultTitle == 'number' ? TabSearch.resultTitle + ' ' + $t('results') : $t(TabSearch.resultTitle), second_title:'' }">
            <template v-slot:content>
                <component v-bind:is="TabSearch.subTabActive" />
            </template>
            <template v-slot:actions>
                <GhAction :dataction="{id: 'export_all_data', class: 'T15_action',text:$t('export_total')}" @click="exportTotal"/>
                <GhAction :dataction="{id: 'export_result_data', class: 'T15_action',text:$t('export_results')}" @click="exportResult"/>
            </template>
        </GhNoDropSelector>
    </div>
</template>
<script>
    import GhSubTab from 'fe-gh-subtab-lib';
    import GhAction from "fe-gh-action-lib";
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhDropSelector from 'fe-gh-dropselector-lib';
    import {mapState} from "vuex";
    import view_search from "@/view/search/search_content/view_search";
    export default {
        name: "list_search",
        components:{
            GhSubTab,
            GhNoDropSelector,
            GhDropSelector,
            GhAction
        },
        computed:{
            ...mapState(['TabSearch']),
        },
        methods:{
            exportTotal(){
                this.$store.dispatch('exportTotal',{data:this.dataexport[this.$store.state.TabSearch.nameSubTabActive]})
            },
            exportResult() {
                const formData = new FormData(document.getElementById('form_search'));
                this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData != '' && this.$store.state.TabSearch.searchData != undefined ? this.$store.dispatch('exportResult',{data:this.dataexport[this.$store.state.TabSearch.nameSubTabActive], formData: [...formData]}):''
            }
        },
        data(){
            return{
                dataexport: {'Itinerarios': 'itineraries'},
                searchgeneral: {component: view_search},
            }
        }
    }
</script>
