import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: '',
    },
    mutations: {
        loadMyItineraryData(state, payload){
            state.records_data = payload;
        }
    },
    actions: {
        async getMyItinerary(state){
            let requestLogin = API_URL + "list_itinerary";
            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadMyItineraryData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};