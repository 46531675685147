import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
Vue.use(vuex);
export default {
    state: {
        searchData:[],
        resultTitle: 'No se ha encontrado ningún resultado',
        haveResult:false,
    },
    mutations: {
        loadActionHistoricalData(state,payload){
            state.searchData = payload
        },
        loadActionHistoricalDataResult(state, payload){
            state.resultTitle = payload
        },
        loadActionHistoricalDataHaveResult(state, payload){
            state.haveResult = payload
        }
    },
    actions: {
        initialActionStateHistorical(state){
            state.commit("loadHistoricalHaveResult", false);
        },
        destroyedActionHistoricalResultData(state){
            state.commit("loadActionHistoricalData", '');
            state.commit('loadActionHistoricalDataResult', 'No se ha encontrado ningún resultado');
            state.commit("loadActionHistoricalDataHaveResult", false);
        },
        async searchActionHistoricalData(state,items){
            let requestLogin = API_URL +'commercial-management/search';
            let params = {};
            var count_state = 0;
            items.formData.forEach((item) => {
                if(item[0] == 'historical[state][]'){
                    params['historical[state]['+count_state+']'] = item[1];
                    count_state++;
                }else {
                    params[item[0]] = item[1];
                }
            });
            await axios({
                method: "get",
                url: requestLogin,
                params: params,
            }).then(
                response => {
                    if(response.status == 200){
                        state.commit("loadActionHistoricalData", response.data.data);
                        state.commit("loadActionHistoricalDataResult", response.data.data.length);
                        state.commit("loadActionHistoricalDataHaveResult", true);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
}
