import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import { API_URL } from "@/common/config";
Vue.use(vuex);

export default{
    state: {
        records_data: {
            recomended: '',
        },
        typeRecomendedButton: false,
        processRecomendedPay: false,
        itemsRecomendedPay: [],
    },
    mutations: {
        loadRecomendedItinerariesData(state, payload){
            state.records_data.recomended = payload;
        },
        loadRecomendedTypeButton: (state, payload) => (state.typeRecomendedButton = payload),
        loadRecomendedProcessPay: (state, payload) => (state.processRecomendedPay = payload),
        loadRecomendedItemsPay: (state, payload) => (state.itemsRecomendedPay = payload),
    },
    actions: {
        async getRecomendedItinerariesToBuy(state){
            let requestLogin = API_URL + "payments";
            await axios({
                method: "get",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                response => {
                    state.commit("loadRecomendedItinerariesData", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        setRecomendedItinerariesPay(state, item) {
            let requestLogin = API_URL + "payments"

            console.log('hi',item);

            axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    console.log(response);
                    await this.dispatch('getRecomendedItinerariesToBuy');
                    state.commit("loadRecomendedTypeButton", false);
                    state.commit("loadRecomendedProcessPay", false);
                    state.commit('loadRecomendedItemsPay', []);
                },
                error => {
                    console.log(error);
                }
            )
        },
    }
};