var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.haveData)?_c('div',[(_vm.show)?_c('GhDropSelector',{attrs:{"datablock":{
            id: 'certificates_section',
            title: _vm.$t('certificates'),
            second_title: _vm.$t('results'),
            num_results: _vm.TabCreation.listCertifictes.length,
        }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                    id: 'certificates_table',
                },"header":_vm.header,"data":_vm.TabCreation.listCertifictes},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'creation_certificates_id_view', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'creation_certificates_id', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'edit_creation_certificate',
                                text: _vm.$t('modify'),
                                icon: require('../../../assets/gh_new_dessign/edit.svg'),
                            }}})],1),_c('GhAction',{attrs:{"dataction":{
                            id: 'delete_creation_certificate',
                            text: _vm.$t('delete'),
                            icon: require('../../../assets/gh_new_dessign/trash.svg'),
                        }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Certificate', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, _vm.$t('delete_certificate'), _vm.$t('preparing_delete_certificate'), 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'creation_certificates'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_add_creation_certificate',
                                    text: _vm.$t('add')
                                }}})],1)],1)]},proxy:true}],null,false,2744461487)})]},proxy:true}],null,false,2378240165)}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }