<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'list_config_group',title: $t('schedule') + ': ' + ConfigSchedule.data.assignment_group }">
            <template v-slot:content>
                <form id="form_schedule" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                        <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">&nbsp;</label></div>
                        <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('monday')}}</label></div>
                        <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('tuesday')}}</label></div>
                        <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('wednesday')}}</label></div>
                        <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('thursday')}}</label></div>
                        <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{$t('friday')}}</label></div>
                        </div>
                    </div>
                    <div class="gh-content-global" style="margin-top: -25px;">
                        <div class="gh-row-content">
                            <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('start_time') + '*'}}</label></div>
                            <div>
                                <template v-if="!$root._route.params.view">
                                    <GhInputHour
                                        :datalabel="{text: ''}"
                                        :datainput="{
                                            id: 'start_monday',
                                            name: 'schedule[start][monday]',
                                            required: true,
                                            label_required: $t('monday_start_time') + '*',
                                            hour: ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.monday && ConfigSchedule.data.start.monday.hour ? ConfigSchedule.data.start.monday.hour : '',
                                            minute: ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.monday && ConfigSchedule.data.start.monday.minute ? ConfigSchedule.data.start.monday.minute : '',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.start.monday.hour + ':' + ConfigSchedule.data.start.monday.minute}}</label>
                                </template>
                            </div>
                            <div>
                                <template v-if="!$root._route.params.view">
                                    <GhInputHour
                                        :datalabel="{text: ''}"
                                        :datainput="{
                                            id: 'start_tuesday',
                                            name: 'schedule[start][tuesday]',
                                            style: 'margin-left: 115px;',
                                            required: true,
                                            label_required: $t('tuesday_start_time') + '*',
                                            hour: ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.tuesday && ConfigSchedule.data.start.tuesday.hour ? ConfigSchedule.data.start.tuesday.hour : '',
                                            minute: ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.tuesday && ConfigSchedule.data.start.tuesday.minute ? ConfigSchedule.data.start.tuesday.minute : ''
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.start.tuesday.hour+ ':' + ConfigSchedule.data.start.tuesday.minute}}</label>
                                </template>
                            </div>
                            <div>
                                <template v-if="!$root._route.params.view">
                                    <GhInputHour
                                        :datalabel="{text: ''}"
                                        :datainput="{
                                            id: 'start_wednesday',
                                            name: 'schedule[start][wednesday]',
                                            style: 'margin-left: 123px;',
                                            required: true,
                                            label_required: $t('wednesday_start_time') + '*',
                                            hour: ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.wednesday && ConfigSchedule.data.start.wednesday.hour ? ConfigSchedule.data.start.wednesday.hour : '',
                                            minute: ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.wednesday && ConfigSchedule.data.start.wednesday.minute ? ConfigSchedule.data.start.wednesday.minute : '',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.start.wednesday.hour + ':' + ConfigSchedule.data.start.wednesday.minute}}</label>
                                </template>
                            </div>
                            <div>
                                <template v-if="!$root._route.params.view">
                                    <GhInputHour
                                        :datalabel="{text: ''}"
                                        :datainput="{
                                            id: 'start_thursday',
                                            name: 'schedule[start][thursday]',
                                            style: 'margin-left: 109px;',
                                            required: true,
                                            label_required: $t('thursday_start_time') + '*',
                                            hour: ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.thursday && ConfigSchedule.data.start.thursday.hour?ConfigSchedule.data.start.thursday.hour:'',
                                            minute: ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.thursday && ConfigSchedule.data.start.thursday.minute?ConfigSchedule.data.start.thursday.minute:''
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.start.thursday.hour+ ':' + ConfigSchedule.data.start.thursday.minute}}</label>
                                </template>
                            </div>
                            <div>
                                <template v-if="!$root._route.params.view">
                                    <GhInputHour
                                        :datalabel="{text: ''}"
                                        :datainput="{
                                            id: 'start_friday',
                                            name: 'schedule[start][friday]',
                                            style: 'margin-left: 115px;',
                                            required: true,
                                            label_required: $t('friday_start_time') + '*',
                                            hour: ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.friday && ConfigSchedule.data.start.friday.hour ?ConfigSchedule.data.start.friday.hour : '',
                                            minute:ConfigSchedule.data && ConfigSchedule.data.start && ConfigSchedule.data.start.friday && ConfigSchedule.data.start.friday.minute ? ConfigSchedule.data.start.friday.minute : '',
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.start.friday.hour + ':' + ConfigSchedule.data.start.friday.minute}}</label>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="gh-content-global" style="padding:0px 15px;">
                        <div class="gh-row-content">
                            <div><label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('end_time') + '*'}}</label></div>
                            <div>
                                <template v-if="!$root._route.params.view">
                                    <GhInputHour
                                        :datalabel="{text: ''}"
                                        :datainput="{
                                            id: 'finish_monday',
                                            name: 'schedule[finish][monday]',
                                            required: true,
                                            label_required: $t('monday_end_time') + '*',
                                            hour: ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.monday && ConfigSchedule.data.finish.monday.hour ? ConfigSchedule.data.finish.monday.hour : '',
                                            minute:ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.monday && ConfigSchedule.data.finish.monday.minute ? ConfigSchedule.data.finish.monday.minute : ''
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.finish.monday.hour + ':' + ConfigSchedule.data.finish.monday.minute}}</label>
                                </template>
                            </div>
                            <div>
                            <template v-if="!$root._route.params.view">
                                <GhInputHour
                                    :datalabel="{text: ''}"
                                    :datainput="{
                                        id: 'finish_tuesday',
                                        name: 'schedule[finish][tuesday]',
                                        style: 'margin-left: 115px;',
                                        required: true,
                                        label_required: $t('tuesday_end_time') + '*',
                                        hour: ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.tuesday && ConfigSchedule.data.finish.tuesday.hour ? ConfigSchedule.data.finish.tuesday.hour : '',
                                        minute: ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.tuesday && ConfigSchedule.data.finish.tuesday.minute ? ConfigSchedule.data.start.tuesday.minute : '',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.finish.tuesday.hour + ':' + ConfigSchedule.data.finish.tuesday.minute}}</label>
                            </template>
                            </div>
                            <div>
                                <template v-if="!$root._route.params.view">
                                <GhInputHour
                                    :datalabel="{text: ''}"
                                    :datainput="{
                                        id: 'finish_wednesday',
                                        name: 'schedule[finish][wednesday]',
                                        style: 'margin-left: 123px;',
                                        required: true,
                                        label_required: $t('wednesday_end_time') + '*',
                                        hour: ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.wednesday && ConfigSchedule.data.finish.wednesday.hour?ConfigSchedule.data.finish.wednesday.hour:'',
                                        minute: ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.wednesday && ConfigSchedule.data.finish.wednesday.minute?ConfigSchedule.data.start.wednesday.minute:'',
                                    }"
                                />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.finish.wednesday.hour+ ':' + ConfigSchedule.data.finish.wednesday.minute}}</label>
                                </template>
                            </div>
                            <div>
                                <template v-if="!$root._route.params.view">
                                <GhInputHour
                                    :datalabel="{text: ''}"
                                    :datainput="{
                                        id: 'finish_thursday',
                                        name: 'schedule[finish][thursday]',
                                        style: 'margin-left: 109px;',
                                        required: true,
                                        label_required: $t('thursday_end_time') + '*',
                                        hour: ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.thursday && ConfigSchedule.data.finish.thursday.hour ? ConfigSchedule.data.finish.thursday.hour : '',
                                        minute: ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.thursday && ConfigSchedule.data.finish.thursday.minute ? ConfigSchedule.data.finish.thursday.minute : '',
                                    }"
                                />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.finish.thursday.hour + ':' + ConfigSchedule.data.finish.thursday.minute}}</label>
                                </template>
                            </div>
                            <div>
                                <template v-if="!$root._route.params.view">
                                <GhInputHour
                                    :datalabel="{text: ''}"
                                    :datainput="{
                                        id: 'finish_friday',
                                        name: 'schedule[finish][friday]',
                                        style: 'margin-left: 115px;',
                                        required: true,
                                        label_required: $t('friday_end_time') + '*',
                                        hour: ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.friday && ConfigSchedule.data.finish.friday.hour ? ConfigSchedule.data.finish.friday.hour : '',
                                        minute: ConfigSchedule.data && ConfigSchedule.data.finish && ConfigSchedule.data.finish.friday && ConfigSchedule.data.finish.friday.minute ? ConfigSchedule.data.finish.friday.minute : '',
                                    }"
                                />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px;">{{ConfigSchedule.data.finish.friday.hour + ':' + ConfigSchedule.data.finish.friday.minute}}</label>
                                </template>
                            </div>
                        </div>
                    </div>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px;">
            <GhButton
                v-if="!$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                type="submit"
                @click="setSchedule()"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputHour from 'fe-gh-input-hour-lib';
    import GhButton from "fe-gh-button-lib";
    import {mapState} from "vuex";
    export default {
        name: "schedule_template",
        components: {
            GhNoDropSelector,
            GhInputHour,
            GhButton
        },
        data(){
            return{
                haveData:false,
            }
        },
        computed:{
           ...mapState(['ConfigSchedule'])
        },
        methods:{
            setSchedule(){
                if(this.Global.checkRequired('form_schedule') == 'OK') {
                    const formData = new FormData(document.getElementById('form_schedule'));
                    this.$store.dispatch('setScheduleData', {
                      formData: [...formData],
                      id: this.$root._route.params.id
                    }, {root: true});
                }
            },
        },
        async beforeMount() {
            await this.$store.dispatch('getScheduleData', {id: this.$root._route.params.id}, {root: true});
            this.haveData=true;
        },
    }
</script>