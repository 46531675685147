<template>
    <div>
        <template v-if="haveData">
            <GhTableCommon
                :extratable="{
                    id: 'table_evaluation_'+id,
                }"
                :header="header"
                :data="TabEvaluation.data[id]"
            >
                <template v-slot:field_redirect="{itemProp, labelProp}">
                    <a class="table-cell_link T13" @click="Global.windowOpen(Global.openSecondWindow('evaluations_id', {id:itemProp.id, background: true}),'evaluate')">
                        {{itemProp[labelProp]}}
                    </a>
                </template>
            </GhTableCommon>
        </template>
        <template v-else-if="!haveData">
            <img :src="require('../../../assets/gh_new_dessign/ajax-loader_blue.gif')">
        </template>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    import {mapState} from "vuex";

    export default {
        name: "table_evaluations",
        components: {
            GhTableCommon
        },
        computed: {
            ...mapState(['TabEvaluation'])
        },
        props:['id'],
        data(){
            return{
                haveData: false,
                header: [
                    {text: this.$t('company'), field: 'business', sorting: true, urlRedirect: true, typeSort: 'code', style: 'min-width: 100px; width: 80%;'},
                    {text: this.$t('date'), field: 'date', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 10%;'},
                    {text: this.$t('state'), field: 'state', sorting: true, typeSort: 'string', style: 'max-width: 80px; width: 10%;'},
                ]
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getEvaluationsByIdSection', {id: this.$props.id}, {root: true});
            this.haveData = true;
        }
    }
</script>