var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.haveData)?_c('GhTableCommon',{attrs:{"extratable":{
            id: 'exercises',
        },"header":_vm.header,"data":_vm.ConfigExercisesTable.records_data.length == 0 ? [] : _vm.ConfigExercisesTable.records_data},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('router-link',{attrs:{"to":{name: 'config_exercise_id_view', params: { id: itemProp.id, view: true}}}},[_c('a',{staticClass:"table-cell_link T13"},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])])]}},{key:"actions",fn:function({itemProp}){return [_c('router-link',{attrs:{"to":{name: 'config_exercise_id', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                        id: 'edit_config_exercises',
                        text: _vm.$t('modify'),
                        icon: require('../../../../assets/gh_new_dessign/edit.svg'),
                    }}})],1),_c('GhAction',{attrs:{"dataction":{
                    id: 'delete_config_exercises',
                    text: _vm.$t('delete'),
                    icon: require('../../../../assets/gh_new_dessign/trash.svg'),
                }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','Exercise', itemProp.id,{'name': itemProp.name, 'description': itemProp.description}, _vm.$t('delete_exercise'), _vm.$t('preparing_delete_exercise'), 'delete')}}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'config_exercise'}}},[_c('GhButton',{attrs:{"datainput":{
                            id: 'btn_add_config_exercises',
                            text: _vm.$t('add')
                        }}})],1)],1)]},proxy:true}],null,false,2852947372)}):(!_vm.haveData)?[_c('img',{attrs:{"src":require('../../../../assets/gh_new_dessign/ajax-loader_blue.gif')}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }