<template>
    <div v-if="haveData == true">
        <div class="gh-content-global">
            <div class="gh-row-content">
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('telephone') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 20%; min-width: 100px;">{{TabAction && TabAction.data_company && TabAction.data_company.telephone ? TabAction.data_company.telephone : ''}}</label>
            </div>
            <input type="hidden" name="type" value="call">
            <div class="gh-row-content">
                <GhDataSelector
                    :datablock="{
                        id: 'label_action_call_state',
                        class: 'T15_b',
                        subtitle: $t('select_call_status') + ':',
                        text: $t('call_status'),
                        label: $t('call_status'),
                        type: 'radio',
                        columns: 3,
                        style: 'margin-left:14px;',
                    }"
                    :datainput="{
                        id: 'value_around_language',
                        name: 'state'
                    }"
                    :feed="getFeeds.feed_state_phone_number"
                />
            </div>
            <div class="gh-row-content">
                <GhTextarea
                    :datalabel="{
                        text: $t('comment') ,
                        class: ' T15',
                        styles:'width: 150px; min-width: 150px !important; text-align: right; margin-right: 12px;',
                    }"
                    :datainput="{
                        id: 'exercise_open_question_statement',
                        name: 'note',
                        value:  '',
                    }"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import GhTextarea from "fe-gh-textarea-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapState} from "vuex";
    export default {
        name: "llamada_template",
        components: {
            GhDataSelector,
            GhTextarea
        },
        computed:{
            ...mapState(['getFeeds','TabAction'])
        },
        data(){
            return{
                haveData:false,
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getStatePhoneNumber', {},{root:true});
            this.haveData = true;
        },
    }
</script>
