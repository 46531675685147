import Vue from "vue";
// import {LoginUrl} from "@/common/config";
import vuex from "vuex";
import router from "../../../routes/router"
import {API_URL} from "@/common/config";
import axios from "axios";
// import {API_URL} from "@/common/config";
// import axios from "axios";
// import Global from "../Global";
Vue.use(vuex);
export default {
    state: {
        quizz: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? [] : JSON.parse(localStorage.getItem('MainItinerary')).quizz,
        name_quizz: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? [] : JSON.parse(localStorage.getItem('MainItinerary')).name_quizz,
        name_quizz_send: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? [] : JSON.parse(localStorage.getItem('MainItinerary')).name_quizz_send,
        id_quizz: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? [] : JSON.parse(localStorage.getItem('MainItinerary')).id_quizz,
        data: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? [] : JSON.parse(localStorage.getItem('MainItinerary')).data,
        support: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? [] : JSON.parse(localStorage.getItem('MainItinerary')).support,
        finished: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? [] : JSON.parse(localStorage.getItem('MainItinerary')).finished,
        text_main: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? [] : JSON.parse(localStorage.getItem('MainItinerary')).text_main,
        array_quizz: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? [] : JSON.parse(localStorage.getItem('MainItinerary')).array_quizz,
        actual_page: JSON.parse(localStorage.getItem('MainItinerary')) === null || JSON.parse(localStorage.getItem('MainItinerary')).length === 0 ? 0 : JSON.parse(localStorage.getItem('MainItinerary')).actual_page,
    },
    mutations:{
        LOAD_QUIZZ(state,payload){
            state.quizz = payload;
        },
        LOAD_NAME_QUIZZ(state,payload){
            state.name_quizz = payload;
        },
        LOAD_SEND_NAME_QUIZZ(state,payload){
            state.name_quizz_send = payload;
        },
        LOAD_ID_QUIZZ(state,payload){
            state.id_quizz = payload;
        },
        LOAD_ARRAY_QUIZZ(state,payload){
            state.array_quizz = payload;
        },
        LOAD_ANSWER_QUIZ(state,payload){
            state.data = payload;
        },
        LOAD_SUPPORT_QUIZ(state,payload){
            state.support = payload;
        },
        LOAD_IF_FINISH(state,payload){
            state.finished = payload;
        },
        LOAD_TEXT_MAIN(state,payload){
            state.text_main = payload;
        },
        LOAD_ACTUAL_PAGE(state,payload){
            state.actual_page = payload;
        }
    },
    actions:{
        async getInitialQuizzes(state,item){
            var idtabsearch = '';
            var slug = '';
            var key = 0;

            for(var i=0;i<router.options.routes.length;i++){
                if(router.options.routes[i].name == 'initial_quizz'){
                    idtabsearch = router.options.routes[i].component;
                }
            }

            for(var t=0;t<router.options.GhSubTabs.length;t++){
                if(router.options.GhSubTabs[t].id == item){
                    slug = router.options.GhSubTabs[t];
                    key = t;
                }
            }

            localStorage.setItem('projects_tab_',item+'projects_tab_');
            localStorage.setItem('projects_tab__selected',JSON.stringify(slug));

            var real_id = 0;

            for(var h=0;h<state.state.array_quizz.length;h++){
                if(state.state.array_quizz[h].id == item){
                    real_id = state.state.array_quizz[h].id;
                }
            }

            await state.commit("LOAD_QUIZZ", idtabsearch);
            await state.commit("LOAD_ID_QUIZZ", real_id);
            await state.commit("LOAD_NAME_QUIZZ", router.options.GhSubTabs[key].value);
            await state.commit("LOAD_SEND_NAME_QUIZZ", router.options.GhSubTabs[key].value);
            await router.push({ name: 'quizz_detail', params: { slug: slug.slug } });
        },
        async getSpecificQuizz(state,item){
            state.commit("LOAD_NAME_QUIZZ", item.value);

            var tab_data = '';
            for(var i=0;i < router.options.GhSubTabs.length;i++){
                if(router.options.GhSubTabs[i].value == item.value){
                    tab_data = router.options.GhSubTabs[i];
                }
            }
            var slug = tab_data.slug ;

            await state.dispatch('getQuizzIsFinished', '', {root: true});
            await state.dispatch('getQuizzData', tab_data, {root: true});
            await state.dispatch('getQuizzDataSupport', tab_data, {root: true});
            state.commit("LOAD_ACTUAL_PAGE", 0);
            await router.replace({ name: 'quizz_detail', params: { slug: slug } });
        },
        async getQuizzIsFinished(state){
            await axios.get(
                API_URL + "itinerary/initial/state"
            ).then(
                async response => {
                    await state.commit("LOAD_IF_FINISH", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuizzData(state,item){
            var real_id = 0;

            for(var i=0;i < state.state.array_quizz.length;i++){
                if(state.state.array_quizz[i].id == item.id){
                    real_id = state.state.array_quizz[i].id;
                }
            }
            state.commit("LOAD_ID_QUIZZ", real_id);

            await axios.get(
                API_URL + "itinerary/initial/" + real_id + "/quizz"
            ).then(
                async response => {
                    await state.commit("LOAD_ANSWER_QUIZ", response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async getQuizzDataSupport(state, item){
            var real_id = 0;

            for(var i=0;i < state.state.array_quizz.length;i++){
                if(state.state.array_quizz[i].id == item.id){
                    real_id = state.state.array_quizz[i].id;
                }
            }
            state.commit("LOAD_ID_QUIZZ", real_id);

            await axios.get(
                API_URL + "itinerary/initial/" + real_id + "/support"
            ).then(
                async response => {
                    await state.commit('LOAD_SUPPORT_QUIZ', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        async setQuizzData(state, item){
            let requestLogin = API_URL + (state.state.finished ? 'itinerary/initial/quizz' : 'itinerary/initial/quizz/' + item.id);
            let formData = new FormData();

            formData.append('quizz[finish]', item.finish);
            formData.append('quizz[activity_id]', item.id);
            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        if(state.state.finished.finish){
                            await router.push({name: 'result_quizz'});
                        }else {
                            await state.dispatch('getQuizzIsFinished', '', {root: true});
                            await state.dispatch('getQuizzData', {id: state.state.data.id}, {root: true});
                        }
                    }

                    // if(item.finish){
                    //     await router.push({name: 'result_quizz'});
                    // }
                },
                error => {
                    console.log(error);
                }
            )
        },
        async LOAD_LIST_QUIZZ(state){

            await axios.get(
                API_URL + "list_itinerary"
            ).then(
                async response => {
                    await state.commit('LOAD_ARRAY_QUIZZ', response.data.data);
                },
                error => {
                    console.log(error);
                }
            )
        },
        GET_TEXT_MAIN(state){
            let requestLogin = API_URL + "text_main"

            axios({
                method: "post",
                url: requestLogin,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(
                async response => {
                    // if(response.data.status == '200') {
                    await state.commit("LOAD_TEXT_MAIN", response.data.data.text);
                    // }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
}
