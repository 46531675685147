<template>
    <div v-if="haveData" style="padding-bottom: 20px">
        <GhSelectorPayment
            :dataBlock="{
                id: 'recomended_itineraries',
                name: 'recomended[]',
                title: ' ',
                process_pay_text: $t('paying'),
                btn_text_shop: '',
                btn_text_pay: '',
                btn_text_cancel: '',
                mapState: 'RecomendedItinerary',
                load_type_button: ['loadRecomendedTypeButton', 'typeRecomendedButton'],
                load_process_pay: ['loadRecomendedProcessPay', 'processRecomendedPay'],
                load_items_pay: ['loadRecomendedItemsPay', 'itemsRecomendedPay'],
                dispatch_payment: 'setRecomendedItinerariesPay'
            }"
            :dataCards="RecomendedItinerary.records_data.recomended"
        >
            <template v-slot:infoBox="{itemProp}">
                <div class="T15_b title_explication">
                    {{itemProp.title_explication}}
                </div>
                <div class="T15">
                    {{itemProp.explication}}
                </div>
                <div class="T15_b title_recomendation">
                    {{itemProp.recomendation}}
                </div>
                <div class="T15">
                    {{itemProp.title_recomendation}}
                </div>
            </template>
        </GhSelectorPayment>
    </div>
</template>

<script>
    import GhSelectorPayment from "fe-gh-selector-payment-lib";
    import {mapState} from "vuex";
    export default {
        name: "recomended_itinerary",
        components:{
            GhSelectorPayment,
        },
        data(){
            return {
                haveData: false
            }
        },
        computed: {
            ...mapState(["RecomendedItinerary"]),
        },
        async beforeMount(){
            await this.$store.dispatch('getRecomendedItinerariesToBuy','',{root:true});
            this.haveData = true;
        }
    }
</script>