<template>
    <div :style=" this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData != [] ? 'margin-top: 12px;' : ''">
        <GhTableCommon
            v-if="this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData != []"
            :extratable="{ id:'result_client_data'}" :data="this.$store.state.TabSearch.searchData && this.$store.state.TabSearch.searchData!=[]?this.$store.state.TabSearch.searchData:[]" :header="header">
        </GhTableCommon>
    </div>
</template>

<script>
    import GhTableCommon from "fe-gh-table-common-lib";
    export default {
        name: "result_itineraries",
        components:{GhTableCommon},
        data(){
            return{
                header: [
                    {text: this.$t('projects'), field: 'projects', sorting: true, typeSort: 'string', style: 'max-width: 190px; width: 23%;'},
                    {text: this.$t('phase'), field: 'phases', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 16%;'},
                    {text: this.$t('state'), field: 'state', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 10%;'},
                    {text: this.$t('cost'), field: 'cost', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 10%;'},
                    {text: this.$t('estimated_time'), field: 'estimatedTime', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 9%;'},
                    {text: this.$t('spent_time'), field: 'spentTime', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 9%;'},
                    {text: this.$t('date_start'), field: 'startDate', sorting: true, typeSort: 'string', style: 'max-width: 50px; width: 9%;'},
                    {text: this.$t('date_end'), field: 'endDate', sorting: true, typeSort: 'string', style: 'max-width:50px; width: 9%;'},
                ],
            }
        },
        destroyed() {
            this.$store.dispatch('destroyedResultData')
        },
    }
</script>