var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"10px"}},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                    text: _vm.$t('possible_answers') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'type_exercise_possible_answers',
                    name: 'type_exercise[possible_answers]',
                    style: 'width: 50px',
                    value: _vm.ConfigTypeExercise.data.possible_answers,
                    type: 'int',
                    limitNumMin: 1
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('possible_answers') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data.possible_answers))])]],2),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{text: _vm.$t('feedback'), style: 'width: 130px', id: 'label_type_exercise_feedback'},"datainput":{required: false, type: 'checkbox', id: 'value_type_exercise_feedback', value: 1, name: 'type_exercise[feedback][]',
                selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.feedback && _vm.ConfigTypeExercise.data.config.feedback[0] == 1 ? true : false,
                disabled: !_vm.$root._route.params.view ? false : true
            }}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('subquestion'),
                style: 'width: 130px',
                id: 'label_type_exercise_subquestion'
            },"datainput":{
                required: false,
                type: 'checkbox',
                id: 'value_type_exercise_subquestion',
                value: 1,
                name: 'type_exercise[subquestion][]',
                selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.subquestion && _vm.ConfigTypeExercise.data.config.subquestion[0] == 1 ? true : false,
                disabled: !_vm.$root._route.params.view ? false : true
            }}})],1),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('GhChecks',{attrs:{"datalabel":{
                text: _vm.$t('condition'),
                style: 'width: 130px',
                id: 'label_type_exercise_condition'
            },"datainput":{
                required: false,
                type: 'checkbox',
                id: 'value_type_exercise_condition',
                value: 1,
                name: 'type_exercise[condition][]',
                selected: _vm.ConfigTypeExercise.data.config && _vm.ConfigTypeExercise.data.config.condition && _vm.ConfigTypeExercise.data.config.condition[0] == 1 ? true : false,
                disabled: !_vm.$root._route.params.view ? false : true
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }