<template>
    <div v-if="haveData">
        <GhNoDropSelector :datablock="{id:'activity',title: $t('activity'),second_title:'' }">
            <template v-slot:content>
                <form id="form_activity">
                    <input type="hidden" name="activity[id]" :value="$root._route.params.id">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'activity_code',
                                        name: 'activity[code]',
                                        style: 'width: 200px',
                                        value: code,
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{code}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'activity_name',
                                        name: 'activity[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: name,
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{name}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description') + '*',
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                        class: 'T15_b',
                                    }"
                                    :datainput="{
                                        id: 'activity_description',
                                        name: 'activity[description]',
                                        value: description,
                                        required: true,
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{description}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhMultipleSearch
                                    :datalabel="{
                                        text: $t('responsible'),
                                        style: 'width: 148px; min-width: 148px; text-align: right; margin-right: 12px;',
                                        class: 'T15_b',
                                        id: 'label_exercise_responsible'
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'activity_responsible_user',
                                        name: 'activity[responsible][name]',
                                        nameS:'activity[responsible][surname]',
                                        nameId:'activity[responsible][id]',
                                        style: 'width: 20.5%; min-width: 200px;',
                                        value: responsible_name,
                                        valueId: responsible_id,
                                        valueS: responsible_surname,
                                        placeholder: $t('name'),
                                        placeholderS: $t('surnames'),
                                        result_1: 'first_name',
                                        result_2: 'last_name',
                                        result_id: 'id',
                                        href: dispatchUserResponsible
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('responsible') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{responsible_name + ' ' + responsible_surname}}</label>
                            </template>
                        </div>
                        <div style="margin-bottom: 20px;">
                            <component v-bind:is="excercise" />
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_activity_sector',
                                        class: 'T15_b',
                                        subtitle: $t('select_sector') + ':',
                                        text: $t('sector'),
                                        label: $t('sector'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:102px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_activity_sector',
                                        name: 'activity[sector][]'
                                    }"
                                    :selected_input="selected_sector"
                                    :disabled_input="preSelecteds.pre_selected_sector"
                                    :feed="getFeeds.feed_sector"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('sector') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_sector).join()}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_activity_bibliography',
                                        class: 'T15_b',
                                        subtitle: $t('select_bibliography') + ':',
                                        text: $t('bibliography'),
                                        label: $t('bibliography'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:71px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_activity_bibliography',
                                        name: 'activity[bibliography][]'
                                    }"
                                    :selected_input="selected_bibliography"
                                    :disabled_input="preSelecteds.pre_selected_bibliography"
                                    :feed="getFeeds.feed_bibliography"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('bibliography') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_bibliography).join()}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_activity_videography',
                                        class: 'T15_b',
                                        subtitle: $t('select_videography') + ':',
                                        text: $t('videography'),
                                        label: $t('videography'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:71px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_activity_videography',
                                        name: 'activity[videography][]'
                                    }"
                                    :selected_input="selected_videography"
                                    :disabled_input="preSelecteds.pre_selected_videography"
                                    :feed="getFeeds.feed_videography"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('videography') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_videography).join()}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_activity_example',
                                        class: 'T15_b',
                                        subtitle: $t('select_example') + ':',
                                        text: $t('examples'),
                                        label: $t('examples'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:88px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_activity_example',
                                        name: 'activity[example][]'
                                    }"
                                    :selected_input="selected_example"
                                    :disabled_input="preSelecteds.pre_selected_example"
                                    :feed="getFeeds.feed_examples"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('examples') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_example).join()}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content" :style="$root._route.params.view ? '' : 'margin-bottom: -5px'">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector :key="refreshSelectors"
                                    :datablock="{
                                        id: 'label_activity_support_material',
                                        class: 'T15_b',
                                        subtitle: $t('select_support_material_2') + ':',
                                        text: $t('support_material_2'),
                                        label: $t('support_material_2'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: 'margin-left:19px;',
                                        required: false,
                                    }"
                                    :datainput="{
                                        id: 'value_activity_support_material',
                                        name: 'activity[support_material][]'
                                    }"
                                    :selected_input="selected_support_material"
                                    :disabled_input="preSelecteds.pre_selected_support_material"
                                    :feed="getFeeds.feed_support_material"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('support_material_2') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(selected_support_material).join()}}</label>
                            </template>
                        </div>
                    </div>
                    <component v-bind:is="reference_thesauros" :props="{selecteds:referenceSelecteds,name:'activity'}"/>
                </form>
            </template>
        </GhNoDropSelector>
        <div class="centered" style="margin-top: 20px;">
            <GhButton v-if="!this.$root._route.params.view"
                :datainput="{
                    id: 'btn_save',
                    text: $t('accept'),
                }"
                @click="postActivity();"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="back();"
            />
        </div>
    </div>
</template>

<script>
    import reference_thesauros from "../../../config/exercises/exercises/template/includes/reference_thesauros.vue"
    import GhNoDropSelector from "fe-gh-no-dropselector-lib";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhMultipleSearch from "fe-gh-multiple-search-lib";
    import {mapState} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import excercise from "./excercise";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: "activity_template",
        components:{
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhMultipleSearch,
            GhDataSelector,
            GhButton,
            excercise,
            reference_thesauros,
        },
        computed: {
            ...mapState(['getFeeds','CreationActivity','preSelecteds'])
        },
        data(){
            return {
                haveData: false,
                excercise: excercise,
                reference_thesauros: reference_thesauros,
                refreshSelectors: 0,
                code: '',
                name: '',
                description: '',
                responsible_id: '',
                responsible_name: '',
                responsible_surname: '',
                selected_sector: [],
                selected_bibliography: [],
                selected_videography: [],
                selected_example: [],
                selected_support_material: [],
                dispatchUserResponsible: 'getUserSearch',
                referenceSelecteds: {}
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getFeedSector','',{root:true});
            await this.$store.dispatch('getFeedExamples','',{root:true});
            await this.$store.dispatch('getFeedBibliography','',{root:true});
            await this.$store.dispatch('getFeedVideography','',{root:true});
            await this.$store.dispatch('getFeedSupportMaterial','',{root:true});

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getDataActivity',{id:this.$root._route.params.id},{root:true});

                this.code = this.CreationActivity.data_activity.code;
                this.name = this.CreationActivity.data_activity.name;
                this.description = this.CreationActivity.data_activity.description;
                this.responsible_id = this.CreationActivity.data_activity.responsable.id;
                this.responsible_name = this.CreationActivity.data_activity.responsable.name;
                this.responsible_surname = this.CreationActivity.data_activity.responsable.surname;

                this.selected_sector = this.CreationActivity.data_activity.sector;
                this.selected_bibliography = this.CreationActivity.data_activity.bibliography;
                this.selected_videography = this.CreationActivity.data_activity.videography;
                this.selected_example = this.CreationActivity.data_activity.example;
                this.selected_support_material = this.CreationActivity.data_activity.support_material;
            }else{
                await this.$store.dispatch('resetDataActivity','',{root:true});
            }

            this.haveData = true;
        },
        methods:{
            back(){
                this.$router.go(-1);
            },
            postActivity(){
                if(this.Global.checkRequired('form_activity') == 'OK') {
                    const formData = new FormData(document.getElementById('form_activity'));

                    this.$store.dispatch('setActivity', {
                        formData: [...formData],
                    }, {root: true});
                }
            }
        }
    }
</script>