import SearchList from "@/view/search/list_search";
import result_itineraries from "@/view/search/result_content/result_itineraries";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/search',
        name: i18n.t('searcher'),
        component: SearchList,
        props: {showMenu: true}
    },
    {
        path: '/result_itineraries',
        name: 'result_itineraries',
        component: result_itineraries,
        props: {showMenu: true}
    },
];

export default routes;