var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[(_vm.show)?_c('GhNoDropSelector',{attrs:{"datablock":{id: 'list_business',title: _vm.$t('company_file')}},scopedSlots:_vm._u([(_vm.haveData)?{key:"content",fn:function(){return [(_vm.view)?[_c('ViewBusiness')]:_vm._e(),(!_vm.view)?[_c('EditBusiness')]:_vm._e()]},proxy:true}:null,(_vm.view)?{key:"actions",fn:function(){return [_c('GhAction',{attrs:{"dataction":{
                    id: 'modify_business',
                    text: _vm.$t('modify'),
                    icon: require('../../assets/gh_new_dessign/edit.svg'),
                }},on:{"click":_vm.changeState}})]},proxy:true}:null],null,true)}):_vm._e(),(!_vm.view)?_c('div',{staticClass:"centered_footer",staticStyle:{"margin-top":"8px"}},[_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save'),
            },"type":"submit"},on:{"click":_vm.modifyBusiness}}),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back'),
            }},on:{"click":_vm.changeState}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }