import ListActions from "@/view/actions/list_actions.vue";
import ActionFileCompany from "@/view/actions/template/company_file.vue";
import i18n from "@/plugins/i18n";

const routes = [
    {
        path: '/actions',
        name: i18n.t('actions'),
        component: ListActions,
        props: {showMenu: true},
    },
    {
        path: '/actions/company',
        name: 'company',
        component: ActionFileCompany,
        props: {id: null, showMenu: true,  otherTitle: i18n.t('view_company')},
        children: [
            {
                path: '/actions/company/:id',
                name: 'company_id',
                component: ActionFileCompany,
                props: {id: null, showMenu: true,  otherTitle: i18n.t('view_company')},
            },
            {
                path: '/actions/company/:id/:view/',
                name: 'company_view',
                component: ActionFileCompany,
                props: {id: null, showMenu: true,  otherTitle: i18n.t('view_company')},
            },
        ],

    }
];

export default routes;
